import Immutable from 'seamless-immutable';
import _ from 'lodash';
import moment from 'moment';

import {
  SAVE_LOCKERS,
  APPEND_LOCKERS,
  SET_LOCKERS_PAGINATION_DATA,
  RESET_LOCKERS_PAGINATION_DATA,
  SELECT_LOCKER,
  RESET_LOCKERS_FILTERS,
  SET_LOCKER_FILTER,
  UPDATE_LOCKER,
  SET_LOCKER_OPERATIONAL_MODE,
  SAVE_LOCKERS_LOCKS,
  SET_LOCKER_LOCKS_FILTERED_BY_TAGS,
  APPEND_LOCKER_LOCKS_FILTERED_BY_TAGS,
  SET_LOCKER_LOCKS_FILTERED_BY_TAGS_PAGINATION_DATA,
  SET_LOCKER_ENTRANCE_LOCKS_FILTERED_BY_TAGS,
  APPEND_LOCKER_ENTRANCE_LOCKS_FILTERED_BY_TAGS,
  SET_LOCKER_ENTRANCE_LOCKS_FILTERED_BY_TAGS_PAGINATION_DATA,
  SET_LOCKER_ORDER,
  SAVE_LOCKER_RESERVATIONS,
  APPEND_LOCKER_RESERVATIONS,
  SET_LOCKER_RESERVATIONS_PAGINATION_DATA,
  RESET_LOCKER_RESERVATIONS_PAGINATION_DATA,
  SELECT_LOCKER_RESERVATION,
  RESET_LOCKER_RESERVATIONS_FILTERS,
  SET_LOCKER_RESERVATION_FILTER,
  UPDATE_LOCKER_RESERVATION,
  SET_LOCKER_RESERVATIONS_OPERATIONAL_MODE,
  SAVE_AVAILABLE_LOCKERS,
  SET_AVAILABLE_LOCKERS_PAGINATION_DATA,
  RESET_AVAILABLE_LOCKERS_PAGINATION_DATA,
} from '../actions/actionTypes/locker';

const initialState = Immutable({
  data: {
    content: [],
    filters: {},
    pagination: {},
    sorting: {
      orderBy: undefined,
      orderDir: undefined,
    },
    lastUpdate: 0,
  },
  reservations: {
    content: [],
    filters: {},
    pagination: {},
    sorting: {
      orderBy: undefined,
      orderDir: undefined,
    },
    lastUpdate: 0,
  },
  locks: {
    content: [],
  },
  selectedLocker: {},
  selectedLockerReservation: {},
  isOperationalMode: false,
  isOperationalModeReservations: false,
  availableLockers: [],
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_LOCKERS:
      return state.setIn(['data', 'content'], action.lockers).setIn(['data', 'lastUpdate'], moment().valueOf());
    case APPEND_LOCKERS:
      return state.setIn(['data', 'content'], [...state.data.content, ...action.lockers]).setIn(['data', 'lastUpdate'], moment().valueOf());
    case SET_LOCKERS_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], action.pagination);
    case RESET_LOCKERS_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], {});
    case SAVE_AVAILABLE_LOCKERS:
      return state.setIn(['availableLockers'], action.lockers);
    case SELECT_LOCKER:
      return state.set('selectedLocker', action.locker);
    case RESET_LOCKERS_FILTERS:
      return state.setIn(['data', 'filters'], {});
    case SET_LOCKER_FILTER:
      return state.setIn(['data', 'filters', action.name], action.value);
    case UPDATE_LOCKER: {
      const index = _.findIndex(state.data.content, data => data.id === action.locker.id);
      if (index !== -1) {
        return state.setIn(['data', 'content', index], action.locker);
      }
      return state;
    }
    case SET_LOCKER_OPERATIONAL_MODE:
      return state.set('isOperationalMode', action.value);
    case SET_LOCKER_RESERVATIONS_OPERATIONAL_MODE:
      return state.set('isOperationalModeReservations', action.value);
    case SAVE_LOCKERS_LOCKS:
      return state.setIn(['locks', 'content'], action.locks);
    case SET_LOCKER_LOCKS_FILTERED_BY_TAGS:
      return state.setIn(['selectedLocker', 'locksFilteredByTags', action.index, 'data'], action.locks);
    case APPEND_LOCKER_LOCKS_FILTERED_BY_TAGS:
      return state.setIn(['selectedLocker', 'locksFilteredByTags', action.index, 'data'],
        [...state.selectedLocker.locksFilteredByTags[action.index].data, ...action.locks]);
    case SET_LOCKER_LOCKS_FILTERED_BY_TAGS_PAGINATION_DATA:
      return state.setIn(['selectedLocker', 'locksFilteredByTags', action.index, 'pagination'], action.pagination);
    case SET_LOCKER_ENTRANCE_LOCKS_FILTERED_BY_TAGS:
      return state.setIn(['selectedLocker', 'locksFilteredByTagsEntrance', action.index, 'data'], action.locks);
    case APPEND_LOCKER_ENTRANCE_LOCKS_FILTERED_BY_TAGS:
      return state.setIn(['selectedLocker', 'locksFilteredByTagsEntrance', action.index, 'data'],
        [...state.selectedLocker.locksFilteredByTagsEntrance[action.index].data, ...action.locks]);
    case SET_LOCKER_ENTRANCE_LOCKS_FILTERED_BY_TAGS_PAGINATION_DATA:
      return state.setIn(['selectedLocker', 'locksFilteredByTagsEntrance', action.index, 'pagination'], action.pagination);
    case SET_LOCKER_ORDER:
      return state.setIn(['data', 'sorting', 'orderBy'], action.orderBy)
                  .setIn(['data', 'sorting', 'orderDir'], action.orderDir);
    case SAVE_LOCKER_RESERVATIONS:
      return state.setIn(['reservations', 'content'], action.reservations).setIn(['reservations', 'lastUpdate'], moment().valueOf());
    case APPEND_LOCKER_RESERVATIONS:
      return state.setIn(['reservations', 'content'], [...state.reservations.content, ...action.reservations]).setIn(['reservations', 'lastUpdate'], moment().valueOf());
    case SET_LOCKER_RESERVATIONS_PAGINATION_DATA:
      return state.setIn(['reservations', 'pagination'], action.pagination);
    case RESET_LOCKER_RESERVATIONS_PAGINATION_DATA:
      return state.setIn(['reservations', 'pagination'], {});
    case SELECT_LOCKER_RESERVATION:
      return state.set('selectedLockerReservation', action.reservation);
    case RESET_LOCKER_RESERVATIONS_FILTERS:
      return state.setIn(['reservations', 'filters'], {});
    case SET_LOCKER_RESERVATION_FILTER:
      return state.setIn(['reservations', 'filters', action.name], action.value);
    case UPDATE_LOCKER_RESERVATION: {
      const index = _.findIndex(state.reservations.content, reservations => reservations.id === action.reservation.id);
      if (index !== -1) {
        return state.setIn(['reservations', 'content', index], action.reservation);
      }
      return state;
    }
    default:
      return state;
  }
}
