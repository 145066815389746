import React from 'react';
import moment from 'moment';
import WarningIcon from '@material-ui/icons/Warning';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import CardIcon from '@material-ui/icons/CreditCard';
import UserEditIcon from '@material-ui/icons/BorderColor';
import { getStandardDeviceIcon } from '../../../_config/utils';

export default class StandardDeviceUpdateEventRow extends React.Component {
  render() {
    const { event: { id, timestamp, actorId, data: { actor, entity } }, isAnonymized, privateUnitIdActor } = this.props;
    return (
      <div style={{ minHeight: 110, padding: 15, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {entity && entity.deviceType ? getStandardDeviceIcon(entity.deviceType) : <CardIcon style={{ fontSize: 30, marginRight: 25, color: '#3f3f3f' }} />}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h3 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0 }}>
              {entity && entity.deviceType ? (
                <Entity entity="standardDevicesUpdateEventTitle" data={{ type: entity.deviceType }} />
              ) : (
                <Entity key={id} entity="credentialRuleUpdate" />
              )}
            </h3>
            <h4 style={{ color: '#3f3f3f', lineHeight: '1.5em', fontWeight: '100', margin: 0, marginTop: 10 }}>
              {isAnonymized ? (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <WarningIcon style={{ color: 'orange', marginRight: 10, fontSize: 20 }} />
                  <h4 style={{ color: '#3f3f3f', lineHeight: '1.5em', fontWeight: '100', margin: 0 }}>
                    <Entity
                      key={id}
                      entity="adminEventRowAnonymizedDescription"
                    />
                  </h4>
                </div>
                ) : (
                  <Entity
                    key={id}
                    entity="standardDeviceUpdateDescription"
                    data={{
                      actor: actorId ? `${actor.firstname || ''} ${actor.lastname || ''} ${actor.email || ''}` : '---',
                      entity: entity.deviceId || entity.entityId || '',
                    }}
                  />
                )}
              {entity && entity.deviceType ? <Entity style={{ marginLeft: 5 }} entity="standardDevicesTypes" data={{ type: entity.deviceType }} /> : null}
            </h4>
          </div>
        </div>
        <h4 style={{ minWidth: 240, textAlign: 'right', marginTop: 10, color: '#3f3f3f' }}>{moment(timestamp).format('LLL')}</h4>
      </div>
    );
  }
}