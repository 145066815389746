import React, { Component } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import { BLUE, PDF_TEMPLATE_VARIABILES } from '../../_config/consts';
import { generatePdfAsCanvasFromTemplate } from '../../_config/utils';
import MDButton from '../MDButton/MDButton.jsx';
import { Entity, ctx as L20NContext  } from '@sketchpixy/rubix/lib/L20n';
import { quillSnowCss } from './quillcss.jsx';

const InlineBlot = Quill.import('blots/inline');
class NonEditableSpanBlot extends InlineBlot {
  static create(value) {
    const node = super.create();
    node.setAttribute('style', `background-color: ${BLUE}; color: white; padding: 2px 5px; user-select: none; font-weight: bold; border-radius: 5px;`);
    node.setAttribute('contenteditable', 'false');
    node.textContent = value || 'PLACEHOLDER';
    return node;
  }
  static value(node) {
    return node.textContent;
  }
}
NonEditableSpanBlot.blotName = 'nonEditableSpan';
NonEditableSpanBlot.tagName = 'span';
NonEditableSpanBlot.className = 'non-editable-span';
Quill.register(NonEditableSpanBlot);

    
const editorContainerStyle = {
  width: `${PDF_TEMPLATE_VARIABILES.A4_WIDTH_PT}pt`,
  margin: '0 auto',
  backgroundColor: '#fff',
  boxShadow: '0 0 10px rgba(0,0,0,0.1)',
};

const editorStyle = {
  paddingTop: `${PDF_TEMPLATE_VARIABILES.MARGIN_TOP_PT}pt`,
  paddingLeft: `${PDF_TEMPLATE_VARIABILES.MARGIN_LATERAL_PT}pt`,
  paddingRight: `${PDF_TEMPLATE_VARIABILES.MARGIN_LATERAL_PT}pt`,
  paddingBottom: `${PDF_TEMPLATE_VARIABILES.MARGIN_BOTTOM_PT}pt`,
  minHeight: `${PDF_TEMPLATE_VARIABILES.A4_HEIGHT_PT}pt`,
  boxSizing: 'border-box'
};


const CUSTOM_QUILL_CSS = `
  .ql-editor {
    font-size: 11pt !important;
    line-height: 1.2;
    padding: 0;
    background-color: white;
    border: 2px dashed #ccc;
    min-height: ${PDF_TEMPLATE_VARIABILES.A4_HEIGHT_PT - PDF_TEMPLATE_VARIABILES.MARGIN_TOP_PT - PDF_TEMPLATE_VARIABILES.MARGIN_BOTTOM_PT}pt;
    font-family: 'Oxygen' !important;
  }
  .ql-editor p {
    font-size: 11pt !important;
  }
  .ql-container {
    
  }
  .ql-editor h1 {
    font-size: 24pt !important;
  }
  .ql-editor h2 {
    font-size: 20pt !important;
  }
  .ql-editor h3 {
    font-size: 16pt !important;
  }
  .ql-editor .ql-size-small {
    font-size: 9pt !important;
  }
  .ql-editor .ql-size-large {
    font-size: 14pt !important;
  }
  .ql-editor .ql-size-huge {
    font-size: 20pt !important;
  }
`;




const CustomToolbar = ({ id, customPlaceholders, quill }) => {
  const insertCustomPlaceholder = (placeholderText) => {
    if (quill) {
      const range = quill.getSelection(true);
      if (range) {
        quill.insertEmbed(range.index, 'nonEditableSpan', placeholderText, Quill.sources.USER);
        quill.setSelection(range.index + 0);
      }
    }
  };

  return (
    <div id={`toolbar-${1}`} style={{position:'sticky', top:0, zIndex:1, backgroundColor:'white'}}>
      <div>
        <select className="ql-size"></select>
        <button className="ql-bold"></button>
        <button className="ql-italic"></button>
        <button className="ql-underline"></button>
        <button className="ql-strike"></button>
        <select className="ql-color"></select>
        <select className="ql-background"></select>
        <button className="ql-link"></button>
        {false?<button className="ql-header" value="1"></button>:null}
        {false?<button className="ql-header" value="2"></button>:null}
        {false?<button className="ql-header" value="3"></button>:null}
        <button className="ql-list" value="ordered"></button>
        <button className="ql-list" value="bullet"></button>
        <select className="ql-align"></select>
        <button className="ql-clean" title="Reset format"></button>
      </div>
      {customPlaceholders && customPlaceholders.length ? (
        <div style={{ marginTop: '5px', borderTop: '1px solid #ccc', paddingTop: '5px', display:'flex', flexDirection:'row', width:'770px', flexWrap:'wrap' }}>
          {customPlaceholders.map((placeholder, index) => (
            <button
              key={index}
              className="ql-customPlaceholder"
              onClick={(e) => {e.stopPropagation();e.preventDefault();insertCustomPlaceholder(placeholder.value)}}
              style={{
                margin: '0 4px',
                backgroundColor: BLUE, color:'white', fontWeight:'bold',
                border: '1px solid #ccc',
                borderRadius: '3px',
                cursor: 'pointer', float:'unset', width:'fit-content', height:'26px', padding:'2px', 
              }}
            >
              {placeholder.value}
            </button>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default class TemplateEditor extends Component {
  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
    this.state = {
      quill: null,
      exporting: false,
    };
  }

  componentDidMount() {
    const { customPlaceholders } = this.props;
    const customPlaceholdersParsed = (customPlaceholders || []).map(e=> {
      return {
        ...e,
        value: L20NContext.getSync(e.value),
      }
    })
    this.setState({ customPlaceholdersParsed })

    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = CUSTOM_QUILL_CSS;
    document.head.appendChild(styleSheet);

    const quill = new Quill(this.editorRef.current, {
      theme: 'snow',
      modules: {
        toolbar: {
          container: `#toolbar-${1}`,
          handlers: {
            'nonEditablePlaceholder': this.insertNonEditablePlaceholder,
          }
        },
        keyboard: {
          bindings: {}
        }
      }
    });
    this.setState({ quill }, () => {
      const editorContent = quill.container.querySelector('.ql-editor');
      if (this.props.initialContent) {
        if (this.props.isGenerateMode) {
          const parsedInitialContent = this.replacePlaceholders(this.props.initialContent)
          editorContent.innerHTML = parsedInitialContent;
        }
        else {
          editorContent.innerHTML = this.props.initialContent;
        }
        quill.update();
      }
      /*
      if (this.props.initialContent) {
        quill.clipboard.dangerouslyPasteHTML(this.props.initialContent);
      }
      */
    });
  }
  
  insertNonEditablePlaceholder = () => {
    const { quill } = this.state;
    if (quill) {
      const range = quill.getSelection(true);
      if (range) {
        quill.insertEmbed(range.index, 'nonEditableSpan', 'PLACEHOLDER', Quill.sources.USER);
      }
    }
  };

  getEditorContent = () => {
    return this.state.quill ? this.state.quill.root.innerHTML : '';
  };

  replacePlaceholders = (editorContent) => {
    const { customPlaceholders, placeholderObjRef } = this.props
    const { customPlaceholdersParsed } = this.state
    const customPlaceholdersMapped = customPlaceholders ? customPlaceholdersParsed.map((placeholder) => {
      const foundPlaceholder = customPlaceholders.find(e=>L20NContext.getSync(e.value)===placeholder.value)
      return {...(foundPlaceholder || {}), value: placeholder.value}
    }) : customPlaceholdersParsed
    return editorContent.replace(
      /<span class="non-editable-span"[^>]*>(.*?)<\/span>/g,
      (_, content) => {
        const foundPlaceholder = customPlaceholdersMapped.find(item => item.value === content);
        if (foundPlaceholder && foundPlaceholder.objFunc && placeholderObjRef)
          return foundPlaceholder.objFunc(placeholderObjRef)
        return content;
      }
    );
  }

  generatePDF = async () => {
    const { fileName } = this.props;
    const { quill } = this.state;
    try {
      this.setState({ exporting: true })
      const editorContent = quill.container.querySelector('.ql-editor').innerHTML.replaceAll("<br>","<br></br>");
      const parsedContent = this.replacePlaceholders(editorContent)
      const htmlContent = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
          <meta charset="UTF-8"></meta>
          <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
          <title>PDF Content</title>
          <link href="https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700" rel="stylesheet"></link>
          <style>
            ${CUSTOM_QUILL_CSS.replace("border: 2px dashed #ccc;","border: 0 !important;")}
            ${quillSnowCss.toString()}
            ${".ql-snow {border: 0 !important;}"}
          </style>
        </head>
        <body>
          <div class="ql-container ql-snow">
            <div class="ql-editor">
              ${parsedContent}
            </div>
          </div>
        </body>
        </html>
      `;
      // TODO await generatePdfAsCanvasFromTemplate(editorContent, fileName);

      const blob = new Blob([htmlContent], { type: 'text/html' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'test.html';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      this.setState({ exporting: false })
    } catch (error) {
      console.error('Errore nella generazione del PDF:', error);
    }
  };

  render() {
    const { containerStyle, isGenerateMode } = this.props;
    const { exporting, quill, customPlaceholdersParsed } = this.state;

    return (
      <div style={{ ...(containerStyle || {})}}>
        <div style={editorContainerStyle}>
          <CustomToolbar id={1} customPlaceholders={isGenerateMode?[]:customPlaceholdersParsed} quill={quill} isGenerateMode={isGenerateMode}></CustomToolbar>
          <div
            ref={this.editorRef}
            style={editorStyle}
            quill={this.state.quill}
          />
          <MDButton
            containerStyle={{ margin:0, marginBottom:10, position:'sticky', bottom:0 }}
            backgroundColor={BLUE}
            disabled={exporting}
            title={<Entity entity={isGenerateMode?"templatePdfDownload":"templatePdfPreview"} />}
            onClick={(e) => {e.stopPropagation();e.preventDefault();this.generatePDF()}}
          />
        </div>
      </div>
    );
  }
}