import Immutable from 'seamless-immutable';
import _ from 'lodash';
import moment from 'moment';

import {
  SAVE_PRIVATE_UNITS,
  APPEND_PRIVATE_UNITS,
  SET_PRIVATE_UNITS_PAGINATION_DATA,
  RESET_PRIVATE_UNITS_PAGINATION_DATA,
  
  SAVE_PRIVATE_UNIT_STANDARD_DEVICES,

  SAVE_PRIVATE_UNITS_DEFAULT,

  SELECT_PRIVATE_UNIT,

  RESET_PRIVATE_UNITS_FILTERS,
  SET_PRIVATE_UNIT_FILTER,

  UPDATE_PRIVATE_UNIT,

  SET_PRIVATE_UNIT_OPERATIONAL_MODE,

  SET_PRIVATE_UNIT_LOCKS_FILTERED_BY_TAGS,
  APPEND_PRIVATE_UNIT_LOCKS_FILTERED_BY_TAGS,
  SET_PRIVATE_UNIT_LOCKS_FILTERED_BY_TAGS_PAGINATION_DATA,
  
  SAVE_ALL_PRIVATE_UNITS,

  SET_PRIVATE_UNIT_ORDER,

  SAVE_PRIVATE_UNIT_TEMPLATES,
  UPDATE_PRIVATE_UNIT_TEMPLATE,
} from '../actions/actionTypes/privateUnit';

const initialState = Immutable({
  data: {
    content: [],
    filters: {},
    pagination: {},
    sorting: {
      orderBy: undefined,
      orderDir: undefined,
    },
    lastUpdate: 0,
  },
  allPrivateUnits: [],
  selectedPrivateUnit: {},
  selectedPrivateUnitStandardDevices: [],
  defaultConfig: {},
  templates: {
    content: [],
    filters: {},
    pagination: {},
    sorting: {
      orderBy: undefined,
      orderDir: undefined,
    },
    lastUpdate: 0,
  },
  isOperationalMode: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_PRIVATE_UNITS:
      return state.setIn(['data', 'content'], action.privateUnits).setIn(['data', 'lastUpdate'], moment().valueOf());
    case APPEND_PRIVATE_UNITS:
      return state.setIn(['data', 'content'], [...state.data.content, ...action.privateUnits]).setIn(['data', 'lastUpdate'], moment().valueOf());
    case SET_PRIVATE_UNITS_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], action.pagination);
    case RESET_PRIVATE_UNITS_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], {});
      
    case SAVE_PRIVATE_UNIT_STANDARD_DEVICES:
      return state.setIn(['selectedPrivateUnitStandardDevices'], action.standardDevices);
    
    case SAVE_PRIVATE_UNITS_DEFAULT:
      return state.set('defaultConfig', action.privateUnitsDefault);
    
    case SELECT_PRIVATE_UNIT:
      return state.set('selectedPrivateUnit', action.privateUnit);
    
    case RESET_PRIVATE_UNITS_FILTERS:
      return state.setIn(['data', 'filters'], {});
    case SET_PRIVATE_UNIT_FILTER:
      return state.setIn(['data', 'filters', action.name], action.value);
    
    case UPDATE_PRIVATE_UNIT: {
      const index = _.findIndex(state.data.content, data => data.id === action.privateUnit.id);
      if (index !== -1) {
        return state.setIn(['data', 'content', index], action.privateUnit);
      }
      return state;
    }

    case SET_PRIVATE_UNIT_OPERATIONAL_MODE:
      return state.set('isOperationalMode', action.value);
    
    case SET_PRIVATE_UNIT_LOCKS_FILTERED_BY_TAGS:
      return state.setIn(['selectedPrivateUnit', 'locksFilteredByTags', action.index, 'data'], action.locks);
    case APPEND_PRIVATE_UNIT_LOCKS_FILTERED_BY_TAGS:
      return state.setIn(['selectedPrivateUnit', 'locksFilteredByTags', action.index, 'data'],
        [...state.selectedPrivateUnit.locksFilteredByTags[action.index].data, ...action.locks]);
    case SET_PRIVATE_UNIT_LOCKS_FILTERED_BY_TAGS_PAGINATION_DATA:
      return state.setIn(['selectedPrivateUnit', 'locksFilteredByTags', action.index, 'pagination'], action.pagination);
    
    case SAVE_ALL_PRIVATE_UNITS:
      return state.set('allPrivateUnits', action.privateUnits);
    
    case SET_PRIVATE_UNIT_ORDER:
      return state.setIn(['data', 'sorting', 'orderBy'], action.orderBy)
                  .setIn(['data', 'sorting', 'orderDir'], action.orderDir);
                  
    case SAVE_PRIVATE_UNIT_TEMPLATES:
      return state.setIn(['templates', 'content'], action.templates).setIn(['data', 'lastUpdate'], moment().valueOf());
    
    case UPDATE_PRIVATE_UNIT_TEMPLATE: {
      const index = _.findIndex(state.templates.content, data => data.id === action.template.id);
      if (index !== -1) {
        return state.setIn(['templates', 'content', index], action.template);
      }
      return state;
    }
    
    default:
      return state;
  }
}
