import {Form} from '@sketchpixy/rubix';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { initialize, submit } from 'redux-form';
import { Field, reduxForm } from 'redux-form';
import FormCardContainer from './Elements/FormCardContainer.jsx';
import TagsSelectorListField from './Elements/TagsSelectorListField.jsx';
import CustomField from './Fields/CustomField.jsx';
import * as PrivateUnitActions from '../../redux/actions/privateUnit.actions';
import * as ModalActions from '../../redux/actions/modal.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import PrivateUnitTemplatesListView from '../PrivateUnits/PrivateUnitTemplatesListView.jsx';
import OperationalView from '../OperationalView/OperationalView.jsx';
import PrivateUnitTemplateForm from './PrivateUnitTemplateForm.jsx';
import { GREEN, LANGUAGES, PRIVATE_UNIT_TEMPLATE_LANGUAGES, PRIVATE_UNIT_TEMPLATE_TYPES } from '../../_config/consts.js';
import MDButton from '../MDButton/MDButton.jsx';

const validate = (values) => {
  const errors = {};
  
  if (values) {    
    if (!values.maxUsers||parseInt(values.maxUsers, 10)<=0) {
      errors.maxUsers = 'required';
    }
    if (!values.maxSmartLocks||parseInt(values.maxSmartLocks, 10)<=0) {
      errors.maxSmartLocks = 'required';
    }
    if (!values.maxInvitations||parseInt(values.maxInvitations, 10)<=0) {
      errors.maxInvitations = 'required';
    }
    if (!values.maxPins||parseInt(values.maxPins, 10)<=0) {
      errors.maxPins = 'required';
    }
  }

  return errors;
};

@reduxForm({ form: 'PrivateUnitsDefaultForm', validate })
@connect(state => ({ form: state.form.PrivateUnitsDefaultForm, privateUnits: state.privateUnit, themeName: state.settings.items.theme.data.themeName }))
class PrivateUnitsDefaultForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isFormVisible: false,
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    this.onFetchTemplates();
  }

  async onFetchTemplates() {
    const { dispatch } = this.props;
    try {
      await dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(PrivateUnitActions.fetchPrivateUnitTemplates());
    } catch (error) {
      
    }
    await dispatch(UtilsActions.setSpinnerVisibile(false));
  }

  onCreateNewTemplate() {
    const { dispatch } = this.props;
    dispatch(initialize('PrivateUnitTemplateForm', {language: PRIVATE_UNIT_TEMPLATE_LANGUAGES[LANGUAGES.ENGLISH].value}));
    this.setState({ isFormVisible: true });
  }

  onTemplateClick(template) {
    const { dispatch } = this.props;
    dispatch(initialize('PrivateUnitTemplateForm', template));
    this.setState({ isFormVisible: true });
  }

  onDeleteTemplateRequest(template) {
    const { dispatch } = this.props;
    dispatch(initialize('PrivateUnitTemplateForm', template));
    this.setState({ isFormVisible: true });
  }

  onDeleteTemplateRequest(template) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        message: 'deletePrivateUnitTemplateConfirmation',
        onConfirmText: <Entity entity="yes" />,
        onConfirm: async () => {
          try {
            await dispatch(PrivateUnitActions.deletePrivateUnitTemplate(template.id))
            await dispatch(ModalActions.hideModal())
            this.setState({ isFormVisible: false });
            this.onFetchTemplates()
          }
          catch (error) {

          }
        },
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onPrivateUnitTemplateFormSubmit(formData) {
    const { dispatch } = this.props;
    const isEditing = formData.id;
    const data = {
      ...formData,
      type: PRIVATE_UNIT_TEMPLATE_TYPES.ONBOARDING,
    };
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      if (isEditing) {
        await dispatch(PrivateUnitActions.editPrivateUnitTempalte(formData.id, data));
      } else {
        await dispatch(PrivateUnitActions.createPrivateUnitTemplate(data));
      }
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity key={isEditing} entity={'privateUnitTemplateEditSuccess'} /></h6>),
        },
      }));
      this.setState({ isFormVisible: false });
      this.onFetchTemplates();
    } catch (error) {
      console.log(error)
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity key={isEditing} entity={'privateUnitTemplateEditError'} /></h6>),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  render() {
    const {
      dispatch,
      form,
      availableLockTags,
      onLockTagsChange,
      locksFilteredByTags,
      onLockFilterModeChange,
      onLockInputChange,
      onFetchMoreLocks,
      themeName,
      privateUnits: { templates: { content: templatesData } }
    } = this.props;
    const { isFormVisible } = this.state;

    return (
      <Form>
        <FormCardContainer
          title={'privateUnitsOperationalBasicDataDefault'}
          headerStyle={{ marginBottom: 20 }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20}}>
            <div style={{ display: 'flex', flexDirection: 'column', flexGrow:1}}>
              <Entity
                componentClass={Field}
                name="maxUsers"
                componentAttribute="placeholder"
                entity="privateUnitsOperationalMaxUsersInsert"
                title={<Entity entity="privateUnitsOperationalMaxUsers" />}
                textFormattingFunction={value => value.replace(/[\s.]+/g, '')}
                containerStyle={{ width: 200 }}
                component={CustomField}
                type="number"
                disabled={false}
                mandatory
              />
              <Entity
                componentClass={Field}
                name="maxSmartLocks"
                componentAttribute="placeholder"
                entity="privateUnitsOperationalMaxSmartLocksInsert"
                title={<Entity entity="privateUnitsOperationalMaxSmartLocks" />}
                textFormattingFunction={value => value.replace(/[\s.]+/g, '')}
                containerStyle={{ width: 200 }}
                component={CustomField}
                type="number"
                disabled={false}
                mandatory
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', flexGrow:1}}>
              <Entity
                componentClass={Field}
                name="maxInvitations"
                componentAttribute="placeholder"
                entity="privateUnitsOperationalMaxInvitationsInsert"
                title={<Entity entity="privateUnitsOperationalMaxInvitations" />}
                textFormattingFunction={value => value.replace(/[\s.]+/g, '')}
                containerStyle={{ width: 200 }}
                component={CustomField}
                type="number"
                disabled={false}
                mandatory
              />
              <Entity
                componentClass={Field}
                name="maxPins"
                componentAttribute="placeholder"
                entity="privateUnitsOperationalMaxPinsInsert"
                title={<Entity entity="privateUnitsOperationalMaxPins" />}
                textFormattingFunction={value => value.replace(/[\s.]+/g, '')}
                containerStyle={{ width: 200 }}
                component={CustomField}
                type="number"
                disabled={false}
                mandatory
              />
            </div>
          </div>
        </FormCardContainer>
        <FormCardContainer
          title="privateUnitsOperationalLockDefaultTitle"
          subtitle="privateUnitsOperationalLockDefaultSubtitle"
        >
          <TagsSelectorListField
            formName="PrivateUnitForm"
            name="lockTags"
            selectorFieldName="lockTagMatchingMode"
            selectedTagsFieldName="selectedLocksTags"
            tags={availableLockTags}
            selectedTags={locksFilteredByTags && locksFilteredByTags[0]}
            form={form}
            tagsType="LOCK"
            title="lockTags"
            placeholder="insertLockTagsForPrivateUnit"
            listTitle="selectedLocks"
            emptyTagSelectionText={'noLocksSelectedWithTag'}
            onFetchMoreItems={page => onFetchMoreLocks(page)}
            onTagsChange={values => onLockTagsChange(values)}
            onInputChange={value => onLockInputChange(value)}
            onFilterModeChange={value => onLockFilterModeChange(value)}
            disabled={false}
            isOptional={true}
          />
        </FormCardContainer>
        <FormCardContainer
          title="privateUnitsOperationalTemplatesTitle"
          subtitle="privateUnitsOperationalTemplatesSubtitle"
        >
          {templatesData.length ? (
            <MDButton
              containerStyle={{ margin: 0, marginLeft: 15, marginRight: 10, marginTop: 5, width:'fit-content' }}
              backgroundColor={GREEN}
              title={<Entity entity="createNewPrivateUnitTemplate" />}
              onClick={() => this.onCreateNewTemplate()}
            />
          ):null}
          <div style={{ marginTop: 30 }}>
            <PrivateUnitTemplatesListView
              templatesData={templatesData}
              onRowClick={(template) => this.onTemplateClick(template)}
              onDeleteTemplateRequest={(template) => this.onDeleteTemplateRequest(template)}
              onCreateNew={() => this.onCreateNewTemplate()}
            />
          </div>
        </FormCardContainer>
        <OperationalView
          themeName={themeName}
          isVisible={isFormVisible}
          onClose={() => this.setState({ isFormVisible: false })}
          style={{ margin: 0, padding: 0 }}
          title={<Entity key={1} entity={"privateUnitTemplateForm"} />}
        >
          <div style={{ padding: 10 }}>
            <PrivateUnitTemplateForm
              onPrivateUnitTemplateFormSubmit={formData => this.onPrivateUnitTemplateFormSubmit(formData)}
            />
          </div>
        </OperationalView>
      </Form>
    );
  }
} 

export default PrivateUnitsDefaultForm;
