import _ from 'lodash';
import { Observable } from 'rxjs';
import {
  FETCH_PRIVATE_UNIT_LOCKS_BY_TAGS,
  CANCEL_FETCH_PRIVATE_UNIT_LOCKS_BY_TAGS,
} from '../redux/actions/actionTypes/privateUnit';

import * as RestService from '../_config/rest';
import {
  setLocksFilteredByTagsEpic,
} from '../redux/actions/privateUnit.actions';
import { rxFetch } from './utils';

export const fetchPrivateUnitsLocksByTagsEpic = (action$, store) =>
  action$.ofType(FETCH_PRIVATE_UNIT_LOCKS_BY_TAGS)
    .debounceTime(500)
    .switchMap((action) => {
      const { lockTagMatchingMode, page, append, index } = action;
      let tags = action.tags;
      if (!_.isArray(tags)) {
        tags = [tags];
      }

      if (tags && tags.length) {
        const params = {
          tagIds: _.map(tags, tag => tag.id || tag),
          page,
          lockTagMatchingMode,
        };

        return rxFetch(RestService.fetchLocksByTags(params))
          .map((response) => {
            if (response.data && response.data.content) {
              const locks = response.data.content;
              const pagination = _.omit(response.data, 'content');
              return setLocksFilteredByTagsEpic(locks, index, pagination, append);
            }
            return setLocksFilteredByTagsEpic([], index);
          })
          .takeUntil(action$.ofType(CANCEL_FETCH_PRIVATE_UNIT_LOCKS_BY_TAGS))
          .catch(() => Observable.empty());
      }
      return Observable.of(setLocksFilteredByTagsEpic([], index));
    });
