import WarningIcon from '@material-ui/icons/Warning';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import moment from 'moment';
import React from 'react';
import { ADMIN_EVENTS_MAP } from '../../../_config/consts';
import SmartLockAddIconCustom from '../../CustomIcons/SmartLockAddIconCustom.jsx';


export default class SmartLockCreationRow extends React.Component {
  render() {
    const { event: { id, actorId, eventType, timestamp, data: { actor, entity } }, isAnonymized, privateUnitIdActor } = this.props;
    const rowVariables = ADMIN_EVENTS_MAP[eventType] || null;
    if (!rowVariables) return null;
    return (
      <div style={{ minHeight: 110, padding: 15, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <SmartLockAddIconCustom style={{ width: 27, marginRight: 30, color: '#3f3f3f' }} />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h3 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0 }}>
              <Entity key={id} entity={rowVariables.title} />
            </h3>
            <h4 style={{ color: '#3f3f3f', lineHeight: '1.5em', fontWeight: '100', margin: 0, marginTop: 10 }}>
              {isAnonymized ? (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <WarningIcon style={{ color: 'orange', marginRight: 10, fontSize: 20 }} />
                  <h4 style={{ color: '#3f3f3f', lineHeight: '1.5em', fontWeight: '100', margin: 0 }}>
                    <Entity
                      key={id}
                      entity="adminEventRowAnonymizedDescription"
                    />
                  </h4>
                </div>
              ) : (
                <Entity
                  key={id}
                  entity={rowVariables.body}
                  data={{
                    actor: actorId ? `${actor.firstname || ''} ${actor.lastname || ''} ${actor.email || ''}` : '---',
                    entity: `${entity.name || ''}`,
                  }}
                />
              )}
            </h4>
          </div>
        </div>
        <h4 style={{ minWidth: 240, textAlign: 'right', marginTop: 10, color: '#3f3f3f' }}>{moment(timestamp).format('LLL')}</h4>
      </div>
    );
  }
}