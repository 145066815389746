module.exports = {
  SAVE_PRIVATE_UNITS: 'SAVE_PRIVATE_UNITS',
  APPEND_PRIVATE_UNITS: 'APPEND_PRIVATE_UNITS',
  SET_PRIVATE_UNITS_PAGINATION_DATA: 'SET_PRIVATE_UNITS_PAGINATION_DATA',
  RESET_PRIVATE_UNITS_PAGINATION_DATA: 'RESET_PRIVATE_UNITS_PAGINATION_DATA',

  SAVE_PRIVATE_UNIT_STANDARD_DEVICES: 'SAVE_PRIVATE_UNIT_STANDARD_DEVICES',

  SAVE_PRIVATE_UNITS_DEFAULT: 'SAVE_PRIVATE_UNITS_DEFAULT',

  SELECT_PRIVATE_UNIT: 'SELECT_PRIVATE_UNIT',

  RESET_PRIVATE_UNITS_FILTERS: 'RESET_PRIVATE_UNITS_FILTERS',
  SET_PRIVATE_UNIT_FILTER: 'SET_PRIVATE_UNIT_FILTER',

  UPDATE_PRIVATE_UNIT: 'UPDATE_PRIVATE_UNIT',

  SET_PRIVATE_UNIT_OPERATIONAL_MODE: 'SET_PRIVATE_UNIT_OPERATIONAL_MODE',
  
  FETCH_PRIVATE_UNIT_LOCKS_BY_TAGS: 'FETCH_PRIVATE_UNIT_LOCKS_BY_TAGS',
  CANCEL_FETCH_PRIVATE_UNIT_LOCKS_BY_TAGS: 'CANCEL_FETCH_PRIVATE_UNIT_LOCKS_BY_TAGS',
  SET_PRIVATE_UNIT_LOCKS_FILTERED_BY_TAGS: 'SET_PRIVATE_UNIT_LOCKS_FILTERED_BY_TAGS',
  APPEND_PRIVATE_UNIT_LOCKS_FILTERED_BY_TAGS: 'APPEND_PRIVATE_UNIT_LOCKS_FILTERED_BY_TAGS',
  SET_PRIVATE_UNIT_LOCKS_FILTERED_BY_TAGS_PAGINATION_DATA: 'SET_PRIVATE_UNIT_LOCKS_FILTERED_BY_TAGS_PAGINATION_DATA',

  SAVE_ALL_PRIVATE_UNITS: 'SAVE_ALL_PRIVATE_UNITS',
  
  SET_PRIVATE_UNIT_ORDER: 'SET_PRIVATE_UNIT_ORDER',
  
  SAVE_PRIVATE_UNIT_TEMPLATES: 'SAVE_PRIVATE_UNIT_TEMPLATES',
  UPDATE_PRIVATE_UNIT_TEMPLATE: 'UPDATE_PRIVATE_UNIT_TEMPLATE',
};
