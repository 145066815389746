import Card from '@material-ui/core/Card';
import LockIcon from '@material-ui/icons/LockOpen';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import Tag from '../../components/Tag.jsx';
import * as UtilActions from '../../redux/actions/utils.actions.js';
import { BLUE, GREEN, ORANGE, PRIVATE_UNITS_STATUS } from '../../_config/consts.js';
import UsersIconCustom from '../CustomIcons/UsersIconCustom.jsx';

@connect((state) => ({ language: state.settings.language }))
class PrivateUnitDetailsRow extends React.Component {
  render() {
    const { key, privateUnit, onClick, isSelected, language, dispatch } = this.props;
    
    const statusColor = privateUnit.state===PRIVATE_UNITS_STATUS.TO_BE_ACTIVATED?ORANGE:privateUnit.state===PRIVATE_UNITS_STATUS.ACTIVE?GREEN:'rgb(63, 63, 63)'
    
    return (
      <Card
        key={key}
        className={`card card-rounded ${isSelected && 'card-selected'}`}
        style={{ borderRadius: 10, marginBottom: 20, position: 'relative', borderLeft: `4px solid ${BLUE}`, padding: 0 }}
        onClick={() => onClick()}
        elevation={2}
      >
        <div className="card-header access-card-header" style={{ paddingRight: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
          <div style={{ marginLeft: 15, paddingTop: 10, maxWidth: '45%' }}>
            <h2 className="card-title" style={{ wordBreak: 'break-all', whiteSpace: 'unset', fontSize: 20, marginTop: 5, marginLeft: 0, marginBottom: 0, color: '#3f3f3f' }}>{privateUnit.name}</h2>
          </div>
        </div>
        <div className="separator" style={{ marginTop: 10 }} />
        <div className="card-body" style={{ paddingTop: 10, paddingBottom: 10, position: 'relative' }}>
          <h6 style={{ color: 'red', margin: 10, fontSize: 13, fontWeight: 'bold', marginTop: 0, textAlign: 'right', position: 'absolute', right: 0, top: 0 }}>
            <Entity key={`${privateUnit.status}`} style={{ textTransform: 'uppercase', color: statusColor }} entity={`privateUnitsStatus_${privateUnit.state}`} />
          </h6>
          <div className="card-inner-body" style={{ width: '100%' }}>
            <div className="card-tag-presentational-container">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <UsersIconCustom style={{ color: '#3f3f3f', width: 20 }} />
                <h4 style={{ margin: 0, fontSize: 16, color: '#3f3f3f', marginLeft: 5 }}>
                  <Entity entity="privateUnitInheritedUserTags" />
                </h4>
              </div>
              <div className="tags-container">
                {_.map(privateUnit.userTags, tag =>
                  <Tag
                    key={tag.id}
                    tag={tag}
                  />,
              )}
              </div>
            </div>
            <div className="card-tag-presentational-container" style={{ marginTop: 10, marginRight: 17 }}>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                <LockIcon style={{ color: '#3f3f3f', fontSize: 20, marginRigtht: 2 }} />
                <h4 style={{ fontSize: 16, color: '#3f3f3f', margin: 0, marginLeft: 5 }}>
                  <Entity entity="privateUnitSharedLocks" />
                </h4>
              </div>
              <div className="tags-container">
                {_.map(privateUnit.lockTags, tag =>
                  <Tag
                    key={tag.id}
                    tag={tag}
                  />)}
              </div>
            </div>
          </div>
        </div>
      </Card>
    );
  }
} 

export default PrivateUnitDetailsRow;
