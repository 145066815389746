import _ from 'lodash';
import moment from 'moment';
import { BACKGROUND_TASK_TYPES, MATCH_TAG_MODE } from '../../_config/consts';
import * as formatter from '../../_config/formatter';
import * as TagsManagementAPI from '../../_config/tagsManagementAPI';
import {
  APPEND_GUESTS_SPECIAL_TAGS,
  APPEND_GUESTS_SPECIAL_TAGS_CATEGORIES,
  APPEND_LOCKS_SPECIAL_TAGS,
  APPEND_TAG_GUESTS_DATA,
  APPEND_TAG_LOCKS_DATA,
  REMOVE_GUEST_TAG_IN_STATE,
  REMOVE_LOCK_TAG_IN_STATE,
  RESET_GUESTS_TAGS_DATA,
  RESET_GUESTS_TAGS_FILTERS,
  RESET_LOCKS_TAGS_DATA,
  RESET_LOCKS_TAGS_FILTERS,
  RESET_TAG_GUESTS_DATA,
  RESET_TAG_LOCKS_DATA,
  SAVE_GUESTS_SPECIAL_TAGS,
  SAVE_GUESTS_SPECIAL_TAGS_CATEGORIES,
  SAVE_GUESTS_SPECIAL_TAGS_CATEGORIES_PAGINATION,
  SAVE_GUESTS_SPECIAL_TAGS_NUMBER,
  SAVE_GUESTS_SPECIAL_TAGS_PAGINATION_DATA,
  SAVE_LOCKS_SPECIAL_TAGS,
  SAVE_LOCKS_SPECIAL_TAGS_CATEGORIES,
  SAVE_LOCKS_SPECIAL_TAGS_CATEGORIES_PAGINATION,
  SAVE_LOCKS_SPECIAL_TAGS_NUMBER,
  SAVE_LOCKS_SPECIAL_TAGS_PAGINATION_DATA,
  SAVE_TAG_GUESTS_DATA,
  SAVE_TAG_GUESTS_PAGINATION,
  SAVE_TAG_LOCKS_DATA,
  SAVE_TAG_LOCKS_PAGINATION,
  SET_GUESTS_TAGS_FILTER_FIELD,
  SET_LOCKS_TAGS_FILTER_FIELD,
  SET_SELECTED_TAG,
  SET_TAGS_OPERATIONAL_MODE,
  UPDATE_GUEST_TAG_IN_STATE,
  UPDATE_LOCK_TAG_IN_STATE,
} from './actionTypes/tagsManagement';
import * as UtilsActions from './utils.actions';

export function saveGuestsSpecialTags(tags) {
  return {
    type: SAVE_GUESTS_SPECIAL_TAGS,
    tags,
  };
}

export function appendGuestsSpecialTags(tags) {
  return {
    type: APPEND_GUESTS_SPECIAL_TAGS,
    tags,
  };
}

export function saveGuestsSpecialTagsPagination(pagination) {
  return {
    type: SAVE_GUESTS_SPECIAL_TAGS_PAGINATION_DATA,
    pagination,
  };
}

export function saveLocksSpecialTags(tags) {
  return {
    type: SAVE_LOCKS_SPECIAL_TAGS,
    tags,
  };
}

export function saveGuestsSpecialTagsNumber(number) {
  return {
    type: SAVE_GUESTS_SPECIAL_TAGS_NUMBER,
    number,
  };
}

export function saveLocksSpecialTagsNumber(number) {
  return {
    type: SAVE_LOCKS_SPECIAL_TAGS_NUMBER,
    number,
  };
}

export function appendLocksSpecialTags(tags) {
  return {
    type: APPEND_LOCKS_SPECIAL_TAGS,
    tags,
  };
}

export function saveLocksSpecialTagsPagination(pagination) {
  return {
    type: SAVE_LOCKS_SPECIAL_TAGS_PAGINATION_DATA,
    pagination,
  };
}

export function setSelectedTag(tag, tagType) {
  return {
    type: SET_SELECTED_TAG,
    tag,
    tagType,
  };
}

export function setOperationalMode(value) {
  return {
    type: SET_TAGS_OPERATIONAL_MODE,
    value,
  };
}

export function setGuestsTagsFilter(field, value) {
  return {
    type: SET_GUESTS_TAGS_FILTER_FIELD,
    field,
    value,
  };
}

export function resetGuestsTagsFilter() {
  return { type: RESET_GUESTS_TAGS_FILTERS };
}

export function resetGuestsTagsData() {
  return { type: RESET_GUESTS_TAGS_DATA };
}

export function setLockTagsFilter(field, value) {
  return {
    type: SET_LOCKS_TAGS_FILTER_FIELD,
    field,
    value,
  };
}

export function resetLocksTagsFilter() {
  return { type: RESET_LOCKS_TAGS_FILTERS };
}

export function resetLocksTagsData() {
  return { type: RESET_LOCKS_TAGS_DATA };
}

export function updateGuestSpecialTagInState(tag) {
  return {
    type: UPDATE_GUEST_TAG_IN_STATE,
    tag,
  };
}

export function removeGuestSpecialTagInState(tagId) {
  return {
    type: REMOVE_GUEST_TAG_IN_STATE,
    tagId,
  };
}

export function removeLockSpecialTagInState(tagId) {
  return {
    type: REMOVE_LOCK_TAG_IN_STATE,
    tagId,
  };
}

export function updateLockSpecialTagInState(tag) {
  return {
    type: UPDATE_LOCK_TAG_IN_STATE,
    tag,
  };
}

export function saveGuestsSpecialTagsCategories(categories) {
  return {
    type: SAVE_GUESTS_SPECIAL_TAGS_CATEGORIES,
    categories,
  };
}

export function appendGuestTagsCategories(categories) {
  return {
    type: APPEND_GUESTS_SPECIAL_TAGS_CATEGORIES,
    categories,
  };
}

export function saveGuestsSpecialTagsCategoriesPagination(pagination) {
  return {
    type: SAVE_GUESTS_SPECIAL_TAGS_CATEGORIES_PAGINATION,
    pagination,
  };
}

export function saveLocksSpecialTagsCategories(categories) {
  return {
    type: SAVE_LOCKS_SPECIAL_TAGS_CATEGORIES,
    categories,
  };
}

export function saveLocksSpecialTagsCategoriesPagination(pagination) {
  return {
    type: SAVE_LOCKS_SPECIAL_TAGS_CATEGORIES_PAGINATION,
    pagination,
  };
}

export function saveTagGuestsData(guests) {
  return {
    type: SAVE_TAG_GUESTS_DATA,
    guests,
  };
}

export function appendTagGuestsData(guests) {
  return {
    type: APPEND_TAG_GUESTS_DATA,
    guests,
  };
}

export function saveTagGuestsPagination(pagination) {
  return {
    type: SAVE_TAG_GUESTS_PAGINATION,
    pagination,
  };
}

export function resetTagGuestsData() {
  return {
    type: RESET_TAG_GUESTS_DATA,
  };
}

export function saveTagLocksData(locks) {
  return {
    type: SAVE_TAG_LOCKS_DATA,
    locks,
  };
}

export function appendTagLocksData(locks) {
  return {
    type: APPEND_TAG_LOCKS_DATA,
    locks,
  };
}

export function saveTagLocksPagination(pagination) {
  return {
    type: SAVE_TAG_LOCKS_PAGINATION,
    pagination,
  };
}

export function resetTagLocksData() {
  return {
    type: RESET_TAG_LOCKS_DATA,
  };
}

/* ------------ GUEST TAGS --------------*/

// Fetch number of guest associated to a tag
export function fetchTagsGuestNumberByTag(tagId) {
  return async (dispatch) => {
    try {
      const params = {
        page: 0,
        pageSize: 1,
        tagIds: [tagId],
        userTagMatchingMode: MATCH_TAG_MODE.EVERY_TAG,
      };
      const tagsResponse = await TagsManagementAPI.fetchGuestsBySpecialTags(params);
      if (tagsResponse && tagsResponse.data) {
        const pagination = _.omit(tagsResponse.data, 'content');
        return pagination.totalElements;
      }
      return 0;
    } catch (error) {
      return 0;
    }
  };
}

// Update a Guest special tag
export function updateGuestSpecialTag(tagId, tagDTO) {
  return async (dispatch) => {
    try {
      const formattedTag = formatter.formatOutputData(formatter.GUEST_TAG, tagDTO);
      const tagUpdateResponse = await TagsManagementAPI.updateGuestsSpecialTag(tagId, formattedTag);
      if (tagUpdateResponse && tagUpdateResponse.data) {
        let tagToSave = tagUpdateResponse.data;
        try {
          const guestsNumber = await dispatch(fetchTagsGuestNumberByTag(tagToSave.id));
          tagToSave = { ...tagToSave, guestsNumber };
        } catch (error) {
          tagToSave = { ...tagToSave, guestsNumber: 0 };
        }
        dispatch(updateGuestSpecialTagInState(tagToSave));
        return tagToSave;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

// Create a Guest special tag
export function createGuestSpecialTag(tagDTO) {
  return async (dispatch) => {
    try {
      const formattedTag = formatter.formatOutputData(formatter.GUEST_TAG, tagDTO);
      const tagUpdateResponse = await TagsManagementAPI.createGuestsSpecialTag(formattedTag);
      if (tagUpdateResponse && tagUpdateResponse.data) {
        dispatch(updateGuestSpecialTagInState({ ...tagUpdateResponse.data, guestsNumber: 0 }));
        return tagUpdateResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

// Given a tags fetch the guest associated to that tag
export function fetchGuestsByTag(tagId, page = 0, pageSize = 100) {
  return async (dispatch) => {
    try {
      const params = {
        page,
        pageSize,
        tagIds: [tagId],
        userTagMatchingMode: MATCH_TAG_MODE.EVERY_TAG,
      };
      const tagsResponse = await TagsManagementAPI.fetchGuestsBySpecialTags(params);
      if (tagsResponse && tagsResponse.data && tagsResponse.data.content) {
        dispatch(saveTagGuestsData(tagsResponse.data.content));
        dispatch(saveTagGuestsPagination(_.omit(tagsResponse.data, 'content')));
        return tagsResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}


// Given a tags fetch the guest associated to that tag
export function fetchAndAppendGuestsByTag(tagId, page = 0, pageSize = 100) {
  return async (dispatch) => {
    try {
      const params = {
        page,
        pageSize,
        tagIds: [tagId],
        userTagMatchingMode: MATCH_TAG_MODE.EVERY_TAG,
      };
      const tagsResponse = await TagsManagementAPI.fetchGuestsBySpecialTags(params);
      if (tagsResponse && tagsResponse.data && tagsResponse.data.content) {
        dispatch(appendTagGuestsData(tagsResponse.data.content));
        dispatch(saveTagGuestsPagination(_.omit(tagsResponse.data, 'content')));
        return tagsResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

// Given a tags fetch ALL the guest associated to that tag
export function fetchAllGuestsByTag(tagId, page = 0, pageSize = 100) {
  return async (dispatch) => {
    const taskId = moment().valueOf();
    let stopFetching = false;
    dispatch(UtilsActions.addBackgroundTaskSingleton({
      id: taskId,
      type: BACKGROUND_TASK_TYPES.DOWNLOAD_TAG_GUESTS_CSV,
      title: 'creatingGuestTagCSV',
      cancelCallback: () => {
        stopFetching = true;
      },
    }));
    try {
      let params = {
        page,
        pageSize,
        tagIds: [tagId],
        userTagMatchingMode: MATCH_TAG_MODE.EVERY_TAG,
      };
      let tagsResponse = await TagsManagementAPI.fetchGuestsBySpecialTags(params);
      if (tagsResponse && tagsResponse.data && tagsResponse.data.content && !_.isEmpty(tagsResponse.data.content)) {
        const guests = [...tagsResponse.data.content];
        let pagination = _.omit(tagsResponse.data, 'content');
        while (!pagination.last && !stopFetching) {
          params = {
            ...params,
            page: pagination.number + 1,
          };
          tagsResponse = await TagsManagementAPI.fetchGuestsBySpecialTags(params);
          guests.push(...tagsResponse.data.content);
          pagination = _.omit(tagsResponse.data, 'content');
        }
        dispatch(UtilsActions.removeBackgroundTask(taskId));
        return { guests, stopFetching };
      }
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      throw new Error();
    } catch (error) {
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      throw error;
    }
  };
}

export function fetchAllGuestSpecialTags() {
  return async (dispatch, getState) => {
    try {
      let params = {
        page: 0,
        pageSize: 50,
        userTagMatchingMode: MATCH_TAG_MODE.EVERY_TAG,
      };
      let tagsResponse = await TagsManagementAPI.fetchGuestsSpecialTags(params);
      if (tagsResponse && tagsResponse.data && tagsResponse.data.content && !_.isEmpty(tagsResponse.data.content)) {
        const tags = [...tagsResponse.data.content];
        let pagination = _.omit(tagsResponse.data, 'content');
        while (!pagination.last) {
          params = {
            ...params,
            page: pagination.number + 1,
          };
          tagsResponse = await TagsManagementAPI.fetchGuestsSpecialTags(params);
          tags.push(...tagsResponse.data.content);
          pagination = _.omit(tagsResponse.data, 'content');
        }
        dispatch(saveGuestsSpecialTags(tags));
        return { tags };
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  }
}

// Fetch guests special tags and number of guests associated to each tag
export function fetchGuestSpecialTags(page = 0, pageSize = 20) {
  return async (dispatch, getState) => {
    const filters = getState().tagsManagement.userTags.filters;
    try {
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const tagsResponse = await TagsManagementAPI.fetchGuestsSpecialTags(params);
      if (tagsResponse && tagsResponse.data) {
        const tagsFetched = tagsResponse.data.content;
        const tagsToSave = [];
        const approvationCalls = _.map(tagsFetched, async (tag) => {
          try {
            const guestsNumber = await dispatch(fetchTagsGuestNumberByTag(tag.id));
            tagsToSave.push({ ...tag, guestsNumber });
          } catch (error) {
            tagsToSave.push({ ...tag, guestsNumber: 0 });
          }
        });
        try {
          await Promise.all(approvationCalls);
          dispatch(saveGuestsSpecialTags(tagsToSave));
          dispatch(saveGuestsSpecialTagsPagination(_.omit(tagsResponse.data, 'content')));
          return tagsResponse.data.content;
        } catch (error) {
          dispatch(saveGuestsSpecialTags(tagsFetched));
          dispatch(saveGuestsSpecialTagsPagination(_.omit(tagsResponse.data, 'content')));
        }
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}


export function fetchGuestsSpecialTagsNumber() {
  return async (dispatch, getState) => {
    try {
      const params = {
        page: 0,
        pageSize: 1,
      };
      const tagsResponse = await TagsManagementAPI.fetchGuestsSpecialTags(params);
      if (tagsResponse && tagsResponse.data) {
        dispatch(saveGuestsSpecialTagsNumber(tagsResponse.data.totalElements));
        
        return tagsResponse.data.totalElements;
      }
    } catch (error) {
      return 0;
    }
  };
}

export function fetchLocksSpecialTagsNumber() {
  return async (dispatch, getState) => {
    try {
      const params = {
        page: 0,
        pageSize: 1,
      };
      const tagsResponse = await TagsManagementAPI.fetchLocksSpecialTags(params);
      if (tagsResponse && tagsResponse.data) {
        dispatch(saveLocksSpecialTagsNumber(tagsResponse.data.totalElements));
        return tagsResponse.data.totalElements;
      }
    } catch (error) {
      return 0;
    }
  };
}

// Fetch guests special tags and number of guests associated to each tag
export function fetchAndAppendGuestSpecialTags(page = 0, pageSize = 20) {
  return async (dispatch, getState) => {
    const filters = getState().tagsManagement.userTags.filters;
    try {
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const tagsResponse = await TagsManagementAPI.fetchGuestsSpecialTags(params);
      if (tagsResponse && tagsResponse.data) {
        const tagsFetched = tagsResponse.data.content;
        const tagsToSave = [];
        const approvationCalls = _.map(tagsFetched, async (tag) => {
          try {
            const guestsNumber = await dispatch(fetchTagsGuestNumberByTag(tag.id));
            tagsToSave.push({ ...tag, guestsNumber });
          } catch (error) {
            tagsToSave.push({ ...tag, guestsNumber: 0 });
          }
        });
        try {
          await Promise.all(approvationCalls);
          dispatch(appendGuestsSpecialTags(tagsToSave));
          dispatch(saveGuestsSpecialTagsPagination(_.omit(tagsResponse.data, 'content')));
          return tagsResponse.data.content;
        } catch (error) {
          dispatch(appendGuestsSpecialTags(tagsFetched));
          dispatch(saveGuestsSpecialTagsPagination(_.omit(tagsResponse.data, 'content')));
        }
        return tagsResponse.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

// Fetch guest tags categories
export function fetchGuestTagsCategories(page = 0, pageSize = 100) {
  return async (dispatch) => {
    try {
      const params = {
        page,
        pageSize,
      };
      const tagsResponse = await TagsManagementAPI.fetchGuestsTagsCategories(params);
      if (tagsResponse && tagsResponse.data) {
        dispatch(saveGuestsSpecialTagsCategories(tagsResponse.data.content));
        dispatch(saveGuestsSpecialTagsCategoriesPagination(_.omit(tagsResponse.data, 'content')));
        return tagsResponse.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

// Fetch guest tag categories
export function fetchAndAppendGuestTagsCategories(page = 0, pageSize = 20) {
  return async (dispatch) => {
    try {
      const params = {
        page,
        pageSize,
      };
      const tagsResponse = await TagsManagementAPI.fetchGuestsTagsCategories(params);
      if (tagsResponse && tagsResponse.data) {
        dispatch(appendGuestTagsCategories(tagsResponse.data.content));
        dispatch(saveGuestsSpecialTagsCategoriesPagination(_.omit(tagsResponse.data, 'content')));
        return tagsResponse.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

// Remove a guest from the tag
export function removeGuestFromTag(tag, guest) {
  return async (dispatch, getState) => {
    try {
      const userTagsFiltered = _.filter(guest.tags, guestTag => guestTag.id !== tag.id);
      const userTagIds = _.map(userTagsFiltered, 'id');
      const tagsResponse = await TagsManagementAPI.updateGuestTags(guest.id, { userTagIds });
      if (tagsResponse && tagsResponse.data) {
        const tagGuests = getState().tagsManagement.userTags.guests.content;
        dispatch(saveTagGuestsData(_.filter(tagGuests, guestInState => guestInState.id !== guest.id)));
        try {
          const guestsNumber = await dispatch(fetchTagsGuestNumberByTag(tag.id));
          dispatch(updateGuestSpecialTagInState({ ...tag, guestsNumber }));
        } catch (error) {
          const tagToUpdate = {..._.omit(tag, 'guestsNumber'), guestsNumber: tag.guestsNumber - 1 };
          dispatch(updateGuestSpecialTagInState(tagToUpdate));
        }
        return tagsResponse.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

// Add a guest to the tag
export function addGuestToTag(tag, guest) {
  return async (dispatch, getState) => {
    try {
      const userTagsFiltered = [...guest.tags, { id: tag.id }];
      const userTagIds = _.map(_.uniqBy(userTagsFiltered, 'id'), 'id'); // avoid duplicates
      const tagsResponse = await TagsManagementAPI.updateGuestTags(guest.id, { userTagIds });
      if (tagsResponse && tagsResponse.data) {
        const tagGuests = getState().tagsManagement.userTags.guests.content;
        dispatch(saveTagGuestsData([tagsResponse.data, ...tagGuests]));
        try {
          const guestsNumber = await dispatch(fetchTagsGuestNumberByTag(tag.id));
          dispatch(updateGuestSpecialTagInState({ ...tag, guestsNumber }));
        } catch (error) {
          const tagToUpdate = {..._.omit(tag, 'guestsNumber'), guestsNumber: tag.guestsNumber + 1 };
          dispatch(updateGuestSpecialTagInState(tagToUpdate));
        }
        return tagsResponse.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

// delete the guest tag
export function deleteGuestTag(tagId) {
  return async (dispatch) => {
    try {
      const tagsResponse = await TagsManagementAPI.deleteGuestsSpecialTag(tagId);
      if (tagsResponse && tagsResponse.data) {
        dispatch(removeGuestSpecialTagInState(tagId));
        return tagsResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

/* ------------ LOCKS TAGS --------------*/


// get the number of lock associated to a tag
export function fetchTagsLockNumberByTag(tagId) {
  return async (dispatch) => {
    try {
      const params = {
        page: 0,
        pageSize: 1,
        tagIds: [tagId],
        lockTagMatchingMode: MATCH_TAG_MODE.EVERY_TAG,
      };
      const tagsResponse = await TagsManagementAPI.fetchLocksBySpecialTags(params);
      if (tagsResponse && tagsResponse.data && tagsResponse.data.content) {
        const pagination = _.omit(tagsResponse.data, 'content');
        return pagination.totalElements;
      }
      return 0;
    } catch (error) {
      return 0;
    }
  };
}


// Create a new lock tag
export function createLockSpecialTag(tagDTO) {
  return async (dispatch) => {
    try {
      const formattedTag = formatter.formatOutputData(formatter.GUEST_TAG, tagDTO);
      const tagUpdateResponse = await TagsManagementAPI.createLocksSpecialTag(formattedTag);
      if (tagUpdateResponse && tagUpdateResponse.data) {
        dispatch(updateLockSpecialTagInState({ ...tagUpdateResponse.data, locksNumber: 0 }));
        return tagUpdateResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

// Update a lock tag
export function updateLockSpecialTag(tagId, tagDTO) {
  return async (dispatch) => {
    try {
      const formattedTag = formatter.formatOutputData(formatter.LOCK_TAG, tagDTO);
      const tagUpdateResponse = await TagsManagementAPI.updateLocksSpecialTag(tagId, formattedTag);
      if (tagUpdateResponse && tagUpdateResponse.data) {
        let tagToSave = tagUpdateResponse.data;
        try {
          const locksNumber = await dispatch(fetchTagsLockNumberByTag(tagToSave.id));
          tagToSave = { ...tagToSave, locksNumber };
        } catch (error) {
          tagToSave = { ...tagToSave, locksNumber: 0 };
        }
        dispatch(updateLockSpecialTagInState(tagToSave));
        return tagToSave;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

// Fetch locks special tags
export function fetchLocksSpecialTags(page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    const filters = getState().tagsManagement.lockTags.filters;
    try {
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const tagsResponse = await TagsManagementAPI.fetchLocksSpecialTags(params);
      if (tagsResponse && tagsResponse.data) {
        const tagsFetched = tagsResponse.data.content;
        const tagsToSave = [];
        const approvationCalls = _.map(tagsFetched, async (tag) => {
          try {
            const locksNumber = await dispatch(fetchTagsLockNumberByTag(tag.id));
            tagsToSave.push({ ...tag, locksNumber });
          } catch (error) {
            tagsToSave.push({ ...tag, locksNumber: 0 });
          }
        });
        try {
          await Promise.all(approvationCalls);
          dispatch(saveLocksSpecialTags(tagsToSave));
          dispatch(saveLocksSpecialTagsPagination(_.omit(tagsResponse.data, 'content')));
          return tagsResponse.data.content;
        } catch (error) {
          dispatch(saveLocksSpecialTags(tagsFetched));
          dispatch(saveLocksSpecialTagsPagination(_.omit(tagsResponse.data, 'content')));
        }
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

// fetch locks special stags
export function fetchAndAppendLocksSpecialTags(page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    const filters = getState().tagsManagement.lockTags.filters;
    try {
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const tagsResponse = await TagsManagementAPI.fetchLocksSpecialTags(params);
      if (tagsResponse && tagsResponse.data) {
        const tagsFetched = tagsResponse.data.content;
        const tagsToSave = [];
        const approvationCalls = _.map(tagsFetched, async (tag) => {
          try {
            const locksNumber = await dispatch(fetchTagsLockNumberByTag(tag.id));
            tagsToSave.push({ ...tag, locksNumber });
          } catch (error) {
            tagsToSave.push({ ...tag, locksNumber: 0 });
          }
        });
        try {
          await Promise.all(approvationCalls);
          dispatch(appendLocksSpecialTags(tagsToSave));
          dispatch(saveLocksSpecialTagsPagination(_.omit(tagsResponse.data, 'content')));
          return tagsResponse.data.content;
        } catch (error) {
          dispatch(appendLocksSpecialTags(tagsFetched));
          dispatch(saveLocksSpecialTagsPagination(_.omit(tagsResponse.data, 'content')));
        }
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

// Fetch lock tags categories
export function fetchLockTagsCategories(page = 0, pageSize = 20) {
  return async (dispatch) => {
    try {
      const params = {
        page,
        pageSize,
      };
      const tagsResponse = await TagsManagementAPI.fetchLocksTagsCategories(params);
      if (tagsResponse && tagsResponse.data) {
        dispatch(saveLocksSpecialTagsCategories(tagsResponse.data.content));
        dispatch(saveLocksSpecialTagsCategoriesPagination(_.omit(tagsResponse.data, 'content')));
        return tagsResponse.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}


// Given a Tag get the locks associated to that tag
export function fetchLocksByTag(tagId, page = 0, pageSize = 100) {
  return async (dispatch) => {
    try {
      const params = {
        page,
        pageSize,
        tagIds: [tagId],
        lockTagMatchingMode: MATCH_TAG_MODE.EVERY_TAG,
      };
      const tagsResponse = await TagsManagementAPI.fetchLocksBySpecialTags(params);
      if (tagsResponse && tagsResponse.data && tagsResponse.data.content) {
        dispatch(saveTagLocksData(tagsResponse.data.content));
        dispatch(saveTagLocksPagination(_.omit(tagsResponse.data, 'content')));
        return tagsResponse.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

// Given a Tag get the locks associated to that tag
export function fetchAndAppendLocksByTag(tagId, page = 0, pageSize = 100) {
  return async (dispatch) => {
    try {
      const params = {
        page,
        pageSize,
        tagIds: [tagId],
        lockTagMatchingMode: MATCH_TAG_MODE.EVERY_TAG,
      };
      const tagsResponse = await TagsManagementAPI.fetchLocksBySpecialTags(params);
      if (tagsResponse && tagsResponse.data && tagsResponse.data.content) {
        dispatch(appendTagLocksData(tagsResponse.data.content));
        dispatch(saveTagLocksPagination(_.omit(tagsResponse.data, 'content')));
        return tagsResponse.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

// Given a tags fetch ALL the lcoks associated to that tag
export function fetchAllLocksByTag(tagId, page = 0, pageSize = 300) {
  return async (dispatch) => {
    const taskId = moment().valueOf();
    let stopFetching = false;
    dispatch(UtilsActions.addBackgroundTaskSingleton({
      id: taskId,
      type: BACKGROUND_TASK_TYPES.DOWNLOAD_TAG_LOCK_CSV,
      title: 'creatingLocksTagCSV',
      cancelCallback: () => {
        stopFetching = true;
      },
    }));
    try {
      let params = {
        page,
        pageSize,
        tagIds: [tagId],
        lockTagMatchingMode: MATCH_TAG_MODE.EVERY_TAG,
      };
      let tagsResponse = await TagsManagementAPI.fetchLocksBySpecialTags(params);
      if (tagsResponse && tagsResponse.data && tagsResponse.data.content && !_.isEmpty(tagsResponse.data.content)) {
        const locks = [...tagsResponse.data.content];
        let pagination = _.omit(tagsResponse.data, 'content');
        let progress = (Math.round(((pagination.number + 1) / pagination.totalPages) * 10) / 10) * 100;
        while (pagination.number + 1 !== pagination.totalPages && !stopFetching) {
          params = {
            ...params,
            page: pagination.number + 1,
          };
          tagsResponse = await TagsManagementAPI.fetchLocksBySpecialTags(params);
          locks.push(...tagsResponse.data.content);
          pagination = _.omit(tagsResponse.data, 'content');
          progress = (Math.round(((pagination.number + 1) / pagination.totalPages) * 10) / 10) * 100;
        }
        dispatch(UtilsActions.removeBackgroundTask(taskId));
        return { locks, stopFetching };
      }
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      throw new Error();
    } catch (error) {
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      throw error;
    }
  };
}

// Remove a lock from the tag
export function removeLockFromTag(tag, lock) {
  return async (dispatch, getState) => {
    try {
      const lockTagsFiltered = _.filter(lock.tags, lockTag => lockTag.id !== tag.id);
      const lockTagIds = _.map(lockTagsFiltered, 'id');
      const tagsResponse = await TagsManagementAPI.updateLockTags(lock.id, { lockTagIds });
      if (tagsResponse && tagsResponse.data) {
        const tagLocks = getState().tagsManagement.lockTags.locks.content;
        dispatch(saveTagLocksData(_.filter(tagLocks, lockInState => lockInState.id !== lock.id)));
        try {
          const locksNumber = await dispatch(fetchTagsLockNumberByTag(tag.id));
          dispatch(updateLockSpecialTagInState({ ...tag, locksNumber }));
        } catch (error) {
          const tagToUpdate = { ..._.omit(tag, 'locksNumber'), locksNumber: tag.locksNumber - 1 };
          dispatch(updateLockSpecialTagInState(tagToUpdate));
        }
        return tagsResponse.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}


// Add a lock from the tag
export function addLockToTag(tag, lock) {
  return async (dispatch, getState) => {
    try {
      const lockTagsFiltered = [...lock.tags, { id: tag.id }];
      const lockTagIds = _.map(_.uniqBy(lockTagsFiltered, 'id'), 'id'); // avoid duplicates
      const tagsResponse = await TagsManagementAPI.updateLockTags(lock.id, { lockTagIds });
      if (tagsResponse && tagsResponse.data) {
        const tagLocks = getState().tagsManagement.lockTags.locks.content;
        dispatch(saveTagLocksData([tagsResponse.data, ...tagLocks]));
        try {
          const locksNumber = await dispatch(fetchTagsLockNumberByTag(tag.id));
          dispatch(updateLockSpecialTagInState({ ...tag, locksNumber }));
        } catch (error) {
          const tagToUpdate = { ..._.omit(tag, 'locksNumber'), locksNumber: tag.locksNumber + 1 };
          dispatch(updateLockSpecialTagInState(tagToUpdate));
        }
        return tagsResponse.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

// Delete a lock Tag
export function deleteLockTag(tagId) {
  return async (dispatch) => {
    try {
      const tagsResponse = await TagsManagementAPI.deleteLocksSpecialTag(tagId);
      if (tagsResponse && tagsResponse.data) {
        dispatch(removeLockSpecialTagInState(tagId));
        return tagsResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}



export function createTagAndAssignToUser(tagsToCreate, users) {
  return async (dispatch, getState) => {
    try {

      // TODO Now cycle the tagsToCreate -> create the tag and add the tag dto to users
      const tagDTO = {
        name:'',
        color: '',
        type: 'User'
      }
      dispatch(createGuestSpecialTag(tagDTO))
    } catch (error) {

    }
  }
}


export function createBatchUserTags(tags) {
  return async (dispatch, getState) => {
    try {
      const createdTags = [];
      const tagsToCreate = _.uniqBy(tags, 'name');
      for (const tag of tagsToCreate) {
        const tagsResponse = await TagsManagementAPI.createGuestsSpecialTag(tag);
        if (tagsResponse && tagsResponse.data) {
          createdTags.push(tagsResponse.data);
        }
      }
      return createdTags;
    } catch (error) {
      throw error;
    }
  };
}