import { IconButton } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import CloseIcon from '@material-ui/icons/Cancel';
import CalendarViewIcon from '@material-ui/icons/Event';
import ListViewIcon from '@material-ui/icons/ViewList';
import { Modal } from '@sketchpixy/rubix';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import qs from 'qs';
import React from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import { connect } from 'react-redux';
import { change, initialize } from 'redux-form';
import { BLUE, LOCKER_RESERVATION_ERRORS, LOCKER_RESERVATION_STATUSES_PARAMETERS, LOCKER_TYPE, RED, VIEW_MODES } from '../_config/consts';
import LockersIconCustom from '../components/CustomIcons/LockersIconCustom.jsx';
import ParcelRoomIconCustom from '../components/CustomIcons/ParcelRoomIconCustom.jsx';
import ListLoadingView from '../components/ListLoadingView.jsx';
import OperationalView from '../components/OperationalView/OperationalView.jsx';
import DeliveriesOperationalSection from '../components/ParcelManagement/DeliveriesOperationalSection.jsx';
import DeliveriesTableView from '../components/ParcelManagement/DeliveriesTableView.jsx';
import DeliveryPinShareView from '../components/ParcelManagement/DeliveryPinShareView.jsx';
import PresentationalViewHeader from '../components/PresentationalView/PresentationalViewHeader.jsx';
import TranslatableOption from '../components/forms/Fields/TranslatableOption.jsx';
import * as LockerActions from '../redux/actions/locker.actions';
import * as ModalActions from '../redux/actions/modal.actions';
import * as UtilsActions from '../redux/actions/utils.actions';

let filterTimeout;
@connect((state) => ({ lockers: state.lockers, lockerReservations: state.lockers.reservations, routing: state.router, viewLoading: state.utils.viewLoading, themeName: state.settings.items.theme.data.themeName, }))
class DeliveriesManagementView extends React.Component {
  constructor(props) {
    super(props);
    const cachedViewMode = localStorage.getItem('lockersReservationViewMode');
    const cachedCalendarViewMode = localStorage.getItem('deliveriesCalendarViewMode');
    this.state = {
      activeTab: cachedViewMode && cachedViewMode === VIEW_MODES.CALENDAR ? 1 : 0,
      showSharingSection: false,
      selectedPinToShare: undefined,
      startingDate: moment().toDate(),
      calendarEvents: [],
      currentMonth: moment().month(),
      defaultCalendarView: cachedCalendarViewMode || 'month',
      deliveriesCalendarViewMode: cachedCalendarViewMode || 'month',
    };
  }
  async componentWillMount() {
    const { dispatch, routing, lockerReservations } = this.props;
    const parsed = qs.parse(routing.location.search, { ignoreQueryPrefix: true });
    dispatch(LockerActions.selectLockerReservation({}));
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(LockerActions.resetLockersFilters());
      await dispatch(LockerActions.resetLockerReservationsFilters());
      await dispatch(LockerActions.fetchAllLocks());
      await dispatch(LockerActions.fetchLockers(0,false,1000));
      await dispatch(LockerActions.fetchLockerReservations());
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
    const lockerReservationId = parsed.lockerId;
    const newEntity = parsed.newEntity;
    if (lockerReservationId) {
      const newLockerReservation = ((lockerReservations && lockerReservations.data) || []).filter(e=>e.id===lockerReservationId).length>0?
        ((lockerReservations && lockerReservations.data) || []).filter(e=>e.id===lockerReservationId)[0]:undefined;
      if (newLockerReservation) {
        this.onSelectDelivery(newLockerReservation);
      }
    }
    if (newEntity) {
      this.onCreateNewDelivery();
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(LockerActions.resetLockerReservationsFilters());
    dispatch(LockerActions.selectLockerReservation({}));
  }

  async fetchDeliveries(page = 0, append = false) {
    const { dispatch } = this.props;
    try {
      this.setState({ viewLoading: true });
      await dispatch(LockerActions.fetchLockerReservations(page, append));
      this.setState({ viewLoading: false });
    } catch (error) {
      this.setState({ viewLoading: false });
    }
  }

  async closeOperationalSection() {
    const { dispatch } = this.props;
    await dispatch(LockerActions.setOperationalModeReservations(false));
    await dispatch(LockerActions.selectLockerReservation({}));
  }

  openOperationalSection() {
    const { dispatch } = this.props;
    dispatch(LockerActions.setOperationalModeReservations(true));
  }

  async onSetFilter(field, value, useTimeout) {
    const { dispatch } = this.props;
    if (value && ((_.isArray(value) && !_.isEmpty(value)) || (_.isString(value) && value.length >= 2) || (_.isNumber(value) && value>0))) {
      await dispatch(LockerActions.setLockerReservationsFilter(field, value));
      if (useTimeout) {
        if (filterTimeout) clearTimeout(filterTimeout);
        filterTimeout = setTimeout(async () => {
          await this.fetchDeliveries()
        }, 500);
      }
      else {
        dispatch(UtilsActions.setSpinnerVisibile(true));
        await this.fetchDeliveries()
        dispatch(UtilsActions.setSpinnerVisibile(false));
      }
    } else {
      if (!useTimeout)
        dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(LockerActions.setLockerReservationsFilter(field, undefined));
      await this.fetchDeliveries()
      if (!useTimeout)
        dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onResetFilters() {
    const { dispatch } = this.props;
    await dispatch(LockerActions.resetLockerReservationsFilters());
    dispatch(initialize('LockersReservationFilters', {}));
    await this.fetchDeliveries();
  }

  async onSelectDelivery(lockerReservation) {
    const { dispatch, lockers } = this.props;
    const selectedLockerReservation = lockers.selectedLockerReservation
    if (selectedLockerReservation&&selectedLockerReservation.id===lockerReservation.id)
      return;
    try {
      dispatch(LockerActions.selectLockerReservation(lockerReservation));
      dispatch(initialize('DeliveryForm', lockerReservation));
      this.openOperationalSection()
    } catch (error) {
      dispatch(LockerActions.selectLockerReservation({}));
    } finally {
      
    }
  }

  async onAppendDeliveries(page) {
    const { dispatch } = this.props;
    const append = true;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await this.fetchDeliveries(page, append);
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onFetchDeliveriesReservationsOnPage(page) {
    const { dispatch } = this.props;
    this.fetchDeliveries(page);
  }

  async onDeliveryEditConfirmed(lockerId, lockerReservationId, lockerReservationData, lockerType) {
    const { dispatch, lockers } = this.props;
    const data = lockerReservationData;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const lockerReservationResponse = await dispatch(LockerActions.editLockerReservation(lockerId,lockerReservationId,data));
      if (lockerReservationResponse.ERROR) {
        let errorMessage = `lockerReservationError${lockerType===LOCKER_TYPE.PARCEL_ROOM ? 'ParcelRoom' : ''}${LOCKER_RESERVATION_ERRORS[lockerReservationResponse.ERROR.toString()] || ""}`;
        dispatch(UtilsActions.setSpinnerVisibile(false));
        dispatch(ModalActions.showModal({
          modalType: 'ERROR_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
          },
        }));
      }
      else {
        dispatch(ModalActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'lockerReservationPropertiesAssigned' }} /></h6>),
          },
        }));
        this.closeOperationalSection()
        dispatch(UtilsActions.setSpinnerVisibile(false));
        this.onRefreshDeliveries()
      }
    } catch (error) {
      let errorMessage = 'lockerReservationError';
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
      this.closeOperationalSection()
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.onRefreshDeliveries()
    }
  }

  async onDeliveryCreateConfirmed(lockerId, lockerReservationData, createAsDelivered, lockerType) {
    const { dispatch } = this.props;
    const data = lockerReservationData;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const lockerReservationResponse = createAsDelivered ? 
        await dispatch(LockerActions.createLockerReservationAsDelivered(lockerId,data)) : 
        await dispatch(LockerActions.createLockerReservation(lockerId,data))
      if (lockerReservationResponse.ERROR) {
        let errorMessage = `lockerReservationError${lockerType===LOCKER_TYPE.PARCEL_ROOM ? 'ParcelRoom' : ''}${LOCKER_RESERVATION_ERRORS[lockerReservationResponse.ERROR.toString()] || ""}`;
        dispatch(UtilsActions.setSpinnerVisibile(false));
        dispatch(ModalActions.showModal({
          modalType: 'ERROR_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
          },
        }));
      }
      else {
        dispatch(ModalActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="lockerReservationCreated"/></h6>),
          },
        }));
        this.closeOperationalSection()
        dispatch(UtilsActions.setSpinnerVisibile(false));
        dispatch(LockerActions.resetLockerReservationsFilters());
        await this.fetchDeliveries();
      }
    } catch (error) {
      let errorMessage = 'lockerReservationError';
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
      this.closeOperationalSection()
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(LockerActions.resetLockerReservationsFilters());
      await this.fetchDeliveries();
    }
  }

  async onDeleteDeliveryRequest(lockerId, lockerReservationId, notes) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        body: <Entity entity="deleteLockerReservationDataMessage" />,
        onConfirmText: <Entity entity="confirm" />,
        onConfirm: () => this.onDeleteDeliveryConfirm(lockerId, lockerReservationId, notes),
        onCancelText: <Entity entity="cancel" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onDeleteDeliveryConfirm(lockerId, lockerReservationId, notes) {  
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(LockerActions.deleteLockerReservationByManager(lockerId, lockerReservationId, notes));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'lockerReservationDeleted' }} /></h6>),
        },
      }));
    } catch (error) {
      let errorMessage = 'lockerReservationDeleteError';
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(LockerActions.setOperationalModeReservations(false));
      dispatch(LockerActions.selectLockerReservation({}));
      this.onRefreshDeliveries()
    }
  }

  onSendToDeliveredRequest(lockerId, reservationPin, deliveredStatusString) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        body: <Entity entity="deliverySendToDeliveredConfirm" data={{ deliveredName: deliveredStatusString }}/>,
        onConfirmText: <Entity entity="confirm" />,
        onConfirm: () => this.onSendToDeliveredConfirm(lockerId, reservationPin),
        onCancelText: <Entity entity="cancel" />,
        onCancel: () => {
          dispatch(ModalActions.hideModal())
        },
      },
    };
    dispatch(ModalActions.showModal(params));
  }
  
  async onSendToDeliveredConfirm(lockerId, reservationPin) {  
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const response = await dispatch(LockerActions.sendLockerReservationToDelivered(lockerId, reservationPin));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="deliverySendToDeliveredSuccess"/></h6>),
        },
      }));
    } catch (error) {
      let errorMessage = 'deliverySendToDeliveredError';
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
    } finally {
      this.closeOperationalSection()
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.onRefreshDeliveries()
    }
  }

  onSendToPickedupRequest(lockerId, reservationPin, pickedUpStatusString) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        body: <Entity entity="deliverySendToPickedupConfirm" data={{ pickedupName: pickedUpStatusString }} />,
        onConfirmText: <Entity entity="confirm" />,
        onConfirm: () => this.onSendToPickedupConfirm(lockerId, reservationPin),
        onCancelText: <Entity entity="cancel" />,
        onCancel: () => {
          dispatch(ModalActions.hideModal())
        },
      },
    };
    dispatch(ModalActions.showModal(params));
  }
  
  async onSendToPickedupConfirm(lockerId, reservationPin) {  
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const response = await dispatch(LockerActions.sendLockerReservationToPickedup(lockerId, reservationPin));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="deliverySendToPickedupSuccess"/></h6>),
        },
      }));
    } catch (error) {
      let errorMessage = 'deliverySendToPickedupError';
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
    } finally {
      this.closeOperationalSection()
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.onRefreshDeliveries()
    }
  }
  
  async onRefreshDeliveries() {
    const { lockerReservations } = this.props;
    const page = lockerReservations && lockerReservations.data && lockerReservations.data.pagination && lockerReservations.data.pagination.number;
    this.onFetchDeliveriesReservationsOnPage(page);
  }

  onOrderChanged() {
    const { dispatch } = this.props;
    this.fetchDeliveries()
  }

  async onCreateNewDelivery() {
    const { dispatch } = this.props;
    dispatch(LockerActions.selectLockerReservation({}));
    dispatch(initialize('DeliveryForm', {}));
    this.openOperationalSection()
  }

  onShowInfo() {
    /* TODO */
    /*
    const helpURL = localizeHelpCenterLink('luckey-floor-plans');
    window.open(helpURL);
    */
  }

  onSharePin(pinData) {
    this.setState({ showSharingSection: true , selectedPinToShare: pinData });
  }

  async onExportElements(format) {
    const { dispatch, lockers } = this.props;
    const lockersData = (lockers && lockers.data && lockers.data.content) || []
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try { await dispatch(LockerActions.exportReservations(format, lockersData)) }
    catch (error) { }
    dispatch(UtilsActions.setSpinnerVisibile(false));
  }

  componentDidUpdate(prevProps, prevState) {
    const { lockerReservations: { content: currentReservations }, lockers } = this.props;
    const { lockerReservations: { content: previousReservations } } = prevProps;
    const lockersData = (lockers && lockers.data && lockers.data.content) || []
    
    if (currentReservations && previousReservations && currentReservations !== previousReservations) {
      const calendarEvents = _.map(currentReservations, (reservation) => {
        const locker = lockersData && lockersData.find(locker => locker.id === reservation.lockerId)
        return {
          id: reservation.id,
          hexColor: BLUE,
          title: (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 3 }}>
              {reservation.lockerId && locker && locker.type === LOCKER_TYPE.LOCKER ? (
                <LockersIconCustom style={{ width: 15 }} />
              ) : (
                <ParcelRoomIconCustom style={{ width: 15 }} />
              )}
              <h4 style={{ margin: 0, marginLeft: 5, fontSize: 15 }}>
                {`#${reservation.id} - ${(locker && locker.name) || ''}`}
              </h4>
            </div>
          ),
          start: moment(reservation.fromDate).toDate(),
          end: moment(reservation.toDate).toDate(),
        }
      });
      this.setState({ calendarEvents });
    }
  }

  onSetCurrentMonth(date) {
    this.setState({
      currentMonth: moment(date).month(),
    });
  }

  async onFetchReservationsWhenMonthChange(date) {
    const { currentMonth } = this.state;
    if (moment(date).month() !== currentMonth) {
      try {
        await this.onFetchReservationsByFilter(date);
      } finally {
        this.onSetCurrentMonth(date);
      }
    }
  }

  async onFetchReservationsByFilter(date) {
    const { dispatch } = this.props;
    const beginDate = moment(date).startOf('month').subtract(1, 'month').valueOf();
    const endDate = moment(date).endOf('month').add(1, 'month').valueOf();
    dispatch(LockerActions.setLockerReservationsFilter('dateIntervalFrom', beginDate));
    dispatch(LockerActions.setLockerReservationsFilter('dateIntervalTo', endDate));
    setTimeout(async () => {
      try {
        await dispatch(LockerActions.fetchLockerReservations(0, 100));
      } catch (error) {
      }
    }, 300);
  }

  async onDeliveryViewModeChange(activeTab) {
    const { dispatch } = this.props;
    const selectedViewMode = activeTab === 0 ? VIEW_MODES.TABLE : VIEW_MODES.CALENDAR;
    if (activeTab === this.state.activeTab) {
      return;
    }
    try {
      dispatch(UtilsActions.setViewLoading(true));
      if (selectedViewMode === VIEW_MODES.CALENDAR) {
        const currentDate = moment();
        await this.onFetchReservationsByFilter(currentDate);
      } else {
        dispatch(LockerActions.resetLockerReservationsFilters());
        await dispatch(LockerActions.fetchLockerReservations(0, 20));
      }
      dispatch(UtilsActions.setViewLoading(false));
    } catch (error) {
      dispatch(UtilsActions.setViewLoading(false));
    }

    this.setState({ activeTab });
    localStorage.setItem('lockersReservationViewMode', selectedViewMode);
  }

  onSelectCalendarInterval(calendarInterval) {
    const dateFrom = moment(calendarInterval.start).valueOf();
    const dateTo = moment(calendarInterval.end).valueOf();
    this.onCreateNewDelivery();
    const { dispatch } = this.props;
    dispatch(change('DeliveryForm', 'fromDate', dateFrom));
    dispatch(change('DeliveryForm', 'toDate', dateTo));
  }

  eventStyleGetter(event, start, end, isSelected) {
    const style = {
      backgroundColor: event.hexColor,
      borderRadius: '0px',
      opacity: 1,
      color: 'white',
      border: '0.5px solid white',
      display: 'block',
    };
    return {
      style,
    };
  }

  onSelectDeliveryFromCalendar(deliveryEvent) {
    const { lockerReservations: { content: reservationsData } } = this.props;
    const reservationToSelect = _.find(reservationsData, reservation => reservation.id === deliveryEvent.id);
    this.onSelectDelivery(reservationToSelect);
  }

  onSelectCalendarViewMode(viewMode) {
    localStorage.setItem('deliveriesCalendarViewMode', viewMode);
    this.setState({ deliveriesCalendarViewMode: viewMode });
  }

  render() {
    const { lockers, lockerReservations, themeName, viewLoading } = this.props;
    const { showSharingSection, selectedPinToShare, activeTab, startingDate, calendarEvents, defaultCalendarView } = this.state;
    const numberOfDeliveries = (lockerReservations && lockerReservations.pagination && lockerReservations.pagination.totalElements) || 0;
    const lockersData = (lockers && lockers.data && lockers.data.content) || []
    const selectedLockerReservationId = lockers?.selectedLockerReservation?.id
    const selectedLockerReservationName = selectedLockerReservationId ? `#${selectedLockerReservationId}` : undefined
    const canEditCreateLockerReservaitons = true; // TODO AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.LOCKER);
    const localizer = momentLocalizer(moment);

    return (
      <div style={{ backgroundColor: 'white'}}>
        <PresentationalViewHeader
          themeName={themeName}
          onNewEntity={canEditCreateLockerReservaitons ? () => this.onCreateNewDelivery() : null}
          newEntityTitle="bookADeliveryPickupPoint"
          newEntityIconName="icon-simple-line-icons-user-following"
          selectableFieldName={'lockerId'}
          selectableFieldTitle={undefined}
          onSelectOption={(value) => this.onSetFilter('lockerId', value==="__ANY__"||isNaN(parseInt(value))?undefined:parseInt(value), true)}
          selectableOptions={[
            (<Entity
              key={"lockerReservationAnyPickupPoint"}
              componentClass={TranslatableOption}
              value={"__ANY__"}
              componentAttribute="text"
              entity={"lockerReservationAnyPickupPoint"}
            />),
            ..._.map(_.sortBy(lockersData, (locker => locker.name.toLowerCase())), locker =>
              <option value={locker.id} key={locker.id}>
                {locker.name}
              </option>
            )
          ]}
          selectableFieldName2={'status'}
          selectableFieldTitle2={undefined}
          onSelectOption2={(value) => this.onSetFilter('status', value==="__ANY__"?undefined:value, true)}
          selectableOptions2={[
            (<Entity
              key={"lockerReservationAnyStatus"}
              componentClass={TranslatableOption}
              value={"__ANY__"}
              componentAttribute="text"
              entity={"lockerReservationAnyStatus"}
            />),
            ..._.map(LOCKER_RESERVATION_STATUSES_PARAMETERS, status =>
              <option value={status.value} key={status.value}>
                {L20NContext.getSync(status.string)}
              </option>
            )
          ]}
          onSearchReset={() => this.onResetFilters()}
          onFilterChange={value => this.onSetFilter('search', _.trim(value), true)}
          onSearchSubmit={() => this.fetchDeliveries()}
          numberOfElements={numberOfDeliveries}
          onExportElements={format => this.onExportElements(format)}
        />
        <div style={{ top: 145, position: 'fixed', backgroundColor: 'white', zIndex: 1000}}>
          <Tabs
            value={activeTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, index) => this.onDeliveryViewModeChange(index)}
            TabIndicatorProps={{
              style: { display: 'none' }
            }}
          >
            <Tab icon={<ListViewIcon style={{ fontSize: 30 }} />} />
            <Tab icon={<CalendarViewIcon style={{ fontSize: 30 }} />} />
          </Tabs>
        </div>
        <div style={{ marginTop: 50 }}>
          {activeTab === 0 ? (
            <DeliveriesTableView
              onSelectDelivery={locker => this.onSelectDelivery(locker)}
              onFetchDeliveriesReservationsOnPage={page => this.onFetchDeliveriesReservationsOnPage(page)}
              onRefreshDeliveries={() => this.onRefreshDeliveries()}
              onNewDelivery={canEditCreateLockerReservaitons ? () => this.onCreateNewDelivery() : null}
              onOrderChanged={() => this.onOrderChanged()}
              onSharePin={pinData => this.onSharePin(pinData)}
            />
          ) : (
            viewLoading ? <ListLoadingView /> : (
              <div style={{ marginTop: 100 }}>
                <BigCalendar
                  events={calendarEvents}
                  localizer={localizer}
                  startAccessor="start"
                  endAccessor="end"
                  selectable
                  showMultiDayTimes
                  culture={moment.locale()}
                  style={{ margin: 'auto', height: 850, width: '95%', padding: 15, paddingTop: 50 }}
                  className="calendar-view"
                  defaultView={defaultCalendarView}
                  defaultDate={startingDate}
                  onSelectEvent={event => this.onSelectDeliveryFromCalendar(event)}
                  onSelectSlot={dateInterval => this.onSelectCalendarInterval(dateInterval)}
                  eventPropGetter={
                    (event, start, end, isSelected) => this.eventStyleGetter(event, start, end, isSelected)
                  }
                  onNavigate={(date) => this.onFetchReservationsWhenMonthChange(date)}
                  onView={(view) => this.onSelectCalendarViewMode(view)}
                  messages={{
                    today: <Entity entity="calendarLabels" data={{ type: 'today' }} />,
                    previous: '<',
                    next: '>',
                    month: <Entity entity="calendarLabels" data={{ type: 'month' }} />,
                    week: <Entity entity="calendarLabels" data={{ type: 'week' }} />,
                    day: <Entity entity="calendarLabels" data={{ type: 'day' }} />,
                    agenda: <Entity entity="calendarLabels" data={{ type: 'agenda' }} />,
                    noEventsInRange: <Entity entity="noDeliveries" />,
                  }}
                />
              </div>
            )
          )}
        </div>

        <OperationalView
          themeName={themeName}
          isVisible={lockers.isOperationalModeReservations}
          onClose={() => this.closeOperationalSection()}
          style={{ margin: 0, padding: 0 }}
          title={<Entity entity={selectedLockerReservationName?selectedLockerReservationName:"createLockerReservation"} data={{ name: 'lockersReservationOperational' }} key={selectedLockerReservationId} />}
        >
          <DeliveriesOperationalSection
            themeName={themeName}
            onDeliveryEditConfirmed={(lockerId, lockerReservationId, lockerReservationData, lockerType)=>this.onDeliveryEditConfirmed(lockerId, lockerReservationId, lockerReservationData, lockerType)}
            onDeliveryCreateConfirmed={(lockerId, lockerData, lockerType) => this.onDeliveryCreateConfirmed(lockerId, lockerData, false, lockerType)}
            onDeliveryCreateAsDeliveredConfirmed={(lockerId, lockerData, lockerType) => this.onDeliveryCreateConfirmed(lockerId, lockerData, true, lockerType)}
            onDeleteDeliveryRequest={selectedLockerReservationId && canEditCreateLockerReservaitons ? 
              (lockerId,lockerReservationId,notes) => this.onDeleteDeliveryRequest(lockerId,lockerReservationId,notes) : null
            }
            onSendToDeliveredRequest={(lockerId,reservationPin,deliveredStatusString) => this.onSendToDeliveredRequest(lockerId,reservationPin,deliveredStatusString)}
            onSendToPickedupRequest={(lockerId,reservationPin,pickedUpStatusString) => this.onSendToPickedupRequest(lockerId,reservationPin,pickedUpStatusString)}
            onSharePin={pinData => this.onSharePin(pinData)}
          />
        </OperationalView>
        <Modal show={showSharingSection} onHide={() => this.setState({ showSharingSection: false })}>
          <div style={{ padding: 20, paddingTop: 10 }}>
            <div style={{ display: 'flex', marginBottom: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <h2 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="shareDeliveryPinCode" /></h2>
              <IconButton onClick={() => this.setState({ showSharingSection: false })}>
                <CloseIcon style={{ color: RED, fontSize: 40 }} />
              </IconButton>
            </div>
            <DeliveryPinShareView pinCode={selectedPinToShare} />
          </div>
        </Modal>
      </div>
    );
  }
} 

export default DeliveriesManagementView;
