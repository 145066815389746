import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import { initialize, submit } from 'redux-form';
import { MATCH_TAG_MODE, PERMISSIONS, PERMISSION_ENTITIES } from '../../../_config/consts';
import AbilityProvider from '../../../permissionsUtils/AbilityProvider.js';
import * as ModalActions from '../../../redux/actions/modal.actions.js';
import * as PrivateUnitActions from '../../../redux/actions/privateUnit.actions';
import * as TagActions from '../../../redux/actions/tag.actions';
import * as UtilsActions from '../../../redux/actions/utils.actions';
import MDButton from '../../MDButton/MDButton.jsx';
import Permission from '../../Permission/Permission.jsx';
import PrivateUnitsDefaultForm from '../../forms/PrivateUnitsDefaultForm.jsx';


@connect((state) => ({lockTags: state.tags.lock.data, defaultConfig: state.privateUnit.defaultConfig.data, privateUnit: state.privateUnit.selectedPrivateUnit}))
class PrivateUnitsSettingsOperationalSection extends React.Component {

  async componentWillMount() {
    const { dispatch } = this.props;
    try {
      await dispatch(PrivateUnitActions.fetchPrivateUnitsDefault());
    } catch(error) {
      console.log(error)
      await dispatch(PrivateUnitActions.savePrivateUnitsDefault({}));
    }

    const { defaultConfig } = this.props;
    await dispatch(initialize('PrivateUnitsDefaultForm', {
      ...defaultConfig,
      lockTagMatchingMode: defaultConfig.lockTagMatchingMode?defaultConfig.lockTagMatchingMode:MATCH_TAG_MODE.AT_LEAST_ONE_TAG,
      maxUsers: defaultConfig.maxUsers?defaultConfig.maxUsers:"0",
      maxSmartLocks: defaultConfig.maxSmartLocks?defaultConfig.maxSmartLocks:"0",
      maxInvitations: defaultConfig.maxInvitations?defaultConfig.maxInvitations:"0",
      maxPins: defaultConfig.maxPins?defaultConfig.maxPins:"0",
    }));

    this.fetchLocksByTags()
  }

  fetchLocksByTags(page, append) {
    const { dispatch } = this.props;
    dispatch(PrivateUnitActions.cancelFetchLocksByTags());
    dispatch(PrivateUnitActions.fetchLocksByTagsFromForm(page, append, true));
  }

  filterLockTags(value) {
    const { dispatch } = this.props;
    const includeSpecialTags = true;
    dispatch(TagActions.cancelFetchLockTagsByFilter());
    dispatch(TagActions.fetchLockTagsByFilter(value, 200, includeSpecialTags));
  }

  async savePrivateUnitsDefault(values) {
    const { dispatch } = this.props;
    console.log(values)
    try {
      const parsedValues = {
        ...values,
        lockTagIds: values.lockTags?values.lockTags.map(e=>{return e.id}):[],
        lockTags:undefined,
      }
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(PrivateUnitActions.editPrivateUnitsDefault(parsedValues));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="settingUpdateSuccessfully" /></h6>),
        },
      }));
    } catch (error) {
      console.log(error)
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="settingUpdateError" /></h6>),
        },
      }));
    }
  }

  render() {
    const { dispatch, form, lockTags, defaultConfig, privateUnit } = this.props;
    return (
      <div style={{ paddingLeft: 20, paddingRight: 25 }}>
        <PrivateUnitsDefaultForm
          availableLockTags={lockTags}
          locksFilteredByTags={(privateUnit && privateUnit.locksFilteredByTags) || []}
          onFetchMoreLocks={page => this.fetchLocksByTags(page, true)}
          onLockInputChange={value => this.filterLockTags(value)}
          onLockTagsChange={() => this.fetchLocksByTags()}
          onLockFilterModeChange={() => this.fetchLocksByTags()}
          onSubmit={values => this.savePrivateUnitsDefault(values)}
        />
        <div style={{ backgroundColor: 'white', position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}>
          <Permission do={[PERMISSIONS.UPDATE]} on={PERMISSION_ENTITIES.COMPANY} abilityHelper={AbilityProvider.getAbilityHelper()}>
            <MDButton
              containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
              style={{ height: 45, borderRadius: 0 }}
              title={<Entity entity="save" />}
              onClick={() => dispatch(submit('PrivateUnitsDefaultForm'))}
            />
          </Permission>
          </div>
      </div>
    );
  }
} 

export default PrivateUnitsSettingsOperationalSection;
