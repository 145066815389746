// @ts-nocheck
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
/* Common Components */
import PrivacyPolicyView from './routes/Agreements/PrivacyPolicyView.jsx';
import TermsAndConditionsView from './routes/Agreements/TermsAndConditionsView.jsx';
import Analytics from './routes/Bookey/Analytics.jsx';
import EventsAgenda from './routes/Bookey/Events/EventsAgenda.jsx';
import ReservationsAgenda from './routes/Bookey/Reservations/ReservationsAgenda.jsx';
import Resources from './routes/Bookey/Resources.jsx';
import Cards from './routes/Cards.jsx';
import Credentials from './routes/Credentials.jsx';
import DashboardView from './routes/DashboardView.jsx';
import DefaultRootView from './routes/DefaultRootView.jsx';
import Gateways from './routes/Gateways.jsx';
import Guests from './routes/Guests.jsx';
import Locks from './routes/Locks.jsx';
import AdminEvents from './routes/LogEvents/AdminEvents.jsx';
import SmartLocksEvents from './routes/LogEvents/SmartLocksEvents.jsx';
import Tickets from './routes/Tickets.jsx';
import TicketsAnalytics from './routes/TicketsAnalytics.jsx';
import PrivateUnits from './routes/PrivateUnits.jsx';
/* Pages */
import { LICENSE_TYPES } from './_config/consts.js';
import AuthGuestRoute from './components/Auth/AuthGuestRoute.jsx';
import AuthRoute from './components/Auth/AuthRoute.jsx';
import ProgressBarOverlay from './components/LoadingProgressBar/ProgressBarOverlay.jsx';
import AdvancedAlertModal from './components/Modals/AdvancedAlertModal.jsx';
import BaseAlertModal from './components/Modals/BaseAlertModal.jsx';
import SupportTicketOperationalSection from './components/OperationalView/SupportTicketOperationalSection.jsx';
import WhatsNewOperationalSection from './components/OperationalView/WhatsNewOperationalSection.jsx';
import SpinnerBackground from './components/Spinner/SpinnerBackground.jsx';
import SpinnerOverlay from './components/Spinner/SpinnerOverlay.jsx';
import AppContainer from './header/AppContainer.jsx';
import EmptyLayout from './header/EmptyLayout.jsx';
import HeaderLayout from './header/HeaderLayout.jsx';
import AccessProfilesView from './routes/AccessProfiles/AccessProfilesView.jsx';
import ActivateAccountView from './routes/ActivateAccountView.jsx';
import AreasManagement from './routes/AreasManagement.jsx';
import BookeyResourceMaps from './routes/Bookey/BookeyResourceMaps.jsx';
import BookeyAvailabilityAgenda from './routes/Bookey/Reservations/BookeyAvailabilityAgenda.jsx';
import ReservationCheckinConfirmView from './routes/Bookey/Reservations/ReservationCheckinConfirmView.jsx';
import ReservationConfirmView from './routes/Bookey/Reservations/ReservationConfirmView.jsx';
import CamerasView from './routes/Cameras/CamerasView.jsx';
import ChainelsActivationView from './routes/ChainelsActivationView.jsx';
import LuckeyStorePage from './routes/CloudCredits/LuckeyStorePage.jsx';
import WalletManagementPage from './routes/CloudCredits/WalletManagementPage.jsx';
import Communications from './routes/Communications.jsx';
import DeliveriesManagementView from './routes/DeliveriesManagementView.jsx';
import FloorPlans from './routes/FloorPlans/FloorPlans.jsx';
import GatewaysAtlasView from './routes/GatewaysAtlasView.jsx';
import GuestRemoteOpenView from './routes/GuestRemoteOpenView.jsx';
import HyperKeys from './routes/HyperKeys.jsx';
import Installers from './routes/Installers.jsx';
import Invitations from './routes/Invitations.jsx';
import ItemRegistry from './routes/ItemRegistry.jsx';
import PickupPointsManagement from './routes/PickupPointsManagement.jsx';
import SubcompaniesSmartLocksEvents from './routes/LogEvents/SubcompaniesSmartLocksEvents.jsx';
import Login from './routes/LoginFlow/Login.jsx';
import LoginEmailOTPView from './routes/LoginFlow/LoginEmailOTPView.jsx';
import LoginInvitationView from './routes/LoginFlow/LoginInvitationView.jsx';
import LoginTwoFactorView from './routes/LoginFlow/LoginTwoFactorView.jsx';
import OctorateActivationView from './routes/OctorateActivationView.jsx';
import PinsView from './routes/PinsView.jsx';
import PlatformUsers from './routes/PlatformUsers.jsx';
import ResetPassword from './routes/ResetPasswordFlow/ResetPassword.jsx';
import ResetPassowrdLanding from './routes/ResetPasswordFlow/ResetPasswordLanding.jsx';
import ResetPassowordOnboardingGuest from './routes/ResetPasswordFlow/ResetPasswordOnboardingGuest.jsx';
import ResetPasswordOnboardingInstaller from './routes/ResetPasswordFlow/ResetPasswordOnboardingInstaller.jsx';
import Roles from './routes/Roles.jsx';
import ServiceNotFoundView from './routes/ServiceNotFoundView.jsx';
import ServiceUnavailableView from './routes/ServiceUnavailableView.jsx';
import Settings from './routes/Settings.jsx';
import TagsManagementView from './routes/TagsManagementView.jsx';
import VisitorsActionsView from './routes/VisitorsManagement/VisitorsActionsView.jsx';
import VisitorsManagementView from './routes/VisitorsManagement/VisitorsManagementView.jsx';

const routes = ({ store, history }) => (
  <DndProvider backend={HTML5Backend}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <AppContainer>
          <Route path="/login" component={Login} />
          <Route path="/loginTwoFactor" component={LoginTwoFactorView} />
          <Route path="/loginOTPEmail" component={LoginEmailOTPView} />
          <Route path="/loginInvitation" component={LoginInvitationView} />
          <Route path="/serviceNotFound" component={ServiceNotFoundView} />
          <Route path="/registration/confirm" component={ResetPassword} />
          <Route path="/password/reset" component={ResetPassword} />
          <Route path="/activateAccount" component={ActivateAccountView} />
          <Route path="/resetPassLanding" component={ResetPassowrdLanding} />
          <Route path="/reservationConfirm" component={ReservationConfirmView} />
          <Route path="/reservationCheckIn" component={ReservationCheckinConfirmView} />
          <Route path="/chainelsActivation" component={ChainelsActivationView} />
          <Route path="/octorateActivation" component={OctorateActivationView} />
          <Route path="/resetPassOnboardingGuest" component={ResetPassowordOnboardingGuest} />
          <Route path="/resetPassOnboardingInstaller" component={ResetPasswordOnboardingInstaller} />
          <Route path="/privacyPolicy" component={PrivacyPolicyView} />
          <Route path="/serviceUnavailable" component={ServiceUnavailableView} />
          <Route path="/termsAndConditions" component={TermsAndConditionsView} />
          <Route path="/visitorsActions" component={VisitorsActionsView} />
          <Switch>
            <AuthRoute exact path="/" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.LITE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.RFID]} component={DefaultRootView} />
            <AuthRoute path="/dashboard" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE]} component={DashboardView} />
            <AuthRoute path="/guests" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.LITE, LICENSE_TYPES.RFID]} component={Guests} />
            <AuthRoute path="/locks" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.LITE, LICENSE_TYPES.RFID]} component={Locks} />
            <AuthRoute path="/cameras"layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE]} component={CamerasView} />
            <AuthRoute path="/tagsManagement" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.LITE, LICENSE_TYPES.RFID]} component={TagsManagementView} />
            <AuthRoute path="/floorPlans" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE]} component={FloorPlans} />
            <AuthRoute path="/gateways" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.LITE, LICENSE_TYPES.RFID]} component={Gateways} />
            <AuthRoute path="/gatewaysAtlas" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.RFID]} component={GatewaysAtlasView} />
            <AuthRoute path="/credentials" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.LITE]} component={Credentials} />
            <AuthRoute path="/cards" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.LITE, LICENSE_TYPES.RFID]} component={Cards} />
            <AuthRoute path="/pins" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.LITE, LICENSE_TYPES.RFID]} component={PinsView} />
            <AuthRoute path="/hyperKeys" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.RFID, LICENSE_TYPES.ENGINE]} component={HyperKeys} />
            <AuthRoute path="/itemRegistry" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.RFID, LICENSE_TYPES.ENGINE]} component={ItemRegistry} />
            <AuthRoute path="/visitorsManagement" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE]} component={VisitorsManagementView} />
            <AuthRoute path="/communications" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE]} component={Communications} />
            <AuthRoute path="/adminEvents" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.RFID, LICENSE_TYPES.ENGINE, LICENSE_TYPES.LITE]} component={AdminEvents} />
            <AuthRoute path="/smartLockEvents" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.RFID, LICENSE_TYPES.ENGINE, LICENSE_TYPES.LITE]} component={SmartLocksEvents} />
            <AuthRoute path="/subcompaniesSmartLockEvents" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.RFID ]} component={SubcompaniesSmartLocksEvents} />
            <AuthRoute path="/roles" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.RFID, LICENSE_TYPES.ENGINE]} component={Roles} />
            <AuthRoute path="/walletCredits" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE,  LICENSE_TYPES.ENGINE, LICENSE_TYPES.RFID, LICENSE_TYPES.LITE]} component={WalletManagementPage} />
            <AuthRoute path="/luckeyStore" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.RFID, LICENSE_TYPES.LITE, LICENSE_TYPES.ENGINE]} component={LuckeyStorePage} />
            <AuthRoute path="/platformUsers" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.RFID, LICENSE_TYPES.LITE, LICENSE_TYPES.ENGINE]} component={PlatformUsers} />
            <AuthRoute path="/installers" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.RFID, LICENSE_TYPES.LITE, LICENSE_TYPES.ENGINE]} component={Installers} />
            <AuthRoute path="/settings" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.LITE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.RFID]} component={Settings} />
            <AuthRoute path="/resources" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE,  LICENSE_TYPES.LITE]} component={Resources} />
            <AuthRoute path="/availability" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.LITE]} component={BookeyAvailabilityAgenda} />
            <AuthRoute path="/reservations" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.LITE]} component={ReservationsAgenda} />
            <AuthRoute path="/analytics" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.LITE]} component={Analytics} />
            <AuthRoute path="/bookeyMaps" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.LITE]} component={BookeyResourceMaps} />
            <AuthRoute path="/manageEvents" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.LITE]} component={EventsAgenda} />
            <AuthRoute path="/invitations" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.LITE]}  component={Invitations} />
            <AuthRoute path="/accessProfiles" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.LITE]} component={AccessProfilesView} />
            <AuthRoute path="/tickets" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE]} component={Tickets} />
            <AuthRoute path="/ticketAnalytics" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE]} component={TicketsAnalytics} />
            <AuthRoute path="/areasManagement" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.RFID, LICENSE_TYPES.ENGINE]} component={AreasManagement} />
            <AuthRoute path="/privateUnits" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE]} component={PrivateUnits} />
            <AuthRoute path="/pickupPointsManagement" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.RFID, LICENSE_TYPES.ENGINE]} component={PickupPointsManagement} />
            <AuthRoute path="/deliveriesManagement" layout={HeaderLayout} licenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.RFID, LICENSE_TYPES.ENGINE]} component={DeliveriesManagementView} />
            <AuthGuestRoute path="/remoteOpen" layout={EmptyLayout} component={GuestRemoteOpenView} avoidFetchSettings />
          </Switch>
        </AppContainer>
      </ConnectedRouter>
      <BaseAlertModal />
      <AdvancedAlertModal />
      <SpinnerOverlay />
      <SpinnerBackground />
      <ProgressBarOverlay />
      <WhatsNewOperationalSection />
      <SupportTicketOperationalSection />
    </Provider>
  </DndProvider>
);

export default routes;
