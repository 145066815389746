// @ts-nocheck
import Warning from '@material-ui/icons/Warning.js';
import { Form } from '@sketchpixy/rubix';
import { Entity, ctx as L20NContext  } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import { LANGUAGES, ORANGE, PERMISSIONS, PERMISSION_ENTITIES, PRIVATE_UNIT_TEMPLATE_CUSTOM_PLACEHOLDERS, PRIVATE_UNIT_TEMPLATE_LANGUAGES } from '../../_config/consts.js';
import MDButton from '../MDButton/MDButton.jsx';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline';
import Permission from '../Permission/Permission.jsx';
import CustomField from './Fields/CustomField.jsx';
import { MaximizeTwoTone } from '@material-ui/icons';
import * as ModalActions from '../../redux/actions/modal.actions';
import TemplateEditor from '../TemplatePDF/TemplateEditor.jsx';


const styles = theme => ({
  button: {
    color: 'red',
    fontSize: 13,
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginLeft: -15,
    width: 'max-content',
  },
});

const validate = values => {
  const errors = {};

  return errors;
};

@reduxForm({ form: 'PrivateUnitTemplateForm', validate })
@connect(state => ({ form: state.form.PrivateUnitTemplateForm }))
class PrivateUnitTemplateForm extends React.Component {

  templateEditorRef = React.createRef();
  handleSubmit = () => {
    const editorContent = this.templateEditorRef.current.getEditorContent();
    this.props.onPrivateUnitTemplateFormSubmit({ ...this.props.form.values, data:editorContent });
  };

  render() {
    const { form, classes, onPrivateUnitTemplateFormSubmit } = this.props;

    const languageOptions = Object.values(PRIVATE_UNIT_TEMPLATE_LANGUAGES).map(e=>{
      return {
        ...e,
        label: L20NContext.getSync(e.label)
      }
    })

    const placeholderObjRef = form && form.values && form.values.placeholderObjRef

    const isGenerateMode = !Boolean(onPrivateUnitTemplateFormSubmit)

    return (
      <Form style={{ paddingLeft: 15, paddingRight: 15, marginTop: 20 }}>
        <input style={{ display: 'none' }} type="number" name="id" />
        {!isGenerateMode && 
        <div style={{display:'flex',flexDirection:'row',alignItems:'flex-end'}}>
          <Field
            id="language"
            name="language"
            title={<Entity entity="privateUnitTemplateLanguage" />}
            component={CustomField}
            className="form-control-select"
            componentClass="select"
          >
            {_.map(languageOptions, item =>
              <option value={item.value} key={item.value}>
                {item.label}
              </option>
            )}
          </Field>
        </div>
        }
        <TemplateEditor
          ref={this.templateEditorRef}
          containerStyle={{height: isGenerateMode ? 750 : 650, overflowY:'auto'}}
          initialContent={(form && form.values && form.values.data) || L20NContext.getSync("privateUnitTemplateEmpty")}
          customPlaceholders={PRIVATE_UNIT_TEMPLATE_CUSTOM_PLACEHOLDERS}
          isGenerateMode={isGenerateMode}
          placeholderObjRef={placeholderObjRef}
        />
        {!isGenerateMode && 
          <MDButton
            containerStyle={{ marginTop:30 }}
            title={<Entity entity="save" />}
            onClick={() => this.handleSubmit()}
          />
        }
      </Form>
    );
  }
} 

export default withStyles(styles)(PrivateUnitTemplateForm);