import _ from 'lodash';
import moment from 'moment';
import { BACKGROUND_TASKS_MAX_QUEUE, SUBSCRIPTION_TYPES } from '../../_config/consts';
import {
  ADD_BACKGROUND_TASK,
  REMOVE_BACKGROUND_TASK, SET_LOADING_PROGRESS_VALUE,
  SET_LOADING_PROGRESS_VISIBLE, SET_SPINNER_VISIBILITY,
  SET_VIEW_LOADING,
  SET_WHATS_NEW_VISIBLE,
  SET_SUPPORT_TICKET_FORM_VISIBLE
} from './actionTypes/utils';
import * as UserActions from './user.actions';
import * as MetricsActions from './metrics.actions';

export function setSpinnerVisibile(spinnerVisible) {
  return {
    type: SET_SPINNER_VISIBILITY,
    spinnerVisible,
  };
}

export function setViewLoading(isLoading) {
  return {
    type: SET_VIEW_LOADING,
    isLoading,
  };
}

export function setProgressBar(isLoading) {
  return {
    type: SET_VIEW_LOADING,
    isLoading,
  };
}

export function setIsWhatsNewVisible(isVisible) {
  return {
    type: SET_WHATS_NEW_VISIBLE,
    isVisible,
  };
}

export function setSupportTicketFormVisible(isVisible) {
  return {
    type: SET_SUPPORT_TICKET_FORM_VISIBLE,
    isVisible,
  };
}

export function setLoadingProgress(progress) {
  return {
    type: SET_LOADING_PROGRESS_VALUE,
    progress,
  };
}

export function setLoadingProgressVisible(isVisible, title = null) {
  return {
    type: SET_LOADING_PROGRESS_VISIBLE,
    isVisible,
    title,
  };
}

export function addBackgroundTask(data) {
  return {
    type: ADD_BACKGROUND_TASK,
    data,
  };
}

export function removeBackgroundTask(taskId) {
  return {
    type: REMOVE_BACKGROUND_TASK,
    taskId,
  };
}

export function addBackgroundTaskIfSlotAvailable(data) {
  return (dispatch, getState) => {
    const stateTasks = getState().utils.backgroundTasks;
    if (stateTasks.length >= BACKGROUND_TASKS_MAX_QUEUE) {
      throw new Error('TOO_MANY_TASKS');
    }
    dispatch(addBackgroundTask(data));
  };
}

export function addBackgroundTaskSingleton(data) {
  return (dispatch, getState) => {
    const stateTasks = getState().utils.backgroundTasks;
    const taskIndex = _.findIndex(stateTasks, task => task.type === data.type);
    if (taskIndex !== -1) {
      throw new Error('TOO_MANY_TASKS_SAME_TYPE');
    }
    dispatch(addBackgroundTaskIfSlotAvailable(data));
  };
}

export function hideProgressVisibleAndReset() {
  return (dispatch) => {
    dispatch(setLoadingProgress(0));
    dispatch(setLoadingProgressVisible(false));
  };
}


export function isProductionEnvironment() {
  try {
    const isLocal = _.includes(window.location.hostname.toLowerCase(), 'localhost');
    const isInternal = _.includes(window.location.hostname.toLowerCase(), '.internal.');
    const isSandbox = _.includes(window.location.hostname.toLowerCase(), '.sandbox.');
    return (!isSandbox && !isInternal && !isLocal);
  } catch (error) {
    return false;
  }
}


export function isArgoOfflineLocksEnabled() {
  return (dispatch, getState) => {
    try {
      const { user: { companyConfigurations: { argoOfflineEnabled } } } = getState();
      return argoOfflineEnabled;
    } catch (error) {
      return false;
    }
  };
}


export function isMetabaseEnabled() {
  return (dispatch, getState) => {
    const { user: { data, companyConfigurations: { advancedAnalytics } } } = getState();
    const isAdvancedAnalyticsEnabled = advancedAnalytics && advancedAnalytics.enabled == 'true';
    const userEmail = data.email;
    const isUserEnabled = advancedAnalytics && advancedAnalytics.users && _.findIndex(advancedAnalytics.users, user => user.email === userEmail) !== -1;
    return isUserEnabled && isAdvancedAnalyticsEnabled;
  };
}

export function getAdvacedAnalyticsURL() {
  return (dispatch, getState) => {
    const { user: { companyConfigurations: { advancedAnalytics } } } = getState();
    return advancedAnalytics && advancedAnalytics.url;
  };
}

export function isSupportUser() {
  return (dispatch, getState) => {
    try {
      const { user: { token: userToken } } = getState();
      if (!userToken || !userToken.hostname) return false;
      if (userToken && userToken.impersonateEmail && _.includes(userToken.impersonateEmail, 'sofialocks')) return true;
      return false;
    } catch (error) {
      return false;
    }
  };
}


export function isDashboardFilteringEnabled() {
  return (dispatch, getState) => {
    const { user: { data, companyConfigurations: { dashboardFiltersEnabled } } } = getState();
    const isDashboardFiltersEnabled = dashboardFiltersEnabled && dashboardFiltersEnabled === true;
    return isDashboardFiltersEnabled;
  };
}

export function getResourcesWorkspaceLabel() {
  return (dispatch, getState) => {
    try {
      const { user: { token: userToken }} = getState();
      if (!userToken || !userToken.hostname) return false;
      const isUnicorn = _.includes(userToken.hostname.toLowerCase(), 'unicorn.');
      if (isUnicorn) {
        return 'workspace'
      }
      return 'resourceWorkspace';
    } catch (error) {
      return 'resourceWorkspace';
    }
  };
}

export function isCustomFieldActive() {
  return (dispatch, getState) => {
    try {
      const { user: { token: userToken, companyConfigurations: { customFieldsEnabled } } } = getState();
      if (!userToken || !userToken.hostname) return false;
      const isSubscriptionActive = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.CUSTOM_FIELDS));
      return (isSubscriptionActive || customFieldsEnabled === true);
    } catch (error) {
      return false;
    }
  };
}

export function areLocksCustomFieldsPresent() {
  return (dispatch, getState) => {
    try {
      const { customFields: { locks: { content } } } = getState();
      if (!content || _.isEmpty(content)) return false;
      return true;
    } catch (error) {
      return false;
    }
  };
}

export function areUsersCustomFieldsPresent() {
  return (dispatch, getState) => {
    try {
      const { customFields: { users: { content } } } = getState();
      if (!content || _.isEmpty(content)) return false;
      return true;
    } catch (error) {
      return false;
    }
  };
}

export function hasSharedLocks() {
  return (dispatch, getState) => {
    try {
      const { locks: { shared: { content } } } = getState();
      if (!content || _.isEmpty(content)) return false;
      return true;
    } catch (error) {
      return false;
    }
  };
}

// TODO REMOVE CONFIG
export function isEagleEyeIntegrationActive() {
  return (dispatch, getState) => {
    try {
      const { user: { token: userToken, companyConfigurations: { eagleEyeCameraEnabled } } } = getState();
      if (!userToken || !userToken.hostname) return false;
      const isSubscriptionActive = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.EAGLE_EYE_CAMERAS_PLUGIN));
      return (isSubscriptionActive || eagleEyeCameraEnabled === true);
    } catch (error) {
      return false;
    }
  };
}

// TODO REMOVE CONFIG
export function isNexudusIntegrationActive() {
  return (dispatch, getState) => {
    try {
      const { user: { token: userToken, companyConfigurations: { nexudusEnabled } } } = getState();
      if (!userToken || !userToken.hostname) return false;
      const isSubscriptionActive = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.NEXUDUS_INTEGRATION));
      return (isSubscriptionActive || nexudusEnabled === true);
    } catch (error) {
      return false;
    }
  };
}

// TODO REMOVE CONFIG
export function isCobotIntegrationActive() {
  return (dispatch, getState) => {
    try {
      const { user: { token: userToken, companyConfigurations: { cobotEnabled } } } = getState();
      if (!userToken || !userToken.hostname) return false;
      const isSubscriptionActive = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.COBOT_INTEGRATION));
      return (isSubscriptionActive || cobotEnabled === true);
    } catch (error) {
      return false;
    }
  };
}

export function isOfficeRndIntegrationActive() {
  return (dispatch, getState) => {
    try {
      const { user: { token: userToken, companyConfigurations: { officeRndEnabled } } } = getState();
      if (!userToken || !userToken.hostname) return false;
      const isSubscriptionActive = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.OFFICERND_INTEGRATION));
      return (isSubscriptionActive || officeRndEnabled === true);
    } catch (error) {
      return false;
    }
  };
}

export function isChainelsIntegrationActive() {
  return (dispatch, getState) => {
    try {
      const { user: { token: userToken, companyConfigurations: { chainelsEnabled } } } = getState();
      if (!userToken || !userToken.hostname) return false;
      const isSubscriptionActive = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.CHAINELS_INTEGRATION));
      return (isSubscriptionActive || chainelsEnabled === true);
    } catch (error) {
      return false;
    }
  };
}

export function isAndcardsIntegrationActive() {
  return (dispatch, getState) => {
    try {
      const { user: { token: userToken, companyConfigurations: { andcardsEnabled } } } = getState();
      if (!userToken || !userToken.hostname) return false;
      const isSubscriptionActive = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.ANDCARDS_INTEGRATION));
      return (isSubscriptionActive || andcardsEnabled === true);
    } catch (error) {
      return false;
    }
  };
}

export function isTenupIntegrationActive() {
  return (dispatch, getState) => {
    try {
      const { user: { token: userToken, companyConfigurations: { tenupEnabled } } } = getState();
      if (!userToken || !userToken.hostname) return false;
      const isSubscriptionActive = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.TENUP_INTEGRATION));
      return (isSubscriptionActive || tenupEnabled === true);
    } catch (error) {
      return false;
    }
  };
}

export function isZapfloorIntegrationActive() {
  return (dispatch, getState) => {
    try {
      const { user: { token: userToken, companyConfigurations: { zapfloorEnabled } } } = getState();
      if (!userToken || !userToken.hostname) return false;
      const isSubscriptionActive = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.ZAPFLOOR_INTEGRATION));
      return (isSubscriptionActive || zapfloorEnabled === true);
    } catch (error) {
      return false;
    }
  };
}

export function isOctorateIntegrationActive() {
  return (dispatch, getState) => {
    try {
      const { user: { token: userToken, companyConfigurations: { octorateEnabled } } } = getState();
      if (!userToken || !userToken.hostname) return false;
      const isSubscriptionActive = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.OCTORATE_INTEGRATION));
      return (isSubscriptionActive || octorateEnabled === true);
    } catch (error) {
      return false;
    }
  };
}

export function isResHarmonicsIntegrationActive() {
  return (dispatch, getState) => {
    try {
      const { user: { token: userToken, companyConfigurations: { resharmonicsEnabled } } } = getState();
      if (!userToken || !userToken.hostname) return false;
      const isSubscriptionActive = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.RES_HARMONICS_INTEGRATION));
      return (isSubscriptionActive || resharmonicsEnabled === true);
    } catch (error) {
      return false;
    }
  };
}



export function isCloudBedsIntegrationActive() {
  return (dispatch, getState) => {
    try {
      const { user: { token: userToken, companyConfigurations: { cloudbedsEnabled } } } = getState();
      if (!userToken || !userToken.hostname) return false;
      const isSubscriptionActive = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.CLOUDBEDS_INTEGRATION));
      return (isSubscriptionActive || cloudbedsEnabled === true);
    } catch (error) {
      return false;
    }
  };
}

export function isOptixIntegrationActive() {
  return (dispatch, getState) => {
    try {
      const { user: { token: userToken, companyConfigurations: { optixEnabled } } } = getState();
      if (!userToken || !userToken.hostname) return false;
      const isSubscriptionActive = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.OPTIX_INTEGRATION));
      return (isSubscriptionActive || optixEnabled === true);
    } catch (error) {
      return false;
    }
  };
}


export function isOffice365IntegrationActive() {
  return (dispatch, getState) => {
    try {
      const { user: { token: userToken, companyConfigurations: { office365Enabled } } } = getState();
      if (!userToken || !userToken.hostname) return false;
      const isSubscriptionActive = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.OFFICE365_INTEGRATION));
      return (isSubscriptionActive || office365Enabled === true);
    } catch (error) {
      return false;
    }
  };
}

export function isMewsIntegrationActive() {
  return (dispatch, getState) => {
    try {
      const { user: { token: userToken, companyConfigurations: { mewsEnabled } } } = getState();
      if (!userToken || !userToken.hostname) return false;
      const isSubscriptionActive = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.MEWS_INTEGRATION));
      return (isSubscriptionActive || mewsEnabled === true);
    } catch (error) {
      return false;
    }
  };
}



// TODO REMOVE CONFIG
export function isMultiTimeProfileActive() {
  return (dispatch, getState) => {
    try {
      const { user: { token: userToken, companyConfigurations: { multiTimeProfileActive } } } = getState();
      if (!userToken || !userToken.hostname) return false;
      const isSubscriptionActive = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.RFID_ADVANCED_ADDON));
      return (isSubscriptionActive || multiTimeProfileActive === true);
    } catch (error) {
      return false;
    }
  };
}

// TODO REMOVE CONFIG
export function isDeviceManagementActive() {
  return (dispatch, getState) => {
    try {
      const { user: { token: userToken, companyConfigurations: { deviceManagementActive } } } = getState();
      if (!userToken || !userToken.hostname) return false;
      return deviceManagementActive === true;
    } catch (error) {
      return false;
    }
  };
}

export function isArgoOnlySystem() {
  return (dispatch, getState) => {
    try {
      const { user: { companyInfo } } = getState();
      if (!companyInfo) return false;
      return companyInfo && companyInfo.numOfVegaLocks === 0 && companyInfo.numOfArgoLocks > 0;
    } catch (error) {
      return false;
    }
  };
}

export function isHolidaySettingActive() {
  return (dispatch, getState) => {
    try {
      const { user: { token: userToken, companyConfigurations: { holidaysActive } } } = getState();
      if (!userToken || !userToken.hostname) return false;
      return holidaysActive === true;
    } catch (error) {
      return false;
    }
  };
}

export function isPrivateUnitsActive() {
  return (dispatch, getState) => {
    try {
      const { user: { token: userToken, companyConfigurations: { privateUnitActive } } } = getState();
      if (!userToken || !userToken.hostname) return false;
      const isSubscriptionActive = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.PRIVATE_UNITS_MANAGEMENT));
      return (isSubscriptionActive || privateUnitActive === true);
    } catch (error) {
      return false;
    }
  };
}
// TODO REMOVE CONFIG
export function isBookeyCapacityActive() {
  return (dispatch, getState) => {
    try {
      const { user: { token: userToken, companyConfigurations: { bookeyCapacityActive } } } = getState();
      if (!userToken || !userToken.hostname) return false;
      return bookeyCapacityActive === true;
    } catch (error) {
      return false;
    }
  };
}

export function isAvoidAppCheckinActive() {
  return (dispatch, getState) => {
    try {
      const { user: { token: userToken, companyConfigurations: { bookeyAvoidAppCheckinActive } } } = getState();
      if (!userToken || !userToken.hostname) return false;
      return bookeyAvoidAppCheckinActive === true;
    } catch (error) {
      return false;
    }
  };
}

export function isArgoMultiTimeProfileActive() {
  return (dispatch, getState) => {
    try {
      const { user: { token: userToken, companyConfigurations: { argoMultiTimeProfileActive } } } = getState();
      if (!userToken || !userToken.hostname) return false;
      return argoMultiTimeProfileActive === true;
    } catch (error) {
      return false;
    }
  };
}

export function isAccessProfileAddonActive() {
  return (dispatch, getState) => {
    try {
      const { user: { token: userToken, companyConfigurations: { accessProfilesActive } } } = getState();
      if (!userToken || !userToken.hostname) return false;
      const isSubscriptionActive = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.ACCESS_PROFILES_ADDON));
      return (isSubscriptionActive || accessProfilesActive === true);
    } catch (error) {
      return false;
    }
  };
}

export function isVisitorsManagementActive() {
  return (dispatch, getState) => {
    try {
      const { user: { token: userToken, companyConfigurations: { visitorsManagementActive } } } = getState();
      if (!userToken || !userToken.hostname) return false;
      const isSubscriptionActive = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.VISITORS_MANAGEMENT));
      return (isSubscriptionActive || visitorsManagementActive === true);
    } catch (error) {
      return false;
    }
  };
}

export function isCapacityManagementActive() {
  return (dispatch, getState) => {
    try {
      const { user: { token: userToken, companyConfigurations: { capacityManagementActive } } } = getState();
      if (!userToken || !userToken.hostname) return false;
      const isSubscriptionActive = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.CAPACITY_MANAGEMENT));
      return (isSubscriptionActive || capacityManagementActive === true);
    } catch (error) {
      return false;
    }
  };
}

export function isTicketingManagementActive() {
  return (dispatch, getState) => {
    try {
      const { user: { token: userToken, companyConfigurations: { ticketingManagementActive } } } = getState();
      if (!userToken || !userToken.hostname) return false;
      const isTicketingManagementActive = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.TICKETING_MANAGEMENT))
      return (isTicketingManagementActive || ticketingManagementActive === true);
    } catch (error) {
      return false;
    }
  };
}

export function isParcelManagementActive() {
  return (dispatch, getState) => {
    try {
      const { user: { token: userToken, companyConfigurations: { parcelManagementActive } } } = getState();
      if (!userToken || !userToken.hostname) return false;
      const isSubscriptionActive = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.PARCEL_MANAGEMENT));
      return (isSubscriptionActive || parcelManagementActive === true);
    } catch (error) {
      return false;
    }
  };
}

export function getCredentialValidityDateFormat() {
  return (dispatch, getState) => {
    const { user: { companyConfigurations } } = getState();
    if (companyConfigurations.checkincheckoutActive) {
      return 'LLL';
    }
    return 'DD MMMM YYYY';
  };
}

export function isHierarchyTreeEnabled() {
  return (dispatch, getState) => {
    try {
      const { user: { token: userToken, companyConfigurations: { isHierarchyTreeEnabled } } } = getState();
      if (!userToken || !userToken.hostname) return false;
      return isHierarchyTreeEnabled === true;
    } catch (error) {
      return false;
    }
  };
}

export function hasIntegrationWithSynchronizationPlugin() {
  return (dispatch, getState) => {
    const { user: { activeIntegrations } } = getState();
    const connectorIntegrations = _.find(activeIntegrations, integration => _.includes(integration.serviceName, 'connector'));
    return connectorIntegrations;
  };
}