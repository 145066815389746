import EventsNumberIcon from '@material-ui/icons/ClearAll';
import CSVIcon from '@material-ui/icons/Description';
import { Card, List, ListItem, Popper } from '@material-ui/core';
import CaretIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import ExcelIcon from '@material-ui/icons/ViewDay';
import WarningIcon from '@material-ui/icons/Warning';
import { MenuItem, NavDropdownHover } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import AbilityProvider from '../../permissionsUtils/AbilityProvider';
import { EXPORT_FORMATS, LICENSE_TYPES } from '../../_config/consts';
import CloudDownloadIconCustom from '../CustomIcons/CloudDownloadIconCustom.jsx';
import CSVIconCustom from '../CustomIcons/CSVIconCustom.jsx';
import ExcelIconCustom from '../CustomIcons/ExcelIconCustom.jsx';


@connect(state => ({ companyConfigurations: state.user.companyConfigurations }))
class EventsExportMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFormat: null,
      isOpen: false,
      anchorEl: null,
    };
  }


  render() {
    const { onExportLogs, numberOfElements, companyConfigurations } = this.props;
    const { isOpen, anchorEl } = this.state;

    const EXPORT_LIMIT = companyConfigurations.maximumExportableLogs;
    const formattedMaximumElements = EXPORT_LIMIT.toLocaleString(undefined);
    const formattedNumberOfElements = numberOfElements ? numberOfElements.toLocaleString(undefined) : 0;
    const isExportForbidden = numberOfElements >= (EXPORT_LIMIT);

    return (
      <div
        onMouseEnter={(e) => this.setState({ anchorEl: e.currentTarget, isOpen: true }) }
        onMouseLeave={(e) => this.setState({ anchorEl: null, isOpen: false })}
      >
    <div
      aria-controls="language-menu"
      aria-haspopup="true"
      onClick={(e) => this.setState({ anchorEl: e.currentTarget, isOpen: true })}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <EventsNumberIcon style={{ color: 'white', marginRight: 10, fontSize: 30 }} />
          <h4 style={{ margin: 0, fontWeight: 'bold', color: 'white' }}><Entity key={numberOfElements} entity="numberOfExportingElements" data={{ elements: formattedNumberOfElements }} /></h4>
          </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
          <CloudDownloadIconCustom style={{ color: 'white', width: 25, marginRight: 10 }} />
          <h4 style={{ fontWeight: 'bold', color: 'white',  }}><Entity entity="export" /></h4>
          <CaretIcon style={{ marginLeft: 10,color: 'white',  marginTop: 2, fontSize: 20 }} />
        </div>
      </div>
    </div>
    <Popper
      open={isOpen}
      anchorEl={anchorEl}
      disablePortal={true}
      placement='bottom-start'
      modifiers={{
        flip: {
          enabled: true,
        },
        preventOverflow: {
          enabled: false,
          boundariesElement: 'viewport',
        }
      }}
      style={{ zIndex: 9999, width: 270, paddingTop: 10, marginTop: 20, marginLeft: 100 }}
    >
      <Card style={{ backgroundColor: 'white', borderTop: '4px solid #157495' }}>
        <div style={{ padding: 10, paddingTop: 5, borderBottom: isExportForbidden ? '0px' : '2px solid #89949b' }}>
          {numberOfElements < (EXPORT_LIMIT / 2) && (
            <h4 style={{ color: '#3f3f3f', margin: 0, padding: 5 }}>
              <Entity key={numberOfElements} entity="exportingNumberOfElements" data={{ numberOfElements: formattedNumberOfElements }} />
            </h4>
          )}
          {numberOfElements > (EXPORT_LIMIT / 2) && numberOfElements < (EXPORT_LIMIT) && (
            <div style={{ paddingBottom: 0 }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <WarningIcon style={{ color: 'orange' }} />
                <h5 style={{ color: '#3f3f3f', margin: 0, marginLeft: 10, fontWeight: 'bold' }}>
                  <Entity entity="warning" />
                </h5>
              </div>
              <h5 style={{ color: '#3f3f3f' }}>
                <Entity key={numberOfElements} entity="exportingNumberOfElementsWarning" data={{ numberOfElements: formattedNumberOfElements }} />
              </h5>
            </div>
          )}
          {isExportForbidden && (
            <div style={{ paddingBottom: 0, marginTop: 10 }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <WarningIcon style={{ color: 'red' }} />
                <h5 style={{ color: '#3f3f3f', margin: 0, marginLeft: 10, fontWeight: 'bold' }}>
                  <Entity entity="warning" />
                </h5>
              </div>
              <h5 style={{ color: 'orangered', marginTop: 5 }}>
                <Entity key={numberOfElements} entity="exportingNumberOfElementsForbidden" />
              </h5>
            </div>
          )}
          <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <InfoIcon style={{ fontSize: 18, color: 'orange' }} />
            <h5 style={{ color: '#3f3f3f', margin: 0, marginLeft: 10, fontWeight: 'bold' }}>
              <Entity entity="maximumNumerOfElementsExport" data={{ max: formattedMaximumElements }} />
            </h5>
          </div>
        </div>
        <List component="nav" aria-label="main mailbox folders">
        {!isExportForbidden ? (
          <ListItem key='flag-header' header style={{ backgroundColor: '#f0f0f0',  borderTop: '2px solid #bdbdbd', marginTop: -10, height: 40 }}>
            <h5 style={{ margin: 0, color: '#777', fontWeight: 'bold' }}><Entity entity="chooseExportFormat" /></h5>
            </ListItem>) : null}
          {!isExportForbidden ? (
            <ListItem
              key={1}
              button
              eventKey={EXPORT_FORMATS.CSV}
              onClick={() => {
                this.setState({ isOpen: false });
                onExportLogs(EXPORT_FORMATS.CSV);
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <CSVIconCustom style={{ color: '#3f3f3f', width: 23, marginTop: 0, marginRight: 15 }} />
                <h5 style={{ color: '#3f3f3f' }}>{'.csv'}</h5>
              </div>
            </ListItem>) : null}
        {!isExportForbidden ? (
          <ListItem
            key={2}
            button
            eventKey={EXPORT_FORMATS.XLSX}
            onClick={() => {
              this.setState({ isOpen: false });
              onExportLogs(EXPORT_FORMATS.XLSX);
            }}
          >
            <div style={{ minWidth: 250, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <ExcelIconCustom style={{ color: '#3f3f3f', width: 23, marginTop: 0, marginRight: 15 }} />
              <h5 style={{ color: '#3f3f3f' }}>{'.xlsx'}</h5>
            </div>
          </ListItem> ): null}
        </List>
      </Card>
    </Popper>
  </div>
    );
  }
} 

export default EventsExportMenu;
