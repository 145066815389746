// @ts-nocheck
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { BLUE, LOCKER_RESERVATION_STATUSES } from '../../_config/consts';
import * as TagActions from '../../redux/actions/tag.actions';
import MDButton from '../MDButton/MDButton.jsx';
import DeliveryDetailsView from './DeliveryDetailsView.jsx';

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});
@connect(state => ({
  form: state.form.DeliveryForm,
  lockerReservation: state.lockers.selectedLockerReservation,
  language: state.settings.language,
  themeName: state.settings.items.theme.data.themeName,
  lockTags: state.tags.lock.data,
  userTags: state.tags.user.data, 
}))
class DeliveriesOperationalSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentWillMount() {
    const { dispatch } = this.props;
  }

  async onSave(lockerReservationData) {
    const { dispatch, form, lockerReservation, onDeliveryEditConfirmed, onDeliveryCreateConfirmed, onDeliveryCreateAsDeliveredConfirmed } = this.props;
    const isCreate = !lockerReservation || _.isEmpty(lockerReservation) || lockerReservation.id === undefined
    if (!lockerReservationData.locker.item) return;
    const lockerId = lockerReservationData.locker.item.id
    const createAsDelivered = isCreate && lockerReservationData.createAsDelivered
    const lockerType = lockerReservationData.locker.item.type
    let lockerReservationDataParsed = {
      name: isCreate ? (`${lockerReservationData.locker.item.name} - ${lockerReservationData.newReservationuserId}`) : undefined,
      fromDate: lockerReservation && lockerReservation.fromDate === lockerReservationData.fromDate ? undefined : lockerReservationData.fromDate,
      userId: lockerReservationData.newReservationuserId,
      notes: lockerReservationData.notes || "",
    }
    if (isCreate) {
      if (createAsDelivered)
        onDeliveryCreateAsDeliveredConfirmed(lockerId, lockerReservationDataParsed, lockerType)
      else
        onDeliveryCreateConfirmed(lockerId, lockerReservationDataParsed, lockerType)
    }
    else
      onDeliveryEditConfirmed(lockerId, lockerReservation.id, lockerReservationDataParsed, lockerType)
  }

  filterGuestTags(value) {
    const { dispatch } = this.props;
    const includeSpecialTags = true;
    dispatch(TagActions.cancelFetchGuestTagsByFilter());
    const tagType = 'USER';
    dispatch(TagActions.fetchGuestTagsByFilter(value, 5, includeSpecialTags, tagType));
  }

  render() {
    const {
      dispatch,
      lockerReservation,
      form,
      lockTags,
      userTags,
      onDeleteDeliveryRequest,
      onSendToDeliveredRequest,
      onSendToPickedupRequest,
      onSharePin,
    } = this.props;
    const isEdit = lockerReservation && lockerReservation.id
    const lockerReservationEditable = lockerReservation && lockerReservation.status===LOCKER_RESERVATION_STATUSES.BOOKED
    const canSubmit = form && (!form.syncErrors || !Object.keys(form.syncErrors).length)
    return (
      <MuiThemeProvider theme={theme}>
        <div>
          <DeliveryDetailsView
            lockerReservation={lockerReservation}
            onSubmit={(lockerReservationData)=>this.onSave(lockerReservationData)}
            availableUserTags={userTags}
            onGuestInputChange={value => this.filterGuestTags(value)}
            onDeleteDeliveryRequest={(notes) => {onDeleteDeliveryRequest(lockerReservation.lockerId,lockerReservation.id,notes)}}
            onSendToDeliveredRequest={(deliveredStatusString) => {onSendToDeliveredRequest(lockerReservation.lockerId,lockerReservation.pinCode,deliveredStatusString)}}
            onSendToPickedupRequest={(pickedUpStatusString) => {onSendToPickedupRequest(lockerReservation.lockerId,lockerReservation.pinCode,pickedUpStatusString)}}
            onSharePin={(pin) => onSharePin(pin)}
          />
          {(!isEdit || lockerReservationEditable) ? (
            <MDButton
              title={<Entity entity="save" />}
              containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
              style={{ height: 45, borderRadius: 0, opacity:canSubmit?1:0.7 }}
              disabled={!canSubmit}
              onClick={() => dispatch(submit('DeliveryForm'))}
            />
          ): null}
        </div>
      </MuiThemeProvider>
    );
  }
} 

export default DeliveriesOperationalSection;
