// @ts-nocheck
import Warning from '@material-ui/icons/Warning.js';
import {
  Form
} from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, submit } from 'redux-form';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import { ORANGE, PERMISSIONS, PERMISSION_ENTITIES, RED, VENDORS_LIST } from '../../_config/consts.js';
import { elaborateForbiddenDaysString, hasFormWritePermission } from '../../_config/utils.js';
import MDButton from '../MDButton/MDButton.jsx';
import FormCardContainer from './Elements/FormCardContainer.jsx';
import InvitationsTimeSelector from './Elements/InvitationsTimeSelector.jsx';
import LocksSelectorField from './Elements/LocksSelectorField.jsx';
import MDRadioButtonsGroupField from './Elements/MDRadioButtonsGroup/MDRadioButtonsGroupField.js';
import CustomField from './Fields/CustomField.jsx';

const validate = (values) => {
  const errors = {};
  if (values) {
    if (!values.name) {
      errors.name = 'required';
    }
    
    if (!values.guestTags || (values.guestTags && _.isEmpty(values.guestTags))) {
      errors.guestTags = 'required';
    }

    if (values.numberOfDevices === undefined) {
      errors.numberOfDevices = 'required';
    }

    if (!values.lockTags || (values.lockTags && _.isEmpty(values.lockTags))) {
      errors.lockTags = 'required';
    }

    if (!values.credentialTimeframe || !values.credentialTimeframe.startDate || !values.credentialTimeframe.endDate) {
      errors.credentialTimeframe = 'reservationDateIntevalErrorMessage';
    }

    if (!values.timeIntervalFrom) {
      errors.timeIntervalFrom = 'required';
    }

    if (!values.timeIntervalTo) {
      errors.timeIntervalTo = 'required';
    }
    if (values.timeIntervalTo && values.timeIntervalFrom) {
      const toTime = moment(values.timeIntervalTo);
      const fromTime = moment(values.timeIntervalFrom);
      const toDate = moment(0).hour(toTime.hour()).minute(toTime.minute()).second(0);
      const fromDate = moment(0).hour(fromTime.hour()).minute(fromTime.minute()).second(0);
      if (toDate.isSameOrBefore(fromDate)) {
        errors.timeIntervalTo = 'Interval not valid';
      }
    }
  }

  return errors;
};

@reduxForm({ form: 'InvitationForm', validate })
@connect(state => ({ form: state.form.InvitationForm, companyConfigurations: state.user.companyConfigurations }))
class InvitationForm extends React.Component {
 
  render() {
    const {
      form,
      availableLockTags,
      onLockTagsChange,
      onLockFilterModeChange,
      onLockInputChange,
      onFetchMoreLocks,
      onDeleteCredential,
      dispatch,
      companyConfigurations,
    } = this.props;
    const isEditing = form && form.values && form.values.id;
    const canReadLock = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.SMART_LOCK);
    const canCreateItem = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.INVITATION);
    const validityDateFrom = form && form.values && form.values.validityDateFrom;
    const validityDateTo = form && form.values && form.values.validityDateTo;
    const validityTimeFrom = form && form.values && form.values.validityTimeFrom;
    const validityTimeTo = form && form.values && form.values.validityTimeTo;
    const timeIntervalTo = form && form.values && form.values.timeIntervalTo;
    const timeIntervalFrom = form && form.values && form.values.timeIntervalFrom;
    const daysString = form && form.values && form.values.daysOfTheWeek && elaborateForbiddenDaysString(form.values.daysOfTheWeek) ? elaborateForbiddenDaysString(form.values.daysOfTheWeek) : '';
    const smartLocksSelected = form && form.values && form.values.selectedLocks;
    const deviceNumberOptions = [
      {
        label: <Entity entity="oneDevice" />,
        value: 1,
      },
      {
        label: <Entity entity="numberOfDevice" data={{ number: 2 }} />,
        value: 2,
      },
      {
        label: <Entity entity="numberOfDevice" data={{ number: 3 }} />,
        value: 3,
      },
      {
        label: <Entity entity="numberOfDevice" data={{ number: 4 }} />,
        value: 4,
      },
    ];
    if (companyConfigurations && companyConfigurations.infiniteDevicesInvitation) deviceNumberOptions.push({ label: <Entity entity="unlimited" />, value: 0 });
    return (
      <Form>
        <FormCardContainer
          title="newInvitation"
          headerStyle={{ marginBottom: 20 }}
        >
          <Entity
            componentClass={Field}
            name="name"
            componentAttribute="placeholder"
            entity="invitationNameDescription"
            title={<Entity entity="invitationName" />}
            component={CustomField}
            type="text"
            mandatory
          />
        </FormCardContainer>
        <FormCardContainer
          title="numberOfAuthorizedDevicesTitle"
          subtitle="numberOfAuthorizedDevicesSubtitle"
        >
          <Field
            name="numberOfDevices"
            title={<Entity entity="daysOfTheWeek" />}
            component={MDRadioButtonsGroupField}
            // onHandleChange={values => this.onProgramTypeSelected(values, 1)}
            containerstyle={{ paddingLeft: 15, marginTop: 0 }}
            horizontal
            showErrorLabel
            options={deviceNumberOptions}
          />
        </FormCardContainer>
        <FormCardContainer
          title="credentialLockTitle"
          subtitle="invitationTagLockSubtitle"
        >
          <LocksSelectorField
            name="lockTags"
            selectedTagsFieldName="selectedLocks"
            formName="InvitationForm"
            tags={availableLockTags}
            selectedTags={smartLocksSelected}
            form={form}
            tagsType="LOCK"
            title="searchForLocks"
            placeholder="insertLockTagsForCredential"
            hideBottomSelectionSection
            selectionSectionTitle="invitationSelectedLocksTitle"
            selectionSectionSubtitle="invitationSelectedLocksSubtitle"
            listTitle="selectedLocks"
            emptyTagSelectionText={canReadLock ? 'noLocksSelectedWithTag' : null}
            onFetchMoreItems={page => onFetchMoreLocks(page)}
            onTagsChange={values => onLockTagsChange(values)}
            onInputChange={value => onLockInputChange(value)}
            onFilterModeChange={values => onLockFilterModeChange(values)}
            disabled={!hasFormWritePermission(PERMISSION_ENTITIES.CREDENTIAL_RULE, isEditing)}
          />
        </FormCardContainer>
        <InvitationsTimeSelector
          formName="InvitationForm"
          canEdit={!isEditing}
          validityDateFrom={validityDateFrom}
          validityDateTo={validityDateTo}
          validityTimeFrom={validityTimeFrom}
          validityTimeTo={validityTimeTo}
          timeIntervalTo={timeIntervalTo}
          timeIntervalFrom={timeIntervalFrom}
          daysString={daysString}
        />
        {isEditing && canCreateItem &&
          <FormCardContainer
            title="deleteCredential"
            subtitle="deleteCredentialWarning"
          >
            <MDButton
              title={<Entity entity="deleteCredential" />}
              backgroundColor={RED}
              onClick={() => onDeleteCredential(form.values.id)}
            />
          </FormCardContainer>
        }
        <MDButton
          title={<Entity entity="save" />}
          containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
          style={{ height: 45, borderRadius: 0 }}
          onClick={() => dispatch(submit('InvitationForm'))}
        />
      </Form>
    );
  }
} 

export default InvitationForm;
