import Card from '@material-ui/core/Card';
import TimeIcon from '@material-ui/icons/AccessTime';
import ClockIcon from '@material-ui/icons/Alarm';
import CheckIcon from '@material-ui/icons/CheckCircle';
import LockIcon from '@material-ui/icons/LockOutlined';
import UserIcon from '@material-ui/icons/PersonOutline';
import WarningIcon from '@material-ui/icons/Warning';
import { Button, Form, Icon } from '@sketchpixy/rubix';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import * as RestService from '../../../_config/rest';
import AbilityProvider from '../../../permissionsUtils/AbilityProvider.js';
import * as ModalActions from '../../../redux/actions/modal.actions';
import ReservationStatusView from '../../../routes/Bookey/Reservations/ReservationStatusView.jsx';
import NewspaperIconCustom from '../../CustomIcons/NewspaperIconCustom.jsx';
import ReservationAdditionalGuestsIconCustom from '../../CustomIcons/ReservationAdditionalGuestsIconCustom.jsx';
import ReservationEmailIconCustom from '../../CustomIcons/ReservationEmailIconCustom.jsx';
import UserDisableIconCustom from '../../CustomIcons/UserDisableIconCustom.jsx';
import ListLoadingView from '../../ListLoadingView.jsx';
import MDButton from '../../MDButton/MDButton.jsx';
import FormCardContainer from '../Elements/FormCardContainer.jsx';
import { BLUE, GREEN, LICENSE_TYPES, ORANGE, RED, RESOURCE_IMAGE_MAP } from './../../../_config/consts';

@connect()
class ReservationDetails extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentUserDetail: null,
      checkInDone: false,
      canUserDeleteCheckIn: true,
    };
  }

  componentWillMount() {
    this.setCheckInAndFetchInformation();
    this.setCanUserDeleteCheckIn();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { reservation: currentReservation } = this.props;
    const { reservation: previousReservation } = prevProps;
    if (currentReservation && previousReservation && currentReservation !== previousReservation) {
      this.setCheckInAndFetchInformation();
      this.setCanUserDeleteCheckIn();
    }
  }

  async getUserCheckInDetails(reservation) {
    const currentUserDetailsResponse = await RestService.getUserDetails(reservation.checkInUserId);
    this.setState({ currentUserDetails: currentUserDetailsResponse.data });
  }

  async setCheckInAndFetchInformation() {
    const { reservation } = this.props;
    if (reservation && reservation.checkInUserId && reservation.checkInTime) {
      await this.getUserCheckInDetails(reservation);
    }
  }

  async onCheckIn(reservationId) {
    const { onCheckInReservation, reservation, dispatch } = this.props;
    if (reservation && !reservation.checkInTime && !reservation.checkInUserId) {
      await onCheckInReservation(reservationId);
    } else {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_MODAL',
        modalProps: { type: 'DEFAULT_ERROR', defaultMessage: <Entity entity="errorCheckingInReservation" /> },
      }));
    }
  }

  setCanUserDeleteCheckIn() {
    const { reservation } = this.props;
    let checkInDate = null;
    let canUserDeleteCheckIn = null;
    if (reservation && reservation.fromDate && reservation.resource && reservation.resource.checkInWindowMinutes) {
      checkInDate = moment(reservation.fromDate).add(reservation.resource.checkInWindowMinutes, 'minutes');
      canUserDeleteCheckIn = moment().isBefore(checkInDate);
      this.setState({ canUserDeleteCheckIn });
    }
  }

  async onDeleteCheckIn(reservationId) {
    const { onDeleteCheckInReservation } = this.props;
    await onDeleteCheckInReservation(reservationId);
  }


  render() {
    const { reservation, onSendCheckinReminder, user: { data: userData }, onDeleteReservation, locksFilteredByTags, guests, checkInLock, onFetchMoreItems, onCheckInReservation, onDeleteCheckInReservation } = this.props;
    const { currentUserDetails, canUserDeleteCheckIn } = this.state;
    const resourceImage = reservation && reservation.resource && reservation.resource.imageUrl ? reservation.resource.imageUrl : 'resource2';
    const resourceMedia = reservation && reservation.resource && reservation.resource.media;
    const checkInLockReservation = reservation.resource.checkInSmartLockId && checkInLock[reservation.resource.checkInSmartLockId].name;
    const guest = reservation && reservation.userId && guests.cachedGuestsMap[reservation.userId];
    const numberOfGuests = reservation && reservation.numberOfPeople && reservation.numberOfPeople > 1 ? (reservation.numberOfPeople - 1) : 0
    const { fromDate, toDate } = reservation;
    const checkInBeforeMinutes = reservation.resource.checkInWindowBeforeMinutes ? reservation.resource.checkInWindowBeforeMinutes : 0;
    const checkInWindowMinutes = reservation.resource.checkInWindowMinutes ? reservation.resource.checkInWindowMinutes : 0;
    const imageUrl = resourceMedia ? `data:image/jpeg;base64,${resourceMedia}` : RESOURCE_IMAGE_MAP[resourceImage];
    const isPersonalReservation = userData && userData.id && reservation.userId && reservation.userId === userData.id;
    const invitedEmails = (reservation && reservation.invitedEmails) || [];
    const canEditReservation = !AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.ENGINE]);
    return (
      <Form>
          <div style={{ marginBottom: 20, minHeight: 200, backgroundPosition: 'center', backgroundBlendMode: 'darken', backgroundColor: 'rgba(0,0,0,0.5)', backgroundImage: `url('${imageUrl}')`, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <h1 style={{ color: 'white', fontWeight: 'bold' }}>{reservation.resource.name}</h1>
            <h3 style={{ color: 'white' }}>{reservation.resource.description}</h3>
          </div>
        <FormCardContainer
          headerStyle={{ marginBottom: 0 }}
          title="reservationDetails"
        >
          {reservation && (reservation.title || reservation.description) && isPersonalReservation ? 
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 15, marginTop: 10 }}>
              <NewspaperIconCustom style={{ color: '#3f3f3f', marginRight: 10, width: 25 }} />
              <div>
                {reservation && reservation.title ? (
                  <h4 className="card-title" style={{ marginLeft: 5 }}>{reservation.title}</h4>
                ) : null}
                {reservation && reservation.description ? (
                  <h5 className="card-description" style={{ margin: 5 }}>{reservation.description}</h5>
                ) : null}
              </div>
            </div>
          : null}
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <TimeIcon style={{ color: '#3f3f3f', marginRight: 10, fontSize: 25 }} />
            <h4 style={{ color: '#3f3f3f', marginLeft: 5 }}>
              {moment(fromDate).isSame(toDate, 'day') ? 
                `${moment(fromDate).format('LL')} / ${moment(fromDate).format('LT')} - ${moment(toDate).format('LT')}` :
                `${moment(fromDate).format('LLL')} - ${moment(toDate).format('LLL')}`
              }
            </h4>
          </div>
          <div>
            {guest ? (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
                <UserIcon style={{ fontSize: 25 }} />
                <div style={{ marginLeft: 10 }}>
                  <h4 className="card-title" style={{ marginLeft: 5 }}>{`${guest.firstname} ${guest.lastname}`}</h4>
                  <h5 className="card-description" style={{ margin: 5 }}>{guest.email}</h5>
                </div>
              </div>
            ) :
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
                <UserDisableIconCustom style={{ color: RED, paddinTop: 8, width: 20, marginRight: 10 }} />
                <h4 style={{ color: '#3f3f3f' }}><Entity entity="guestHasBeenDeleted" /></h4>
              </div>
            }
          </div>
          <div>
            {numberOfGuests > 0 ? (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 15 }}>
                <ReservationAdditionalGuestsIconCustom style={{ color: '#3f3f3f', width: 26 }} />
                <div style={{ marginLeft: 10 }}>
                  <h4 className="card-title" style={{ marginLeft: 5 }}>{`${numberOfGuests} ${L20NContext.getSync('reservationNumberOfGuests')}`}</h4>
                </div>
              </div>
            ) : null}
          </div>
          <div>
            {invitedEmails && invitedEmails.length ? (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
                <ReservationEmailIconCustom style={{ width: 25 }} />
                <div style={{ marginLeft: 10 }}>
                  <h4 className="card-title" style={{ marginLeft: 5 }}><Entity entity="reservationInvitedEmailsResult" /></h4>
                  <div style={{display:'flex', flexDirection:'row', alignItems:'center', flexWrap:'wrap', marginTop: 5}}>
                    {_.map(invitedEmails, (invitedEmail, index) => {
                      const email = invitedEmail.email || "";
                      const found = invitedEmail.found || false;
                      return (
                        <div key={index} style={{ display: 'flex', flexDirection:'row', alignItems:'center', margin: 0 }}>
                          {found ? (
                            <p style={{ margin: 0, alignSelf: 'flex-start' }} data-tip={L20NContext.getSync('reservationInvitedEmailsResultOK')}>
                              <CheckIcon style={{ fontSize: 20, color: GREEN, marginLeft:10 }} />
                            </p>
                          ) : (
                            <p style={{ margin: 0, alignSelf: 'flex-start' }} data-tip={L20NContext.getSync('reservationInvitedEmailsResultKO')}>
                              <WarningIcon style={{ fontSize: 20, color: ORANGE, marginLeft:10 }} />
                            </p>
                          )}
                          <h5 className="card-description" style={{ margin: 5, marginTop: 0 }}>{email}</h5>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <ReservationStatusView reservation={reservation} />
        </FormCardContainer>
        <FormCardContainer
          title="checkIn"
          subtitle="checkInWarning"
        >
          {reservation.checkInUserId && reservation.checkInTime ?
            <Card style={{ marginTop: 20, height: 70, display: 'flex', flexDirection: 'row', alignItems: 'center', borderRadius: 10, justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', justifyContent: 'center', marginLeft: 10, alignItems: 'center' }}>
                <Icon glyph="icon-simple-line-icons-check" style={{ color: 'green', marginLeft: 15, fontSize: 25 }} />
                <h4 style={{ marginLeft: 10, color: '#3f3f3f', marginBottom: 15 }}> {currentUserDetails && currentUserDetails.username ? currentUserDetails.username : ''} - {reservation && reservation.checkInTime ? moment(reservation.checkInTime).format('LLLL') : null} </h4>
              </div>
              {onDeleteCheckInReservation && 
                <div style={{ display: 'flex', alignItems: 'center', backgroundColor: RED, height: '100%', borderTopRightRadius: 10, borderBottomRightRadius: 10 }}>
                  <Button bsStyle="link" onClick={() => this.onDeleteCheckIn(reservation.id)} disabled={!canUserDeleteCheckIn}>
                    <Icon style={{ color: 'white', fontSize: 20 }} glyph="icon-simple-line-icons-trash" />
                  </Button>
                </div>
              }
            </Card>
            :
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <h4 style={{ fontWeight: 'bold', color: '#3f3f3f' }}><Entity entity="checkInWindow" /></h4>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <ClockIcon style={{ color: '#3f3f3f', marginRight: 10, fontSize: 20 }} />
                <h4 style={{ color: '#3f3f3f', marginBottom: 0, marginTop: 0 }}>{`${moment(fromDate).format('LL')}  / ${moment(fromDate).subtract(checkInBeforeMinutes, 'minutes').format('HH:mm')} - ${moment(fromDate).add(checkInWindowMinutes, 'minutes').format('HH:mm')}`}</h4>
              </div>
              {onCheckInReservation && canEditReservation && 
                <MDButton
                  title={<Entity entity="checkInNow" />}
                  backgroundColor={BLUE}
                  containerStyle={{ width: '90%', marginTop: 20 }}
                  onClick={() => this.onCheckIn(reservation.id)}
                />
              }
              {onSendCheckinReminder && canEditReservation &&
                <MDButton
                  title={<Entity entity="sendReminder" />}
                  backgroundColor={ORANGE}
                  containerStyle={{ width: '90%', marginTop: 5 }}
                  onClick={() => onSendCheckinReminder(reservation.id)}
                />
              }
            </div>
          }

        </FormCardContainer>
        {locksFilteredByTags && locksFilteredByTags[0] && reservation.resource.resourceSmartLockTags && !_.isEmpty(locksFilteredByTags[0].data) && (
          <FormCardContainer title="accessRoute" subtitle="accessRouteDescription">
            <div style={{ padding: 5, backgroundColor: '#f1f1f1', borderRadius: 10 }}>
              <div style={{ maxHeight: 200, overflowY: 'scroll' }}>
                <InfiniteScroll
                  pageStart={0}
                  useWindow={false}
                  initialLoad={false}
                  loadMore={() => onFetchMoreItems(locksFilteredByTags[0].currentPage + 1)}
                  hasMore={locksFilteredByTags[0].currentPage + 1 < locksFilteredByTags[0].totalPages}
                  loader={<ListLoadingView />}
                >
                  {
                    _.map(locksFilteredByTags[0].data, (lock, key) => {
                      const lockName = lock.name;
                      return (
                        <div
                          key={key}
                          style={{ padding: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 0, marginTop: 0, backgroundColor: lockName === checkInLockReservation ? '#00800036' : 'white', justifyContent: 'space-between' }}
                        >
                          <div style={{ marginLeft: 10, display: 'flex', alignItems: 'center'}}>
                            <LockIcon className="resourceInfoRow-info-col-icon" style={{ color: lockName === checkInLockReservation ? 'black' : 'gray' }} />
                            <h5 style={{ marginLeft: 5, fontWeight: 'bold', color: lockName === checkInLockReservation ? 'black' : 'gray' }}>{lockName}</h5>
                          </div>
                          {lockName === checkInLockReservation ? (
                            <div className="resourceInfoRow-typeTag" style={{ backgroundColor: 'white', marginTop: '0px !important', marginRight: 5, marginBottom: 5 }}>
                              <h5 style={{ color: 'black', margin: 3 }}>{<Entity entity="checkInLock" />}</h5>
                            </div>
                          ) : null}
                        </div>
                      );
                    })
                  }
                </InfiniteScroll>
              </div>
            </div>
          </FormCardContainer>
        )}
        {onDeleteReservation && canEditReservation &&
          <FormCardContainer
            title="deleteReservation"
            subtitle="deleteReservationWarning"
          >
            <MDButton
              onClick={() => onDeleteReservation(reservation.id)}
              title={<Entity entity="deleteReservation" />}
              backgroundColor={RED}
            />
          </FormCardContainer>
        }
        <ReactTooltip delayShow={200} effect="float" />
      </Form>
    );
  }
} 

export default ReservationDetails;
