// @ts-nocheck
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { createTheme, withStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Autorenew';
import CloudIcon from '@material-ui/icons/ImportExport';
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline';
import RoleIcon from '@material-ui/icons/VerifiedUser';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { BLUE, GREEN, MOBILE_UDID_STATES, PERMISSIONS, PERMISSION_ENTITIES, VISIBLE_TAGS_NUMBER } from '../../_config/consts';
import { getColorFromThemeName } from '../../_config/utils';
import AbilityProvider from '../../permissionsUtils/AbilityProvider';
import * as GuestActions from '../../redux/actions/guest.actions';
import CustomFieldsDisplayView from '../CustomFields/CustomFieldsDisplayView.jsx';
import AccessProfilesIconCustom from '../CustomIcons/AccessProfilesIconCustom.jsx';
import DeleteOutlineIconCustom from '../CustomIcons/DeleteOutlineIconCustom.jsx';
import DeviceRemoveIconCustom from '../CustomIcons/DeviceRemoveIconCustom.jsx';
import OfficeModeIconCustom from '../CustomIcons/OfficeModeIconCustom.jsx';
import PrivateUnitsIconCustom from '../CustomIcons/PrivateUnitsIconCustom.jsx';
import RemoveEmailIconCustom from '../CustomIcons/RemoveEmailIconCustom.jsx';
import SelectMultipleCheckBoxIconCustom from '../CustomIcons/SelectMultipleCheckBoxIconCustom.jsx';
import SortAscendingIconCustom from '../CustomIcons/SortAscendingIconCustom.jsx';
import SortDescendingIconCustom from '../CustomIcons/SortDescendingIconCustom.jsx';
import SortIconCustom from '../CustomIcons/SortIconCustom.jsx';
import TagAddIconCustom from '../CustomIcons/TagAddIconCustom.jsx';
import TagRemoveIconCustom from '../CustomIcons/TagRemoveIconCustom.jsx';
import UnsortIconCustom from '../CustomIcons/UnsortIconCustom.jsx';
import UserCheckIconCustom from '../CustomIcons/UserCheckIconCustom.jsx';
import UserDisableIconCustom from '../CustomIcons/UserDisableIconCustom.jsx';
import EmptyView from '../EmptyView.jsx';
import ListLoadingView from '../ListLoadingView.jsx';
import MDTablePaginationActions from '../MDTable/MDTablePaginationActions.jsx';
import Tag from '../Tag.jsx';

const styles = theme => ({
  table: {
    overflow: 'scroll',
  },
  tableColumnText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  tableColumnHeader: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableColumnHeaderText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  tableColumnHeaderArrow: {
    padding: 10,
    marginLeft: 10,
  },
  tableColumnHeaderArrowIcon: {
    fontSize: 20,
    width: 20,
  },
  tableColumnHeaderSortIcon: {
    width: 24,
  },
  tableHeader: {
    padding: 5,
    paddingLeft: 25,
  },
  disabledInput: {
    display: 'none',
  },
  guestTag: {
    padding: 3,
  },
  rowSelected: {
    backgroundColor: 'aliceblue !important',
    color: 'aliceblue !important',
  },
  rowHovered: {
    cursor: 'pointer',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  button: {
    color: '#E53935',
    fontSize: 12,
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  buttonPU: {
    color: BLUE,
    fontSize: 12,
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  guestRowContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowWithAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowActionButton: {
    width: 45,
    height: 45,
  },
  rowActionButtonIcon: {
    fontSize: 20,
    color: '#E53935',
  },
  paginationText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});
@connect((state) => ({ viewLoading: state.utils.viewLoading, accessProfiles: state.accessProfiles.data, companyConfigurations: state.user.companyConfigurations, guests: state.guests, privateUnits: state.privateUnit.allPrivateUnits, themeName: state.settings.items.theme.data.themeName }))

class GuestTableView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectionModeActive: false,
      selectedAllActive: false,
      selectedRows: [],
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(GuestActions.setOperationalMode(false));
  }

  onToggleTableSelection() {
    const { selectionModeActive, selectedRows, selectedAllActive } = this.state;
    const selection = !selectionModeActive ? selectedRows : [];
    const selectAllValue = !selectionModeActive ? selectedAllActive : false;
    this.setState({ selectionModeActive: !selectionModeActive, selectedRows: selection, selectedAllActive: selectAllValue });
  }

  onToggleRowSelection(isSelected, guest) {
    const { selectedRows } = this.state;
    const newSelection = selectedRows;
    if (!isSelected) {
      newSelection.push(guest);
    } else {
      const index = _.findIndex(newSelection, existingGuest => existingGuest.id === guest.id);
      _.pullAt(newSelection, index);
    }
    this.setState({ selectedRows: newSelection });
  }

  onSelectAll(isSelected) {
    const { selectedRows } = this.state;
    let newSelection = selectedRows;
    if (!isSelected) {
      const { guests: { data: { content: guestData } } } = this.props;
      newSelection = _.clone(guestData.filter(e=>!e.privateUnit));
    } else {
      newSelection = [];
    }
    this.setState({ selectedRows: newSelection, selectedAllActive: !isSelected });
  }

  onDeleteGuestBatch(guests) {
    const { onDeleteGuestBatch } = this.props;
    this.setState({ selectedRows: [], selectionModeActive: false });
    onDeleteGuestBatch(guests);
  }

  onDisableGuestsBatch(guests) {
    const { onDisableGuestsBatch } = this.props;
    this.setState({ selectedRows: [], selectionModeActive: false });
    onDisableGuestsBatch(guests);
  }

  onAddTagsBatch(guests) {
    const { onAddTagsBatch } = this.props;
    this.setState({ selectedRows: [], selectionModeActive: false });
    onAddTagsBatch(guests);
  }

  onRemoveTagsBatch(guests) {
    const { onRemoveTagsBatch } = this.props;
    this.setState({ selectedRows: [], selectionModeActive: false });
    onRemoveTagsBatch(guests);
  }

  onToggleOfficeModeGuestsBatch(guests) {
    const { onToggleOfficeModeGuestsBatch } = this.props;
    this.setState({ selectedRows: [], selectionModeActive: false });
    onToggleOfficeModeGuestsBatch(guests);
  }

  async handleOrderChange(column) {
    const { onOrderChanged, dispatch } = this.props;
    const { guests: { data: { sorting: {orderBy, orderDir} } } } = this.props;
    const orderDir_new = orderBy===column?(orderDir==='ASC'?'DESC':'ASC'):'ASC';
    await dispatch(GuestActions.setOrder(column, orderDir_new));
    onOrderChanged()
  };

  async onResetOrder() {
    const { onOrderChanged, dispatch } = this.props;
    await dispatch(GuestActions.setOrder(undefined, undefined));
    onOrderChanged()
  }
  

  async onRefreshTable() {
    const { onRefreshGuests } = this.props;
    onRefreshGuests();
  };

  render() {
    const {
      classes,
      guests: { data: { content: guestData, pagination: { number: currentPage, size, totalPages, totalElements }, filters, sorting: {orderBy, orderDir} } },
      guests: { selectedGuest },
      privateUnits,
      accessProfiles: { content: accessProfilesData },
      onResetGuestPassword,
      onDisableGuestRequest,
      onDeleteGuestRequest,
      onOpenPrivateUnitRequest,
      onRemoveDeviceRequest,
      onFetchGuestOnPage,
      onSelectGuest,
      onRefreshGuests,
      onNewGuest,
      viewLoading,
      themeName,
      companyConfigurations,
      isAccessProfileAddonActive,
      onSetGuestTagsVisibility,
    } = this.props;
    const { selectionModeActive, selectedAllActive, selectedRows } = this.state;
    const isCovivioIntegrationEnabled = companyConfigurations && companyConfigurations.covivioConnectorEnabled;
    const canCreateGuest = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.CREATE], PERMISSION_ENTITIES.USER);
    const canDeleteGuest = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.DELETE], PERMISSION_ENTITIES.USER);
    const canEditGuest = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.UPDATE], PERMISSION_ENTITIES.USER);
    const canReadUserTags = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.USER_TAG);
    const headerColor = getColorFromThemeName(themeName);
    return (
      <div className="list-view details-section" style={{ top: 190, paddingBottom: 140, width: '87%' }}>
        <div>
          {!_.isEmpty(guestData) ?
            <Card style={{ overflow: 'scroll' }}>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow style={{ backgroundColor: headerColor }}>
                    <TableCell className={classes.tableHeader} colSpan={selectionModeActive ? 4 : 3}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h3 className={classes.tableColumnText} style={{ color: 'white', marginTop: 15, marginBottom: 15 }}><Entity entity="platformGuests" /></h3>
                        <IconButton onClick={() => onRefreshGuests()} style={{ marginLeft: 10, marginTop: 5 }}>
                          <RefreshIcon style={{ color: 'white', fontSize: 25 }} />
                        </IconButton>
                        {canEditGuest ? (
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <IconButton onClick={() => this.onToggleTableSelection()} style={{ marginTop: 5 }}>
                              <SelectMultipleCheckBoxIconCustom style={{ color: 'white', width: 24 }} />
                            </IconButton>
                            {selectionModeActive && !_.isEmpty(selectedRows) ? (
                              <div style={{ marginLeft: 8, display: 'flex', alignItems: 'center', marginTop: 3 }}>
                                <IconButton onClick={() => this.onDeleteGuestBatch(selectedRows)}>
                                  <DeleteOutlineIconCustom style={{ color: 'white', width: 25 }} />
                                </IconButton>
                              </div>
                            ) : null}
                            {selectionModeActive && !_.isEmpty(selectedRows) ? (
                              <div style={{ marginLeft: 8, display: 'flex', alignItems: 'center', marginTop: 3 }}>
                                <IconButton onClick={() => this.onDisableGuestsBatch(selectedRows)}>
                                  <UserDisableIconCustom style={{ color: 'white', width: 25 }} />
                                </IconButton>
                              </div>
                            ) : null}
                            {selectionModeActive && !_.isEmpty(selectedRows) ? (
                              <div style={{ marginLeft: 8, display: 'flex', alignItems: 'center', marginTop: 3 }}>
                                <IconButton onClick={() => this.onAddTagsBatch(selectedRows)}>
                                  <TagAddIconCustom style={{ color: 'white', width: 25 }} />
                                </IconButton>
                              </div>
                            ) : null}
                            {selectionModeActive && !_.isEmpty(selectedRows) ? (
                              <div style={{ marginLeft: 8, display: 'flex', alignItems: 'center', marginTop: 3 }}>
                                <IconButton onClick={() => this.onRemoveTagsBatch(selectedRows)}>
                                  <TagRemoveIconCustom style={{ color: 'white', width: 25 }} />
                                </IconButton>
                              </div>
                            ) : null}
                            {selectionModeActive && !_.isEmpty(selectedRows) ? (
                              <div style={{ marginLeft: 8, display: 'flex', alignItems: 'center', marginTop: 3 }}>
                                <IconButton onClick={() => this.onToggleOfficeModeGuestsBatch(selectedRows)}>
                                  <OfficeModeIconCustom style={{ color: 'white', width: 25 }} />
                                </IconButton>
                              </div>
                            ) : null}
                            
                          </div>
                        ) : null}
                        {orderBy && 
                          <IconButton onClick={() => this.onResetOrder()} style={{ marginLeft: 5, marginTop: 5 }}>
                            <UnsortIconCustom style={{ color: 'white', width: 25 }} />
                          </IconButton>
                        }
                      </div>
                    </TableCell>
                    <TableCell />
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="guestTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchGuestOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          iconColor="white"
                          page={currentPage}
                          lastPage={totalPages - 1}
                          rowsPerPage={size}
                          onChangePage={(event, page) => onFetchGuestOnPage(page)}
                        />
                      )}
                    />
                  </TableRow>
                  <TableRow>
                    {selectionModeActive ? (
                      <TableCell padding="checkbox">
                        <Checkbox classes={{root: 'custom-checkbox-root'}} color="primary" checked={selectedAllActive} onChange={checked => this.onSelectAll(selectedAllActive)} />
                      </TableCell>
                    ) : null}
                    <TableCell>
                      <div className={classes.tableColumnHeader}>
                        <h4 className={classes.tableColumnHeaderText}><Entity entity="guest" /></h4>
                        {orderBy === 'email'?(
                          <IconButton onClick={() => this.handleOrderChange('email')} style={{opacity: 1}} className={classes.tableColumnHeaderArrow}>
                            {orderDir==='ASC'|| orderBy!=='email'? <SortDescendingIconCustom className={classes.tableColumnHeaderArrowIcon}/> : <SortAscendingIconCustom className={classes.tableColumnHeaderArrowIcon}/>}
                          </IconButton>
                        ):(
                          <IconButton onClick={() => this.handleOrderChange('email')} style={{opacity: 0.6}} className={classes.tableColumnHeaderArrow}>
                            {<SortIconCustom className={classes.tableColumnHeaderSortIcon}/>}
                          </IconButton>
                        )}
                      </div>
                    </TableCell>
                    <TableCell style={{ minWidth: 200 }}><h4 className={classes.tableColumnHeaderText}><Entity entity="company" /></h4></TableCell>
                    <TableCell style={{ minWidth: 220 }}><h4 className={classes.tableColumnHeaderText}><Entity entity="userState" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnHeaderText}><Entity entity="deviceUUID" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnHeaderText}><Entity entity="actions" /></h4></TableCell>
                    {isAccessProfileAddonActive ? <TableCell><h4 className={classes.tableColumnHeaderText}><Entity entity="accessProfile" /></h4></TableCell> : null}
                    {canReadUserTags ? <TableCell><h4 className={classes.tableColumnHeaderText}><Entity entity="guestTags" /></h4></TableCell> : <TableCell />}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(guestData, (guest) => {
                    const cannotEditExternalUser = guest.extId && isCovivioIntegrationEnabled;
                    const statusColor = !guest.enabled && !guest.withoutEmail ? '#E53935' : '#43A047';
                    const isRowSelected = _.indexOf(selectedRows, guest) !== -1;
                    const accessProfile = _.find(accessProfilesData, { id: guest.accessProfileId });
                    const showMoreTagsSectionVisible = guest && guest.tags && _.size(guest.tags) > VISIBLE_TAGS_NUMBER;
                    const visibleTags = guest && guest.showAllTags ? guest.tags : _.slice(guest.tags, 0, VISIBLE_TAGS_NUMBER);
                    const isPrivateUnitUser = guest.privateUnit
                    const privateUnitId = guest.privateUnitId
                    const privateUnit = privateUnitId && privateUnits.find(e=>e.id===privateUnitId) || undefined
                    return (
                      <TableRow
                        key={guest.id}
                        className={classes.row}
                        hover
                        classes={{
                          selected: classes.rowSelected,
                          hover: isPrivateUnitUser?'':classes.rowHovered,
                        }}
                        onClick={isPrivateUnitUser ? ()=>{} : selectionModeActive ? () => this.onToggleRowSelection(isRowSelected, guest) : () => onSelectGuest(guest)}
                        selected={selectedGuest && selectedGuest.id === guest.id}
                      >
                        {selectionModeActive ? (
                          <TableCell padding="checkbox">
                            <Checkbox classes={{root: 'custom-checkbox-root'}} color="primary" style={{opacity:isPrivateUnitUser?0:1, pointerEvents:isPrivateUnitUser?'none':'unset'}} checked={isRowSelected} onChange={() => {this.onToggleRowSelection(isRowSelected, guest)}} />
                          </TableCell>
                        ) : null}
                        <TableCell>
                          <div className={classes.guestRowContainer}>
                            {isPrivateUnitUser ? (
                              <div style={{ width: 'max-content' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, marginBottom: 5 }}>
                                  <h5 style={{ fontWeight: 'bold', fontSize: 17, margin: 0, marginTop: 5, marginRight: 5 }}>{`[PU] ${(privateUnit && privateUnit.name) || ""}`}</h5>
                                </div>
                                <h5 style={{ marginTop: 7 }}>{`${L20NContext.getSync('guest')} ${guest.id}`}</h5>
                              </div>
                            ) : (
                              <div style={{ width: 'max-content' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, marginBottom: 5 }}>
                                  <h5 style={{ fontWeight: 'bold', fontSize: 17, margin: 0, marginTop: 5, marginRight: 5 }}>{`${guest.firstname} ${guest.lastname}`}</h5>
                                  {guest.extId ? <p style={{ margin: 0, alignSelf: 'flex-start' }} data-tip={L20NContext.getSync('guestSyncFromSource')}><CloudIcon style={{ color: '#4DB6AC', marginTop: 3, marginRight: 5, marginLeft: 0, fontSize: 20 }} /></p> : null}
                                  {guest.isGuestManager ? <p style={{ margin: 0, alignSelf: 'flex-start', managerTop: 2 }} data-tip={L20NContext.getSync('guestIsManager')}><RoleIcon style={{ color: 'orange', marginTop: 0, fontSize: 18 }} /></p> : null}
                                  {guest.canTogglePassageMode ? <p style={{ margin: 0, marginLeft: 2, marginTop: 6, alignSelf: 'flex-start', }} data-tip={L20NContext.getSync('userCanActivateOfficeMode')}><OfficeModeIconCustom style={{ color: GREEN, marginTop: 0, width: 18 }} /></p> : null}
                                </div>
                                {guest.withoutEmail ?  (
                                  <div style={{ display: 'flex', marginTop: 7, marginBottom: 10, flexDirection: 'row', alignItems: 'center' }}>
                                    <RemoveEmailIconCustom style={{ color: '#3f3f3f', width: 15, marginRight: 5 }} />
                                    <h5 style={{ color: '#3f3f3f', margin: 0 }}><Entity entity="noEmailSet" /></h5>
                                  </div>
                                ) : (<h5 style={{ marginTop: 7 }}>{guest.email}</h5>)}
                                <CustomFieldsDisplayView
                                  textStyle={{ fontSize: 13, fontWeight: 'normal' }}
                                  hideIcon
                                  containerStyle={{ minWidth: 400, marginLeft: -5, marginTop: -5, flexWrap: 'wrap', marginBottom: 10 }}
                                  customFields={guest.customFields}
                                />
                              </div>
                            )}
                          </div>
                        </TableCell>
                        <TableCell>
                          {!isPrivateUnitUser && <h5>{guest.companyName}</h5>}
                        </TableCell>
                        <TableCell>
                          <div className={classes.rowWithAction}>
                            <div style={{ backgroundColor: statusColor, width: 8, height: 8, borderRadius: 50, marginRight: 5 }} />
                            <h5 style={{ margin: 0, marginTop: -2 }}>
                              <Entity
                                key={`${guest.enabled}-${guest.withoutEmail}`}
                                style={{ fontWeight: 'bold', textTransform: 'uppercase', color: statusColor, textAlign: 'center', fontSize: 12 }}
                                entity={!guest.enabled && !guest.withoutEmail ? 'userDisabled' : 'userEnabled'}
                              />
                            </h5>
                            {!cannotEditExternalUser && canEditGuest && guest.enabled && !guest.isGuestManager && !guest.withoutEmail ? (
                              <p style={{ margin: 0, alignSelf: 'flex-start' }} data-tip={L20NContext.getSync('deactivateUser')}>
                                <IconButton
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    onDisableGuestRequest(guest);
                                  }}
                                  className={classes.rowActionButton}
                                >
                                  <UserDisableIconCustom className={classes.rowActionButtonIcon} style={{ width: 20, marginLeft: 2 }} />
                                </IconButton>
                              </p>
                            ) : null}
                            {!cannotEditExternalUser && canEditGuest && !guest.enabled && !guest.isGuestManager && !guest.withoutEmail ? (
                              <p style={{ margin: 0, alignSelf: 'flex-start' }} data-tip={L20NContext.getSync('activateUser')}>
                                <IconButton
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    onResetGuestPassword(guest);
                                  }}
                                  className={classes.rowActionButton}
                                >
                                  <UserCheckIconCustom className={classes.rowActionButtonIcon} style={{ color: '#43A047', width: 20, marginLeft: 2 }} />
                                </IconButton>
                              </p>
                            ) : null}
                          </div>
                        </TableCell>
                        <TableCell>
                          {isPrivateUnitUser && privateUnitId ? (
                            <div>
                              <Button
                                onClick={(event) => {
                                  event.stopPropagation();
                                  onOpenPrivateUnitRequest(privateUnitId);
                                }}
                                className={classes.buttonPU}
                              >
                                {`${L20NContext.getSync('openPrivateUnit')}`}
                                <PrivateUnitsIconCustom style={{ width: 20, marginLeft: 5 }} />
                              </Button>
                            </div>
                          ):null}
                          {!isPrivateUnitUser && guest && guest.mobileUuidState === MOBILE_UDID_STATES.SET ? (
                            <div className={classes.rowWithAction}>
                              {guest.mobileUuid ? (
                                <Entity
                                  key={`${guest.mobileUuid}`}
                                  style={{ fontWeight: 'bold', textTransform: 'uppercase', color: '#43A047', textAlign: 'center', fontSize: 12, margin: 0 }}
                                  entity="registered"
                                />
                              ) : ''}
                              {canEditGuest && guest.mobileUuid ? (
                                <p style={{ margin: 0, alignSelf: 'flex-start', cursor: 'pointer' }} data-tip={L20NContext.getSync('resetDevice')}>
                                  <IconButton
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      onRemoveDeviceRequest(guest);
                                    }}
                                    className={classes.rowActionButton}
                                    style={{ marginLeft: 2 }}
                                  >
                                    <DeviceRemoveIconCustom className={classes.rowActionButtonIcon} style={{ width: 20 }} />
                                  </IconButton>
                                </p>
                              ) : null}
                            </div>
                          ) : null}
                        </TableCell>
                        <TableCell>
                          {!cannotEditExternalUser && canDeleteGuest ? (
                            <div className={classes.actionContainer} style={{ justifyContent: 'flex-start', marginLeft: -15 }}>
                              <Button
                                onClick={(event) => {
                                  event.stopPropagation();
                                  onDeleteGuestRequest(guest);
                                }}
                                className={classes.button}
                              >
                                <Entity entity="delete" />
                                <DeleteIcon style={{ fontSize: 20, marginLeft: 5 }} />
                              </Button>
                            </div>
                        ) : null}
                        </TableCell>
                        {isAccessProfileAddonActive ? (
                          <TableCell>
                            {!isPrivateUnitUser && accessProfile ? (
                              <div
                                key={accessProfile.id}
                                className="tag"
                                style={{
                                  marginTop: 5,
                                  marginBottom: 2,
                                  padding: 5,
                                  alignItems: 'center',
                                  backgroundColor: BLUE,
                                  borderColor: BLUE,
                                  display: 'inline-flex',
                                  color: 'white',
                                }}
                              >
                                <AccessProfilesIconCustom style={{ width: 15, color: 'white' }} />
                                <h5 style={{ margin: 0, marginLeft: 5, fontSize: 14 }}>{accessProfile.name}</h5>
                            </div>
                            ): null}
                          </TableCell>
                        ) : null}
                        <TableCell>
                          {!isPrivateUnitUser && canReadUserTags ?
                            <div className="row-tags-container" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}>
                              {guest && guest.userTag && (
                                <Tag
                                  tag={guest.userTag}
                                  style={{ padding: 5 }}
                                  textStyle={{ fontSize: 14 }}
                                />
                              )}
                              {_.map(visibleTags, tag =>
                                <Tag
                                  key={tag.id}
                                  tag={tag}
                                  style={{ padding: 5 }}
                                  textStyle={{ fontSize: 14 }}
                                />,
                              )}
                            </div>
                            : null}
                            {showMoreTagsSectionVisible ? (
                              <div>
                                {guest && guest.showAllTags ? (
                                  <h5
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    onSetGuestTagsVisibility(guest, false);
                                  }}
                                  className="link-label"
                                  style={{ color: BLUE, marginLeft: 10, marginTop: 18 }}>
                                    <Entity entity="showLess" />
                                  </h5>
                                ) : (
                                  <h5
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onSetGuestTagsVisibility(guest, true);
                                    }}
                                    className="link-label"
                                    style={{ color: BLUE, marginLeft: 10, marginTop: 18 }}>
                                      ...<Entity entity="showXMore" data={{ number: _.size(guest.tags) - VISIBLE_TAGS_NUMBER }} />
                                    </h5>
                                  )}
                              </div>
                            ): null}
                      </TableCell>
                        <ReactTooltip delayShow={200} effect="float" />
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow style={{ backgroundColor: headerColor }}>
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="guestTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchGuestOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          page={currentPage}
                          iconColor="white"
                          lastPage={totalPages - 1}
                          rowsPerPage={size}
                          onChangePage={(event, page) => onFetchGuestOnPage(page)}
                        />
                      )}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Card>
            : (
              <div>
                {!viewLoading ? (
                  <EmptyView
                    title={filters && !filters.search ? 'noGuests' : 'noGuestsFound'}
                    subtitle={filters && !filters.search ? 'noGuestsDescription' : 'noGuestsFoundSearchDescription'}
                    onNewEntity={canCreateGuest && filters && !filters.search ? () => onNewGuest() : null}
                    newEntityTitle="addGuest"
                    iconName="icon-simple-line-icons-user"
                  />) : <ListLoadingView />}
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(GuestTableView);
