// @ts-nocheck
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { change, submit } from 'redux-form';
import { BLUE, MATCH_TAG_MODE, PERMISSION_ENTITIES, PERMISSIONS } from '../../_config/consts';
import * as LockerActions from '../../redux/actions/locker.actions';
import * as TagActions from '../../redux/actions/tag.actions';
import MDButton from '../MDButton/MDButton.jsx';
import PickupPointForm from './PickupPointForm.jsx';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import moment from 'moment';
import momentTz from 'moment-timezone';

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});
@connect(state => ({
  form: state.form.PickupPointForm,
  locker: state.lockers.selectedLocker,
  language: state.settings.language,
  themeName: state.settings.items.theme.data.themeName,
  lockTags: state.tags.lock.data,
}))
class PickupPointsOperationalSection extends React.Component {

  componentWillMount() {
    const { dispatch } = this.props;
    const includeSpecialTags = true;
    dispatch(TagActions.cancelFetchLockTagsByFilter());
    dispatch(TagActions.fetchLockTags(includeSpecialTags, 0, 100));
  }

  filterLockTags(value) {
    const { dispatch } = this.props;
    const includeSpecialTags = true;
    dispatch(TagActions.cancelFetchLockTagsByFilter());
    dispatch(TagActions.fetchLockTagsByFilter(value, 100, includeSpecialTags));
  }

  fetchLocksByTags(tags = [], page = 0, lockTagMatchingMode = MATCH_TAG_MODE.EVERY_TAG, append = false) {
    const { dispatch } = this.props;
    dispatch(LockerActions.cancelFetchLocksByTags());
    dispatch(LockerActions.fetchLocksByTags(tags, lockTagMatchingMode, page, append));
    dispatch(change('PickupPointForm', 'resourceSmartLockTags', tags));
  }

  filterLockTagsEntrance(value) {
    const { dispatch } = this.props;
    const includeSpecialTags = true;
    dispatch(TagActions.cancelFetchLockTagsByFilter());
    dispatch(TagActions.fetchLockTagsByFilter(value, 100, includeSpecialTags));
  }

  fetchLocksByTagsEntrance(tags = [], page = 0, lockTagMatchingMode = MATCH_TAG_MODE.EVERY_TAG, append = false) {
    const { dispatch } = this.props;
    dispatch(LockerActions.cancelFetchEntranceLocksByTags());
    dispatch(LockerActions.fetchEntranceLocksByTags(tags, lockTagMatchingMode, page, append));
    dispatch(change('PickupPointForm', 'resourceSmartLockTagsEntrance', tags));
  }

  async onSave(lockerData) {
    const { dispatch, form, locker, onPickupPointEditConfirmed, onPickupPointCreateConfirmed } = this.props;

    const isCreate = !locker||_.isEmpty(locker)||locker.id===undefined

    if (isCreate && !lockerData.type)
      return;
    
    let timeIntervalFrom =
      (moment(lockerData.timeIntervalFrom).hours() * 3600) + (moment(lockerData.timeIntervalFrom).minutes() * 60) + moment(lockerData.timeIntervalFrom).seconds();
    let timeIntervalTo =
      (moment(lockerData.timeIntervalTo).hours() * 3600) + (moment(lockerData.timeIntervalTo).minutes() * 60) + moment(lockerData.timeIntervalTo).seconds();

    timeIntervalFrom = Math.max(1,timeIntervalFrom)
    timeIntervalTo = Math.min(86339,timeIntervalTo)

    let lockerDataParsed = {
      name: lockerData.name,
      type: isCreate ? lockerData.type : undefined,
      lockIds: isCreate ? lockerData.smartLocksIds : undefined,
      timeInterval: {
        from: timeIntervalFrom,
        to: timeIntervalTo,
      },
      daysOfTheWeek: lockerData.daysOfTheWeek,
      entranceLockIds: lockerData.entranceLockIds || [],
      pickupDays: lockerData.pickupDays,
      operationTimeout: lockerData.operationTimeout,
      timeZone: isCreate ? momentTz.tz.guess() : lockerData.timeZone,
    }

    if (isCreate)
      onPickupPointCreateConfirmed(lockerDataParsed)
    else
      onPickupPointEditConfirmed(locker.id,lockerDataParsed)
  }

  render() {
    const {
      dispatch,
      locker,
      form,
      lockTags,
    } = this.props;
    const canEditPickupPoint = true // TODO AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.LOCKER);
    const canSubmit = form && (!form.syncErrors || !Object.keys(form.syncErrors).length)
    return (
      <MuiThemeProvider theme={theme}>
        <div>
          <PickupPointForm
            locker={locker}
            onSubmit={(lockerData)=>this.onSave(lockerData)}
            locksFilteredByTags={locker && locker.locksFilteredByTags ? locker.locksFilteredByTags[0] : []}
            availableLockTags={lockTags}
            onLockInputChange={value => this.filterLockTags(value)}
            onLockTagsChange={values => this.fetchLocksByTags(values, 0, form && form.values && form.values.lockTagMatchingMode)}
            onFetchMoreLocks={page => this.fetchLocksByTags(form && form.values && form.values.resourceSmartLockTags, page, form && form.values && form.values.lockTagMatchingMode, true)}
            onLockFilterModeChange={mode => this.fetchLocksByTags(form && form.values && form.values.resourceSmartLockTags, 0, mode)}
            locksFilteredByTagsEntrance={locker && locker.locksFilteredByTagsEntrance ? locker.locksFilteredByTagsEntrance[0] : []}
            availableLockTagsEntrance={lockTags}
            onLockInputChangeEntrance={value => this.filterLockTagsEntrance(value)}
            onLockTagsChangeEntrance={values => this.fetchLocksByTagsEntrance(values, 0, form && form.values && form.values.lockTagMatchingModeEntrance)}
            onFetchMoreLocksEntrance={page => this.fetchLocksByTagsEntrance(form && form.values && form.values.resourceSmartLockTagsEntrance, page, form && form.values && form.values.lockTagMatchingModeEntrance, true)}
            onLockFilterModeChangeEntrance={mode => this.fetchLocksByTagsEntrance(form && form.values && form.values.resourceSmartLockTagsEntrance, 0, mode)}
          />
          {canEditPickupPoint ? (
            <MDButton
              title={<Entity entity="save" />}
              containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
              style={{ height: 45, borderRadius: 0 }}
              style={{ height: 45, borderRadius: 0, opacity:canSubmit?1:0.7 }}
              disabled={!canSubmit}
              onClick={() => dispatch(submit('PickupPointForm'))}
            />
          ): null}
        </div>
      </MuiThemeProvider>
    );
  }
} 

export default PickupPointsOperationalSection;
