import WarningIcon from '@material-ui/icons/Warning';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import moment from 'moment';
import React from 'react';
import DeleteOutlineIconCustom from '../../CustomIcons/DeleteOutlineIconCustom.jsx';

export default class RuleDeletedEventRow extends React.Component {
  render() {
    const { event: { id, timestamp, actorId, data: { actor, entity } }, isAnonymized, privateUnitIdActor } = this.props;
    return (
      <div style={{ minHeight: 110, padding: 15, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <DeleteOutlineIconCustom style={{ width: 30, marginRight: 25, color: '#3f3f3f' }} />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h3 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0 }}>
              <Entity key={id} entity="credentialRuleDelete" />
            </h3>
            {isAnonymized ? (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
                <WarningIcon style={{ color: 'orange', marginRight: 10, fontSize: 20 }} />
                <h4 style={{ color: '#3f3f3f', lineHeight: '1.5em', fontWeight: '100', margin: 0 }}>
                  <Entity
                    key={id}
                    entity="adminEventRowAnonymizedDescription"
                  />
                </h4>
              </div>
              ) : (
                <h4 style={{ color: '#3f3f3f', lineHeight: '1.5em', fontWeight: '100', margin: 0, marginTop: 10 }}>
                  <Entity
                    key={id}
                    entity="credentialRuleDeleteDescription"
                    data={{
                      actor: actorId && actor ? `${actor.firstname || ''} ${actor.lastname || ''} ${actor.email || ''}` : '---',
                      entity: entity ? `${entity.name || entity.entityId || ''}` : '---',
                    }}
                  />
                </h4>
            )}
          </div>
        </div>
        <h4 style={{ minWidth: 240, textAlign: 'right', marginTop: 10, color: '#3f3f3f' }}>{moment(timestamp).format('LLL')}</h4>
      </div>
    );
  }
}