import {
  Col, Row
} from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import qs from 'qs';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { connect } from 'react-redux';
import LicenseSectionView from '../components/Settings/LicenseSectionView.jsx';
import SettingsDetailsSection from '../components/Settings/SettingsDetailsSection.jsx';
import SettingsOperationalSection from '../components/Settings/SettingsOperationalSection.jsx';
import AbilityProvider from '../permissionsUtils/AbilityProvider';
import * as PlatformUsersActions from '../redux/actions/platformUsers.actions';
import * as SettingsActions from '../redux/actions/setting.actions';
import * as UserActions from '../redux/actions/user.actions';
import { PERMISSIONS, PERMISSION_ENTITIES } from '../_config/consts.js';

@connect((state) => ({ routing: state.router, form: state.form.PlatformUserForm, settings: state.settings, platformUsers: state.platformUsers, themeName: state.settings.items.theme.data.themeName }))
class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLicenseSection: false,
    };
  }
  
  componentWillMount() {
    const { dispatch, routing, settings } = this.props;
    const parsed = qs.parse(routing.location.search, { ignoreQueryPrefix: true });
    dispatch(SettingsActions.selectSetting({}));
    dispatch(UserActions.fetchCurrentActiveIntegrations());
    const showLicense = parsed.showLicense;
    const showSystemSettings = parsed.showSystemSettings;
    const showPrivateUnitSettings = parsed.showPrivateUnitSettings;
    const canReadCompanyData = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.COMPANY);
    if (showLicense && canReadCompanyData && settings && settings.items) {
      dispatch(SettingsActions.selectSetting(settings.items.license));
      dispatch(SettingsActions.setOperationalMode(true));
    }
    const showWallet = parsed.showWallet;
    if (showWallet && canReadCompanyData && settings && settings.items) {
      dispatch(SettingsActions.selectSetting(settings.items.wallet));
      dispatch(SettingsActions.setOperationalMode(true));
    }
    if (showSystemSettings) {
      dispatch(SettingsActions.selectSetting(settings.items.systemSettings));
      dispatch(SettingsActions.setOperationalMode(true));
    }
    if (showPrivateUnitSettings) {
      dispatch(SettingsActions.selectSetting(settings.items.privateUnits));
      dispatch(SettingsActions.setOperationalMode(true));
    }
  }


  render() {
    const { settings, themeName, dispatch } = this.props;
    const { showLicenseSection } = this.state;
    const themeClass = `list-view-header bg-${themeName}-theme`;
    return (
      <div>
        <Row>
          <Col xs={12} md={12} sm={12} className={themeClass} style={{ marginLeft: 25 }}>
            <h3 style={{ marginBottom: 19 }}>
              <Entity entity="sectionTitle" data={{ name: 'settingsDetails' }} />
            </h3>
          </Col>
        </Row>
        <SettingsDetailsSection
          onOpenLicenseSetting={() => this.setState({ showLicenseSection: true })}
        />
        <ReactCSSTransitionGroup
          transitionName="bounce"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
        >
          {settings.isOperationalMode &&
            <SettingsOperationalSection
              themeName={themeName}
            />
          }
        </ReactCSSTransitionGroup>
        <ReactCSSTransitionGroup
          transitionName="bounce"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
        >
          {showLicenseSection ? (
            <LicenseSectionView
              themeName={themeName}
              onClose={() => this.setState({ showLicenseSection: false })}
            />
          ) : null}
        </ReactCSSTransitionGroup>
      </div>
    );
  }
} 

export default Settings;
