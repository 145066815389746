// @ts-nocheck
import React from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/core';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import MDButton from '../MDButton/MDButton.jsx';
import Warning from '@material-ui/icons/Warning';
import {
  FormControl, FormGroup
} from '@sketchpixy/rubix';
import { ORANGE, RED } from '../../_config/consts';


const styles = theme => ({
  viewContainer: {
    margin: 13,
    marginBottom: 30,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleText: {
    marginRight: 5,
    marginBottom: 5,
    fontSize: 25,
    fontWeight: 'bold',
    color: '#3f3f3f',
    textAlign: 'left',
  },
  sectionText: {
    margin: 0,
    marginRight: 5,
    fontSize: 16,
    lineHeight: 1.4,
    color: '#3f3f3f',
    textAlign: 'left',
  },
  warningIcon: {
    color: ORANGE,
    fontSize: 40,
  },
  italicText: {
    fontStyle: 'italic',
    marginLeft: 5,
  },
});

class PrivateUnitDeleteView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      deleteText: undefined,
      feedbackText: undefined,
      hasInputError: false,
    };
  }

  onChangeText(deleteText) {
    this.setState({ deleteText });
    setTimeout(() => this.onValidateInput(), 500);
  }

  onValidateInput() {
    const { deleteText } = this.state;
    const validationString = L20NContext.getSync('deletePermanentlyText');
    const isValid = validationString === _.trim(deleteText.toLowerCase());
    this.setState({ hasInputError: !isValid });
    return isValid;
  }

  onChangeFeedbackText(feedbackText) {
    this.setState({ feedbackText });
  }

  onSubmitForm() {
    const { feedbackText } = this.state;
    const { onRemoveSubscriptionConfirm } = this.props;
    const isValid = this.onValidateInput();
    if (isValid && onRemoveSubscriptionConfirm) {
      onRemoveSubscriptionConfirm(feedbackText);
    }
  }

  render() {
    const { classes } = this.props;
    const { hasInputError, feedbackText, deleteText } = this.state;
    return (
      <div className={classes.viewContainer}>
        <input style={{ display: 'none' }} type="text" name="deleteInput" />
        <div className={classes.titleContainer}>
          <Warning className={classes.warningIcon} />
          <h2 className={classes.titleText} style={{ margin: 0, marginLeft: 10, marginTop: 0 }}><Entity entity="warning" /></h2>
        </div>
        <h2 className={classes.sectionText}><Entity entity="deletePrivateUnitWarning" /></h2>
        <h2 className={classes.sectionText} style={{ marginTop: 20, marginBottom: 10 }}><Entity entity="deletePrivateUnitWarning2" /><span className={classes.italicText}><Entity entity="deletePermanentlyText" /></span></h2>
        <FormGroup
          controlId="formBasicText"
          validationState={hasInputError ? 'error' : 'success'}
        >
          <FormControl
            name="deleteInput"
            type="text"
            value={deleteText}
            onPaste={e => e.preventDefault()}
            onChange={e => this.onChangeText(e.target.value)}
          />
          {hasInputError ? <h6 style={{ color: 'red', marginTop: 6 }}><Entity entity="errorInputCoincide" /></h6> : null}
        </FormGroup>
        <MDButton
          containerStyle={{ marginTop: 40 }}
          title={<Entity entity="deletePermanentlyText" />}
          backgroundColor={RED}
          disabled={hasInputError || !deleteText}
          onClick={() => this.onSubmitForm()}
        />
      </div>
    );
  }
}
export default withStyles(styles)(PrivateUnitDeleteView);
