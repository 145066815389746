import { CheckCircle } from '@material-ui/icons';
import { Form } from '@sketchpixy/rubix';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import ReactTooltip from 'react-tooltip';
import Cancel from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/AddCircle';
import WarningIcon from '@material-ui/icons/Warning';
import { Field, FieldArray, change, reduxForm } from 'redux-form';
import { Row } from '@sketchpixy/rubix';
import TimeIcon from '@material-ui/icons/AccessTime';
import { BLUE, GREEN, ORANGE, RED, reservationSearchModeOptions, reservationSearchModeValues } from '../../../_config/consts.js';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import * as formatter from '../../../_config/formatter';
import FormCardContainer from '../Elements/FormCardContainer.jsx';
import DatePickerField from '../Fields/DatePickers/SingleDatePicker/DatePickerField.jsx';
import GuestSelector from '../Fields/GuestSelector.jsx';
import MDCheckBoxField from '../Fields/MDCheckBox/MDCheckBoxField.js';
import SelectableRowField from '../Fields/SelectableRows/SelectableRowField.jsx';
import CustomField from '../Fields/CustomField.jsx';
import SelectableField from '../Fields/SelectableInput/SelectableField.jsx';
import { reservationActions as ReservationActions, resourceActions as ResourceActions } from '@bottega52/bookey-redux-module';
import * as ReservationsActions from '../../../redux/actions/reservations.actions';
import * as TagsManagementActions from '../../../redux/actions/tagsManagement.actions';
import * as GuestActions from '../../../redux/actions/guest.actions';
import CopyIconCustom from '../../CustomIcons/CopyIconCustom.jsx';
import * as UtilsActions from '../../../redux/actions/utils.actions';
import { Card } from '@material-ui/core';
import ArrowLeftIconCustom from '../../CustomIcons/ArrowLeftIconCustom.jsx';
import ArrowRightIconCustom from '../../CustomIcons/ArrowRightIconCustom.jsx';
import { withStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/CheckCircle';
import MDButton from '../../MDButton/MDButton.jsx';
import { Divider } from '@material-ui/core';
import TimePickerField2 from '../Fields/TimePicker/TimePickerField2.jsx';
import MDRadioButtonsGroupField from '../Elements/MDRadioButtonsGroup/MDRadioButtonsGroupField.js';
import BasicModal from '../../Modals/BasicModal.jsx';

const localizer = momentLocalizer(moment);

const onRenderInvitedEmails = (props) => {
  const { dispatch, fields, formData, onInitializeNewInvitedEmail, filteredGuestsForInvitedEmails, onGuestInputChange } = props;
  const numberOfInvitedEmails = _.size(fields);
  return (
    <div style={{}}>
      {fields && fields.map((invitedEmailFieldName, index) => {
        const invitedEmail = formData && formData.invitedEmails && formData.invitedEmails[index]
        return (
          <div key={`invitedEmail${index + 1}`} elevation={2} style={{width:'fit-content'}}>
            <Divider />
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: -10, paddingRight: 10 }}>
              <Row style={{marginLeft:0, marginRight:0, display:'flex', flexDirection:'row', alignItems:'center',}}>
                <div style={{ paddingLeft: 0, width:400 }}>
                  <Field
                    title={null}
                    id={`invitedEmails[${index}].guest`}
                    name={`invitedEmails[${index}].guest`}
                    singleTag
                    mutableTags={
                      (
                        invitedEmail&&invitedEmail.guest&&invitedEmail.guest.name&&invitedEmail.guest.id<0 ? 
                        [{ id: invitedEmail.guest.id, name: invitedEmail.guest.name, type:'user', color:"#ba68c8" }] : 
                        invitedEmail&&invitedEmail.search ? 
                        [{ id: -1, name: invitedEmail.search, type:'user', color:"#ba68c8" }] : 
                        [])
                      .concat(
                        _.uniqBy(filteredGuestsForInvitedEmails.map(e=>{return { id: e.id, name: e.email, type:'user', color:"#ba68c8" }}), 'id')
                      )
                    }
                    placeholder={<Entity entity="insertGuestTagsForCard" />}
                    component={GuestSelector}
                    onInputChange={value => {
                      dispatch(change('ReservationFormSlots', `invitedEmails[${index}].search`, value || ""));
                      onGuestInputChange(value)
                    }}
                    onHandleChange={value => {
                      dispatch(change('ReservationFormSlots', `invitedEmails[${index}].search`, ""));
                      dispatch(change('ReservationFormSlots', `invitedEmails[${index}].guest`, {id: (value && value.id) || -1, name: (value && value.name) || ""}));
                    }}
                  />
                </div>
                <div style={{marginBottom:10}}>
                  {!invitedEmail || !invitedEmail.guest || !invitedEmail.guest.name ? (
                    <CheckIcon style={{ fontSize: 20, color: GREEN, marginLeft:10, opacity:0 }} />
                  ) : invitedEmail.guest.id>0 ? (
                    <p style={{ margin: 0, alignSelf: 'flex-start' }} data-tip={L20NContext.getSync('reservationInvitedEmailsResultOK')}>
                      <CheckIcon style={{ fontSize: 20, color: GREEN, marginLeft:10 }} />
                    </p>
                  ) : (
                    <p style={{ margin: 0, alignSelf: 'flex-start' }} data-tip={L20NContext.getSync('reservationInvitedEmailsResultKO')}>
                      <WarningIcon style={{ fontSize: 20, color: ORANGE, marginLeft:10 }} />
                    </p>
                  )}
                </div>
              </Row>
              <IconButton
                style={{ marginLeft: 30, marginBottom: 15 }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!formData.isEditing) {
                    fields.remove(index);
                  }
                }}
              >
                <DeleteIcon style={{ color: RED, fontSize: 20 }} />
              </IconButton>
            </div>
          </div>
        )
        
      })}
      <Card
        style={{ height: 40, marginBottom: 20, padding: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', width:'fit-content' }}
        className="card card-rounded"
        onClick={() => {
          fields.push({});
          onInitializeNewInvitedEmail(numberOfInvitedEmails);
        }}
      >
        <AddIcon style={{ color: '#4DB6AC', marginRight: 15, fontSize: 25 }} />
        <h4 style={{ fontWeight: 'bold', marginTop:0, marginBottom:0 }}><Entity entity="newResourceReservationAutomaticEmail" /></h4>
      </Card>
      <ReactTooltip delayShow={200} effect="float" />
    </div>
  );
}

const styles = theme => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  titleText: {
    marginRight: 5,
    marginBottom: 5,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#3f3f3f',
    textAlign: 'left',
  },
  sectionText: {
    margin: 0,
    marginRight: 5,
    fontSize: 18,
    lineHeight: 1.4,
    color: '#3f3f3f',
    textAlign: 'left',
  },
  exampleContainer: {
    marginTop: 15,
    borderRadius: 5,
  },
  exampleText: {
    marginLeft: 12,
    marginTop: 10,
    fontSize: 15,
    color: '#3f3f3f',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  warningTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
    marginTop: -20,
  },
  guestCardContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  guestDataText: {
    fontWeight: 'bold',
    margin: 0,
    color: '#3f3f3f',
    marginTop: 2,
  },
});

const validate = (values) => {
  const errors = {};
  if (!values.firstname || (values.firstname && values.firstname.length === 0)) {
    errors.firstname = 'required';
  }
  if (!values.resourceId && !values.resourceSelected) {
    errors.resourceId = 'required';
    errors.resourceSelected = 'required';
  }
  if (!values.dateFrom) {
    errors.dateFrom = 'required';
  }
  return errors;
};

function generateTimeIntervals(slotSizeHours, startingHour=0, endingHour=24) {
  const intervals = [];
  const hourInMilliseconds = 60 * 60 * 1000;
  for (let i = startingHour; i <= (endingHour - slotSizeHours); i += slotSizeHours) {
    const fromDate = i * hourInMilliseconds;
    const toDate = (i + slotSizeHours) * hourInMilliseconds;
    intervals.push({ fromDate: fromDate, toDate: toDate, index: Math.round(i/slotSizeHours) });
  }
  return intervals;
}

function addTimeToIntervals(intervals, additionalTime) {
  return intervals.map(interval => ({
    fromDate: interval.fromDate + additionalTime,
    toDate: interval.toDate + additionalTime,
    index: interval.index,
  }));
}

let filterTimeout;
@reduxForm({ form: 'ReservationFormSlots', validate })
@connect(state => ({ form: state.form.ReservationFormSlots }))
class ReservationFormSlots extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      orari: [],
      durate: [],
      startingDurateIndex: 0,
      slotSelected: null,
      occupiedIntervals: [],
      userResources: [],
      slotRefs: {},
      filteredGuestsForInvitedEmails: [],
      isMultidayMode: false
    }
  }  

  async componentDidMount() {
    const { dispatch } = this.props;
    await dispatch(UtilsActions.setSpinnerVisibile(true));
    await this.fetchUserResources()
    await this.fetchTimeAvailability(false)
    await dispatch(UtilsActions.setSpinnerVisibile(false));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.slotSelected !== prevState.slotSelected && this.state.slotSelected) {
      const selectedRef = this.state.slotRefs[this.state.slotSelected.index];
      if (selectedRef && selectedRef.current) {
        selectedRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
    ReactTooltip.rebuild();
  }

  async onFilterGuestsForInvitedEmails(value) {
    const { dispatch } = this.props;
    setTimeout(async () => {
      const guests = await dispatch(GuestActions.fetchUsersForSelector(value));
      this.setState({ filteredGuestsForInvitedEmails: guests });
    }, 800);
  }

  isReservationOverlapping(timeFrom, timeTo) {
    const { occupiedIntervals } = this.state
    if (!timeFrom || !timeTo)
      return false;

    const newReservationStart = moment(timeFrom).valueOf()
    const newReservationEnd = moment(timeTo).valueOf()

    return occupiedIntervals.some(e => {
      if (e.isNewEvent) return false;

      const existingReservationStart = moment(e.start).valueOf();
      const existingReservationEnd = moment(e.end).valueOf();

      return (
        newReservationStart < existingReservationEnd && 
        newReservationEnd > existingReservationStart
      );
    });
  }

  isReservationTooShort(timeFrom, timeTo, selectedResource) {
    if (!timeFrom || !timeTo || !selectedResource)
      return false;

    const minimumReservationLengthMinutes = selectedResource.minimumReservationTimeMinutes ? 
      selectedResource.minimumReservationTimeMinutes: undefined
    
    if (!minimumReservationLengthMinutes)
      return false

    return (moment(timeTo).valueOf() - moment(timeFrom).valueOf()) < (minimumReservationLengthMinutes*60*1000)
  }

  isReservationTooLong(timeFrom, timeTo, selectedResource) {
    if (!timeFrom || !timeTo || !selectedResource)
      return false;

    const maximumReservationLengthMinutes = selectedResource.maximumReservationTimeMinutes ? 
      selectedResource.maximumReservationTimeMinutes: undefined
    
    if (!maximumReservationLengthMinutes)
      return false
    
    return (moment(timeTo).valueOf() - moment(timeFrom).valueOf()) > (maximumReservationLengthMinutes*60*1000)
  }

  isReservationInDisabledIntervals(timeFrom, timeTo, selectedResource) {
    if (!timeFrom || !timeTo || !selectedResource)
      return false;

    const disabledIntervals = this.getDisabledResourceIntervals()

    const newReservationStart = moment(timeFrom).valueOf()
    const newReservationEnd = moment(timeTo).valueOf()

    return disabledIntervals.some(e => {
      const disabledIntervalStart = moment(e.start).valueOf();
      const disabledIntervalEnd = moment(e.end).valueOf();

      return (
        newReservationStart < disabledIntervalEnd && 
        newReservationEnd > disabledIntervalStart
      );
    });
  }

  getDisabledHours() {
    const { form } = this.props;
    if (form.values.dateFrom && moment(form.values.dateFrom).startOf('day').isSame(moment().startOf('day'))) {
      return _.times(moment().hour(), i => i);
    }
    return [];
  }

  getDisabledMinutes(selectedHour) {
    const { form } = this.props;
    if (form.values.dateFrom && moment(form.values.dateFrom).startOf('day').isSame(moment().startOf('day')) && selectedHour === moment().hour()) {
      return _.times(moment().minute(), i => i);
    }
    return [];
  }

  getDisabledResourceIntervals() {
    const { dispatch, form } = this.props;
    const { userResources } = this.state;

    const selectedDate = form && form.values && form.values.dateFrom && moment(form.values.dateFrom).valueOf()
    const selectedResource = userResources && form && form.values && form.values.resourceSelected && form.values.resourceSelected.value && 
      _.find(userResources, res => res.id === form.values.resourceSelected.value);
    
    if (!selectedDate || !selectedResource)
      return []

    const timeInterval = selectedResource.timeInterval && selectedResource.timeInterval.from!==undefined && selectedResource.timeInterval.to!==undefined ? 
      selectedResource.timeInterval : undefined
    const additionalTimeIntervals = selectedResource.additionalTimeIntervals && selectedResource.additionalTimeIntervals.length ? 
      selectedResource.additionalTimeIntervals : []
    const timeIntervals = [...(timeInterval ? [timeInterval] : []), ...(additionalTimeIntervals || [])].sort((a, b) => a.from - b.from);
    
    const disabledIntervals = [];

    let loopFromMinutes = 0
    timeIntervals.forEach((interval) => {
      const fromMinutes = interval.from || 0
      const toMinutes = interval.to || (86400 - 1)
      if (fromMinutes>loopFromMinutes) {
        const newStartHour = Math.floor(loopFromMinutes / 3600)
        const newStartMinutes = Math.floor(loopFromMinutes / 60) - (newStartHour*60)
        const newEndHour = Math.floor(fromMinutes / 3600)
        const newEndMinutes = Math.floor(fromMinutes / 60) - (newEndHour*60)
        disabledIntervals.push(
          {start: moment(selectedDate).set({ hour: newStartHour, minute: newStartMinutes }).valueOf(), 
          end: moment(selectedDate).set({ hour: newEndHour, minute: newEndMinutes }).valueOf()}
        )
      }
      loopFromMinutes = toMinutes
    })
    if (loopFromMinutes > 0 && loopFromMinutes < (86400 - 1) ) {
      const newStartHour = Math.floor(loopFromMinutes / 3600)
      const newStartMinutes = Math.floor(loopFromMinutes / 60) - (newStartHour*60)
      const newEndHour = Math.floor((86400 - 1) / 3600)
      const newEndMinutes = Math.floor((86400 - 1) / 60) - (newEndHour*60)
      disabledIntervals.push(
        {start: moment(selectedDate).set({ hour: newStartHour, minute: newStartMinutes }).valueOf(), 
        end: moment(selectedDate).set({ hour: newEndHour, minute: newEndMinutes }).valueOf()}
      )
    }

    return disabledIntervals;
  }
  
  getDayPropGetter = date => {
    const today = new Date();
    if (moment(date).isSame(today, 'day')) {
      return {
        style: {
          backgroundColor: 'transparent',
        }
      };
    }
    return {};
  };

  getEventPropGetter = (event) => {
    return {
      style: {
        backgroundColor: event.isNewEvent?BLUE:RED,
        color: 'white',
        borderColor: 'white',
        cursor: 'default',
        pointerEvents: 'none'
      }
    };
  };

  getSlotPropGetter = (date) => {
    const date_moment = moment(date).valueOf()

    const disabledIntervals = this.getDisabledResourceIntervals()
    const isDisabled = disabledIntervals.some(interval => 
      date_moment >= interval.start && date_moment < interval.end
    );
  
    return {
      className: isDisabled ? 'disabled-slot' : '',
      style: {
        backgroundColor: isDisabled ? '#d3d3d3' : '',
        pointerEvents: isDisabled ? 'none' : 'unset',
        cursor: isDisabled ? 'default' : 'pointer',
      },
    };
  };

  getCurrentSearchMode() {
    const { form, isMultidayMode } = this.props;

    if (isMultidayMode) {
      return reservationSearchModeValues.SEARCH_RESOURCES;
    }

    const searchModeResource = form && form.values && form.values.searchMode && form.values.searchMode===reservationSearchModeValues.SELECT_RESOURCE
    const searchModeAvailable = form && form.values && form.values.searchMode && form.values.searchMode===reservationSearchModeValues.SEARCH_RESOURCES

    return (searchModeResource ? reservationSearchModeValues.SELECT_RESOURCE : searchModeAvailable ? reservationSearchModeValues.SEARCH_RESOURCES : undefined)
  }

  handleSelectCalendarSlot = (slotInfo) => {
    const isSlotDefinedByDrag = Boolean(slotInfo.bounds)
    this.onChangedTimeFrom(moment(slotInfo.start).valueOf(), isSlotDefinedByDrag ? moment(slotInfo.end).valueOf() : undefined)
  };

  onChangedTimeFrom(newTimeFrom, newTimeTo=undefined) {
    const { dispatch, form } = this.props;
    const { occupiedIntervals, userResources } = this.state;

    const selectedResource = userResources && form && form.values && form.values.resourceSelected && form.values.resourceSelected.value && 
      _.find(userResources, res => res.id === form.values.resourceSelected.value);
    
    const defaultEventLength_ms = selectedResource ? (
      (selectedResource.minimumReservationTimeMinutes ? (selectedResource.minimumReservationTimeMinutes*60*1000) : (
          selectedResource.minimumReservationTimeMinutes ? (Math.min(60,selectedResource.minimumReservationTimeMinutes)*60*1000) : (60*60*1000)
        )
      )
    ) : (60*60*1000)

    const currentStart_ms = form && form.values && form.values.timeFrom && moment(form.values.timeFrom).valueOf()
    const currentEnd_ms = form && form.values && form.values.timeTo && moment(form.values.timeTo).valueOf()
    const currentDelta_ms = (currentEnd_ms && currentStart_ms && currentEnd_ms>=currentStart_ms) ? 
      (moment(currentEnd_ms).diff(moment(currentStart_ms),'milliseconds')) : defaultEventLength_ms

    const newStart_ms = moment(newTimeFrom).valueOf()
    const newEnd_ms = newTimeTo ? moment(newTimeTo).valueOf() : Math.min(
      newStart_ms + currentDelta_ms,
      moment(newStart_ms).endOf('day').valueOf()
    )

    dispatch(change('ReservationFormSlots', 'timeFrom', newStart_ms))
    dispatch(change('ReservationFormSlots', 'timeTo', newEnd_ms))

    // Se non c'è ancora evento virtuale, lo creo (se no lo aggiorno)
    if (_.some(occupiedIntervals,e=>e.isNewEvent)) {
      this.setState(prevState => ({
        occupiedIntervals: prevState.occupiedIntervals.map(ev => 
          ev.isNewEvent ? { 
            ...ev, 
            start: new Date(newStart_ms),
            end: new Date(newEnd_ms),
          } : ev
        )
      }));
    }
    else {
      const newEvent = {
        start: new Date(newStart_ms),
        end: new Date(newEnd_ms),
        title: L20NContext.getSync('newReservation'),
        isNewEvent: true,
      };
    
      this.setState(prevState => ({
        occupiedIntervals: [...prevState.occupiedIntervals, newEvent]
      }));
    }
  }

  onChangedTimeTo(value) {
    const { dispatch } = this.props;
    dispatch(change('ReservationFormSlots', 'timeTo', value))
    this.setState(prevState => ({
      occupiedIntervals: prevState.occupiedIntervals.map(ev => 
        ev.isNewEvent ? { ...ev, end: new Date(value) } : ev
      )
    }));
  }

  async onChangedTimeFromSearch(value) {
    const { dispatch } = this.props;
    await dispatch(change('ReservationFormSlots', 'timeFromSearch', value))
    this.fetchAvailableResources()
  }

  async onChangedTimeToSearch(value) {
    const { dispatch } = this.props;
    await dispatch(change('ReservationFormSlots', 'timeToSearch', value))
    this.fetchAvailableResources()
  }

  async onChangedNumberOfGuests(value) {
    const { dispatch } = this.props;
    await dispatch(change('ReservationFormSlots', 'numberOfGuests', value))
    if (this.getCurrentSearchMode()===reservationSearchModeValues.SELECT_RESOURCE)
      this.fetchTimeAvailability()
    else
      this.fetchAvailableResources()
  }

  async fetchUserResources() {
    const { dispatch, form } = this.props;
    const userTagSelected = form && form.values && form.values.firstname && form.values.firstname.id
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      let userId = undefined
      if (userTagSelected) {
        const guests = await dispatch(TagsManagementActions.fetchGuestsByTag(userTagSelected));
        if (guests && guests.content && guests.content.length && guests.content[0].id)
          userId = guests.content[0].id
      }
      const userResources = await dispatch(ReservationsActions.fetchUserResources(userId))
      
      this.setState({ userResources })

      const resourceIsSelected = Boolean(form && form.values && form.values.resourceSelected)
      const allowedResourceFound = userResources && form && form.values && form.values.resourceSelected && form.values.resourceSelected.value && 
        _.find(userResources, res => res.id === form.values.resourceSelected.value);
      if (resourceIsSelected && !allowedResourceFound) {
        dispatch(change('ReservationFormSlots', 'resourceSelected', null))
        dispatch(change('ReservationFormSlots', 'timeFrom', null))
        dispatch(change('ReservationFormSlots', 'timeTo', null))
        this.setState({ slotSelected: null, orari: [], durate: [], occupiedIntervals: [] })
        this.userNotAllowedModal.open();
      }

      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      console.log(error)
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async fetchAvailableResources() {
    const { dispatch, form, dateHasBeenChanged, onGuestTagsChange } = this.props;
    const { isMultidayMode } = this.state;

    await dispatch(change('ReservationFormSlots', 'timeFrom', null))
    await dispatch(change('ReservationFormSlots', 'timeTo', null))
    await dispatch(change('ReservationFormSlots', 'resourceId', null))

    const userTagId = form.values.firstname && form.values.firstname.id ? form.values.firstname.id : null;
    const numberOfPeople = (form && form.values && form.values.numberOfGuests ? parseInt(form.values.numberOfGuests,10) : 0) + 1
    const dateToParse = {
      dateFrom: form.values.dateFrom,
      dateTo: isMultidayMode ? form.values.dateTo : form.values.dateFrom,
      startTime: form.values.timeFromSearch,
      endTime: form.values.timeToSearch,
    };
    onGuestTagsChange();
    dateHasBeenChanged(dateToParse, userTagId, numberOfPeople, undefined, undefined);
  }

  onSelectResource(resourceId) {
    const { dispatch } = this.props;
    const { userResources } = this.state
    const selectedResource = userResources && _.find(userResources, res => res.id === resourceId);
    if (selectedResource) {
      if (selectedResource.automaticCheckIn)
        dispatch(change('ReservationFormSlots', 'checkInNotNecessary', true));
      if (!selectedResource.enableInvitedEmails)
        dispatch(change('ReservationFormSlots', 'invitedEmails', []));
    }
    
    dispatch(change('ReservationFormSlots', 'timeFrom', null))
    dispatch(change('ReservationFormSlots', 'timeTo', null))
    setTimeout(async () => {this.fetchTimeAvailability()}, 200);
  }

  async onSelectResourceAvailable(resourceId) {
    const { availabileResources, dispatch, form } = this.props;
    const selectedResource = availabileResources && availabileResources.content && _.find(availabileResources.content, res => res.id === resourceId);
    if (selectedResource) {
      if (selectedResource.automaticCheckIn)
        dispatch(change('ReservationFormSlots', 'checkInNotNecessary', true));
      if (!selectedResource.enableInvitedEmails)
        dispatch(change('ReservationFormSlots', 'invitedEmails', []));

      // Se risorsa slottizzata, devo selezionare effettivamente lo slot temporale, altrimenti imposto già intervallo di reservation
      if (selectedResource.capacity<=1) {
        await dispatch(change('ReservationFormSlots', 'timeFrom', form.values.timeFromSearch))
        await dispatch(change('ReservationFormSlots', 'timeTo', form.values.timeToSearch))
      }
      else {
        await dispatch(change('ReservationFormSlots', 'timeFrom', null))
        await dispatch(change('ReservationFormSlots', 'timeTo', null))
        this.fetchTimeAvailability()
      }
    }
    else {
      // Risorsa non trovata / non selezionata
      await dispatch(change('ReservationFormSlots', 'timeFrom', null))
      await dispatch(change('ReservationFormSlots', 'timeTo', null))
    }
  }

  fetchMoreAvailableResource(page) {
    const { form, onFetchMoreAvailableResources } = this.props;
    const { isMultidayMode } = this.state;
    const dateToParse = {
      dateFrom: form.values.dateFrom,
      dateTo: isMultidayMode ? form.values.dateTo : form.values.dateFrom,
      startTime: form.values.timeFromSearch,
      endTime: form.values.timeToSearch,
    };
    const userTagId = form.values.firstname ? form.values.firstname.id : null;
    const numberOfPeople = (form && form.values && form.values.numberOfGuests ? parseInt(form.values.numberOfGuests,10) : 0) + 1
    const formattedDates = formatter.formatOutputData(formatter.RESERVATION_DATES, dateToParse);
    const { dateFrom, dateTo } = formattedDates;
    const availableResourcesData = { userTagId, dateFrom, dateTo, numberOfPeople };
    const pageSize = 20;
    const append = true;
    onFetchMoreAvailableResources(page, pageSize, availableResourcesData, append );
  }

  async onSelectReservationDate(field, date) {
    const { dispatch, form } = this.props;
    await dispatch(change('ReservationFormSlots', field, date ? moment(date).startOf('day').valueOf() : moment().startOf('day').valueOf()));
    dispatch(change('ReservationFormSlots', 'timeFrom', null))
    dispatch(change('ReservationFormSlots', 'timeTo', null))

    if (this.getCurrentSearchMode()===reservationSearchModeValues.SELECT_RESOURCE)
      this.fetchTimeAvailability()
    else
      this.fetchAvailableResources()
  }

  async onSelectSearchMode(value) {
    if (value===reservationSearchModeValues.SEARCH_RESOURCES)
      this.fetchAvailableResources()
  }

  async fetchTimeAvailability(setSpinner = true) {
    const { dispatch, form, availabileResources } = this.props;
    const { userResources } = this.state;
    this.setState({ slotSelected: null, orari: [], durate: [], occupiedIntervals: [] })

    const searchModeResource = this.getCurrentSearchMode()===reservationSearchModeValues.SELECT_RESOURCE
    const searchModeAvailable = this.getCurrentSearchMode()===reservationSearchModeValues.SEARCH_RESOURCES

    const selectedResource1 = searchModeResource && userResources && form && form.values && form.values.resourceSelected && form.values.resourceSelected.value && 
      _.find(userResources, res => res.id === form.values.resourceSelected.value);
    const selectedResource2 = searchModeAvailable && availabileResources && availabileResources.content && form && form.values && form.values.resourceId && 
      _.find(availabileResources.content, res => res.id === form.values.resourceId);
    const selectedResource = selectedResource1 ? selectedResource1 : selectedResource2 ? selectedResource2 : undefined
    
    const selectedDate = form && form.values && form.values.dateFrom

    if (!selectedResource || !selectedDate)
      return;

    if (selectedResource.capacity>1)
      this.fetchTimeAvailability_slot(selectedResource, selectedDate, setSpinner)
    else
      this.fetchTimeAvailability_normal(selectedResource, selectedDate, setSpinner)
  }

  async fetchTimeAvailability_normal(selectedResource, selectedDate, setSpinner = true) {
    const { dispatch, form } = this.props;
    if (setSpinner)
      dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      // Prendo tutte le reservations di questa resource per generare gli intervalli di disponibilità
      const reservationFilters = {
        resourceId: selectedResource.id,
        fromDate: moment(selectedDate).startOf('day').valueOf(),
        toDate: moment(selectedDate).endOf('day').valueOf(),
        onlyPersonal: false,
      }
      const reservations = await dispatch(ReservationActions.fetchAllReservations({ ...reservationFilters }));

      let occupiedIntervals = [];
      if (reservations && reservations.length) {
        // Sposto la fromDate indietro di freeBeforeMinutes e la toDate avanti di freeAfterMinutes
        reservations.forEach(reservation => {
          reservation.fromDate = moment(reservation.fromDate)
            .subtract(selectedResource.freeBeforeMinutes, 'minutes')
            .valueOf();
          reservation.toDate = moment(reservation.toDate)
            .add(selectedResource.freeAfterMinutes, 'minutes')
            .valueOf();
        });
        reservations.sort((a, b) => a.fromDate - b.fromDate);
        const mergedIntervals = [];
        let currentInterval = { fromDate: reservations[0].fromDate, toDate: reservations[0].toDate };
        for (let i = 1; i < reservations.length; i++) {
            const reservation = reservations[i];
            if (reservation.fromDate <= currentInterval.toDate) {
              currentInterval.toDate = Math.max(currentInterval.toDate, reservation.toDate);
            } 
            else {
              mergedIntervals.push(currentInterval);
              currentInterval = { fromDate: reservation.fromDate, toDate: reservation.toDate };
            }
        }
        mergedIntervals.push(currentInterval);

        occupiedIntervals = mergedIntervals.map(interval => ({
          start: new Date(interval.fromDate),
          end: new Date(interval.toDate),
          title: L20NContext.getSync('occupiedResource')
        }));
      }
      
      this.setState({ occupiedIntervals })

      // Se avevo già settato un timeFrom ed un timeTo (provenienza da CapacityAgenda) --> creo newEvent
      const currentStart_ms = form && form.values && form.values.timeFrom && moment(form.values.timeFrom).valueOf()
      const currentEnd_ms = form && form.values && form.values.timeTo && moment(form.values.timeTo).valueOf()
      if (currentStart_ms && currentEnd_ms)
        this.onChangedTimeFrom(currentStart_ms)
      
      if (setSpinner)
        dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      this.setState({ orari: [], durate: [], occupiedIntervals: [] })
      if (setSpinner)
        dispatch(UtilsActions.setSpinnerVisibile(false));
      console.log(error)
    }
  }

  async fetchTimeAvailability_slot(selectedResource, selectedDate, setSpinner = true) {
    const { dispatch, form } = this.props;

    if (setSpinner)
      dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const numberOfGuests_current = form && form.values && form.values.numberOfGuests ? parseInt(form.values.numberOfGuests,10) : 0

      const slotSize = selectedResource.minimumReservationTimeMinutes
      if (!slotSize)
        return;
      const slotSizeHours = slotSize/60

      const currentDateIsToday = moment(selectedDate).startOf('day').valueOf()===moment().startOf('day').valueOf()
      const firstReservableSlotToday = currentDateIsToday ? Math.ceil((moment().diff(moment().startOf('day'), 'minutes') / 60)/slotSizeHours) : 0

      const timeIntervalFromSeconds = selectedResource.timeInterval.from;
      const timeIntervalToSeconds = selectedResource.timeInterval.to;
      const additionalTimeIntervalsSeconds = []
      if (selectedResource.additionalTimeIntervals) {
        selectedResource.additionalTimeIntervals.forEach((item) => {
          const additionalTimeIntervalFrom = item.from;
          const additionalTimeIntervalTo = item.to;
          additionalTimeIntervalsSeconds.push({
            from: additionalTimeIntervalFrom,
            to: additionalTimeIntervalTo,
          })
        });
      }
      const timeIntervalsSeconds = [{from: timeIntervalFromSeconds, to: timeIntervalToSeconds}, ...additionalTimeIntervalsSeconds].sort((a, b) => a.from - b.from);

      const firstReservableHour = timeIntervalsSeconds[0].from / 3600
      const lastReservableHour = (timeIntervalsSeconds[timeIntervalsSeconds.length-1].to + 1) / 3600
      const firstReservableSlot = Math.max( Math.ceil(firstReservableHour/slotSizeHours), firstReservableSlotToday )
      const lastReservableSlot = Math.floor(lastReservableHour/slotSizeHours)

      // Se non ho "maximumReservationTimeMinutes" --> il numero massimo di slot per prenotazione == "24h"
      const totalSlotsInDay = Math.floor((24*60)/slotSize)
      let maximumReservableSlots = selectedResource.maximumReservationTimeMinutes ? 
        Math.floor(selectedResource.maximumReservationTimeMinutes/slotSize) : totalSlotsInDay

      const selectedTimestamp = moment(selectedDate).startOf('day').valueOf()
      
      const resourceCapacity = selectedResource.capacity
      const durate = generateTimeIntervals(slotSizeHours, 0, slotSizeHours*maximumReservableSlots)

      const resourceDisabledIntervals = this.getDisabledResourceIntervals()

      const deltaSlots = generateTimeIntervals(slotSizeHours, slotSizeHours*firstReservableSlot, slotSizeHours*lastReservableSlot)
      const slots = addTimeToIntervals(structuredClone(deltaSlots),selectedTimestamp)
      const occupancyResponse = await dispatch(ResourceActions.fetchResourceOccupancyMultislot(
        selectedResource.id,{ slots: slots.map(e=>{return {fromDate:(e.fromDate+1),toDate:(e.toDate-1)}})}))

      const occupancySlots = occupancyResponse.slots.map((item,i)=>{
        return {
          ...item,
          fromDate: item.fromDate-1,
          toDate: item.toDate+1,
          index:i
        }
      })
      const occupancySlotsParsed = occupancySlots.map(item => ({
        ...item,
        available: item.occupancy+numberOfGuests_current<resourceCapacity,
      }));
      const orari = occupancySlotsParsed.map((item,i)=>{
        return {
          fromDate: item.fromDate,
          slots: _.cloneDeep(occupancySlotsParsed.slice(i, Math.min(i + maximumReservableSlots, occupancySlotsParsed.length)))
        }
      })

      let indexCounter = 0
      orari.forEach((orario,i) => {
        orario.slots.forEach((slot,j) => {
          slot.fromDate = orario.slots[0].fromDate
          slot.available = _.every(orario.slots.slice(0,j+1),e=>e.available) && !_.some(resourceDisabledIntervals,e=>e.start < slot.toDate && e.end > slot.fromDate)
          slot.index = indexCounter++
        })
      })
 
      this.setState({ orari, durate, startingDurateIndex:0 })

      // Se avevo già settato un timeFrom ed un timeTo (provenienza da CapacityAgenda) --> seleziono slot.
      // Anche se ho selezionato una "availableResource slottizzata" provo a vedere se c'è uno slot che combacia
      //       con l'intervallo di ricerca impostato dall'utente. Se non trovo --> resetto
      const timeFrom_current = form && form.values && form.values.timeFrom && moment(form.values.timeFrom).valueOf()
      const timeTo_current = form && form.values && form.values.timeFrom && moment(form.values.timeTo).valueOf()
      const timeFromSearch_current = form && form.values && form.values.timeFromSearch && moment(form.values.timeFromSearch).valueOf()
      const timeToSearch_current = form && form.values && form.values.timeFromSearch && moment(form.values.timeToSearch).valueOf()

      const currentStart_ms = this.getCurrentSearchMode()===reservationSearchModeValues.SELECT_RESOURCE ? 
        timeFrom_current : timeFromSearch_current
      const currentEnd_ms = this.getCurrentSearchMode()===reservationSearchModeValues.SELECT_RESOURCE ? 
        timeTo_current : timeToSearch_current
      if (currentStart_ms && currentEnd_ms) {
        let found = false
        for (var i = 0; i < orari.length; i++) {
          const orario = orari[i]
          let foundInLoop = false;
          for (var j = 0; j < orario.slots.length; j++) {
            const slot = orario.slots[j]
            if (slot.fromDate===currentStart_ms && slot.toDate===currentEnd_ms && slot.available) {
              this.setState((prevState) => ({
                slotSelected: slot,
                slotRefs: { ...prevState.slotRefs, [slot.index]: prevState.slotRefs[slot.index] || React.createRef() }
              }));
              dispatch(change('ReservationFormSlots', 'timeFrom', slot.fromDate))
              dispatch(change('ReservationFormSlots', 'timeTo', slot.toDate))
              foundInLoop = true;
              break;
            }
          }
          if (foundInLoop) {
            found = true;
            break;
          }
        }
        if (!found) {
          dispatch(change('ReservationFormSlots', 'timeFrom', null))
          dispatch(change('ReservationFormSlots', 'timeTo', null))
        }
      }

      if (setSpinner)
        dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      this.setState({ orari: [], durate: [], occupiedIntervals: [] })
      if (setSpinner)
        dispatch(UtilsActions.setSpinnerVisibile(false));
      console.log(error)
    }
  }

  async onSelectGuest(value) {
    const { form, onChangeReservationUser} = this.props;

    onChangeReservationUser(value)

    if (this.getCurrentSearchMode()===reservationSearchModeValues.SELECT_RESOURCE)
      setTimeout(async () => {this.fetchUserResources()}, 200);
    else
      setTimeout(async () => {this.fetchAvailableResources()}, 200);
  }

  onSlotSelected(slot) {
    const { dispatch } = this.props;
    this.setState({ slotSelected: slot })
    dispatch(change('ReservationFormSlots', 'timeFrom', slot.fromDate))
    dispatch(change('ReservationFormSlots', 'timeTo', slot.toDate))
  }

  onInitializeNewInvitedEmail(emailId) {
    const { dispatch } = this.props;    
    dispatch(change('ReservationFormSlots', `invitedEmails[${emailId}]`, {guest: {id:-1, name:""}, search: ""}));
  }

  toggleMultidayMode = () => {
    const { dispatch, form } = this.props;
    this.setState(prevState => {
      const newState = { isMultidayMode: !prevState.isMultidayMode };
      if (!newState.isMultidayMode && form.values.dateTo) {
        // Reset dateTo to dateFrom when switching back to single day
        dispatch(change('ReservationFormSlots', 'dateTo', form.values.dateFrom));
        dispatch(change('ReservationFormSlots', 'timeTo', form.values.timeFrom));
      }
      return newState;
    }, () => {
      // Refetch availability after mode change
      if (this.getCurrentSearchMode()===reservationSearchModeValues.SELECT_RESOURCE)
        setTimeout(async () => {this.fetchUserResources()}, 200);
      else
        setTimeout(async () => {this.fetchAvailableResources()}, 200);
    });
  }

  render() {
    const { 
      form, classes, onSubmitReservationForm, 
      availabileResources, dispatch, onGuestInputChange, availableUserTags, guestsFilteredByTags, multidayEnabled
    } = this.props;
    const { 
      orari, durate, startingDurateIndex, slotSelected, 
      userResources, occupiedIntervals, slotRefs,
      filteredGuestsForInvitedEmails, isMultidayMode
    } = this.state;

    const formData = form && form.values;

    const searchModeResource = this.getCurrentSearchMode()===reservationSearchModeValues.SELECT_RESOURCE
    const searchModeAvailable = this.getCurrentSearchMode()===reservationSearchModeValues.SEARCH_RESOURCES

    const selectedDate = form && form.values && form.values.dateFrom

    const selectedResource1 = searchModeResource && userResources && form && form.values && form.values.resourceSelected && form.values.resourceSelected.value && 
      _.find(userResources, res => res.id === form.values.resourceSelected.value);
    const selectedResource2 = searchModeAvailable && availabileResources && availabileResources.content && form && form.values && form.values.resourceId && 
      _.find(availabileResources.content, res => res.id === form.values.resourceId);
    const selectedResource = selectedResource1 ? selectedResource1 : selectedResource2 ? selectedResource2 : undefined
    const isAutomaticCheckin = selectedResource && selectedResource.automaticCheckIn;
    const isResourceSlotted = selectedResource && selectedResource.capacity>1
    const isInvitedEmailsEnabled = selectedResource && selectedResource.enableInvitedEmails

    const selectedUserTagId = form && form.values && form.values.firstname && form.values.firstname.id

    const shownDurateNumber = 4
    const shownDurate = durate.slice(startingDurateIndex,Math.min(startingDurateIndex+shownDurateNumber, durate.length))

    const selectedTimeFrom = form && form.values && form.values.timeFrom && moment(form.values.timeFrom).valueOf()
    const selectedTimeTo = form && form.values && form.values.timeTo && moment(form.values.timeTo).valueOf()
    const timeIntervalMissing = !(selectedTimeFrom && selectedTimeTo)
    const timeFromGreaterThanTimeTo = selectedTimeFrom && selectedTimeTo && (selectedTimeFrom >= selectedTimeTo)
    const timeIntervalOverlapping = searchModeResource && !isResourceSlotted && 
      this.isReservationOverlapping(selectedTimeFrom, selectedTimeTo)
    const timeIntervalTooShort = searchModeResource && !isResourceSlotted && 
      this.isReservationTooShort(selectedTimeFrom, selectedTimeTo, selectedResource)
    const timeIntervalTooLong = searchModeResource && !isResourceSlotted && 
      this.isReservationTooLong(selectedTimeFrom, selectedTimeTo, selectedResource)
    const timeIntervalInDisabledIntervals = searchModeResource && !isResourceSlotted && 
      this.isReservationInDisabledIntervals(selectedTimeFrom, selectedTimeTo, selectedResource)

    let canSubmitForm = this.getCurrentSearchMode() && 
      selectedDate && selectedUserTagId && selectedResource &&
      selectedTimeFrom && selectedTimeTo && (
        !timeFromGreaterThanTimeTo && !timeIntervalMissing && !timeIntervalOverlapping && 
        !timeIntervalTooShort && !timeIntervalTooLong && 
        !timeIntervalInDisabledIntervals
      );

    if (isMultidayMode) {
      canSubmitForm = this.getCurrentSearchMode() &&
        selectedDate && selectedUserTagId && selectedResource &&
      form && form.values && moment(form.values.dateTo).set({
        hour: moment(form.values.timeTo).hours(),
        minute: moment(form.values.timeTo).minutes()
      }).isAfter(moment(form.values.dateFrom).set({
        hour: moment(form.values.timeFrom).hours(),
        minute: moment(form.values.timeFrom).minutes()
      }));
    }
    
    const timeFromSearch_current = form && form.values && form.values.timeFromSearch && moment(form.values.timeFromSearch).valueOf()
    const timeToSearch_current = form && form.values && form.values.timeFromSearch && moment(form.values.timeToSearch).valueOf()
    const searchAvailabilityFrom_current = this.getCurrentSearchMode()===reservationSearchModeValues.SEARCH_RESOURCES && selectedDate ? timeFromSearch_current : undefined
    const searchAvailabilityTo_current = this.getCurrentSearchMode()===reservationSearchModeValues.SEARCH_RESOURCES && selectedDate ? timeToSearch_current : undefined
    const searchAvailabilityFrom_date = searchAvailabilityFrom_current ? moment(searchAvailabilityFrom_current) : undefined
    const searchAvailabilityTo_date = searchAvailabilityTo_current ? moment(searchAvailabilityTo_current) : undefined
    if (searchAvailabilityFrom_date) {
      searchAvailabilityFrom_date.year(moment(selectedDate).year())
      searchAvailabilityFrom_date.month(moment(selectedDate).month())
      searchAvailabilityFrom_date.date(moment(selectedDate).date())
    }
    if (searchAvailabilityTo_date) {
      searchAvailabilityTo_date.year(moment(selectedDate).year())
      searchAvailabilityTo_date.month(moment(selectedDate).month())
      searchAvailabilityTo_date.date(moment(selectedDate).date())
    }
    const searchAvailabilityFrom = searchAvailabilityFrom_date ? searchAvailabilityFrom_date.valueOf() : undefined
    const searchAvailabilityTo = searchAvailabilityFrom_date ? searchAvailabilityTo_date.valueOf() : undefined

    const numberOfGuests_current = form && form.values && form.values.numberOfGuests ? parseInt(form.values.numberOfGuests,10) : 0
    const isBookeyCapacityActive = dispatch(UtilsActions.isBookeyCapacityActive());
    return (
      <>
      <Form>
        <FormCardContainer
          title="reservationInfo"
          subtitle="reservationInfoDescription"
        >
          <Entity
            componentClass={Field}
            name="title"
            componentAttribute="placeholder"
            entity="insertTitle"
            title={<Entity entity="reservationName" />}
            component={CustomField}
          />
        </FormCardContainer>
        
        <FormCardContainer
          title="selectUser"
          subtitle="userWarning"
        >
          <Field
            title={<Entity entity="insertName" />}
            id="first"
            name="firstname"
            singleTag
            tags={availableUserTags.filter(e=>e.type==='user')}
            guests={guestsFilteredByTags}
            placeholder={<Entity entity="insertGuestTagsForCard" />}
            component={GuestSelector}
            onInputChange={value => onGuestInputChange(value)}
            onHandleChange={value => this.onSelectGuest(value)}
            mandatory
          />
          {isBookeyCapacityActive ? (
            <div style={{ display: 'flex', flexDirection: 'row',paddingLeft:0 }}>
              <h4 style={{ color: '#3f3f3f', marginRight: 10, marginTop: 11, fontSize:16 }}>{<Entity entity="reservationNumberOfGuests"/>}</h4>
              <Entity
                componentClass={Field}
                name="numberOfGuests"
                type="number"
                textFormattingFunction={value => value.replace(/[\s.]+/g, '')}
                componentAttribute="placeholder"
                component={CustomField}
                onHandleChange={value => {this.onChangedNumberOfGuests(value)}}
                containerStyle={{ width: 100 }}
                className="form-control-custom"
              />
            </div>
          ) : null}
        </FormCardContainer>

        <FormCardContainer
          title="reservationDateAndResource"
          subtitle="reservationDateAndResourceSubtitle"
        >
          {multidayEnabled && (
            <div style={{ opacity: isAutomaticCheckin ? 0.5 : 1 }}>
              <Field
                name="isMultiday"
                label={<Entity entity="enableMultidayReservation" />}
                titleStyle={{ fontSize: 16, fontWeight: 'bold', color: '#3f3f3f' }}
                component={MDCheckBoxField}
                onChange={this.toggleMultidayMode}
                value={isMultidayMode}
              />
              <h4 style={{ marginTop: -10, lineHeight: 1.5, marginLeft: 35, fontSize: 15, color: '#3f3f3f', fontWeight: 'light' }}>
                <Entity entity="enableMultidayReservationDescription" />
              </h4>
            </div>
          )}

          {!isMultidayMode ? (
            <div style={{ marginLeft: 0, display: 'flex' }} >
              <div style={{marginTop:26}}>
                <Field
                  title={<Entity entity="date" />}
                  id="dateFrom"
                  name="dateFrom"
                  dateContainer={{ width: 120, marginRight: 100 }}
                  onHandleChange={value => {this.onSelectReservationDate("dateFrom", value)}}
                  component={DatePickerField}
                  isOutsideRange={date => date.isBefore(moment().startOf('day'))}
                  mandatory
                />
              </div>
              <div style={{display:'flex', flexDirection:'row'}}>
                <Field
                  name="searchMode"
                  title={<Entity entity="deviceUUIDMode" />}
                  component={MDRadioButtonsGroupField}
                  onHandleChange={value => {this.onSelectSearchMode(value)}}
                  radioLabelStyle={{ marginTop: 30, marginBottom: 30 }}
                  options={reservationSearchModeOptions.map(e=>{return {
                    label: <Entity entity={e.label} />,
                    value: e.value
                  }})}
                />
                <div style={{display:'flex', flexDirection:'column', marginLeft: 10}}>
                  <div style={{marginTop: -16, opacity:searchModeResource?1:0, 
                    cursor:searchModeResource?'':'default', pointerEvents:searchModeResource?'unset':'none'}}>
                    <div style={{display:'flex', flexDirection:'row', marginBottom:13}}>
                      <CopyIconCustom style={{width:20, color:'rgb(63,63,63)', marginRight:4}}/>
                      <span style={{color:'rgb(63,63,63)', fontWeight:'bold', fontSize: 16}}>
                        <Entity entity="resource" />
                      </span>
                    </div>
                    <Field
                      name="resourceSelected"
                      component={SelectableField}
                      noCache
                      options={_.map(userResources, resource => ({ value: resource.id, label: resource.name }))}
                      placeholder={<Entity entity="resource" />}
                      titleStyle={{ color: 'white' }}
                      containerstyle={{ minWidth: 340, marginRight: 10 }}
                      onInputChange={value => {}}
                      onSelect={resource => this.onSelectResource(resource && resource.value ? resource.value : null)}
                    />
                  </div>
                  <div style={{marginTop: 12, opacity:searchModeAvailable?1:0, 
                    cursor:searchModeAvailable?'':'default', pointerEvents:searchModeAvailable?'unset':'none',
                    display:'flex', flexDirection:'row'}}>
                    <div>
                      <div style={{display:'flex', flexDirection:'row', marginBottom:10}}>
                        <TimeIcon style={{fontSize:20, color:'rgb(63,63,63)', marginRight:4}}/>
                        <span style={{color:'rgb(63,63,63)', fontWeight:'bold', fontSize: 16}}>
                          <Entity entity="reservationStartTime" />
                        </span>
                      </div>
                      <Field
                        title={""}
                        name="timeFromSearch"
                        containerStyle={{ marginTop: 0 }}
                        disabledHours={() => this.getDisabledHours()}
                        disabledMinutes={selectedHour => this.getDisabledMinutes(selectedHour)}
                        component={TimePickerField2}
                        hidePlaceholder={true}
                        force24HoursFormat={true}
                        onHandleChange={value => this.onChangedTimeFromSearch(value)}
                      />
                    </div>
                    <div style={{marginLeft: 10}}>
                      <div style={{display:'flex', flexDirection:'row', marginBottom:10}}>
                        <TimeIcon style={{fontSize:20, color:'rgb(63,63,63)', marginRight:4}}/>
                        <span style={{color:'rgb(63,63,63)', fontWeight:'bold', fontSize: 16}}>
                          <Entity entity="reservationEndTime" />
                        </span>
                      </div>
                      <Field
                        title={""}
                        name="timeToSearch"
                        containerStyle={{ marginTop: 0 }}
                        disabledHours={() => this.getDisabledHours()}
                        disabledMinutes={selectedHour => this.getDisabledMinutes(selectedHour)}
                        component={TimePickerField2}
                        hidePlaceholder={true}
                        force24HoursFormat={true}
                        onHandleChange={value => this.onChangedTimeToSearch(value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ marginLeft: 0, display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', alignItems: 'flex-start', gap: 120, marginBottom: 20 }}>
                <div style={{ width: 120 }}>
                  <Field
                    title={<Entity entity="dateFrom" />}
                    id="dateFrom"
                    name="dateFrom"
                    dateContainer={{ width: 120 }}
                    onHandleChange={value => this.onSelectReservationDate("dateFrom", value)}
                    component={DatePickerField}
                    isOutsideRange={date => date.isBefore(moment().startOf('day'))}
                    mandatory
                    titleStyle={{ marginBottom: 8, fontSize: 16, color: '#3f3f3f', whiteSpace: 'nowrap' }}
                  />
                </div>
                <div style={{ width: 120 }}>
                  <Field
                    title={<Entity entity="endDate" />}
                    id="dateTo"
                    name="dateTo"
                    dateContainer={{ width: 120 }}
                    onHandleChange={value => this.onSelectReservationDate("dateTo", value)}
                    component={DatePickerField}
                    isOutsideRange={date => {
                      const fromDate = form.values.dateFrom;
                      return date.isBefore(moment().startOf('day')) || (fromDate && date.isBefore(moment(fromDate)));
                    }}
                    mandatory
                    titleStyle={{ marginBottom: 8, fontSize: 16, color: '#3f3f3f', whiteSpace: 'nowrap' }}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'flex-start', gap: 120 }}>
                <div style={{ width: 120 }}>
                  <Field
                    title={<Entity entity="reservationStartTime" />}
                    name="timeFromSearch"
                    component={TimePickerField2}
                    onHandleChange={value => this.onChangedTimeFromSearch(value)}
                    titleStyle={{ marginBottom: 8, fontSize: 16, color: '#3f3f3f', whiteSpace: 'nowrap' }}
                  />
                </div>
                <div style={{ width: 120 }}>
                  <Field
                    title={<Entity entity="reservationEndTime" />}
                    name="timeToSearch"
                    component={TimePickerField2}
                    onHandleChange={value => this.onChangedTimeToSearch(value)}
                    titleStyle={{ marginBottom: 8, fontSize: 16, color: '#3f3f3f', whiteSpace: 'nowrap' }}
                  />
                </div>
              </div>
            </div>
          )}

          {isAutomaticCheckin ? (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
              <CheckCircle style={{ color: GREEN, fontSize: 20 }} />
              <h5 style={{ color: '#3f3f3f', marginLeft: 10, fontSize: 16, fontWeight: 'bold' }}>{<Entity entity="resourceWithAutomaticCheckin" />}</h5>
            </div>
          ) : (
            <div style={{ opacity: isAutomaticCheckin ? 0.5 : 1 }}>
              <Field
                name="checkInNotNecessary"
                label={<Entity entity="checkInNotNecessary" />}
                titleStyle={{ fontSize: 16, fontWeight: 'bold', color: '#3f3f3f' }}
                containerstyle={{ marginTop: 20 }}
                disabled={isAutomaticCheckin}
                component={MDCheckBoxField}
              />
              <h4 style={{ marginTop: -10, lineHeight: 1.5, marginLeft: 35, fontSize: 15, color: '#3f3f3f', fontWeight: 'light' }}><Entity entity="checkInNotNecessaryDecription" /></h4>
            </div>
          )}
        </FormCardContainer>

        {searchModeAvailable && 
          <FormCardContainer
            title="availableResources"
            style={{ marginBottom: 250 }}
          >
            <Field
              name="resourceId"
              component={SelectableRowField}
              options={availabileResources}
              emptyOptionsText={<Entity entity="availableResourcesSelectDates" />}
              onFilterChange={(value) => {}}
              onFetchMoreItems={(page) => this.fetchMoreAvailableResource(page)}
              onHandleChange={(resourceId) => this.onSelectResourceAvailable(resourceId)}
              isSingleChoice
              mandatory
            />
          </FormCardContainer>
        }

        {selectedDate && 
          ((searchModeResource && selectedResource) || (searchModeAvailable && selectedResource && isResourceSlotted)) && 
          <FormCardContainer
            title="reservationTime"
            subtitle="reservationTimeSubtitle"
          >
            {isResourceSlotted?(
              <div>
                {slotSelected ? (
                  <div style={{display:'flex', flexDirection:'row', alignItems:'center', paddingLeft:10,
                    border:`1px solid ${GREEN}`, borderRadius:5, width:'fit-content'
                  }}>
                    <div style={{color:'rgb(63,63,63)', fontWeight:'bold', fontSize: 16, marginRight: 10}}>
                      <Entity entity="timeIntervalSlotSelected" />
                    </div>
                    <Card
                      style={{padding:10, margin:0, textAlign:'center', width:160, minWidth:160, 
                        fontWeight:'bold', fontSize:'1.2em', cursor:'default', backgroundColor: GREEN,
                      }}
                    >
                      {`${moment(slotSelected.fromDate).format('HH:mm')}-${moment(slotSelected.toDate).format('HH:mm')}`}
                    </Card>
                  </div>
                ):<div style={{height:64,width:1}}></div>}
                <div style={{overflowY:'auto', maxHeight:400, width:'100%'}}>
                  <div style={{position:'sticky', top:0, display:'flex', flexDirection:'row', alignItems:'center', marginBottom:10, backgroundColor:'white'}}>
                    <div style={{width:100, color:'rgb(63,63,63)', fontWeight:'bold', fontSize: 16, textAlign:'center'}}>
                      <Entity entity="timeIntervalFrom" />
                    </div>
                    {_.map(shownDurate, (durata,index) => {
                      const durataMinutes = (durata.index+1)*(moment(durata.toDate).diff(moment(durata.fromDate), 'minutes'))
                      return (
                        <div style={{padding:6, paddingTop:16, paddingBottom:16, width:160, minWidth:160, margin:0, marginLeft:5, marginRight:5, }}>
                          {index==0 && startingDurateIndex!=0 &&
                            <IconButton 
                              onClick={() => this.setState({ startingDurateIndex: Math.max(startingDurateIndex-1,0) }) } 
                              style={{ margin:0, marginLeft: -16, marginTop: 6, padding:4, position: 'absolute' }}
                            >
                              <ArrowLeftIconCustom style={{ color: BLUE, width: 25 }} />
                            </IconButton>
                          }
                          {index==shownDurate.length-1 && startingDurateIndex<(durate.length-shownDurateNumber) && 
                            <IconButton 
                              onClick={() => this.setState({ startingDurateIndex: Math.min(startingDurateIndex+1,durate.length-shownDurateNumber) })} 
                              style={{ margin:0, marginLeft: 112, marginTop: 6, padding:4, position: 'absolute' }}
                            >
                              <ArrowRightIconCustom style={{ color: BLUE, width: 25 }} />
                            </IconButton>
                          }
                          <h4 style={{fontWeight: 'bold', color: '#3f3f3f', textAlign: 'center', userSelect:'none'}}>
                            {(durataMinutes >= 60)?(`${durataMinutes / 60} ${L20NContext.getSync('hours')}`):`${durataMinutes} ${L20NContext.getSync('minutes')}`}
                          </h4>
                        </div>
                      )
                    })}
                  </div>
                  {_.map(orari, (orario,index) => {
                    if (
                      searchAvailabilityFrom && searchAvailabilityTo && orario.fromDate && 
                      (orario.fromDate<(searchAvailabilityFrom-60*60*1000) || orario.fromDate>(searchAvailabilityTo+60*60*1000))
                    )
                      return null
                    const shownSlots = orario.slots.slice(startingDurateIndex,startingDurateIndex+shownDurate.length)
                    return (
                      <div key={"O"+(index)} style={{display:'flex', flexDirection:'row', alignItems:'center', marginBottom:10}}>
                        <div style={{width:100, color:'rgb(63,63,63)', fontWeight:'bold', fontSize: 16, textAlign:'center'}}>
                          {`${moment(orario.fromDate).format('HH:mm')}`}
                        </div>
                        {_.map(shownSlots, (slot) => {
                          return (
                            <Card 
                              key={"S"+slot.index}
                              ref={(el) => {
                                slotRefs[slot.index] = slotRefs[slot.index] || React.createRef();
                                slotRefs[slot.index].current = el;
                              }}
                              style={{padding:10, margin:0, marginLeft:5, marginRight:5, textAlign:'center', width:160, minWidth:160, 
                                fontWeight:'bold', fontSize:'1.2em', cursor: slot.available?'pointer':'default',
                                backgroundColor: slotSelected&&slotSelected.index==slot.index?GREEN:slot.available?'rgb(248,248,248)':'rgb(160,160,160)',
                              }}
                              onClick={()=>{
                                if(slot.available && !(slotSelected&&slotSelected.index==slot.index)) {
                                  this.onSlotSelected(slot)
                                }
                              }}
                            >
                              {`${moment(slot.fromDate).format('HH:mm')}-${moment(slot.toDate).format('HH:mm')}`}
                            </Card>
                          )
                        })}
                        {!shownSlots.length?(
                          <div style={{height:44, width:1}}></div>
                        ):null}
                      </div>
                    );
                  })}
                </div>
              </div>
            ):(
              <div>
                {numberOfGuests_current>0 ? (
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', marginBottom: 20, marginTop:10 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom:10 }}>
                      <WarningIcon style={{ color: 'orange', marginRight: 10, fontSize: 24 }} />
                      <h4 style={{ color: '#3f3f3f', lineHeight: '1.5em', fontWeight: 'bold', margin: 0, fontSize:16 }}>
                        <Entity entity={"reservationTooManyGuests"}/>
                      </h4>
                    </div>
                  </div>
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', marginBottom: 20, marginTop:10 }}>
                    <div>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                        <div>
                          <div style={{display:'flex', flexDirection:'row', marginBottom:10}}>
                            <TimeIcon style={{fontSize:20, color:'rgb(63,63,63)', marginRight:4}}/>
                            <span style={{color:'rgb(63,63,63)', fontWeight:'bold', fontSize: 16}}>
                              <Entity entity="reservationStartTime" />
                            </span>
                            <span style={{color:'rgb(215, 31, 75)', fontWeight:'bold', fontSize: 16}}>
                              *
                            </span>
                          </div>
                          <Field
                            title={""}
                            name="timeFrom"
                            containerStyle={{ marginTop: 0 }}
                            disabledHours={() => this.getDisabledHours()}
                            disabledMinutes={selectedHour => this.getDisabledMinutes(selectedHour)}
                            component={TimePickerField2}
                            hidePlaceholder={true}
                            force24HoursFormat={true}
                            onHandleChange={value => this.onChangedTimeFrom(value)}
                          />
                        </div>
                        <div style={{marginLeft: 20, }}>
                          <div style={{display:'flex', flexDirection:'row', marginBottom:10}}>
                            <TimeIcon style={{fontSize:20, color:'rgb(63,63,63)', marginRight:4}}/>
                            <span style={{color:'rgb(63,63,63)', fontWeight:'bold', fontSize: 16}}>
                              <Entity entity="reservationEndTime" />
                            </span>
                            <span style={{color:'rgb(215, 31, 75)', fontWeight:'bold', fontSize: 16}}>
                              *
                            </span>
                          </div>
                          <Field
                            title={""}
                            name="timeTo"
                            containerStyle={{ marginTop: 0, }}
                            component={TimePickerField2}
                            hidePlaceholder={true}
                            force24HoursFormat={true}
                            onHandleChange={value => this.onChangedTimeTo(value)}
                          />
                        </div>
                      </div>
                      {(
                        timeIntervalMissing || timeFromGreaterThanTimeTo || timeIntervalOverlapping || 
                        timeIntervalTooShort || timeIntervalTooLong || 
                        timeIntervalInDisabledIntervals
                      ) && 
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom:10, maxWidth:270 }}>
                          <WarningIcon style={{ color: 'orange', marginRight: 10, fontSize: 24 }} />
                          <h4 style={{ color: '#3f3f3f', lineHeight: '1.5em', fontWeight: 'bold', margin: 0, fontSize:16 }}>
                            <Entity
                              key={
                                JSON.stringify({
                                  timeIntervalMissing,timeFromGreaterThanTimeTo,timeIntervalOverlapping,
                                  timeIntervalTooShort,timeIntervalTooLong,
                                  timeIntervalInDisabledIntervals,
                                })
                              }
                              entity={
                                timeIntervalMissing ? "reservationTimeIntervalMissing" : 
                                timeFromGreaterThanTimeTo ? "reservationTimeInvalid" : 
                                timeIntervalOverlapping ? "reservationTimeOverlapping" :
                                timeIntervalTooShort ? "reservationTimeTooShort" :
                                timeIntervalTooLong ? "reservationTimeTooLong" :
                                timeIntervalInDisabledIntervals ? "reservationTimeInDisabledIntervals" :
                                "reservationTimeInvalid"
                              }
                            />
                          </h4>
                        </div>
                      }
                    </div>
                    <BigCalendar
                      dayPropGetter={this.getDayPropGetter}
                      eventPropGetter={this.getEventPropGetter}
                      slotPropGetter={this.getSlotPropGetter}
                      selectable
                      onSelectSlot={this.handleSelectCalendarSlot}
                      localizer={localizer}
                      events={occupiedIntervals}
                      defaultView='day'
                      views={['day']}
                      startAccessor="start"
                      endAccessor="end"
                      toolbar={false}
                      style={{ 
                        height: 300, marginTop: 0, paddingTop: 0, position: 'relative', top: '0px', 
                        flexGrow:1, marginLeft:40, marginRight:20 
                      }}
                      date={selectedDate?(new Date(selectedDate)):undefined}
                    />
                  </div>
                )}
              </div>
            )}
          </FormCardContainer>
        }
        {isInvitedEmailsEnabled &&
          <FormCardContainer
            title="reservationInvitedEmails"
            subtitle="reservationInvitedEmailsDescription"
          >
            <FieldArray
              name={`invitedEmails`}
              component={onRenderInvitedEmails}
              formData={formData}
              form={form}
              dispatch={dispatch}
              filteredGuestsForInvitedEmails={filteredGuestsForInvitedEmails}
              onGuestInputChange={(value)=>{onGuestInputChange(value);this.onFilterGuestsForInvitedEmails(value)}}
              onInitializeNewInvitedEmail={emailId => this.onInitializeNewInvitedEmail(emailId)}
            />
          </FormCardContainer>
        }

        <MDButton
          title={<Entity entity="save" />}
          containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
          style={{ 
            height: 45, borderRadius: 0, 
            opacity: canSubmitForm?1:0.5, cursor: canSubmitForm?'pointer':'default',
            pointerEvents: canSubmitForm?'unset':'none'
          }}
          disabled={!canSubmitForm}
          onClick={() => onSubmitReservationForm()}
        />
      </Form >
      <BasicModal
        ref={(m) => { this.userNotAllowedModal = m; }}
        hideCloseButton
        body={
          <div>
            <div style={{ marginLeft: 13, marginBottom: 30, padding: 10 }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h3 style={{ margin: 0, fontWeight: 'bold', color: '#3f3f3f' }} />
                <IconButton onClick={() => {this.userNotAllowedModal.close()}}>
                  <Cancel style={{ color: '#e95841', fontSize: 35 }} />
                </IconButton>
              </div>
              <div className={classes.warningTitleContainer}>
                <WarningIcon style={{ color: 'orange', fontSize: 30 }} />
                <h2 className={classes.titleText} style={{ margin: 0, marginLeft: 10, fontSize: 25 }}><Entity entity="warning" /></h2>
              </div>
              <div className={classes.guestCardContainer}>
                <h2 className={classes.sectionText} style={{ color: '#3f3f3f' }}><Entity entity="reservationUserNotAllowedResource" /></h2>
              </div>
            </div>
          </div>
        }
      />
      </>
    );
  }
} 

export default withStyles(styles)(ReservationFormSlots);