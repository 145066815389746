import _ from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import * as PrivateUnitActions from '../../redux/actions/privateUnit.actions';
import { PERMISSIONS, PERMISSION_ENTITIES } from '../../_config/consts';
import EmptyView from '../EmptyView.jsx';
import ListLoadingView from '../ListLoadingView.jsx';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import PrivateUnitDetailsRow from './PrivateUnitDetailsRow.jsx';

@connect(state => ({
  privateUnit: state.privateUnit,
  viewLoading: state.utils.viewLoading,
  themeName: state.settings.items.theme.data.themeName,
  routing: state.router,
}))
class PrivateUnitsListView extends React.Component {


  appendPrivateUnits(page) {
    const { dispatch } = this.props;
    const append = true;
    dispatch(PrivateUnitActions.fetchPrivateUnits(page, append));
  }

  closeOperationalSection() {
    const { dispatch } = this.props;
    dispatch(PrivateUnitActions.setOperationalMode(false));
  }

  render() {
    const { privateUnit, listContainerStyle, privateUnit: { selectedPrivateUnit, data: { pagination: { number: currentPage, totalPages } } }, onSelectPrivateUnit, onNewPrivateUnit, viewLoading, themeName } = this.props;
    // TODO permissions
    const canCreateNewPrivateUnits = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.CREATE], PERMISSION_ENTITIES.USER);
    return (
      <div className="list-view details-section" style={{ top: 190, paddingBottom: 40, width: '90%', overflow: 'auto', ...listContainerStyle }}>
        <InfiniteScroll
          initialLoad={false}
          loadMore={() => this.appendPrivateUnits(currentPage + 1)}
          hasMore={currentPage + 1 < totalPages}
          loader={<ListLoadingView />}
          useWindow={false}
        >
          <div style={{ width: '41%' }}>
            {!_.isEmpty(privateUnit.data.content) ?
              _.map(privateUnit.data.content, privateUnit =>
                <PrivateUnitDetailsRow
                  themeColor={themeName}
                  key={privateUnit.id}
                  isSelected={selectedPrivateUnit && selectedPrivateUnit.id === privateUnit.id}
                  privateUnit={privateUnit}
                  onClick={() => onSelectPrivateUnit(privateUnit)}
                />)
                : (
                  <div>
                    {!viewLoading ? (
                      <EmptyView
                        title="noPrivateUnit"
                        onNewEntity={canCreateNewPrivateUnits ? () => onNewPrivateUnit() : null}
                        newEntityTitle="addPrivateUnit"
                        subtitle="startAddPrivateUnits"
                        iconName="icon-simple-line-icons-user-following"
                        onShowInfo={null}
                      />) : <ListLoadingView />}
                  </div>
                )
              }
          </div>
        </InfiniteScroll>
      </div>
    );
  }
} 

export default PrivateUnitsListView;
