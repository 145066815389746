import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import RefreshIcon from '@material-ui/icons/Autorenew';
import NewFromTemplateIcon from '@material-ui/icons/FileCopyOutlined';
import Warning from '@material-ui/icons/Warning';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import AbilityProvider from '../../permissionsUtils/AbilityProvider';
import * as UtilActions from '../../redux/actions/utils.actions.js';
import { BLUE, GREEN, ORANGE, PERMISSIONS, PERMISSION_ENTITIES, PRIVATE_UNITS_STATUS, RESERVATION_STATUSES, RESERVATION_STATUSES_PARAMETERS } from '../../_config/consts';
import { getColorFromThemeName } from '../../_config/utils';
import EmptyView from '../EmptyView.jsx';
import ListLoadingView from '../ListLoadingView.jsx';
import MDTablePaginationActions from '../MDTable/MDTablePaginationActions.jsx';
import Tag from '../Tag.jsx';

const styles = theme => ({
  table: {
    overflow: 'scroll',
  },
  tableColumnText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  disabledInput: {
    display: 'none',
  },
  guestTag: {
    padding: 3,
  },
  rowSelected: {
    backgroundColor: 'aliceblue !important',
    color: 'aliceblue !important',
  },
  rowHovered: {
    cursor: 'pointer',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  guestRowContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowWithAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowActionButton: {
    width: 40,
    height: 40,
  },
  rowActionButtonIcon: {
    fontSize: 20,
    color: '#E53935',
  },
  paginationText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
  button: {
    color: 'red',
    fontSize: 13,
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginLeft: -15,
    width: 'max-content',
  },
  buttonPositive: {
    color: BLUE,
    fontSize: 13,
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginLeft: -15,
    marginRight: 10,
    width: 'max-content',
  },
});

@connect((state) => ({ viewLoading: state.utils.viewLoading, privateUnit: state.privateUnit, themeName: state.settings.items.theme.data.themeName }))
class PrivateUnitsTableView extends React.Component {

  componentWillUnmount() {
    const { dispatch } = this.props;
    
  }

  render() {
    const {
      classes,
      privateUnit,
      privateUnit: { data: { content: privateUnitsData, pagination: { number: currentPage, size, totalPages, totalElements }, filters } },
      privateUnit: { selectedPrivateUnit },
      viewLoading,
      themeName,
      onNewPrivateUnit,
      onSelectPrivateUnit,
      onRefreshPrivateUnits,
      onFetchPrivateUnitsOnPage,
      dispatch
    } = this.props;
    
    // TODO permissions
    const canCreateNewPrivateUnits = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.CREATE], PERMISSION_ENTITIES.USER);
    const headerColor = getColorFromThemeName(themeName);

    return (
      <div className="list-view details-section" style={{ top: 190, paddingBottom: 140, width: '87%' }}>
        <div>
          {!_.isEmpty(privateUnitsData) ?
            <Card style={{ overflow: 'scroll' }}>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow style={{ backgroundColor: headerColor }}>
                    <TableCell colSpan={3}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h3 className={classes.tableColumnText} style={{ color: 'white', marginTop: 15, marginBottom: 15 }}><Entity entity="privateUnits" /></h3>
                        <IconButton onClick={() => onRefreshPrivateUnits()} style={{ marginLeft: 10, marginTop: 5 }}>
                          <RefreshIcon style={{ color: 'white', fontSize: 25 }} />
                        </IconButton>
                      </div>
                    </TableCell>
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="privateUnitsTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchPrivateUnitsOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          iconColor="white"
                          page={currentPage}
                          lastPage={totalPages - 1}
                          rowsPerPage={size}
                          onChangePage={(event, page) => onFetchPrivateUnitsOnPage(page)}
                        />
                      )}
                    />
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3}><h4 className={classes.tableColumnText}><Entity entity="name" /></h4></TableCell>
                    <TableCell colSpan={3}><h4 className={classes.tableColumnText}><Entity entity="privateUnitInheritedUserTags" /></h4></TableCell>
                    <TableCell colSpan={3}><h4 className={classes.tableColumnText}><Entity entity="privateUnitSharedLocks" /></h4></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(privateUnitsData, (privateUnit) => {
    
                    const statusColor = privateUnit.state===PRIVATE_UNITS_STATUS.TO_BE_ACTIVATED?ORANGE:
                      privateUnit.state===PRIVATE_UNITS_STATUS.ACTIVE?GREEN:'rgb(63, 63, 63)'
                    
                    return (
                      <TableRow
                        key={privateUnit.id}
                        className={classes.row}
                        hover
                        classes={{
                          selected: classes.rowSelected,
                          hover: classes.rowHovered,
                        }}
                        onClick={event => onSelectPrivateUnit(privateUnit)}
                        selected={selectedPrivateUnit && selectedPrivateUnit.id === privateUnit.id}
                      >
                        <TableCell colSpan={3}>
                          <div className={classes.guestRowContainer} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <h5 style={{ fontWeight: 'bold', fontSize: 18 }}>{`${privateUnit.name}`}</h5>
                            <h4 style={{ color: statusColor, fontSize: 13, marginTop: 0, fontWeight: 'bold' }}>
                              {L20NContext.getSync(`privateUnitsStatus_${privateUnit.state}`)}
                            </h4>
                          </div>
                        </TableCell>
                        <TableCell colSpan={3}>
                          <div className="tags-container">
                            {_.map(privateUnit.userTags, tag =>
                              <Tag
                                key={tag.id}
                                tag={tag}
                              />,
                            )}
                          </div>
                        </TableCell>
                        <TableCell colSpan={3}>
                          <div className="tags-container">
                            {_.map(privateUnit.lockTags, tag =>
                              <Tag
                                key={tag.id}
                                tag={tag}
                              />)}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow style={{ backgroundColor: headerColor }}>
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="privateUnitsTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchPrivateUnitsOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          page={currentPage}
                          iconColor="white"
                          lastPage={totalPages - 1}
                          rowsPerPage={size}
                          onChangePage={(event, page) => onFetchPrivateUnitsOnPage(page)}
                        />
                      )}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Card>
            : (
              <div>
                {!viewLoading ? (
                  <EmptyView
                    title="noPrivateUnit"
                    onNewEntity={canCreateNewPrivateUnits ? () => onNewPrivateUnit() : null}
                    newEntityTitle="addPrivateUnit"
                    subtitle="startAddPrivateUnits"
                    iconName="icon-simple-line-icons-user-following"
                    onShowInfo={null}
                />) : <ListLoadingView />}
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(PrivateUnitsTableView);
