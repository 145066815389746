import { CheckCircle } from '@material-ui/icons';
import { Form } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Field, change, reduxForm } from 'redux-form';
import { GREEN } from '../../../_config/consts.js';
import * as formatter from '../../../_config/formatter';
import FormCardContainer from '../Elements/FormCardContainer.jsx';
import DatePickerField from '../Fields/DatePickers/SingleDatePicker/DatePickerField.jsx';
import GuestSelector from '../Fields/GuestSelector.jsx';
import MDCheckBoxField from '../Fields/MDCheckBox/MDCheckBoxField.js';
import SelectableRowField from '../Fields/SelectableRows/SelectableRowField.jsx';
import TimePickerField from '../Fields/TimePicker/TimePickerField.jsx';
import CustomField from '../Fields/CustomField.jsx';

const validate = (values) => {
  const errors = {};
  const period = moment(values.dateTo).diff(moment(values.dateFrom), 'hours');
  if (!values.firstname || (values.firstname && values.firstname.length === 0)) {
    errors.firstname = 'required';
  }
  if (period > 420) {
    errors.dateTo = 'tooManyDaysSelected';
  }
  if (period < 0) {
    errors.dateTo = 'endDateAfterFromDate';
  }
  if (!values.resourceId) {
    errors.resourceId = 'required';
  }
  return errors;
};

@reduxForm({ form: 'ReservationForm', validate })
@connect(state => ({ form: state.form.ReservationForm }))
class ReservationForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filterTimeout: '',
      isMultidayMode: false
    }
  }

  onHandleChange(value, field, resourceName, page) {
    const { dateHasBeenChanged, form, onGuestTagsChange } = this.props;
    const { isMultidayMode } = this.state;
    let dateInterval = {};
    let userTagId = '';
    switch (field) {
      case 'dateFrom':
        dateInterval = {
          start: value,
          end: isMultidayMode ? form.values.dateTo || value : value,
          startTime: form.values.timeFrom,
          endTime: form.values.timeTo,
        };
        userTagId = form.values.firstname ? form.values.firstname.id : '';
        break;
      case 'dateTo':
          dateInterval = {
            start: form.values.dateFrom,
            end: value,
            startTime: form.values.timeFrom,
            endTime: form.values.timeTo,
          };
          userTagId = form.values.firstname ? form.values.firstname.id : '';
          break;
      case 'timeFrom':
        dateInterval = {
          start: form.values.dateFrom,
          end: isMultidayMode ? form.values.dateTo : form.values.dateFrom,
          startTime: value,
          endTime: form.values.timeTo,
        };
        userTagId = form.values.firstname ? form.values.firstname.id : '';
        break;
      case 'timeTo':
        dateInterval = {
          start: form.values.dateFrom,
          end: isMultidayMode ? form.values.dateTo : form.values.dateFrom,
          startTime: form.values.timeFrom,
          endTime: value,
        };
        userTagId = form.values.firstname ? form.values.firstname.id : '';
        break;
      case 'firstname':
        dateInterval = {
          start: form.values.dateFrom,
          end: isMultidayMode ? form.values.dateTo : form.values.dateFrom,
          startTime: form.values.timeFrom,
          endTime: form.values.timeTo,
        };
        userTagId = value ? value.id : '';
        onGuestTagsChange(value);
        break;
      default:
        dateInterval = {
          start: form.values.dateFrom,
          end: isMultidayMode ? form.values.dateTo : form.values.dateFrom,
          startTime: form.values.timeFrom,
          endTime: form.values.timeTo,
        };
        userTagId = form.values.firstname ? form.values.firstname.id : '';
    }
    const dateToParse = {
      dateFrom: dateInterval.start,
      dateTo: dateInterval.end,
      startTime: dateInterval.startTime,
      endTime: dateInterval.endTime,
    };
    dateHasBeenChanged(dateToParse, userTagId, resourceName, page);
  }

  /* async onResourceFilterChange(value) {
    const { dispatch, form } = this.props;
    const { filterTimeout } = this.state;
    if (filterTimeout) clearTimeout(filterTimeout);
    this.setState({
      filterTimeout: setTimeout(() => {
        this.onHandleChange(null, null, value);
      }, 500),
    });
  } */

  fetchMoreAvailableResource(page) {
    const { form, onFetchMoreAvailableResources } = this.props;
    const dateToParse = {
      dateFrom: form.values.dateFrom,
      dateTo: form.values.dateTo,
      startTime: form.values.timeFrom,
      endTime: form.values.timeTo,
    };
    const userTagId = form.values.firstname ? form.values.firstname.id : '';
    const formattedDates = formatter.formatOutputData(formatter.RESERVATION_DATES, dateToParse);
    const { dateFrom, dateTo } = formattedDates;
    const availableResourcesData = { userTagId, dateFrom, dateTo };
    const pageSize = 20;
    const append = true;
    onFetchMoreAvailableResources(page, pageSize, availableResourcesData, append );
  }

  getDisabledHours() {
    const { form } = this.props;
    if (form.values.dateFrom && moment(form.values.dateFrom).startOf('day').isSame(moment().startOf('day'))) {
      return _.times(moment().hour(), i => i);
    }
    return [];
  }

  getDisabledMinutes(selectedHour) {
    const { form } = this.props;
    if (form.values.dateFrom && moment(form.values.dateFrom).startOf('day').isSame(moment().startOf('day')) && selectedHour === moment().hour()) {
      return _.times(moment().minute(), i => i);
    }
    return [];
  }

  onSelectResource(resourceId) {
    const { availabileResources, dispatch } = this.props;
    const selectedResource = availabileResources && availabileResources.content && _.find(availabileResources.content, res => res.id === resourceId);
    if (selectedResource && selectedResource.automaticCheckIn) {
      dispatch(change('ReservationForm', 'checkInNotNecessary', true));
    }
  }

  toggleMultidayMode = () => {
    const { dispatch, form } = this.props;
    this.setState(prevState => {
      const newState = { isMultidayMode: !prevState.isMultidayMode };
      if (!newState.isMultidayMode && form.values.dateTo) {
        // Reset dateTo to dateFrom when switching back to single day
        dispatch(change('ReservationForm', 'dateTo', form.values.dateFrom));
      }
      return newState;
    });
  }

  render() {
    const { availabileResources, availableUserTags, guestsFilteredByTags, onGuestInputChange, form, onFetchMoreAvailableResources, multidayEnabled } = this.props;
    const { isMultidayMode } = this.state;
    const selectedResource = availabileResources && availabileResources.content && form && form.values && form.values.resourceId && _.find(availabileResources.content, res => res.id === form.values.resourceId);
    const isAutomaticCheckin = selectedResource && selectedResource.automaticCheckIn;
    return (
      <Form>
        <FormCardContainer
          title="reservationInfo"
          subtitle="reservationInfoDescription"
        >
          <Entity
            componentClass={Field}
            name="title"
            componentAttribute="placeholder"
            entity="insertTitle"
            title={<Entity entity="reservationName" />}
            component={CustomField}
          />
        </FormCardContainer>
        <FormCardContainer
          title="selectUser"
          subtitle="userWarning"
        >
          <Field
            title={<Entity entity="insertName" />}
            id="first"
            name="firstname"
            singleTag
            tags={availableUserTags}
            guests={guestsFilteredByTags}
            placeholder={<Entity entity="insertGuestTagsForCard" />}
            component={GuestSelector}
            onInputChange={value => onGuestInputChange(value)}
            onHandleChange={value => this.onHandleChange(value, 'firstname')}
            mandatory
          />
        </FormCardContainer>
        <FormCardContainer
          title="reservationDateAndTime"
          subtitle="dateAndTimeWarning"
        >
          {multidayEnabled && (
            <div style={{ marginLeft: 50, marginBottom: 20 }}>
              <Field
                name="isMultiday"
                label={<Entity entity="enableMultidayReservation" />}
                titleStyle={{ fontSize: 16, fontWeight: 'bold', color: '#3f3f3f' }}
                component={MDCheckBoxField}
                onChange={this.toggleMultidayMode}
                value={isMultidayMode}
              />
              <h4 style={{ marginTop: -10, lineHeight: 1.5, marginLeft: 35, fontSize: 15, color: '#3f3f3f', fontWeight: 'light' }}>
                <Entity entity="enableMultidayReservationDescription" />
              </h4>
            </div>
          )}
          <div style={{ marginLeft: 50, display: 'flex', flexDirection: 'column', gap: 20 }} >
            <div style={{ display: 'flex', alignItems: 'flex-start', gap: 80 }}>
              <div style={{ width: 120 }}>
                <Field
                  title={<Entity entity="dateFrom" />}
                  id="dateFrom"
                  name="dateFrom"
                  dateContainer={{ width: 120 }}
                  onHandleChange={value => this.onHandleChange(value, 'dateFrom')}
                  component={DatePickerField}
                  isOutsideRange={date => date.isBefore(moment().startOf('day'))}
                  mandatory
                  titleStyle={{ marginBottom: 8, fontSize: 16, color: '#3f3f3f' }}
                />
              </div>
              <div style={{ width: 120 }}>
                <Field
                  title={<Entity entity="reservationStartTime" />}
                  name="timeFrom"
                  disabledHours={() => this.getDisabledHours()}
                  disabledMinutes={selectedHour => this.getDisabledMinutes(selectedHour)}
                  component={TimePickerField}
                  onHandleChange={value => this.onHandleChange(value, 'timeFrom')}
                  titleStyle={{ marginBottom: 8, fontSize: 16, color: '#3f3f3f' }}
                />
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'flex-start', gap: 80 }}>
              <div style={{ width: 120 }}>
                {isMultidayMode ? (
                  <Field
                    title={<Entity entity="endDate" />}
                    id="dateTo"
                    name="dateTo"
                    dateContainer={{ width: 120 }}
                    onHandleChange={value => this.onHandleChange(value, 'dateTo')}
                    component={DatePickerField}
                    isOutsideRange={date => {
                      const fromDate = form.values.dateFrom;
                      return date.isBefore(moment().startOf('day')) || (fromDate && date.isBefore(moment(fromDate)));
                    }}
                    mandatory
                    titleStyle={{ marginBottom: 8, fontSize: 16, color: '#3f3f3f', whiteSpace: 'nowrap' }}
                  />
                ) : <div style={{ height: 20 }} />}
              </div>
              <div style={{ width: 120 }}>
                <Field
                  title={<Entity entity="reservationEndTime" />}
                  name="timeTo"
                  component={TimePickerField}
                  onHandleChange={value => this.onHandleChange(value, 'timeTo')}
                  titleStyle={{ marginBottom: 8, fontSize: 16, color: '#3f3f3f' }}
                />
              </div>
            </div>
          </div>
          {isAutomaticCheckin ? (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
              <CheckCircle style={{ color: GREEN, fontSize: 20 }} />
              <h5 style={{ color: '#3f3f3f', marginLeft: 10, fontSize: 16, fontWeight: 'bold' }}>{<Entity entity="resourceWithAutomaticCheckin" />}</h5>
            </div>
          ) : (
          <div style={{ opacity: isAutomaticCheckin ? 0.5 : 1 }}>
            <Field
              name="checkInNotNecessary"
              label={<Entity entity="checkInNotNecessary" />}
              titleStyle={{ fontSize: 16, fontWeight: 'bold', color: '#3f3f3f' }}
              containerstyle={{ marginTop: 20 }}
              disabled={isAutomaticCheckin}
              component={MDCheckBoxField}
            />
            <h4 style={{ marginTop: -10, lineHeight: 1.5, marginLeft: 35, fontSize: 15, color: '#3f3f3f', fontWeight: 'light' }}><Entity entity="checkInNotNecessaryDecription" /></h4>
          </div>
        )}
        </FormCardContainer>
        <div style={{ marginBottom: 65 }}>
          <FormCardContainer
            title="availableResources"
            style={{ marginBottom: 250 }}
          >
            <Field
              name="resourceId"
              component={SelectableRowField}
              options={availabileResources}
              emptyOptionsText={<Entity entity="availableResourcesSelectDates" />}
              onFilterChange={(value) => this.onResourceFilterChange(value)}
              onFetchMoreItems={(page) => this.fetchMoreAvailableResource(page)}
              onHandleChange={(resourceId) => this.onSelectResource(resourceId)}
              isSingleChoice
              mandatory
            />
          </FormCardContainer>
        </div>
      </Form >
    );
  }
} 

export default ReservationForm;
