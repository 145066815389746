import TextIcon from '@material-ui/icons/HelpOutline';
import {
  Form
} from '@sketchpixy/rubix';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import { Warning } from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import CardIcon from '@material-ui/icons/CreditCard';
import { initialize, submit } from 'redux-form';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import CopyIcon from '@material-ui/icons/InsertDriveFile';
import { Field, change, reduxForm } from 'redux-form';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import { BLUE, CARD_TYPES, GREEN, ORANGE, PERMISSIONS, PERMISSION_ENTITIES, PRIVATE_UNITS_STATUS, PRIVATE_UNIT_TEMPLATE_LANGUAGES, RED } from '../../_config/consts.js';
import { hasFormWritePermission, isUserWithoutEmail } from '../../_config/utils.js';
import MDButton from '../MDButton/MDButton.jsx';
import FormCardContainer from './Elements/FormCardContainer.jsx';
import TagsSelectorListField from './Elements/TagsSelectorListField.jsx';
import CustomField from './Fields/CustomField.jsx';
import MDSwitchField from './Fields/MDSwitch/MDSwitchField.jsx';
import { Divider, Tooltip } from '@material-ui/core';
import CopyToClipboard from 'react-copy-to-clipboard';
import * as PrivateUnitActions from '../../redux/actions/privateUnit.actions';
import * as ModalActions from '../../redux/actions/modal.actions';
import * as GuestActions from '../../redux/actions/guest.actions';
import PrivateUnitDeviceForm from './PrivateUnitDeviceForm.jsx';
import OperationalView from '../OperationalView/OperationalView.jsx';
import PrivateUnitCardsTableView from '../PrivateUnits/PrivateUnitCardsTableView.jsx';
import PrivateUnitTemplateForm from './PrivateUnitTemplateForm.jsx';
import GuestSelector from './Fields/GuestSelector.jsx';
import FormFlatContainer from './Elements/FormFlatContainer.jsx';
import UsersIconCustom from '../CustomIcons/UsersIconCustom.jsx';

const validate = (values) => {
  const errors = {};
  
  if (values) {
    if (!values.name) {
      errors.name = 'required';
    }
    
    if (!values.maxUsers||parseInt(values.maxUsers, 10)<=0) {
      errors.maxUsers = 'required';
    }
    if (!values.maxSmartLocks||parseInt(values.maxSmartLocks, 10)<=0) {
      errors.maxSmartLocks = 'required';
    }
    if (!values.maxInvitations||parseInt(values.maxInvitations, 10)<=0) {
      errors.maxInvitations = 'required';
    }
    if (!values.maxPins||parseInt(values.maxPins, 10)<=0) {
      errors.maxPins = 'required';
    }

    if (values.batchCreation) {
      if (!values.batchCreationFrom||parseInt(values.batchCreationFrom, 10)<=0) {
        errors.batchCreationFrom = 'required';
      }
      if (!values.batchCreationTo||parseInt(values.batchCreationTo, 10)<=0) {
        errors.batchCreationTo = 'required';
      }
    }
    
  }

  return errors;
};

@reduxForm({ form: 'PrivateUnitForm', validate })
@connect(state => ({ form: state.form.PrivateUnitForm, privateUnit: state.privateUnit.selectedPrivateUnit, standardDevices: state.privateUnit.selectedPrivateUnitStandardDevices, privateUnits: state.privateUnit, themeName: state.settings.items.theme.data.themeName }))
class PrivateUnitForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isDeviceFormVisible: false,
      isTemplateFormVisible: false,
      filteredGuestsForOwner: [],
    };
  }

  async componentDidUpdate(prevProps) {
    const { privateUnit: oldPrivateUnit } = prevProps;
    const { privateUnit: newPrivateUnit } = this.props;
    if (newPrivateUnit && oldPrivateUnit && oldPrivateUnit.id !== newPrivateUnit.id)
      this.setState({ isTemplateFormVisible: false })
  }

  onCreateNewDevice() {
    const { dispatch } = this.props;
    dispatch(initialize('PrivateUnitDeviceForm', {deviceId: "", deviceType: CARD_TYPES.GENERIC_CARD}));
    this.setState({ isDeviceFormVisible: true });
  }

  onDeviceSelected(device) {
    const { dispatch } = this.props;
    dispatch(initialize('PrivateUnitDeviceForm', device));
    this.setState({ isDeviceFormVisible: true });
  }

  onDeviceFormSubmit(formData) {
    const { onPrivateUnitDeviceFormSubmit } = this.props;
    this.setState({ isDeviceFormVisible: false });
    onPrivateUnitDeviceFormSubmit(formData)
  }

  onDeleteDeviceConfirm(standardDeviceId) {
    const { onDeletePrivateUnitDeviceConfirm } = this.props;
    this.setState({ isDeviceFormVisible: false });
    onDeletePrivateUnitDeviceConfirm(standardDeviceId)
  }

  async generateRedemptionCertificate() {
    const { dispatch, form, privateUnits: { templates: { content: templatesData } }, privateUnit, standardDevices } = this.props
    const templateId = form && form.values && form.values.templateId
    if (templateId) {
      const templateData = templatesData.find(e=>e.id.toString()===templateId.toString())
      if (templateData) {
        const placeholderObjRef = {...privateUnit}
        placeholderObjRef.standardDevicesIds = standardDevices ? standardDevices.map(e=>e.deviceId) : undefined
        await dispatch(initialize('PrivateUnitTemplateForm', {data: (templateData.data || ""), placeholderObjRef}));
        this.setState({ isTemplateFormVisible: true });
      }
    }
  }

  async openNoRedemptionCertificateTemplatesDialogOpen() {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        body: <Entity entity="privateUnitNoRedemptionCertificateTemplatesWarning" />,
        onConfirmText: <Entity entity="confirm" />,
        onConfirm: () => {dispatch(ModalActions.hideModal());dispatch(push('/settings?showPrivateUnitSettings=true'))},
        onCancelText: <Entity entity="cancel" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onFilterGuestsForOwner(value) {
    const { dispatch } = this.props;
    setTimeout(async () => {
      const guests = await dispatch(GuestActions.fetchGuestsForSelector(value));
      this.setState({ filteredGuestsForOwner: guests });
    }, 800);
  }

  render() {
    const {
      form,
      themeName,
      privateUnit,
      standardDevices,
      availableLockTags,
      availableUserTags,
      onLockTagsChange,
      locksFilteredByTags,
      onLockFilterModeChange,
      onGuestInputChange,
      onLockInputChange,
      onFetchMoreLocks,
      onDeletePrivateUnit,
      onPrivateUnitFormSubmit,
      privateUnits: { templates: { content: templatesData } }
    } = this.props;
    const { dispatch } = this.props;
    const { isDeviceFormVisible, isTemplateFormVisible, filteredGuestsForOwner } = this.state;
    const isEditing = form && form.values && form.values.id;
    // TODO permissions
    const canEditOrCreatePrivateUnit = hasFormWritePermission(PERMISSION_ENTITIES.USER, isEditing);
    // TODO permissions
    const canDeletePrivateUnit = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.DELETE], PERMISSION_ENTITIES.USER);
    const canReadUser = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.USER);
    const canReadLock = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.SMART_LOCK);
    
    const privateUnitId = form && form.values.id ? form.values.id : null;

    const isBatchCreation = form && form.values && form.values.batchCreation
    const name_current = form && form.values && form.values.name
    const batchCreationFrom_current  = form && form.values && form.values.batchCreationFrom && parseInt(form.values.batchCreationFrom, 10)
    const batchCreationTo_current = form && form.values && form.values.batchCreationTo && parseInt(form.values.batchCreationTo, 10)
    const batchCreationTotal = batchCreationFrom_current>0&&batchCreationTo_current>0&&batchCreationTo_current>batchCreationFrom_current?
      (batchCreationTo_current-batchCreationFrom_current+1):undefined
    const batchCreationFirstName = batchCreationTotal&&name_current?(`${name_current} ${batchCreationFrom_current}`):undefined
    const batchCreationLastName = batchCreationTotal&&name_current?(`${name_current} ${batchCreationTo_current}`):undefined

    const statusColor = privateUnit.state===PRIVATE_UNITS_STATUS.TO_BE_ACTIVATED?ORANGE:privateUnit.state===PRIVATE_UNITS_STATUS.ACTIVE?GREEN:'rgb(63, 63, 63)'

    const templateOptions = templatesData.map(e=>{
      return {
        ...e,
        label: PRIVATE_UNIT_TEMPLATE_LANGUAGES[e.language] && PRIVATE_UNIT_TEMPLATE_LANGUAGES[e.language].label ? 
          L20NContext.getSync(PRIVATE_UNIT_TEMPLATE_LANGUAGES[e.language].label) : e.language,
        value: e.id,
      }
    })
    const templateIdSelected = form && form.values && form.values.templateId

    const mainUserAssigned = Boolean(form && form.values && form.values.owner && form.values.owner.id)
    const mainUser = form && form.values && form.values.owner
    const isActivated = form && form.values && form.values.state === PRIVATE_UNITS_STATUS.ACTIVE

    return (
      <>
      <Form>
        <FormCardContainer
          title={'privateUnitsOperationalBasicData'}
          headerStyle={{ marginBottom: 20 }}
        >
          <Entity
            componentClass={Field}
            name="name"
            componentAttribute="placeholder"
            entity="privateUnitsOperationalNameInsert"
            title={<Entity entity="privateUnitsOperationalName" />}
            component={CustomField}
            type="text"
            disabled={!canEditOrCreatePrivateUnit}
            mandatory
          />
          {!isEditing?(
            <div style={{marginTop:20, display:'flex', flexDirection:'column'}}>
              <Field
                name="batchCreation"
                label={<Entity entity="privateUnitsOperationalCreateBatchSubtitle" />}
                titleStyle={{ fontSize: 16, color: '#3f3f3f', fontWeight: 'bold' }}
                component={MDSwitchField}
                disabled={false}
              />
            </div>
          ):(
            <div style={{marginTop:20, display:'flex', flexDirection:'column'}}>
              <span style={{fontSize:16, color:'rgb(63, 63, 63)', fontWeight:'bold', marginTop:0}}>
                {L20NContext.getSync(`privateUnitsStatus`)}
              </span>
              <span style={{fontSize:16, marginTop: 0, fontWeight:'bold', padding:5, color:statusColor, marginLeft: 0 }}>
                {L20NContext.getSync(`privateUnitsStatus_${privateUnit.state}`)}
              </span>
            </div>
          )}
          {isBatchCreation?(
            <div>
              {/* 
              <div style={{fontSize:16,color:'rgb(63, 63, 63)',fontWeight:'bold',marginTop:0}}>
                {L20NContext.getSync('privateUnitsOperationalNumberOfUnits')}
              </div>
              */}
              <div style={{ display: 'flex', flexDirection: 'row',paddingLeft:0 }}>
                <h4 style={{ color: '#3f3f3f', marginRight: 10, marginTop: 11, fontSize:16 }}>{<Entity entity="privateUnitsOperationalNumberOfUnitsFrom"/>}</h4>
                <Entity
                  componentClass={Field}
                  name="batchCreationFrom"
                  type="number"
                  textFormattingFunction={value => value.replace(/[\s.]+/g, '')}
                  componentAttribute="placeholder"
                  component={CustomField}
                  containerStyle={{ width: 100 }}
                  className="form-control-custom"
                />
                <h4 style={{ color: '#3f3f3f', marginRight: 10, marginLeft: 10, marginTop: 11, fontSize:16 }}>{<Entity entity="privateUnitsOperationalNumberOfUnitsTo"/>}</h4>
                <Entity
                  componentClass={Field}
                  name="batchCreationTo"
                  type="number"
                  textFormattingFunction={value => value.replace(/[\s.]+/g, '')}
                  componentAttribute="placeholder"
                  component={CustomField}
                  containerStyle={{ width: 100 }}
                  className="form-control-custom"
                />
              </div>
            </div>
          ):null}
          {isBatchCreation && name_current && batchCreationTotal ? (
            <div style={{display:'flex', flexDirection:'column', marginTop:-10, marginLeft:10, paddingLeft:10, borderRadius:5, borderLeft:`5px solid ${BLUE}`}} 
              key={batchCreationTotal.toString()}>
              <div style={{display:'flex', flexDirection:'row', alignItems:'baseline'}}>
                <h4 style={{ color: '#3f3f3f', marginRight: 5, marginTop: 6, fontSize:18, fontWeight:'bold' }}>{batchCreationTotal}</h4>
                <h4 style={{ color: '#3f3f3f', marginRight: 10, marginTop: 6, fontSize:16, textDecoration:'underline' }}>{<Entity entity="privateUnitsOperationalNumberOfUnitsTotal"/>}</h4>
              </div>
              <h4 style={{ color: '#3f3f3f', margin: 0, fontSize:16 }}>{batchCreationFirstName}</h4>
              <h4 style={{ color: '#3f3f3f', marginTop: 5, marginBottom: 5, fontSize:16 }}>...</h4>
              <h4 style={{ color: '#3f3f3f', margin: 0, marginBottom: 10, fontSize:16 }}>{batchCreationLastName}</h4>
            </div>
          ):null}
          {mainUserAssigned ? ( 
            <FormFlatContainer
              icon={<UsersIconCustom style={{ width: 22, marginTop: 2, marginRight: 2 }} />}
              title="privateUnitsOperationalMainUser"
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  <h5 style={{ fontWeight: 'bold', fontSize: 16,  marginTop: 5, marginBottom: 2 }}>{`${(mainUser.firstname)} ${(mainUser.lastname)}`}</h5>
                  <h5 style={{ marginTop: 0 }}>{`${mainUser.email}`}</h5>
                </div>
              </div>
            </FormFlatContainer>
          ) : (
            !isBatchCreation && !isActivated ? (
              <div style={{marginTop:20, display:'flex', flexDirection:'column'}}>
                <span style={{fontSize:16, color:'rgb(63, 63, 63)', fontWeight:'bold', marginTop:0}}>
                  {L20NContext.getSync(`privateUnitsOperationalMainUser`)}
                </span>
                <Field
                  title={null}
                  id={'ownerUser'}
                  name={'ownerUser'}
                  singleTag
                  mutableTags={[...new Map(filteredGuestsForOwner.map(e => [e.email, e])).values()].map(e => ({ id: e.id, name: e.email, type: 'user', color: "#ba68c8" }))}
                  placeholder={<Entity entity="privateUnitsOperationalOwnerSearch" />}
                  component={GuestSelector}
                  onInputChange={value => {this.onFilterGuestsForOwner(value)}}
                  onHandleChange={value => {dispatch(change('PrivateUnitForm', 'ownerUser', value ? {id: value.id, name: value.name} : null));}}
                />
              </div>
            ) : null
          )}
          <Divider orientation='horizontal' style={{marginTop:20}} />
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20}}>
            <div style={{ display: 'flex', flexDirection: 'column', flexGrow:1}}>
              <Entity
                componentClass={Field}
                name="maxUsers"
                componentAttribute="placeholder"
                entity="privateUnitsOperationalMaxUsersInsert"
                title={<Entity entity="privateUnitsOperationalMaxUsers" />}
                textFormattingFunction={value => value.replace(/[\s.]+/g, '')}
                containerStyle={{ width: 200 }}
                component={CustomField}
                type="number"
                disabled={!canEditOrCreatePrivateUnit}
                mandatory
              />
              <Entity
                componentClass={Field}
                name="maxSmartLocks"
                componentAttribute="placeholder"
                entity="privateUnitsOperationalMaxSmartLocksInsert"
                title={<Entity entity="privateUnitsOperationalMaxSmartLocks" />}
                textFormattingFunction={value => value.replace(/[\s.]+/g, '')}
                containerStyle={{ width: 200 }}
                component={CustomField}
                type="number"
                disabled={!canEditOrCreatePrivateUnit}
                mandatory
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', flexGrow:1}}>
              <Entity
                componentClass={Field}
                name="maxInvitations"
                componentAttribute="placeholder"
                entity="privateUnitsOperationalMaxInvitationsInsert"
                title={<Entity entity="privateUnitsOperationalMaxInvitations" />}
                textFormattingFunction={value => value.replace(/[\s.]+/g, '')}
                containerStyle={{ width: 200 }}
                component={CustomField}
                type="number"
                disabled={!canEditOrCreatePrivateUnit}
                mandatory
              />
              <Entity
                componentClass={Field}
                name="maxPins"
                componentAttribute="placeholder"
                entity="privateUnitsOperationalMaxPinsInsert"
                title={<Entity entity="privateUnitsOperationalMaxPins" />}
                textFormattingFunction={value => value.replace(/[\s.]+/g, '')}
                containerStyle={{ width: 200 }}
                component={CustomField}
                type="number"
                disabled={!canEditOrCreatePrivateUnit}
                mandatory
              />
            </div>
          </div>
        </FormCardContainer>
        <FormCardContainer
          title="privateUnitsOperationalLockTitle"
          subtitle="privateUnitsOperationalLockSubtitle"
        >
          <TagsSelectorListField
            formName="PrivateUnitForm"
            name="lockTags"
            selectorFieldName="lockTagMatchingMode"
            selectedTagsFieldName="selectedLocksTags"
            tags={availableLockTags.filter(e=>e.type!=="private_unit")}
            selectedTags={locksFilteredByTags && locksFilteredByTags[0]}
            form={form}
            tagsType="LOCK"
            title="lockTags"
            placeholder="insertLockTagsForPrivateUnit"
            listTitle="selectedLocks"
            emptyTagSelectionText={canReadLock ? 'noLocksSelectedWithTag' : null}
            onFetchMoreItems={page => onFetchMoreLocks(page)}
            onTagsChange={values => onLockTagsChange(values)}
            onInputChange={value => onLockInputChange(value)}
            onFilterModeChange={value => onLockFilterModeChange(value)}
            disabled={!canEditOrCreatePrivateUnit}
            isOptional={true}
          />
        </FormCardContainer>
        <FormCardContainer
          title="privateUnitsOperationalUserTagsTitle"
          subtitle="privateUnitsOperationalUserTagsSubtitle"
        >
          <TagsSelectorListField
            formName="PrivateUnitForm"
            name="guestTags"
            selectorFieldName="guestTagMatchingMode"
            selectedTagsFieldName="selectedGuestTags"
            tags={availableUserTags.filter(e=>e.type!=="private_unit"&&e.type!=="user")}
            selectedTags={null}
            form={form}
            tagsType="GUEST"
            title="usersTags"
            placeholder="insertGuestTagsForPrivateUnit"
            listTitle="selectedUsers"
            emptyTagSelectionText={canReadUser ? 'noUsersForSelectedTag' : null}
            onFetchMoreItems={null}
            onTagsChange={null}
            onInputChange={value => onGuestInputChange(value)}
            onFilterModeChange={null}
            disabled={!canEditOrCreatePrivateUnit}
            isOptional={true}
          />
        </FormCardContainer>
        {isEditing?(
          <FormCardContainer
            title="privateUnitsOperationalManagementTitle"
            subtitle="privateUnitsOperationalManagementSubtitle"
          >
            {privateUnit.redemptionCode?(
              <div style={{marginTop:20, display:'flex', flexDirection:'column'}}>
                <span style={{fontSize:16, color:'rgb(63, 63, 63)', fontWeight:'bold', marginTop:0}}>
                  {L20NContext.getSync(`privateUnitsOperationalRedemptionCode`)}
                </span>
                <div style={{marginLeft:0, display:'flex', flexDirection:'row', alignItems:'center'}}>
                  <span style={{fontSize:16, marginTop:0, textDecoration:'underline'}}>
                    {privateUnit.redemptionCode}
                  </span>
                  <div style={{flexGrow:1}}>
                    <CopyToClipboard text={privateUnit.redemptionCode} onCopy={() => {}}>
                      <MDButton
                        title={<Entity entity={"copy"} />}
                        backgroundColor={BLUE}
                        onClick={() => null}
                        containerStyle={{ marginLeft: 10, width:'min-content', marginTop:0, marginBottom:0 }}
                        style={{ color: 'white', fontSize: 15, marginRight: 10, paddingLeft:10, paddingRight:10, paddingTop:5, paddingBottom:5, 
                          width:'min-content', minHeight:0 }}
                        icon={<CopyIcon style={{ marginLeft: 10 }} />}
                      />
                    </CopyToClipboard>
                  </div>
                  {templateOptions.length &&
                    <div style={{marginBottom:-15}}>
                      <Field
                        id="templateId"
                        name="templateId"
                        title={null}
                        component={CustomField}
                        className="form-control-select"
                        componentClass="select"
                      >
                        {_.map(templateOptions, item =>
                          <option value={item.value} key={item.value}>
                            {item.label}
                          </option>
                        )}
                      </Field>
                    </div>
                  }
                  <MDButton
                    backgroundColor={templateIdSelected?BLUE:'gray'}
                    containerStyle={{ margin: 0, marginLeft: 20, marginTop: 4 }}
                    title={<Entity entity={"privateUnitsOperationalCertificateButton"} />}
                    onClick={() => {
                      if (templateOptions.length)
                        this.generateRedemptionCertificate()
                      else
                        this.openNoRedemptionCertificateTemplatesDialogOpen()
                    }}
                    disabled={!templateIdSelected}
                  />
                </div>
              </div>
            ):null}
            <div style={{marginTop:20, display:'flex', flexDirection:'column'}}>
              <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'end'}}>
                <div style={{display:'flex', flexDirection:'row'}}>
                  <span style={{fontSize:16, color:'rgb(63, 63, 63)', fontWeight:'bold', marginTop:0}}>
                    {L20NContext.getSync(`privateUnitsOperationalCards`)}
                  </span>
                  <CardIcon style={{ marginLeft: 10, fontSize:26 }} />
                </div>
                <MDButton
                  backgroundColor={BLUE}
                  containerStyle={{ margin: 0, marginLeft: 20, marginTop: 4 }}
                  title={L20NContext.getSync(`privateUnitsOperationalCardsNew`)}
                  onClick={() => {this.onCreateNewDevice()}}
                />
              </div>
              <PrivateUnitCardsTableView
                cards={standardDevices}
                onSelectCard={(card) => {this.onDeviceSelected(card)}}
              />
            </div>
          </FormCardContainer>
        ):null}
        {isEditing && canDeletePrivateUnit &&
          <FormCardContainer
            title="deletePrivateUnit"
            subtitle="deletePrivateUnitWarning"
          >
            <MDButton
              title={<Entity entity="deletePrivateUnit" />}
              backgroundColor={RED}
              onClick={() => onDeletePrivateUnit(privateUnitId)}
            />
          </FormCardContainer>
        }
        {canEditOrCreatePrivateUnit && (
          <div style={{ backgroundColor: 'white', position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}>
            <MDButton
              title={<Entity entity="save" />}
              containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
              style={{ height: 45, borderRadius: 0 }}
              disabled={false}
              onClick={() => onPrivateUnitFormSubmit()}
            />
          </div>
        )}
      </Form>
      <OperationalView
        themeName={themeName}
        isVisible={isDeviceFormVisible}
        onClose={() => this.setState({ isDeviceFormVisible: false })}
        style={{ margin: 0, padding: 0 }}
        title={<Entity key={1} entity={"privateUnitDeviceForm"} />}
      >
        <div style={{ padding: 10, paddingBottom: '30%' }}>
          <PrivateUnitDeviceForm
            onSubmit={formData => this.onDeviceFormSubmit(formData)}
            onValidateForm={() => dispatch(submit('PrivateUnitDeviceForm'))}
            onDeletePrivateUnitDeviceConfirm={(standardDeviceId) => this.onDeleteDeviceConfirm(standardDeviceId)}
          />
        </div>
      </OperationalView>
      <OperationalView
        themeName={themeName}
        isVisible={isTemplateFormVisible}
        onClose={() => this.setState({ isTemplateFormVisible: false })}
        style={{ margin: 0, padding: 0 }}
        title={<Entity key={1} entity={"privateUnitTemplateGenerationForm"} />}
      >
        <div style={{ padding: 10 }}>
          <PrivateUnitTemplateForm
            onPrivateUnitTemplateGenerate={formData => this.onPrivateUnitTemplateGenerate(formData)}
          />
        </div>
      </OperationalView>
      </>
    );
  }
} 

export default PrivateUnitForm;
