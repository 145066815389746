import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import ListViewIcon from '@material-ui/icons/ViewList';
import CardViewIcon from '@material-ui/icons/ViewStream';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import qs from 'qs';
import React from 'react';
import { connect } from 'react-redux';
import { change, initialize, submit } from 'redux-form';
import { LICENSE_TYPES, MATCH_TAG_MODE, PERMISSIONS, PERMISSION_ENTITIES, VIEW_MODES } from '../_config/consts';
import CredentialsOperationalSection from '../components/Credentials/CredentialsOperationalSection.jsx';
import SmartCredentialsExplanationView from '../components/Credentials/SmartCredentialsExplanationView.jsx';
import OperationalView from '../components/OperationalView/OperationalView.jsx';
import PresentationalViewHeader from '../components/PresentationalView/PresentationalViewHeader.jsx';
import AbilityProvider from '../permissionsUtils/AbilityProvider.js';
import * as PrivateUnitActions from '../redux/actions/privateUnit.actions';
import * as GuestsActions from '../redux/actions/guest.actions';
import * as ModalActions from '../redux/actions/modal.actions';
import * as SettingsActions from '../redux/actions/setting.actions';
import * as TagActions from '../redux/actions/tag.actions';
import * as UtilsActions from '../redux/actions/utils.actions';
import PrivateUnitsListView from '../components/PrivateUnits/PrivateUnitsListView.jsx';
import PrivateUnitsTableView from '../components/PrivateUnits/PrivateUnitsTableView.jsx';
import PrivateUnitsIconCustom from '../components/CustomIcons/PrivateUnitsIconCustom.jsx';
import PrivateUnitsOperationalSection from '../components/PrivateUnits/PrivateUnitsOperationalSection.jsx';
import PrivateUnitDeleteView from '../components/PrivateUnits/PrivateUnitDeleteView.jsx';


let filterTimeout;
@connect(state => ({ privateUnit: state.privateUnit, routing: state.router, languange: state.settings.language, viewLoading: state.utils.viewLoading, themeName: state.settings.items.theme.data.themeName }))
class PrivateUnits extends React.Component {

  constructor(props) {
    super(props);
    const cachedViewMode = localStorage.getItem('privateUnitsViewMode');
    this.state = {
      activeTab: cachedViewMode && cachedViewMode === VIEW_MODES.CARDS ? 1 : 0,
    };
  }

  async componentWillMount() {
    const { dispatch, routing } = this.props;
    const parsed = qs.parse(routing.location.search, { ignoreQueryPrefix: true });
    try {
      await dispatch(PrivateUnitActions.fetchPrivateUnitsDefault());
    } catch(error) {
      console.log(error)
      dispatch(PrivateUnitActions.savePrivateUnitsDefault({}));
    }
    await dispatch(PrivateUnitActions.selectPrivateUnit({}));
    await dispatch(PrivateUnitActions.resetPrivateUnitsFilters());
    this.closeOperationalSection();
    dispatch(PrivateUnitActions.fetchPrivateUnitTemplates());
    this.onFetchPrivateUnits();
    dispatch(SettingsActions.fetchCompanyMetrics());
    const privateUnitId = parsed.privateUnitId;
    if (privateUnitId) {
      const privateUnit = await dispatch(PrivateUnitActions.getPrivateUnitDetails(privateUnitId));
      if (privateUnit)
        this.onSelectPrivateUnit(privateUnit);
    }
    const newEntity = parsed.newEntity;
    if (newEntity) {
      this.onNewPrivateUnitButtonClicked();
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(PrivateUnitActions.selectPrivateUnit({}));
    dispatch(PrivateUnitActions.resetPrivateUnitsFilters());
    
    dispatch(PrivateUnitActions.setOperationalMode(false));
  }

  async onFetchPrivateUnits() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    try {
      await dispatch(PrivateUnitActions.fetchPrivateUnits());
    } finally {
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  async onNewPrivateUnitButtonClicked() {
    const { dispatch, privateUnit } = this.props;
    await dispatch(PrivateUnitActions.selectPrivateUnit({}));
    const privateUnitDefault = privateUnit.defaultConfig?privateUnit.defaultConfig.data:{}
    dispatch(initialize('PrivateUnitForm', {
      guestTagMatchingMode: MATCH_TAG_MODE.AT_LEAST_ONE_TAG,
      batchCreationFrom: "101",
      batchCreationTo: "102",

      ...privateUnitDefault,
      id: undefined,

      lockTagMatchingMode: privateUnitDefault.lockTagMatchingMode?privateUnitDefault.lockTagMatchingMode:MATCH_TAG_MODE.AT_LEAST_ONE_TAG,
      maxUsers: privateUnitDefault.maxUsers?privateUnitDefault.maxUsers:"0",
      maxSmartLocks: privateUnitDefault.maxSmartLocks?privateUnitDefault.maxSmartLocks:"0",
      maxInvitations: privateUnitDefault.maxInvitations?privateUnitDefault.maxInvitations:"0",
      maxPins: privateUnitDefault.maxPins?privateUnitDefault.maxPins:"0",
    }));
    dispatch(PrivateUnitActions.setOperationalMode(true));
  }

  closeOperationalSection() {
    const { dispatch } = this.props;
    dispatch(PrivateUnitActions.selectPrivateUnit({}));
    dispatch(PrivateUnitActions.setOperationalMode(false));
  }

  onSetFilter(field, value) {
    const { dispatch } = this.props;
    dispatch(PrivateUnitActions.setFilter(field, value));
    if (filterTimeout) clearTimeout(filterTimeout);
    filterTimeout = setTimeout(async () => {
      await this.onFetchPrivateUnits();
    }, 500);
  }

  onSearchReset() {
    const { dispatch } = this.props;
    dispatch(PrivateUnitActions.resetPrivateUnitsFilters());
    this.onFetchPrivateUnits();
  }

  onShowInfoModal() {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONTENT_MODAL',
      modalProps: {
        title: <Entity entity="smartphoneCredentials" />,
        content: <SmartCredentialsExplanationView />,
        modalStyle: { height: '90% !important' },
        onOutsideClick: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }
  
  onPrivateUnitsListModeChange(activeTab) {
    const { dispatch } = this.props;
    const selectedViewMode = activeTab === 0 ? VIEW_MODES.TABLE : VIEW_MODES.CARDS;
    this.setState({ activeTab });
    PrivateUnitActions.setPrivateUnitsViewMode(selectedViewMode);
    if (activeTab === 0) {
      dispatch(PrivateUnitActions.resetPrivateUnitsPaginationData());
      dispatch(PrivateUnitActions.fetchPrivateUnits());
    }
  }

  onFetchPrivateUnitOnPage(page) {
    const { dispatch } = this.props;
    dispatch(PrivateUnitActions.fetchPrivateUnits(page));
  }

  async onSelectPrivateUnit(item) {
    const { dispatch } = this.props;
    const { privateUnit: { templates: { content: templatesData } } } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      const privateUnit = await dispatch(PrivateUnitActions.getPrivateUnitDetails(item.id));
      await dispatch(PrivateUnitActions.fetchPrivateUnitStandardDevices(item.id));
      await dispatch(PrivateUnitActions.selectPrivateUnit(privateUnit));
      // Fetch locks by tag to show them when private unit is selected
      await dispatch(PrivateUnitActions.fetchLocksByTags(privateUnit.lockTags, privateUnit.lockTagMatchingMode));
      await dispatch(initialize('PrivateUnitForm', {
        ...privateUnit,
        guestTags: privateUnit.userTags?privateUnit.userTags.filter(e=>e.type!=="private_unit"):[],
        templateId: templatesData && templatesData.length ? templatesData[0].id : undefined,
      }));
      dispatch(PrivateUnitActions.setOperationalMode(true));
    } catch (error) {
      dispatch(PrivateUnitActions.selectPrivateUnit({}));
    }
    dispatch(UtilsActions.setSpinnerVisibile(false));
  }

  onDeletePrivateUnit(privateUnitId) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONTENT_MODAL',
      modalProps: {
        title: <Entity entity="deletePrivateUnitTitle" />,
        content: 
        <PrivateUnitDeleteView 
          onRemoveSubscriptionConfirm={async() => {
            try {
              await dispatch(PrivateUnitActions.deletePrivateUnit(privateUnitId))
              await dispatch(ModalActions.hideModal())
              this.closeOperationalSection()
              this.onFetchPrivateUnits()
            }
            catch (error) {

            }}
          }
        />,
        modalStyle: { height: '90% !important' },
        onOutsideClick: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onPrivateUnitDeviceFormSubmit(formData) {
    const { dispatch, privateUnit } = this.props;
    const selectedPrivateUnit = privateUnit.selectedPrivateUnit;
    const data = {
      ...formData,
    };
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(PrivateUnitActions.assignStandardDeviceToPrivateUnit(selectedPrivateUnit.id,data))
      await dispatch(PrivateUnitActions.fetchPrivateUnitStandardDevices(selectedPrivateUnit.id));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity key={selectedPrivateUnit.id} entity={'createPrivateUnitDeviceSuccess'} /></h6>),
        },
      }));
    } catch (error) {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity key={selectedPrivateUnit.id} entity={'createPrivateUnitDeviceError'} /></h6>),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onDeletePrivateUnitDeviceConfirm(standardDeviceId) {
    const { dispatch, privateUnit } = this.props;
    const selectedPrivateUnit = privateUnit.selectedPrivateUnit;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(PrivateUnitActions.removeStandardDeviceFromPrivateUnit(selectedPrivateUnit.id,standardDeviceId))
      await dispatch(PrivateUnitActions.fetchPrivateUnitStandardDevices(selectedPrivateUnit.id));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="deletePrivateUnitDeviceSuccess" /></h6>),
        },
      }));
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  render() {
    const { privateUnit, themeName, dispatch, viewLoading } = this.props;
    const { activeTab } = this.state;
    // TODO permissions
    const canCreateNewPrivateUnits = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.CREATE], PERMISSION_ENTITIES.USER);
    const numberOfPrivateUnits = privateUnit && privateUnit.data && privateUnit.data.pagination && privateUnit.data.pagination.totalElements ? privateUnit.data.pagination.totalElements : 0;
    const isLuckeyLite = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.LITE]);
    const selectedPrivateUnitID = privateUnit && privateUnit.selectedPrivateUnit ? privateUnit.selectedPrivateUnit.id : undefined
    return (
      <div>
        <PresentationalViewHeader
          themeName={themeName}
          onFilterChange={value => this.onSetFilter('name', _.trim(value))}
          onNewEntity={canCreateNewPrivateUnits ? () => this.onNewPrivateUnitButtonClicked() : null}
          onSearchSubmit={() => this.onFetchPrivateUnits()}
          onSearchReset={() => this.onSearchReset()}
          searchGuestContainerStyle={{ width: 280 }}
          newEntityTitle="addPrivateUnit"
          searchPlaceholderEntityName="privateUnitName"
          newEntityIconName="icon-simple-line-icons-user-following"
          isLoading={viewLoading}          
          onInfo={() => this.onShowInfoModal()}
          onExportElements={null}
          numberOfElements={numberOfPrivateUnits}
          entitiesNumberSection={
            <div style={{ marginRight: 20, marginTop: 13, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <PrivateUnitsIconCustom style={{ width: 22 }} />
              <h4 style={{ marginTop: 5 }}>{numberOfPrivateUnits}</h4>
            </div>
          }
        />
        {!isLuckeyLite && privateUnit && privateUnit.data && !_.isEmpty(privateUnit.data) ? (
          <div style={{ top: 140, position: 'fixed', backgroundColor: 'white' }}>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, index) => this.onPrivateUnitsListModeChange(index)}
            >
              <Tab icon={<ListViewIcon style={{ fontSize: 30 }} />} />
              <Tab icon={<CardViewIcon style={{ fontSize: 30 }} />} />
            </Tabs>
          </div>
        ) : null}
        {activeTab === 0 && !isLuckeyLite ? (
          <PrivateUnitsTableView
            onNewPrivateUnit={() => this.onNewPrivateUnitButtonClicked()}
            onSelectPrivateUnit={privateUnit => this.onSelectPrivateUnit(privateUnit)}
            onRefreshPrivateUnits={() => this.onFetchPrivateUnits()}
            onFetchPrivateUnitsOnPage={page => this.onFetchPrivateUnitsOnPage(page)}
          />
          ) : null}
        {activeTab === 1 || isLuckeyLite ? (
          <PrivateUnitsListView
            listContainerStyle={{ top: isLuckeyLite ? 140 : 190 }}
            onSelectPrivateUnit={privateUnit => this.onSelectPrivateUnit(privateUnit)}
            onNewPrivateUnit={() => this.onNewPrivateUnitButtonClicked()}
          />
          ) : null}
        <OperationalView
          themeName={themeName}
          isVisible={privateUnit.isOperationalMode}
          onClose={() => this.closeOperationalSection()}
          title={<Entity entity={selectedPrivateUnitID?"privateUnitsOperationalEdit":"privateUnitsOperationalCreate"} />}
        >
          <PrivateUnitsOperationalSection
            onPrivateUnitFormSubmit={() => dispatch(submit('PrivateUnitForm'))}
            onShowInfoModal={() => this.onShowInfoModal()}
            onDeletePrivateUnit={privateUnitId => this.onDeletePrivateUnit(privateUnitId)}
            onDeletePrivateUnitDeviceConfirm={(standardDeviceId) => this.onDeletePrivateUnitDeviceConfirm(standardDeviceId)}
            onPrivateUnitDeviceFormSubmit={(formData) => this.onPrivateUnitDeviceFormSubmit(formData)}
          />
        </OperationalView>
      </div>
    );
  }
} 

export default PrivateUnits;
