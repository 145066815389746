// @ts-nocheck
import { Card, withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { InfoOutlined } from '@material-ui/icons';
import { Form } from '@sketchpixy/rubix';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { change, Field, reduxForm } from 'redux-form';
import { BLUE, LOCKER_RESERVATION_HISTORY_ACTIONS, LOCKER_RESERVATION_HISTORY_ACTIONS_MAP, LOCKER_RESERVATION_STATUSES, LOCKER_RESERVATION_STATUSES_PARAMETERS, LOCKER_TYPE, MATCH_TAG_MODE, RED } from '../../_config/consts.js';
import * as RestService from '../../_config/rest';
import * as LockerActions from '../../redux/actions/locker.actions';
import * as ModalActions from '../../redux/actions/modal.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import ArrowRightIconCustom from '../CustomIcons/ArrowRightIconCustom.jsx';
import EmailSendIconCustom from '../CustomIcons/EmailSendIconCustom.jsx';
import AddCircleOutlineIconCustom from '../CustomIcons/AddCircleOutlineIconCustom.jsx';
import LockersIconCustom from '../CustomIcons/LockersIconCustom.jsx';
import WarningIcon from '@material-ui/icons/Warning';
import PackageIconCustom from '../CustomIcons/PackageIconCustom.jsx';
import ParcelDeliveredIconCustom from '../CustomIcons/ParcelDeliveredIconCustom.jsx';
import ParcelPickedupIconCustom from '../CustomIcons/ParcelPickedupIconCustom.jsx';
import ParcelRoomIconCustom from '../CustomIcons/ParcelRoomIconCustom.jsx';
import ShareIconCustom from '../CustomIcons/ShareIconCustom.jsx';
import FormCardContainer from '../forms/Elements/FormCardContainer.jsx';
import CustomField from '../forms/Fields/CustomField.jsx';
import DatePickerField from '../forms/Fields/DatePickers/SingleDatePicker/DatePickerField.jsx';
import DatePickerFieldCustomBorder from '../forms/Fields/DatePickers/SingleDatePicker/DatePickerFieldCustomBorder.jsx';
import GuestSelector from '../forms/Fields/GuestSelector.jsx';
import MDCheckBoxField from '../forms/Fields/MDCheckBox/MDCheckBoxField.js';
import SelectableField from '../forms/Fields/SelectableInput/SelectableField.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import Tag from '../Tag.jsx';
import CalendarClockIconCustom from '../CustomIcons/CalendarClockIconCustom.jsx';
import DeleteOutlineIconCustom from '../CustomIcons/DeleteOutlineIconCustom.jsx';
import HistoryIconCustom from '../CustomIcons/HistoryIconCustom.jsx';
import EmptyView from '../EmptyView.jsx';

const styles = theme => ({
  attachment: {
    backgroundColor: '#BBBBBB',
    color: 'white',
    padding: '10px 15px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: BLUE,
    }
  },
});

const validate = values => {
  const errors = {};

  if (!values.fromDate) {
    errors.fromDate = 'required';
  }

  if (!values.firstname || !values.newReservationuserId) {
    errors.firstname = 'required';
  }

  if (!values.locker) {
    errors.locker = 'required';
  }

  return errors;
};

@reduxForm({ form: 'DeliveryForm', validate })
@connect(state => ({ lockers: state.lockers, form: state.form.DeliveryForm, language: state.settings.language, companyConfigurations: state.user.companyConfigurations }))
class DeliveryDetailsView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      lockersOptions: [],
      loadingAvailableLockers: false,
    }
  }

  async componentDidMount() {
    const { lockerReservation } = this.props;
    this.init(lockerReservation)
  }

  componentWillReceiveProps(nextProps) {
    const { lockerReservation: lockerReservationOld } = this.props;
    const { lockerReservation } = nextProps;
    if (lockerReservation.id!==lockerReservationOld.id)
      this.init(lockerReservation)
  }

  async init(lockerReservation) {
    const { dispatch, lockers } = this.props;
    const lockersData = (lockers && lockers.data && lockers.data.content) || []
    const foundLocker = (lockerReservation.lockerId && lockersData.find(e=>e.id===lockerReservation.lockerId)) || undefined
    const foundLockerParsed = foundLocker ? { label: foundLocker.name, value: foundLocker.id, item:foundLocker } : null
    dispatch(change('DeliveryForm', 'fromDate', lockerReservation.fromDate ? lockerReservation.fromDate : null));
    dispatch(change('DeliveryForm', 'toDate', lockerReservation.toDate ? (lockerReservation.toDate-1) : null));
    dispatch(change('DeliveryForm', 'locker', foundLockerParsed));
    dispatch(change('DeliveryForm', 'newReservationuserId', lockerReservation && lockerReservation.user && lockerReservation.user.id || null));
    dispatch(change('DeliveryForm', 'firstname', lockerReservation && lockerReservation.id ? {} : null));
  }

  async onChangeGuest(userTag) {
    const { dispatch, form } = this.props;
    const userTagId = userTag ? userTag.id : undefined
    if (userTagId) {
      const userResponse = await RestService.fetchUsersByTags({ tagIds: [userTagId], userTagMatchingMode: MATCH_TAG_MODE.EVERY_TAG });
      if (userResponse.data.content && !_.isEmpty(userResponse.data.content) && userResponse.data.content.length && userResponse.data.content[userResponse.data.content.length-1].id) {
        const newReservationuserId = userResponse.data.content[userResponse.data.content.length-1].id
        dispatch(change('DeliveryForm', 'newReservationuserId', newReservationuserId));
        const fromDate_current = form && form.values && form.values.fromDate
        if (fromDate_current && newReservationuserId)
          this.onFetchAvailableLockers(fromDate_current, newReservationuserId);
        else {
          await dispatch(LockerActions.saveAvailableLockers([]));
          this.onFilterLockers("")
          dispatch(change('DeliveryForm', 'locker', null));
          this.onChangeLocker(null)
        }
      }
      else {
        dispatch(change('DeliveryForm', 'newReservationuserId', null));
        await dispatch(LockerActions.saveAvailableLockers([]));
        this.onFilterLockers("")
        dispatch(change('DeliveryForm', 'locker', null));
        this.onChangeLocker(null)
      }
    }
    else {
      dispatch(change('DeliveryForm', 'newReservationuserId', null));
      await dispatch(LockerActions.saveAvailableLockers([]));
      this.onFilterLockers("")
      dispatch(change('DeliveryForm', 'locker', null));
      this.onChangeLocker(null)
    }
  }

  async onChangeFromDate(value) {
    const { dispatch, form, lockerReservation} = this.props;
    const { } = this.state;

    const fromDate_old = form && form.values && form.values.fromDate
    const toDate_old = form && form.values && form.values.toDate

    const fromDate_new = value ? moment(value).startOf('day').valueOf() : null
    dispatch(change('DeliveryForm', 'fromDate', fromDate_new));
    const locker_current = form && form.values && form.values.locker
    if (locker_current && locker_current.item) {
      const toDate_new = moment(fromDate_new).startOf('day').add(((locker_current.item.pickupDays-1) || 0), 'days').endOf('day').valueOf();
      dispatch(change('DeliveryForm', 'toDate', toDate_new));
    }
    const deliveryId = lockerReservation && lockerReservation.id
    const userId_current = form && form.values && form.values.newReservationuserId
    if (!deliveryId) {
      if (fromDate_new && userId_current)
        this.onFetchAvailableLockers(fromDate_new, userId_current); 
      else {
        await dispatch(LockerActions.saveAvailableLockers([]));
        this.onFilterLockers("")
        dispatch(change('DeliveryForm', 'locker', null));
        this.onChangeLocker(null)
      }
    }
    else {
      const lockerId_current = lockerReservation && lockerReservation.lockerId
      const lockerReservationId = lockerReservation && lockerReservation.id
      if (fromDate_new && userId_current && lockerId_current && lockerReservationId) {
        const lockerAvailableResult = await this.onCheckLockerAvailability(fromDate_new, userId_current, lockerId_current, lockerReservationId);
        if (!lockerAvailableResult) {
          dispatch(change('DeliveryForm', 'fromDate', fromDate_old));
          dispatch(change('DeliveryForm', 'toDate', toDate_old));
          let errorMessage = `lockerReservationDateNotAvailableForThisLocker`;
          dispatch(UtilsActions.setSpinnerVisibile(false));
          dispatch(ModalActions.showModal({
            modalType: 'ERROR_ALERT',
            modalProps: {
              message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
            },
          }));
        }
      }
    }
  }

  async onChangeCreateAsDelivered(value) {}

  async onChangeLocker(value) {
    const { dispatch, form } = this.props;
    dispatch(change('DeliveryForm', 'createAsDelivered', false));
    if (value && value.item) {
      const fromDate_current = form && form.values && form.values.fromDate
      const toDate_new = moment(fromDate_current).startOf('day').add(((value.item.pickupDays-1) || 0), 'days').endOf('day').valueOf();
      dispatch(change('DeliveryForm', 'toDate', toDate_new));
    }
    else {
      dispatch(change('DeliveryForm', 'toDate', null));
    }
  }

  onFilterLockers(value) {
    const { lockers } = this.props;
    const lockersData = (lockers && lockers.availableLockers) || []
    const filteredLockers = _.filter(lockersData, locker => locker.name.toLowerCase().includes(value.toLowerCase()));
    const lockersOptions =  _.map(filteredLockers, locker => ({ label: locker.name, value: locker.id, item:locker }));
    this.setState({ lockersOptions });
    return value;
  }

  onSendReminderEmailRequest(lockerId, reservationId) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        body: <Entity entity="deliveryNotifyUserToPickUpConfirm" />,
        onConfirmText: <Entity entity="confirm" />,
        onConfirm: () => this.onSendReminderEmailConfirm(lockerId, reservationId),
        onCancelText: <Entity entity="cancel" />,
        onCancel: () => {
          dispatch(ModalActions.hideModal())
        },
      },
    };
    dispatch(ModalActions.showModal(params));
  }
  
  async onSendReminderEmailConfirm(lockerId, reservationId) {  
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const response = await dispatch(LockerActions.sendReminderEmail(lockerId, reservationId));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="deliveryNotifyUserToPickUpSuccess"/></h6>),
        },
      }));
    } catch (error) {
      let errorMessage = 'deliveryNotifyUserToPickUpError';
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
    }
  }

  async onFetchAvailableLockers(fromDate, userId) {
    const { dispatch, form } = this.props;
    try {
      this.setState({ loadingAvailableLockers: true });
      await dispatch(UtilsActions.setSpinnerVisibile(true));
      const result = await dispatch(LockerActions.fetchAvailableLockers(fromDate, userId));
      this.onFilterLockers("")

      const locker_current = form && form.values && form.values.locker
      const lockerId = locker_current && locker_current.item && locker_current.item.id
      if (lockerId && !_.find(result, locker => locker.id === lockerId)) {
        dispatch(change('DeliveryForm', 'locker', null));
        this.onChangeLocker(null)
      }

      await dispatch(UtilsActions.setSpinnerVisibile(false));
      this.setState({ loadingAvailableLockers: false });
      return result;
    } 
    catch (error) {
      await dispatch(UtilsActions.setSpinnerVisibile(false));
      this.setState({ loadingAvailableLockers: false });
      return [];
    }
  }

  async onCheckLockerAvailability(fromDate, userId, lockerId, lockerReservationId) {
    const { dispatch, form } = this.props;
    try {
      this.setState({ loadingAvailableLockers: true });
      await dispatch(UtilsActions.setSpinnerVisibile(true));
      const result = await dispatch(LockerActions.fetchAvailableLockers(fromDate, userId, lockerId, lockerReservationId, true));
      await dispatch(UtilsActions.setSpinnerVisibile(false));
      this.setState({ loadingAvailableLockers: false });
      return (result && result.length > 0);
    } catch (error) {
      await dispatch(UtilsActions.setSpinnerVisibile(false));
      this.setState({ loadingAvailableLockers: false });
      return false;
    }
  }

  render() {
    const { 
      classes, dispatch, lockers, form, formStyle, timeUnitsOptions, language,
      availableUserTags, onGuestInputChange, 
      lockerReservation, 
      onDeleteDeliveryRequest,
      onSendToDeliveredRequest,
      onSendToPickedupRequest,
      onSharePin
    } = this.props;
    const { lockersOptions, loadingAvailableLockers } = this.state;

    const deliveryId = lockerReservation && lockerReservation.id
    const isEdit = Boolean(deliveryId)
    const lockerReservationStatus = lockerReservation && lockerReservation.status
    const lockerReservationEditable = lockerReservationStatus===LOCKER_RESERVATION_STATUSES.BOOKED
    const lockerReservationDeletedFromManager = lockerReservationStatus===LOCKER_RESERVATION_STATUSES.DELETED_FROM_MANAGER

    const fromDate_current = form && form.values && form.values.fromDate
    const locker_current = form && form.values && form.values.locker;
    const notes_initial = form && form.initial && form.initial.notes
    const notes_current = form && form.values && form.values.notes
    const newReservationuserId_current = form && form.values && form.values.newReservationuserId

    const lockerId = locker_current && locker_current.item && locker_current.item.id
    const lockerType = locker_current && locker_current.item ? 
      (locker_current.item.type === LOCKER_TYPE.LOCKER ? LOCKER_TYPE.LOCKER : locker_current.item.type === LOCKER_TYPE.PARCEL_ROOM ? LOCKER_TYPE.PARCEL_ROOM : undefined) 
      : undefined
    
    const canSendReminderToPickUp = lockerId && lockerReservationStatus && _.includes([
        LOCKER_RESERVATION_STATUSES.DELIVERING,
        LOCKER_RESERVATION_STATUSES.DELIVERED,
        LOCKER_RESERVATION_STATUSES.EXPIRED,
      ], lockerReservationStatus
    ) 

    const canSendToDelivered = lockerId && lockerType === LOCKER_TYPE.PARCEL_ROOM && lockerReservationStatus && _.includes([
        LOCKER_RESERVATION_STATUSES.BOOKED,
        LOCKER_RESERVATION_STATUSES.DELIVERING,
      ], lockerReservationStatus
    ) 

    const canSendToPickedup = lockerId && lockerType === LOCKER_TYPE.PARCEL_ROOM && lockerReservationStatus && _.includes([
        LOCKER_RESERVATION_STATUSES.DELIVERED,
      ], lockerReservationStatus
    ) 

    const canDelete = isEdit && !lockerReservationDeletedFromManager && lockerId

    const deliveredStatusString = LOCKER_RESERVATION_STATUSES_PARAMETERS[LOCKER_RESERVATION_STATUSES.DELIVERED] ? 
      L20NContext.getSync(LOCKER_RESERVATION_STATUSES_PARAMETERS[LOCKER_RESERVATION_STATUSES.DELIVERED].string || "") : "Delivered"

    const pickedUpStatusString = LOCKER_RESERVATION_STATUSES_PARAMETERS[LOCKER_RESERVATION_STATUSES.PICKED_UP] ? 
      L20NContext.getSync(LOCKER_RESERVATION_STATUSES_PARAMETERS[LOCKER_RESERVATION_STATUSES.PICKED_UP].string || "") : "Picked up"

    const reservationLock = lockerReservation && lockerReservation.lock
    const reservationLockTag = reservationLock ? {
      color: '#ba68c8',
      name: reservationLock.name ? reservationLock.name.toLowerCase() : '',
      type: 'lock',
    } : undefined;

    const reservationPin = lockerReservation && lockerReservation.pinCode

    const createAsDelivered = form && form.values && form.values.createAsDelivered
    
    const userTag_edit = lockerReservation && lockerReservation.user && lockerReservation.user.tags && lockerReservation.user.tags.find(e=>e.type==='user')

    const history = _.get(lockerReservation, 'history', []).filter(
      (historyItem) => _.includes(LOCKER_RESERVATION_HISTORY_ACTIONS, historyItem.action)
    );

    const historyParsed = [...history].reverse();

    return (
      <Form style={formStyle} autoComplete="off" style={{ padding: 20, paddingBottom: 120 }}>
        <FormCardContainer
          title="deliveryData"
          subtitle={!isEdit && 'createDeliverySubscriptionPickupPoint'}
          icon={<PackageIconCustom style={{ width: 24, color: '#3f3f3f' }} />}
          iconButtonAction={null}
        >
          {!isEdit ? (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 20  }}>
              <InfoOutlined style={{ fontSize: 20, color: '#3f3f3f', marginRight: 5 }} />
              <h4 style={{ paddingLeft: 0, margin: 0, fontSize:16, color:'#3F3F3F' }}>
                <Entity entity="pinCreateAfterDeliveryCreation" />
              </h4>
            </div>
          ) : null}
          <div style={{display: 'none', width: 0,height: 0, position: 'absolute' }}>
            <Entity
              componentClass={Field}
              name="newReservationUserId"
              componentAttribute="placeholder"
              entity="insertName"
              component={CustomField}
              containerStyle={{pointerEvents:'none'}}
            />
          </div>
          {isEdit ? (
            <div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div>
                  <h4 style={{ paddingLeft: 0, margin: 0, marginTop: 10, marginBottom: 5, fontWeight: 'bold', color:'rgb(63,63,63)', fontSize:'16px' }}>
                    <Entity entity={"lockerReservationPin"} />
                  </h4>
                  <h4 style={{ paddingLeft: 0, margin: 0, marginTop: 5, marginBottom: 25, color:'rgb(63,63,63)', fontSize:'17px' }}>
                    {reservationPin || ""}
                  </h4>
                </div>
                <IconButton
                style={{ marginTop: 5 }}
                onClick={(e) => onSharePin(reservationPin)}>
                  <ShareIconCustom style={{ width: 20, color: BLUE }} />
                </IconButton>
              </div>
              <div style={{ marginBottom: 20 }}>
                <h4 style={{ paddingLeft: 0, margin: 0, marginTop: 0, marginBottom: 8, fontWeight: 'bold', color:'rgb(63,63,63)', fontSize:'16px' }}>
                  <Entity entity={"lockerReservationStatus"} />
                </h4>
                {lockerReservation && lockerReservation.status ? (
                  <h5 style={{ fontWeight: 'bold', margin: 0 }}>
                    <Entity
                      key={`status_${lockerReservation.status}`}
                      style={{ textTransform: 'uppercase', color: LOCKER_RESERVATION_STATUSES_PARAMETERS[lockerReservation.status].color }}
                      entity={LOCKER_RESERVATION_STATUSES_PARAMETERS[lockerReservation.status].string}
                    />
                  </h5>
                ) : null}
              </div>
            </div>
          ) : null}
          {isEdit ? (
            <div>
              <h4 style={{ paddingLeft: 0, margin: 0, marginTop: 0, marginBottom: 0, fontWeight: 'bold', color:'rgb(63,63,63)', fontSize:'16px' }}>
                <Entity entity="lockerReservationInsertGuest"/>
              </h4>
              <div style={{marginBottom:25, marginLeft:-5}}>
                {userTag_edit && 
                  <Tag
                    key={userTag_edit.id}
                    tag={userTag_edit}
                  />
                }
              </div>
            </div>
          ) : (
            <Field
              title={<Entity entity="lockerReservationInsertGuest" />}
              id="first"
              name="firstname"
              singleTag
              tags={availableUserTags.filter(e=>e.type==='user')}
              guests={undefined}
              placeholder={<Entity entity="lockerReservationInsertGuestDescription" />}
              component={GuestSelector}
              onInputChange={value => onGuestInputChange(value)}
              onHandleChange={value => {this.onChangeGuest(value)}}
              mandatory
            />
          )}
          <div style={{display:'flex', flexDirection:'column'}}>
            <h4 style={{ paddingLeft: 0, margin: 0, marginTop: 0, marginBottom: 8, fontWeight: 'bold', fontSize: 16, color:'#3F3F3F' }}>
              <Entity key={(!isEdit || lockerReservationEditable)} entity={"lockerReservationDateFrom"}/>
              { (!isEdit || lockerReservationEditable) ? <span style={{ color: 'red' }}>*</span> : null }
            </h4>
            <div style={{ display:'flex', flexDirection:'row', alignItems:'end', marginBottom: 15 }}>
              <Field
                title={null}
                subtitle={null}
                id="fromDate"
                name="fromDate"
                placeholder='---'
                dateContainer={{ marginRight: 20 }}
                onHandleChange={value => {this.onChangeFromDate(value)}}
                component={DatePickerFieldCustomBorder}
                hideIcon={true}
                disabled={false}
                isOutsideRange={date => date.isBefore(moment().startOf('day'))}
                mandatory={(!isEdit || lockerReservationEditable)}
                hideErrorMessage={true}
                useErrorBorder={true}
                containerStyle={{ width: 150, marginLeft:-1, pointerEvents: (!isEdit || lockerReservationEditable) ? 'unset' : 'none' }}
              />
            </div>
          </div>
          {isEdit || (fromDate_current && newReservationuserId_current) ? (
            <>
              {isEdit ? (
                <div style={{display:'flex', flexDirection:'row', alignItems:'end'}}>
                  <div>
                    <h4 style={{ paddingLeft: 0, margin: 0, marginTop: 10, marginBottom: 5, fontWeight: 'bold', color:'rgb(63,63,63)', fontSize:'16px' }}>
                      <Entity entity={"lockerReservationInsertPickupPoint"} />
                    </h4>
                    <h4 style={{ paddingLeft: 0, margin: 0, marginTop: 5, marginBottom: 25, color:'rgb(63,63,63)', fontSize:'17px', display:'flex', alignItems:'center' }}>
                      {lockerType === LOCKER_TYPE.LOCKER ? 
                        <LockersIconCustom style={{ width: 22, color: '#3f3f3f', marginRight: 7 }}/> : 
                        lockerType === LOCKER_TYPE.PARCEL_ROOM ? 
                        <ParcelRoomIconCustom style={{ width: 22, color: '#3f3f3f', marginRight: 7 }}/> : 
                        null
                      }
                      {locker_current && locker_current.item ? locker_current.item.name : ""}
                    </h4>
                  </div>
                  {canSendToDelivered && 
                    <MDButton
                      title={
                        <Entity 
                          entity="lockerReservationMarkAsDelivered" 
                          data={{deliveredName: deliveredStatusString}} 
                        />
                      }
                      containerStyle={{ margin: 0, marginBottom: 28, marginLeft: 80, width:'fit-content' }}
                      icon={<ParcelDeliveredIconCustom style={{ width: 15, color: 'white', marginLeft: 10, marginTop: 0 }} />}
                      style={{ minHeight: 30 }}
                      backgroundColor={BLUE}
                      onClick={() => onSendToDeliveredRequest(deliveredStatusString)}
                    />
                  }
                  {canSendToPickedup && 
                    <MDButton
                      title={
                        <Entity 
                          entity="lockerReservationMarkAsPickedup" 
                          data={{pickedupName: pickedUpStatusString}} 
                        />
                      }
                      containerStyle={{ margin: 0, marginBottom: 28, marginLeft: 80, width:'fit-content' }}
                      icon={<ParcelPickedupIconCustom style={{ width: 15, color: 'white', marginLeft: 10, marginTop: 0 }} />}
                      style={{ minHeight: 30 }}
                      backgroundColor={BLUE}
                      onClick={() => onSendToPickedupRequest(pickedUpStatusString)}
                    />
                  }
                </div>
              ) : (
                loadingAvailableLockers || (lockersOptions && lockersOptions.length > 0) ? (
                  <Field
                    name="locker"
                    title={<Entity entity="lockerReservationPickupPoint" />}
                    subtitle={null}
                    component={SelectableField}
                    options={lockersOptions}
                    placeholder={<Entity entity="lockerReservationInsertPickupPointDescription" />}
                    containerstyle={{ marginBottom: 20, marginTop: -10 }}
                    helpText={undefined}
                    onSelect={locker => {this.onChangeLocker(locker)}}
                    onInputChange={name => this.onFilterLockers(name)}
                    mandatory={!isEdit}
                  />
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 14, marginLeft: 0, marginTop: 10 }}>
                    <WarningIcon style={{ color: 'orange', marginRight: 5, marginLeft: 10, fontSize: 18 }} />
                    <h4 style={{ color: '#3f3f3f', lineHeight: '1.2em', margin: 0, fontSize: 16, fontWeight: 'bold' }}>
                      <Entity entity="lockerReservationNoAvailableLockers"/>
                    </h4>
                  </div>
                )
              )}
            </>
          ) : null}
          {reservationLock && lockerType === LOCKER_TYPE.LOCKER ? (
            <div>
              <h4 style={{ paddingLeft: 0, margin: 0, marginTop: 10, marginBottom: 0, fontWeight: 'bold', color:'rgb(63,63,63)', fontSize:'16px' }}>
                <Entity entity="lockerReservationSlot"/>
              </h4>
              <div style={{marginBottom:25, marginLeft:-5}}>
                <Tag textStyle={{ fontSize: 12 }} style={{ paddingTop: 4, paddingBottom: 4 }} tag={reservationLockTag} />
              </div>
            </div>
          ) : null}
          {isEdit || (fromDate_current && newReservationuserId_current && locker_current) ? (
            <Field
              title={<Entity entity="lockerReservationDateTo" />}
              id="toDate"
              name="toDate"
              placeholder='---'
              dateContainer={{ marginRight: 20 }}
              onHandleChange={() => {}}
              component={DatePickerField}
              disabled={false}
              hideIcon={true}
              containerStyle={{ width: 150, marginLeft:-1, pointerEvents: 'none' }}
            />
          ) : null}
          {!isEdit && lockerType === LOCKER_TYPE.PARCEL_ROOM ? (
            <div style={{ marginTop: 20, marginBottom: 10 }}>
              <Field
                name="createAsDelivered"
                label={<Entity entity="lockerReservationCreateAsDelivered" />}
                titleStyle={{ fontSize: 16, fontWeight: 'bold', color: '#3f3f3f' }}
                containerstyle={{ marginTop: 0 }}
                component={MDCheckBoxField}
                onHandleChange={value => this.onChangeCreateAsDelivered(value)}
              />
              <h4 style={{ marginTop: -10, lineHeight: 1.5, marginLeft: 35, fontSize: 15, color: '#3f3f3f', fontWeight: 'light' }}>
                <Entity 
                  entity="lockerReservationCreateAsDeliveredDescription" 
                  data={{deliveredName: deliveredStatusString}} 
                />
              </h4>
            </div>
          ) : null}
          {isEdit || (fromDate_current && newReservationuserId_current && locker_current) ? (
            <>
              {isEdit ? (
                <div style={{ marginTop: 20 }}>
                  <h5 style={{ color: '#3f3f3f', fontWeight: 'bold', fontSize: 16, }}>{<Entity entity="itemNotes" />}</h5>
                  <h5>{notes_initial || '---'}</h5>
                </div>
              ) : (
                <Entity
                  componentClass={Field}
                  name="notes"
                  componentAttribute="placeholder"
                  entity="insertDeliveryDescription"
                  title={<Entity entity="itemNotes" />}
                  component={CustomField}
                  mandatory={false}
                  type="textarea"
                  containerStyle={{pointerEvents:!isEdit?'unset':'none', marginTop: 15 }}
                />
              )}
            </>
          ) : null}
          {isEdit && canSendReminderToPickUp ? (
            <div style={{display:'flex', flexDirection:'row', alignItems:'end', marginTop: 20}}>
              <div>
                <h4 style={{ paddingLeft: 0, margin: 0, marginTop: 0, marginBottom: 8, fontWeight: 'bold', fontSize:16, color:'#3F3F3F' }}>
                  <Entity entity={"deliveryNotifyUserToPickUp"}/>
                </h4>
                <h4 style={{ paddingLeft: 0, margin: 0, marginTop: 0, marginBottom: 5, color:'rgb(63,63,63)', fontSize:'14px' }}>
                  {<Entity entity="deliveryNotifyUserToPickUpDescription" />}
                </h4>
              </div>
              <MDButton
                title={<Entity entity="deliveryNotifyUserToPickUp" />}
                containerStyle={{ margin: 0, marginRight: 20, marginBottom: 5, marginLeft: 30, width:'fit-content' }}
                icon={<EmailSendIconCustom style={{ width: 15, color: 'white', marginLeft: 10, marginTop: 0 }} />}
                style={{ minHeight: 30 }}
                backgroundColor={BLUE}
                onClick={() => this.onSendReminderEmailRequest(lockerId, deliveryId)}
              />
            </div>
          ) : null}
        </FormCardContainer>
        {isEdit && (
          <FormCardContainer
            title="lockerReservationHistory"
            icon={<HistoryIconCustom style={{ width: 24, color: '#3f3f3f' }} />}
          >
            {historyParsed && historyParsed.length > 0 ? (
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#f6f6f6', borderRadius: 10, paddingLeft: 5, paddingRight: 15 }}>
                    {_.map(historyParsed, (historyItem, i) => (
                      <>
                      {LOCKER_RESERVATION_HISTORY_ACTIONS_MAP[historyItem.action] ? (
                        <div 
                          key={i} 
                          style={{ display: 'flex', alignItems: 'center', padding: '15px 0', borderBottom: i !== historyParsed.length - 1 ? '1px solid #e0e0e0' : 'none'}}
                        >
                          {LOCKER_RESERVATION_HISTORY_ACTIONS_MAP[historyItem.action]?.icon}
                          <div style={{flex: 1, color: '#3f3f3f', fontSize: '16px', paddingRight: '15px'}}>
                            {L20NContext.getSync(LOCKER_RESERVATION_HISTORY_ACTIONS_MAP[historyItem.action]?.label || "")}
                          </div>
                          <h4 style={{ minWidth: 240, textAlign: 'right', marginTop: 10, color: '#3f3f3f', fontSize: 15 }}>
                            {moment(historyItem.time).format('LLL')}
                          </h4>
                        </div>
                      ) : null}
                      </>
                    ))}
                  </div>
            ) : (
              <EmptyView
                title="noDeliveryHistoryFound"
                subtitle="noDeliveryHistoryDescription"
                containerStyle={{ marginTop: 20, marginBottom: 20 }}
              />
            )}
          </FormCardContainer>
        )}
        {canDelete &&
          <FormCardContainer
            title={"lockerReservationDelete"}
            subtitle={"lockerReservationDeleteWarning"}
          >
            <Entity
              componentClass={Field}
              name="notes"
              title={<Entity entity="lockerReservationDeletionNotes" />}
              componentAttribute="placeholder"
              entity="lockerReservationDeletionNotesSubtitle"
              component={CustomField}
              type="textarea"
              className="form-control-custom"
              mandatory={true}
              containerStyle={{}}
            />
            <MDButton
              title={<Entity entity="lockerReservationDelete" />}
              backgroundColor={RED}
              style={{opacity:notes_current?1:0.7}}
              onClick={() => onDeleteDeliveryRequest(notes_current)}
              disabled={!notes_current}
            />
          </FormCardContainer>
        }
      </Form>
    );
  }
} 

export default withStyles(styles)(DeliveryDetailsView);
