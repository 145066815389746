import { Box, Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Autorenew';
import NewFromTemplateIcon from '@material-ui/icons/FileCopyOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Warning from '@material-ui/icons/Warning';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { BLUE, CARD_TYPES, CREDENTIAL_RULE_STATUSES, PERMISSIONS, PERMISSION_ENTITIES, RESERVATION_STATUSES, RESERVATION_STATUSES_PARAMETERS } from '../../_config/consts';
import { getColorFromThemeName, isUserWithoutEmail } from '../../_config/utils';
import AbilityProvider from '../../permissionsUtils/AbilityProvider';
import RemoveEmailIconCustom from '../CustomIcons/RemoveEmailIconCustom.jsx';
import EmptyView from '../EmptyView.jsx';
import ListLoadingView from '../ListLoadingView.jsx';
import MDTablePaginationActions from '../MDTable/MDTablePaginationActions.jsx';
import Tag from '../Tag.jsx';
import { InfoOutlined } from '@material-ui/icons';

const styles = theme => ({
  table: {
    overflow: 'scroll',
  },
  tableColumnText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  disabledInput: {
    display: 'none',
  },
  guestTag: {
    padding: 3,
  },
  rowSelected: {
    backgroundColor: 'aliceblue !important',
    color: 'aliceblue !important',
  },
  rowHovered: {
    cursor: 'pointer',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  button: {
    color: '#E53935',
    fontSize: 13,
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  guestRowContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowWithAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowActionButton: {
    width: 40,
    height: 40,
  },
  rowActionButtonIcon: {
    fontSize: 20,
    color: '#E53935',
  },
  paginationText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
  buttonPositive: {
    color: BLUE,
    fontSize: 13,
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginLeft: -15,
    width: 'max-content',
  },
});

@connect((state) => ({ viewLoading: state.utils.viewLoading, companyConfigurations: state.user.companyConfigurations, themeName: state.settings.items.theme.data.themeName }))
class PrivateUnitCardsTableView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

  }

  render() {
    const {
      classes,
      cards,
      onSelectCard,
      themeName,
    } = this.props;
    const {} = this.state;

    return (
      <div className="list-view details-section" style={{ top: 190, paddingBottom: 40, paddingLeft: 0, paddingRight: 0, width: '100%', position:'unset' }}>
        <div>
          {!_.isEmpty(cards) ?
            <Card style={{ overflow: 'scroll' }}>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow style={{backgroundColor:BLUE}}>
                    <TableCell><h4 className={classes.tableColumnText} style={{color:'white'}}><Entity entity="privateUnitDeviceId" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText} style={{color:'white'}}><Entity entity="privateUnitDeviceType" /></h4></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(cards, (card) => {
                    return (
                        <TableRow
                          key={card.id}
                          className={classes.row}
                          hover
                          classes={{
                            selected: classes.rowSelected,
                            hover: classes.rowHovered,
                          }}
                          onClick={event => onSelectCard(card)}
                          selected={false}
                        >
                          <TableCell>
                            <div className={classes.guestRowContainer} style={{ minWidth: 150 }}>
                              <h5 style={{ flex: 1, fontSize: 16, fontWeight: 'bold', color: '#3f3f3f' }}>
                                {`#${card.deviceId}`}
                              </h5>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className={classes.guestRowContainer} style={{ minWidth: 150 }}>
                              <h5 style={{ flex: 1, fontSize: 16, fontWeight: 'bold', color: '#3f3f3f' }}>
                                {<Entity entity="cardTypes" data={{ type: card.deviceType?card.deviceType:CARD_TYPES.GENERIC_CARD }} />}
                              </h5>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
              </Table>
            </Card>
            : (
              <div>
                
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(PrivateUnitCardsTableView);
