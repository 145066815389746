import React from 'react';
import { connect } from 'react-redux';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import * as PrivateUnitActions from '../../redux/actions/privateUnit.actions';
import * as TagActions from '../../redux/actions/tag.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import * as ModalActions from '../../redux/actions/modal.actions.js';
import PrivateUnitForm from '../forms/PrivateUnitForm.jsx';

@connect((state) => ({
  privateUnit: state.privateUnit.selectedPrivateUnit,
  userTags: state.tags.user.data,
  privateUnitForm: state.form.PrivateUnitForm,
  lockTags: state.tags.lock.data,
  language: state.settings.language,
}))
class PrivateUnitsOperationalSection extends React.Component {


  componentWillMount() {
    const { dispatch } = this.props;
    const includeSpecialTags = true;
    dispatch(TagActions.fetchGuestTags(includeSpecialTags, null, 0, 20));
    dispatch(TagActions.fetchLockTags(includeSpecialTags, 0, 20));
    
    this.fetchLocksByTags()
  }

  async savePrivateUnit(values) {
    const { dispatch } = this.props;
    const isBatchCreation = values.batchCreation
    const parsedValues = isBatchCreation ? {
      ...values,

      lockTagIds: values.lockTags?_.map(values.lockTags,e=>{return e.id}):[],
      userTagIds: values.guestTags?_.map(values.guestTags,e=>{return e.id}):[],
      
      ownerUserId: undefined,

      placeholder: values.name,
      fromNumber: values.batchCreationFrom,
      toNumber: values.batchCreationTo,
      name: `${values.name} {{PLACEHOLDER}}`,
      placeholder: `{{PLACEHOLDER}}`,

      owner: undefined,
      ownerUser: undefined,
      guestTagMatchingMode: undefined,
      selectedLocksTags: undefined,
      selectedGuestTags: undefined,
      lockTags: undefined,
      guestTags: undefined,
      batchCreation: undefined,
      batchCreationFrom: undefined,
      batchCreationTo: undefined,
    } : {
      ...values,

      lockTagIds: values.lockTags?_.map(values.lockTags.filter(e=>e.type!=="private_unit"),e=>{return e.id}):[],
      userTagIds: values.guestTags?_.map(values.guestTags.filter(e=>e.type!=="private_unit"),e=>{return e.id}):[],

      ownerUserId: (values.ownerUser && values.ownerUser.id) || undefined,
      
      owner: undefined,
      ownerUser: undefined,
      guestTagMatchingMode: undefined,
      selectedLocksTags: undefined,
      selectedGuestTags: undefined,
      lockTags: undefined,
      guestTags: undefined,
      batchCreation: undefined,
      batchCreationFrom: undefined,
      batchCreationTo: undefined,
    }
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      if (values.id) {
        await dispatch(PrivateUnitActions.editPrivateUnit(values.id,parsedValues));
      } else {
        if (isBatchCreation)
          await dispatch(PrivateUnitActions.createPrivateUnitsBatch(parsedValues));
        else
          await dispatch(PrivateUnitActions.createPrivateUnit(parsedValues));
      }
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.closeOperationalSection()
    }
  }

  fetchLocksByTags(page, append) {
    const { dispatch } = this.props;
    dispatch(PrivateUnitActions.cancelFetchLocksByTags());
    dispatch(PrivateUnitActions.fetchLocksByTagsFromForm(page, append));
  }

  filterGuestTags(value) {
    const { dispatch } = this.props;
    const includeSpecialTags = true;
    dispatch(TagActions.cancelFetchGuestTagsByFilter());
    dispatch(TagActions.fetchGuestTagsByFilter(value, 50, includeSpecialTags));
  }

  filterLockTags(value) {
    const { dispatch } = this.props;
    const includeSpecialTags = true;
    dispatch(TagActions.cancelFetchLockTagsByFilter());
    dispatch(TagActions.fetchLockTagsByFilter(value, 200, includeSpecialTags));
  }


  closeOperationalSection() {
    const { dispatch } = this.props;
    dispatch(PrivateUnitActions.setOperationalMode(false));
    dispatch(PrivateUnitActions.fetchPrivateUnits());
  }



  render() {
    const { onPrivateUnitFormSubmit, userTags, lockTags, privateUnit, onDeletePrivateUnit, onDeletePrivateUnitDeviceConfirm, onPrivateUnitDeviceFormSubmit } = this.props;
    return (
      <div style={{ marginBottom: 300 }}>
        <PrivateUnitForm
          availableUserTags={userTags}
          availableLockTags={lockTags}
          locksFilteredByTags={(privateUnit && privateUnit.locksFilteredByTags) || []}
          onFetchMoreLocks={page => this.fetchLocksByTags(page, true)}
          onGuestInputChange={value => this.filterGuestTags(value)}
          onLockInputChange={value => this.filterLockTags(value)}
          onLockTagsChange={() => this.fetchLocksByTags()}
          onLockFilterModeChange={() => this.fetchLocksByTags()}
          onSubmit={values => this.savePrivateUnit(values)}
          onDeletePrivateUnit={privateUnitId => onDeletePrivateUnit(privateUnitId)}
          onDeletePrivateUnitDeviceConfirm={standardDeviceId => onDeletePrivateUnitDeviceConfirm(standardDeviceId)}
          onPrivateUnitDeviceFormSubmit={formData => onPrivateUnitDeviceFormSubmit(formData)}
          onPrivateUnitFormSubmit={() => onPrivateUnitFormSubmit()}
        />
      </div>
    );
  }
} 

export default PrivateUnitsOperationalSection;
