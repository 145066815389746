// @ts-nocheck
import Warning from '@material-ui/icons/Warning.js';
import { Form } from '@sketchpixy/rubix';
import { Entity, ctx as L20NContext  } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import { CARD_TYPES, ORANGE, PERMISSIONS, PERMISSION_ENTITIES, RED } from '../../_config/consts.js';
import MDButton from '../MDButton/MDButton.jsx';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline';
import Permission from '../Permission/Permission.jsx';
import CustomField from './Fields/CustomField.jsx';
import { MaximizeTwoTone } from '@material-ui/icons';
import * as ModalActions from '../../redux/actions/modal.actions';
import TranslatableOption from './Fields/TranslatableOption.jsx';
import { localizeHelpCenterLink } from '../../_config/utils.js';


const styles = theme => ({
  button: {
    color: 'red',
    fontSize: 13,
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginLeft: -15,
    width: 'max-content',
  },
});

const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = 'required';
  } else if (_.trim(values.name).length > 50) {
    errors.name = 'ticketCategoryTooLong';
  }
  return errors;
};

@reduxForm({ form: 'PrivateUnitDeviceForm', validate })
@connect(state => ({ form: state.form.PrivateUnitDeviceForm }))
class PrivateUnitDeviceForm extends React.Component {
  
  onDeletePrivateUnitDeviceRequest() {
    const { dispatch, form, onDeletePrivateUnitDeviceConfirm } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        body: <Entity entity="deletePrivateUnitDeviceConfirm" />,
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => onDeletePrivateUnitDeviceConfirm(form.values.id),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  onShowCardTypeInfo() {
    const helpURL = localizeHelpCenterLink('supported-rfid-cards');
    window.open(helpURL);
  }

  render() {
    const { onValidateForm, form, classes } = this.props;

    const cardTypes = _.omit(CARD_TYPES, CARD_TYPES.ISEO_PIN)

    return (
      <Form style={{ paddingLeft: 15, paddingRight: 15, marginTop: 20 }}>
        <input style={{ display: 'none' }} type="number" name="id" />
        <div style={{display:'flex',flexDirection:'column'}}>
          <div style={{width:400}}>
            <Entity
              componentClass={Field}
              name="deviceId"
              componentAttribute="placeholder"
              entity="privateUnitDeviceIdInsert"
              title={<Entity entity="privateUnitDeviceId" />}
              component={CustomField}
              disabled={Boolean(form?.values?.id)}
              type="text"
              mandatory
            />
            <Field
              id="deviceType"
              name="deviceType"
              title={<Entity entity="privateUnitDeviceType" />}
              component={CustomField}
              className="form-control-select"
              componentClass="select"
              disabled={Boolean(form?.values?.id)}
              helpText={(
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 10 }} onClick={() => this.onShowCardTypeInfo()}>
                  <InfoIcon style={{ marginRight: 5, color: 'darkblue' }} />
                  <h3 className="link-label" style={{ color: 'darkblue', fontWeight: '100', fontSize: 13, margin: 0 }}>{<Entity entity="cardTypeHelp" />}</h3>
                </div>
              )}
            >
              {_.map(cardTypes, (type, index) =>
                <Entity
                  key={type}
                  componentClass={TranslatableOption}
                  value={type}
                  componentAttribute="text"
                  entity="cardTypes"
                  data={{ type }}
                />)
              }
            </Field>
          </div>
          {form?.values?.id?(
            <MDButton
              backgroundColor={RED}
              title={<Entity entity="deletePrivateUnitDevice" />}
              onClick={() => this.onDeletePrivateUnitDeviceRequest()}
              icon={<DeleteIcon style={{ color: 'white', marginLeft: 10, fontSize: 15 }} />}
            />
          ):(
            <MDButton
              title={<Entity entity="save" />}
              onClick={() => onValidateForm()}
            />
          )}
        </div>
      </Form>
    );
  }
} 

export default withStyles(styles)(PrivateUnitDeviceForm);