// @ts-nocheck
import { Card, CircularProgress, createTheme, IconButton, withStyles } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import RefreshIcon from '@material-ui/icons/Autorenew';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import {
  FormControl
} from '@sketchpixy/rubix';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { BLUE, GREEN } from '../../_config/consts';
import UserDisableIconCustom from '../CustomIcons/UserDisableIconCustom.jsx';
import UsersIconCustom from '../CustomIcons/UsersIconCustom.jsx';
import SimpleExportMenu from '../ExportMenus/SimpleExportMenu.jsx';

const styles = theme => ({
  titleText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginTop: 5,
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '99.9%',
    borderRadius: 10,
    padding: 15,
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    overflow: 'visible'
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerTextIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  icon: {
    fontSize: 40,
    color: '#3f3f3f',
  },
  countText: {
    color: '#3f3f3f',
    margin: 0,
    fontSize: 35,
  },
  progress: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    left: '50%',
    position: 'absolute',
    height: '38px !important',
    width: '38px !important',
    zIndex: 10000,
  },
  progressAreaOccupancy: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    height: '30px !important',
    width: '30px !important',
  },
  headerText: {
    alignSelf: 'flex-start',
    fontWeight: 'bold',
  },
  chartContainer: {
    width: '100%',
    maxHeight: 400,
    overflowY: 'scroll',
    marginTop: 10,
  },
  refreshHeader: {
    position: 'absolute',
    right: 10,
    top: 5,
    display: 'flex',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  refreshIcon: {
    fontSize: 30,
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    width: '30%',
    marginTop: 10,
    borderRadius: 5,
  },
  guestRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  searchHeader: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  tableColumnText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

const EmptyAnalyticsView = () => (
  <div style={{ height: 100, flexDirection: 'column', margin: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <UserDisableIconCustom style={{ color: '#bdbdbd', width: 35 }} />
    <h3 style={{ color: '#bdbdbd', fontWeight: 'bold', marginTop: 0, fontSize: 18 }}><Entity entity="noActiveUsersFound" /></h3>
  </div>
);

class DailyActiveUsersList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      filteredUsers: props.users,
      filterText: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    const { users: newUsers } = nextProps;
    const { users: oldUsers } = this.props;
    if (newUsers !== oldUsers) {
      this.setState({ filteredUsers: newUsers });
    }
  }

  onSearchUsers(text) {
    const { users } = this.props;
    this.setState({ filterText: text });
    const filteredUsers = _.filter(users, user => user.firstname.toLowerCase().indexOf(text) > -1 || user.lastname.toLowerCase().indexOf(text) > -1 || user.email.toLowerCase().indexOf(text) > -1);
    this.setState({ filteredUsers });
  }

  onSearchReset() {
    const { users } = this.props;
    this.setState({ filterText: '', filteredUsers: users });
  }

  render() {
    const { users, classes, textColor, isRefreshingAccessChartData, isHeaderHidden, onExportActiveUsersList, lastUpdate, onShowGuestLogs, onRefreshMetric, isCapacityManagementActive, isRefreshingAreasOccupancy, areasData, areasOccupancy, accessesFilterEndDate } = this.props;
    const { filteredUsers, filterText } = this.state;
    const showCurrentArea = isCapacityManagementActive && accessesFilterEndDate>=moment().valueOf()
    return (
      <MuiThemeProvider theme={theme}>
        <Card className={classes.container} style={{ opacity: isRefreshingAccessChartData ? 0.5 : 1 }} elevation={3}>
          {!isHeaderHidden ? (
            <div className={classes.header}>
              <h3 className={classes.titleText} style={{ color: textColor || '#3f3f3f' }}><Entity entity="dailyActiveUsers" /></h3>
              <div className={classes.headerTextIconContainer}>
                <UsersIconCustom style={{ width: 35, color: textColor || '#3f3f3f' }} />
                <h2 className={classes.titleText} style={{ marginTop: 0, marginLeft: 10, color: textColor || '#3f3f3f' }}>{_.size(users)}</h2>
              </div>
            </div>
          ) : null}
          {users && !_.isEmpty(users) ? (
            <div className={classes.searchHeader}>
              <Card className={classes.searchContainer}>
                <FormControl
                  type="text"
                  value={filterText}
                  placeholder={L20NContext.getSync('guestNameSearch')}
                  className="form-control-noBorder"
                  style={{ width: '100%', borderRadius: 5 }}
                  onChange={e => this.onSearchUsers(e.target.value)}
                />
                {filterText ? (
                  <IconButton style={{ height: 20, width: 20, marginLeft: -60, marginRight: 10 }} onClick={() => this.onSearchReset()}>
                    <CloseIcon />
                  </IconButton>
                ) : null}
                <SearchIcon style={{ marginLeft: filterText ? 0 : -30 }} />
              </Card>
              <SimpleExportMenu
                titleContainerStyle={{ marginLeft: 25, marginTop: 5 }}
                iconStyle={{ color: textColor || GREEN }}
                exportTitleStyle={{ color: textColor || GREEN }}
                onExportLogs={format => onExportActiveUsersList(format)}
              />
            </div>
          ) : null}
          <div className={classes.chartContainer}>
            {filteredUsers && !_.isEmpty(filteredUsers) ? (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="guest" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="firstAccess" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="lastAccess" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="lastAccessLock" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="guestDetailsEvents" /></h5></TableCell>
                    {showCurrentArea && 
                      <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="area" /></h5></TableCell>
                    }
                  </TableRow>
                </TableHead>
              <TableBody>
                {_.map(filteredUsers, (user) => {
                  const currentAreasActive = showCurrentArea?areasOccupancy.filter(e=>e.userIds&&e.userIds.some(f=>f===user.id)):[];
                  const currentAreasExpired = showCurrentArea?areasOccupancy.filter(e=>e.expiredIds&&e.expiredIds.some(f=>f===user.id)):[];
                  const currentAreasParsed = [
                    ...currentAreasActive.map(e=>{return {id:e.id,active:true,name:areasData.filter(f=>f.id===e.id).length>0?areasData.filter(f=>f.id===e.id)[0].name:undefined}}),
                    ...currentAreasExpired.map(e=>{return {id:e.id,active:false,name:areasData.filter(f=>f.id===e.id).length>0?areasData.filter(f=>f.id===e.id)[0].name:undefined}}),
                  ]
                  return (
                  <TableRow
                    key={user.id}
                    className={classes.row}
                    hover
                    classes={{
                      selected: classes.rowSelected,
                      hover: classes.rowHovered,
                    }}
                    onClick={() => onShowGuestLogs(user)}
                  >
                    <TableCell>
                      <h5 style={{ color: '#3f3f3f' }}>
                        {user.invitationCode ? <Entity entity="invitationCodeLog" data={{ code: user.invitationCode }} /> :
                      (
                        `${user.firstname || '--'} ${user.isPrivateUnitUser ? '' : (user.lastname || '--')} ${user.isPrivateUnitUser?"":"-"} ${user.isPrivateUnitUser ? '' : (user.email || '--')}`
                      )}
                      </h5>
                    </TableCell>
                    <TableCell>
                      <h5 style={{ margin: 0, color: '#3f3f3f' }}>{user.firstAccessTimestamp ? moment(user.firstAccessTimestamp).format('LLL') : '---'}</h5>
                    </TableCell>
                    <TableCell>
                      <h5 style={{ margin: 0, color: '#3f3f3f' }}>{user.lastAccessTimestamp ? moment(user.lastAccessTimestamp).format('LLL') : '---'}</h5>
                    </TableCell>
                    <TableCell>
                      <h5 style={{ margin: 0, color: '#3f3f3f' }}>{user.isPrivateUnitUser ? "" : user.lastOpenDoor ? `${user.lastOpenDoor.name}` : '---'}</h5>
                    </TableCell>
                    <TableCell>
                      <h5 style={{ color: '#3f3f3f' }}>{_.size(user.guestEvents)}</h5>
                    </TableCell>
                    {showCurrentArea && 
                      <TableCell>
                        {!_.size(currentAreasParsed)?(
                          isRefreshingAreasOccupancy&&!isRefreshingAccessChartData?(
                            <CircularProgress color="primary" className={classes.progressAreaOccupancy} />
                          ):(
                            <h5 style={{ color: '#3f3f3f' }}></h5>
                          )
                        ):(
                          _.map(currentAreasParsed, (currentArea) => {
                            return (
                              <Card key={currentArea.id} style={{padding:5,margin:5,display:'flex',alignItems:'center',justifyContent:'center',
                                backgroundColor:currentArea.active?GREEN:'grey',opacity:'0.7', width:'fit-content'}}>
                                <h5 style={{ margin: 0, color: '#3f3f3f', fontWeight:'bold', color:'white' }}>
                                  {`${currentArea.name?currentArea.name.substring(0,Math.min(currentArea.name.length,40)):""}
                                  ${currentArea.name&&currentArea.name.length>40?"...":""} ${currentArea.active?"":("("+L20NContext.getSync('areaUserExpired')+")")}`}
                                </h5>
                              </Card>
                            )
                          })
                        )}
                      </TableCell>
                    }
                  </TableRow>
                  )
                })}
              </TableBody>
              </Table>
            ) : <EmptyAnalyticsView />}
          </div>
          <div className={classes.refreshHeader}>
            <h5 style={{ color: '#3f3f3f', margin: 0, marginTop: 2 }}>{<Entity entity="lastUpdate" />}:{moment(lastUpdate).format('LLL')}</h5>
            <IconButton
              disabled={isRefreshingAccessChartData}
              style={{ opacity: isRefreshingAccessChartData ? 0.5 : 1 }}
              onClick={() => onRefreshMetric('smartLocksEvents')}
            >
              <RefreshIcon className={classes.refreshIcon} style={{ color: textColor || BLUE }} />
            </IconButton>
          </div>
          {isRefreshingAccessChartData ? <CircularProgress color="primary" className={classes.progress} /> : null}
        </Card>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(DailyActiveUsersList);
