// @ts-nocheck
import { Collapse } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Domain } from '@material-ui/icons';
import AtlasIcon from '@material-ui/icons/Airplay';
import SettingsIcon from '@material-ui/icons/BrightnessLow';
import HyperGateIcon from '@material-ui/icons/Cast';
import CardIcon from '@material-ui/icons/CreditCard';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import RegistryIcon from '@material-ui/icons/HdrWeak';
import InvitationsIcon from '@material-ui/icons/InsertLink';
import LockIcon from '@material-ui/icons/LockOpen';
import RolesIcon from '@material-ui/icons/Security';
import AnalyticsIcon from '@material-ui/icons/ShowChart';
import GatewayIcon from '@material-ui/icons/WifiTethering';
import { withStyles } from '@material-ui/styles';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
import { LICENSE_TYPES, PERMISSIONS, PERMISSION_ENTITIES, SUBSCRIPTION_TYPES } from '../_config/consts';
import { getPlantName } from '../_config/utils';
import AccessProfilesIconCustom from '../components/CustomIcons/AccessProfilesIconCustom.jsx';
import AdminEventsIconCustom from '../components/CustomIcons/AdminEventsIconCustom.jsx';
import AgendaIconCustom from '../components/CustomIcons/AgendaIconCustom.jsx';
import AreasManagementIconCustom from '../components/CustomIcons/AreasManagementIconCustom.jsx';
import BookeyAvailabilityIconCustom from '../components/CustomIcons/BookeyAvailabilityIconCustom.jsx';
import CameraIconCustom from '../components/CustomIcons/CameraIconCustom.jsx';
import CommunicationIconCustom from '../components/CustomIcons/CommunicationIconCustom.jsx';
import CopyIconCustom from '../components/CustomIcons/CopyIconCustom.jsx';
import DashboardIconCustom from '../components/CustomIcons/DashboardIconCustom.jsx';
import EventIconCustom from '../components/CustomIcons/EventIconCustom.jsx';
import InstallerOutlinedIconCustom from '../components/CustomIcons/InstallerOutlinedIconCustom.jsx';
import KeyOutlinedIconCustom from '../components/CustomIcons/KeyOutlinedIconCustom.jsx';
import ListBoxIconCustom from '../components/CustomIcons/ListBoxIconCustom.jsx';
import PackageCheckIconCustom from '../components/CustomIcons/PackageCheckIconCustom.jsx';
import LockersIconCustom from '../components/CustomIcons/LockersIconCustom.jsx';
import MapIconCustom from '../components/CustomIcons/MapIconCustom.jsx';
import MessageAlertIconCustom from '../components/CustomIcons/MessageAlertIconCustom.jsx';
import PackageIconCustom from '../components/CustomIcons/PackageIconCustom.jsx';
import PinIconCustom from '../components/CustomIcons/PinIconCustom.jsx';
import PlatformUserIconCustom from '../components/CustomIcons/PlatformUserIconCustom.jsx';
import PrivateUnitsIconCustom from '../components/CustomIcons/PrivateUnitsIconCustom.jsx';
import SmartLocksEventsIconCustom from '../components/CustomIcons/SmartLocksEventsIconCustom.jsx';
import SmartPhoneKeyIconCustom from '../components/CustomIcons/SmartPhoneKeyIconCustom.jsx';
import StoreOutlinedIconCustom from '../components/CustomIcons/StoreOutlinedIconCustom.jsx';
import SubcompanyEventIconCustom from '../components/CustomIcons/SubcompanyEventIconCustom.jsx';
import TagIconCustom from '../components/CustomIcons/TagIconCustom.jsx';
import UsersIconCustom from '../components/CustomIcons/UsersIconCustom.jsx';
import VisitorsIconCustom from '../components/CustomIcons/VisitorsIconCustom.jsx';
import Permission from '../components/Permission/Permission.jsx';
import AbilityProvider from '../permissionsUtils/AbilityProvider.js';
import * as UserActions from '../redux/actions/user.actions';
import * as UtilsActions from '../redux/actions/utils.actions';
import PickupPointIconCustom from '../components/CustomIcons/PickupPointIconCustom.jsx';


const styles = {
  icon: {
    fontSize: 23,
    width: 23,
  },
  iconSmall: {
    fontSize: 20,
    width: 20,
  },
  iconBig: {
    fontSize: 24,
    width: 24,
  },
  sidebar: {
    paddingBottom: 70,
    paddingTop: 0
  }
};

const MenuItemRenderer = ({ dispatch, item, emptyItem, renderCondition }) => {
  if (renderCondition) {
    return item;
  }
  return (emptyItem || <span />);
};

const MenuItem = ({ isSelected, title, icon, onClick, iconStyle, containerStyle }) => (
  <div className={`sidebar-row ${isSelected ? 'sidebar-row-active' : ''}`} style={{ ...containerStyle }}>
    <ListItem button selected={isSelected} style={{ borderRadius: 5, height: 50, margin: 0, padding: 0, paddingLeft: 20 }} onClick={() => onClick()}>
      {icon ? <ListItemIcon className={`sidebar-row-icon ${isSelected ? 'sidebar-row-icon-active' : ''}`} style={{ ...iconStyle }}>{icon}</ListItemIcon> : null}
      <h4 className={`sidebar-row-text ${isSelected ? 'sidebar-row-text-active' : ''}`}>{title}</h4>
    </ListItem>
  </div>
);

const CollapsibleMenu = ({ isOpen, headerIcon, headerTitle, children, onToggleOpen }) => (
  <div>
    <div className="sidebar-row">
      <ListItem button onClick={() => onToggleOpen(!isOpen)} style={{ borderRadius: 5, height: 50, margin: 0, padding: 0, paddingLeft: 20 }}>
        <ListItemIcon className="sidebar-row-icon">
          {headerIcon}
        </ListItemIcon>
        <h4 className="sidebar-row-text" style={{ margin: 0, marginRight: 10 }}>{headerTitle}</h4>
        {isOpen ? <ExpandLess style={{ marginLeft: 'auto', marginRight: 10 }} /> : <ExpandMore style={{ marginLeft: 'auto', marginRight: 10 }} />}
      </ListItem>
    </div>
    <Collapse in={isOpen} timeout="auto" unmountOnExit>
      <List component="div" disablePadding style={{ paddingLeft: 10 }}>
        {children}
      </List>
    </Collapse>
  </div>
);

@withRouter
@connect(state => ({ userToken: state.user.token, routing: state.router, userLicense: state.user.license, user: state.user, themeData: state.settings.items.theme.data, subcompanies: state.settings.subcompanies }))
class AppSidebar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isGatewayDropdownOpened: false,
      isEventsDropdownOpened: false,
      marketplaceCollapseOpen: false,
      isTicketingDropdownOpen: false,
      isParcelDropdownOpen: false,
    };
  }

  handleChange(e) {
    this._nav.search(e.target.value);
  }
  
  render() {
    const { dispatch, themeData, userLicense, subcompanies, location, user, classes } = this.props;
    const companyLogo = themeData && themeData.logo;
    const licenseType = userLicense && userLicense.domainProductType;
    const canReadGuest = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.USER);
    const canReadLocks = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.SMART_LOCK);
    const canReadGateways = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.GATEWAY);
    const canReadAccesRules = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.CREDENTIAL_RULE);
    const canReadCards = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.STANDARD_DEVICE);
    const canReadLogs = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.LOG);
    const canEditGuestsTags = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.USER_TAG);
    const canEditLocksTags = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.SMART_LOCK_TAG);
    const hideAccessRuleMenu =  (!canReadGuest && !canReadLocks && !canReadGateways && !canReadLogs && !canEditLocksTags && !canEditGuestsTags);
    const hideCredentialsRulesMenu = !canReadAccesRules && !canReadCards;
    const isInvitationPluginActive = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.INVITATIONS));
    const isBookeyActive = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.BOOKEY));
    const isTicketingManagementActive = dispatch(UtilsActions.isTicketingManagementActive());
    const isCapacityManagementActive = dispatch(UtilsActions.isCapacityManagementActive());
    const isItemRegistryPluginActive = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.REGISTRY));
    const isCommunicationPluginActive = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.COMMUNICATIONS));
    const isF9000PluginActive = dispatch(UserActions.isF9000AddonActive());
    const isMarketPlaceEnabled = dispatch(UserActions.isMarketPlaceEnabled());
    const isFakeCloudCredits = dispatch(UserActions.isFakeCloudCredits());
    const isCameraSectionActive = dispatch(UtilsActions.isEagleEyeIntegrationActive());
    const hasSharedLocks = dispatch(UtilsActions.hasSharedLocks());
    const isAccessProfileAddonActive = dispatch(UtilsActions.isAccessProfileAddonActive());
    const isVisitorsManagementActive = dispatch(UtilsActions.isVisitorsManagementActive());
    // TODO permissions
    const isPrivateUnitsActive = dispatch(UtilsActions.isPrivateUnitsActive())
    const isParcelManagementActive = dispatch(UtilsActions.isParcelManagementActive());
    const isBookeyCapacityActive = dispatch(UtilsActions.isBookeyCapacityActive());
    const isWalletPendingOrReady = dispatch(UserActions.isWalletPendingActivationOrReadyToActivate());

    const systemHasAtlas = user.systemHasAtlas;
    return (
      <div style={{ width: '100%' }}>
        <List component="nav" className={classes.sidebar}>
          {!hideAccessRuleMenu && (
            <div>
              <h5 className="sidebar-header-custom">
                <Entity entity="plantManagement" />
              </h5>
              <hr className="sidebar-separator" />
              <Permission withLicenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE]} abilityHelper={AbilityProvider.getAbilityHelper()}>
                <MenuItem
                  path="/dashboard"
                  icon={<DashboardIconCustom className={classes.icon} />}
                  title={<Entity entity="dashboard" />}
                  isSelected={location && location.pathname && location.pathname === '/dashboard'}
                  onClick={() => dispatch(push('/dashboard'))}
                />
              </Permission>
              <Permission withLicenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.LITE, LICENSE_TYPES.RFID]} do={[PERMISSIONS.READ]} on={PERMISSION_ENTITIES.USER} abilityHelper={AbilityProvider.getAbilityHelper()}>
                <MenuItem
                  path="/guests"
                  icon={<UsersIconCustom className={classes.icon} style={{ width: 24.5 }} />}
                  title={<Entity entity="guests" />}
                  isSelected={location && location.pathname && location.pathname === '/guests'}
                  onClick={() => dispatch(push('/guests'))}
                />
              </Permission>
              <Permission withLicenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.LITE, LICENSE_TYPES.RFID]} do={[PERMISSIONS.READ]} on={PERMISSION_ENTITIES.SMART_LOCK} abilityHelper={AbilityProvider.getAbilityHelper()}>
                <MenuItem
                  path="/locks"
                  icon={<LockIcon className={classes.icon} />}
                  title={<Entity entity="locks" />}
                  isSelected={location && location.pathname && location.pathname === '/locks'}
                  onClick={() => dispatch(push('/locks'))}
                />
              </Permission>
              <Permission withLicenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE]} abilityHelper={AbilityProvider.getAbilityHelper()}>
                {isCameraSectionActive ? (
                  <MenuItem
                    path="/cameras"
                    icon={<CameraIconCustom className={classes.icon} />}
                    iconStyle={{ width: 23 }}
                    title={<Entity entity="cameras" />}
                    isSelected={location && location.pathname && location.pathname === '/cameras'}
                    onClick={() => dispatch(push('/cameras'))}
                  />
                ) : null}
              </Permission>
              <Permission do={[PERMISSIONS.READ]} on={PERMISSION_ENTITIES.GATEWAY} withLicenses={[LICENSE_TYPES.LITE]} abilityHelper={AbilityProvider.getAbilityHelper()}>
                <MenuItem
                  path="/gateways"
                  icon={<HyperGateIcon className={classes.icon} />}
                  containerStyle={{ marginBottom: 1 }}
                  title={<Entity entity="hyperGatesPro" />}
                  isSelected={location && location.pathname && location.pathname === '/gateways'}
                  onClick={() => dispatch(push('/gateways'))}
                />
              </Permission>
              {systemHasAtlas ? (
                <Permission do={[PERMISSIONS.READ]} on={PERMISSION_ENTITIES.GATEWAY} withLicenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.RFID]} abilityHelper={AbilityProvider.getAbilityHelper()}>
                  <CollapsibleMenu
                    isOpen={this.state.isGatewayDropdownOpened}
                    headerIcon={<GatewayIcon className={classes.icon} />}
                    headerTitle={<Entity entity="gateways" />}
                    onToggleOpen={isOpen => this.setState({ isGatewayDropdownOpened: isOpen })}
                  >
                    <MenuItem
                      path="/gateways"
                      icon={<HyperGateIcon className={classes.icon} />}
                      iconStyle={{ fontSize: 22 }}
                      containerStyle={{ marginBottom: 1 }}
                      title={<Entity entity="hyperGatesPro" />}
                      isSelected={location && location.pathname && location.pathname === '/gateways'}
                      onClick={() => dispatch(push('/gateways'))}
                    />
                    <Permission do={[PERMISSIONS.READ]} on={PERMISSION_ENTITIES.GATEWAY} abilityHelper={AbilityProvider.getAbilityHelper()}>
                      <MenuItem
                        path="/atlas"
                        icon={<AtlasIcon className={classes.icon} />}
                        title={<Entity entity="hyperGatesAtlas" />}
                        isSelected={location && location.pathname && location.pathname === '/gatewaysAtlas'}
                        onClick={() => dispatch(push('/gatewaysAtlas'))}
                      />
                    </Permission>
                  </CollapsibleMenu>
                </Permission>
              ): (
                <Permission do={[PERMISSIONS.READ]} on={PERMISSION_ENTITIES.GATEWAY} withLicenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.RFID]} abilityHelper={AbilityProvider.getAbilityHelper()}>
                  <MenuItem
                    path="/gateways"
                    icon={<HyperGateIcon className={classes.icon} />}
                    iconStyle={{ fontSize: 22 }}
                    containerStyle={{ marginBottom: 1 }}
                    title={systemHasAtlas ? <Entity entity="hyperGatesPro" /> : 'HyperGates'}
                    isSelected={location && location.pathname && location.pathname === '/gateways'}
                    onClick={() => dispatch(push('/gateways'))}
                  />
                </Permission>
              )}
              {(canEditGuestsTags || canEditLocksTags) ?
                <Permission withLicenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.LITE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.RFID]} abilityHelper={AbilityProvider.getAbilityHelper()}>
                  <MenuItem
                    path="/tagsManagement"
                    icon={<TagIconCustom className={classes.icon} style={{ width: 27 }} />}
                    iconStyle={{ width: 30 }}
                    title={<Entity entity="tagsManagement" />}
                    isSelected={location && location.pathname && location.pathname === '/tagsManagement'}
                    onClick={() => dispatch(push('/tagsManagement'))}
                  />
                </Permission>
              : null}
              <Permission withLicenses={[LICENSE_TYPES.ENTERPRISE]} abilityHelper={AbilityProvider.getAbilityHelper()}>
                <MenuItem
                  path="/floorPlans"
                  icon={<MapIconCustom className={classes.icon} />}
                  iconStyle={{ fontSize: 28, width: 28 }}
                  title={<Entity entity="floorPlans" />}
                  isSelected={location && location.pathname && location.pathname === '/floorPlans'}
                  onClick={() => dispatch(push('/floorPlans'))}
                />
              </Permission>
              {isCapacityManagementActive && (
                <MenuItem
                  path="/areasManagement"
                  icon={<AreasManagementIconCustom className={classes.icon} style={{ width: 22 }} />}
                  title={<Entity entity="areasManagement" />}
                  isSelected={location && location.pathname && location.pathname === '/areasManagement'}
                  onClick={() => dispatch(push('/areasManagement'))}
                />
              )}
              {isPrivateUnitsActive && (
                <MenuItem
                  path="/privateUnits"
                  icon={<PrivateUnitsIconCustom className={classes.icon} style={{ width: 22 }} />}
                  title={<Entity entity="privateUnits" />}
                  isSelected={location && location.pathname && location.pathname === '/privateUnits'}
                  onClick={() => dispatch(push('/privateUnits'))}
                />
              )}
              {isParcelManagementActive && (
                <CollapsibleMenu
                  isOpen={this.state.isParcelDropdownOpen}
                  headerIcon={<PackageIconCustom className={classes.icon} style={{ marginTop: 2, width: 24 }} />}
                  headerTitle={<Entity entity="parcelManagement" />}
                  onToggleOpen={isOpen => this.setState({ isParcelDropdownOpen: isOpen })}
                >
                  <MenuItem
                    path="/deliveriesManagement"
                    icon={<PackageCheckIconCustom className={classes.icon} />}
                    title={<Entity entity="deliveries" />}
                    isSelected={location && location.pathname && location.pathname === '/deliveriesManagement'}
                    onClick={() => dispatch(push('/deliveriesManagement'))}
                  />
                  <MenuItem
                    path="/pickupPointsManagement"
                    icon={<PickupPointIconCustom className={classes.icon} style={{ marginTop: 2, width: 20 }} />}
                    title={<Entity entity="pickupPointsManagement" />}
                    isSelected={location && location.pathname && location.pathname === '/pickupPointsManagement'}
                    onClick={() => dispatch(push('/pickupPointsManagement'))}
                  />
                </CollapsibleMenu>
              )}
              {isVisitorsManagementActive ? (
                <Permission withLicenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE]} abilityHelper={AbilityProvider.getAbilityHelper()}>
                  <MenuItem
                    path="/visitorsManagement"
                    icon={<VisitorsIconCustom className={classes.icon} />}
                    title={<Entity entity="visitorsManagement" />}
                    iconStyle={{ width: 22 }}
                    isSelected={location && location.pathname && location.pathname === '/visitorsManagement'}
                    onClick={() => dispatch(push('/visitorsManagement'))}
                  />
                </Permission>
              ) : null}
              {isItemRegistryPluginActive ? (
                <Permission withLicenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE]} abilityHelper={AbilityProvider.getAbilityHelper()}>
                  <MenuItem
                    path="/registry"
                    icon={<RegistryIcon className={classes.icon} />}
                    title={<Entity entity="itemRegistry" />}
                    iconStyle={{ fontSize: 23 }}
                    isSelected={location && location.pathname && location.pathname === '/itemRegistry'}
                    onClick={() => dispatch(push('/itemRegistry'))}
                  />
                </Permission>
              ) : null}
              {isCommunicationPluginActive ? (
                <Permission withLicenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE]} abilityHelper={AbilityProvider.getAbilityHelper()}>
                  <MenuItem
                    path="/communications"
                    icon={<CommunicationIconCustom className={classes.icon} />}
                    iconStyle={{ width: 23 }}
                    title={<Entity entity="communications" />}
                    isSelected={location && location.pathname && location.pathname === '/communications'}
                    onClick={() => dispatch(push('/communications'))}
                  />
                </Permission>
              ) : null}
            </div>
          )}
          {isTicketingManagementActive && (
            <CollapsibleMenu
              isOpen={this.state.isTicketingDropdownOpen}
              headerIcon={<MessageAlertIconCustom className={classes.icon} style={{ marginTop: 2 }} />}
              headerTitle={<Entity entity="tickets" />}
              onToggleOpen={isOpen => this.setState({ isTicketingDropdownOpen: isOpen })}
            >
              <MenuItem
                path="/tickets"
                icon={<ListBoxIconCustom className={classes.icon} style={{ marginTop: 2 }} />}
                title={<Entity entity="ticketsList" />}
                isSelected={location && location.pathname && location.pathname === '/tickets'}
                onClick={() => dispatch(push('/tickets'))}
              />
              <MenuItem
                path="/ticketAnalytics"
                icon={<AnalyticsIcon className={classes.icon} />}
                title={<Entity entity="ticketAnalytics" />}
                isSelected={location && location.pathname && location.pathname === '/ticketAnalytics'}
                onClick={() => dispatch(push('/ticketAnalytics'))}
              />
            </CollapsibleMenu>
          )}
          {!hideCredentialsRulesMenu && (
            <div>
              <h5 className="sidebar-header-custom">
                <Entity entity="credentialRules" />
              </h5>
              <hr className="sidebar-separator" />
              <Permission do={[PERMISSIONS.READ]} on={PERMISSION_ENTITIES.CREDENTIAL_RULE} withLicenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.LITE]} abilityHelper={AbilityProvider.getAbilityHelper()}>
                <MenuItem
                  path="/credentials"
                  iconStyle={{ width: 24 }}
                  icon={<SmartPhoneKeyIconCustom className={classes.icon} />}
                  title={<Entity entity="smartphones" />}
                  isSelected={location && location.pathname && location.pathname === '/credentials'}
                  onClick={() => dispatch(push('/credentials'))}
                />
              </Permission>
              <Permission do={[PERMISSIONS.READ]} on={PERMISSION_ENTITIES.STANDARD_DEVICE} withLicenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.LITE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.RFID]} abilityHelper={AbilityProvider.getAbilityHelper()}>
                <MenuItem
                  path="/cards"
                  icon={<CardIcon className={classes.icon} />}
                  title={<Entity entity="cards" />}
                  isSelected={location && location.pathname && location.pathname === '/cards'}
                  onClick={() => dispatch(push('/cards'))}
                />
              </Permission>
              <Permission do={[PERMISSIONS.READ]} on={PERMISSION_ENTITIES.STANDARD_DEVICE} withLicenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.LITE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.RFID]} abilityHelper={AbilityProvider.getAbilityHelper()}>
                <MenuItem
                  path="/pins"
                  icon={<PinIconCustom className={classes.icon} />}
                  title={<Entity entity="pins" />}
                  isSelected={location && location.pathname && location.pathname === '/pins'}
                  onClick={() => dispatch(push('/pins'))}
                />
              </Permission>
              {isF9000PluginActive ? (
                <Permission do={[PERMISSIONS.READ]} withLicenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.RFID]} on={PERMISSION_ENTITIES.STANDARD_DEVICE} abilityHelper={AbilityProvider.getAbilityHelper()}>
                  <MenuItem
                    path="/hyperKeys"
                    icon={<KeyOutlinedIconCustom className={classes.icon} />}
                    title={<Entity entity="mechatronicsKey" />}
                    isSelected={location && location.pathname && location.pathname === '/hyperKeys'}
                    onClick={() => dispatch(push('/hyperKeys'))}
                  />
                </Permission>
              ) : null}
              <Permission withLicenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.LITE]} abilityHelper={AbilityProvider.getAbilityHelper()}>
                <MenuItemRenderer
                  renderCondition={isInvitationPluginActive}
                  item={
                    <MenuItem
                      path="/invitations"
                      icon={<InvitationsIcon className={classes.icon} />}
                      title={<Entity entity="invitationsTitle" />}
                      isSelected={location && location.pathname && location.pathname === '/invitations'}
                      onClick={() => dispatch(push('/invitations'))}
                    />
                  }
                />
              </Permission>
            </div>
          )}
          <Permission withLicenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.LITE]} abilityHelper={AbilityProvider.getAbilityHelper()}>
            <MenuItemRenderer
              renderCondition={isBookeyActive}
              emptyItem={<div />}
              item={
                <div>
                  <h5 className="sidebar-header-custom"><Entity entity="reservationsAndEvents" /></h5>
                  <hr className="sidebar-separator" />
                </div>
              }
            />
          </Permission>
          <Permission withLicenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.LITE]} abilityHelper={AbilityProvider.getAbilityHelper()}>
            <MenuItemRenderer
              renderCondition={isBookeyActive}
              item={
                <MenuItem
                  path="/resources"
                  icon={<CopyIconCustom className={classes.iconSmall} style={{ width: 21}} />}
                  title={<Entity entity="manageResources" />}
                  isSelected={location && location.pathname && location.pathname === '/resources'}
                  onClick={() => dispatch(push('/resources'))}
                />
              }
            />
          </Permission>
          {isBookeyCapacityActive ? (
            <Permission do={[PERMISSIONS.READ]} on={PERMISSION_ENTITIES.USER} withLicenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.LITE]} abilityHelper={AbilityProvider.getAbilityHelper()}>
              <MenuItemRenderer
                renderCondition={isBookeyActive}
                item={
                  <MenuItem
                    path="/availability"
                    icon={<BookeyAvailabilityIconCustom className={classes.icon} />}
                    title={<Entity entity="bookeyAvailability" />}
                    isSelected={location && location.pathname && location.pathname === '/availability'}
                    onClick={() => dispatch(push('/availability'))}
                  />
                }
              />
            </Permission>
          ) : null}
          <Permission do={[PERMISSIONS.READ]} on={PERMISSION_ENTITIES.USER} withLicenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.LITE]} abilityHelper={AbilityProvider.getAbilityHelper()}>
            <MenuItemRenderer
              renderCondition={isBookeyActive}
              item={
                <MenuItem
                  path="/reservations"
                  icon={<AgendaIconCustom className={classes.icon} />}
                  title={<Entity entity="reservations" />}
                  isSelected={location && location.pathname && location.pathname === '/reservations'}
                  onClick={() => dispatch(push('/reservations'))}
                />
              }
            />
          </Permission>
          <Permission withLicenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.LITE]} abilityHelper={AbilityProvider.getAbilityHelper()}>
            <MenuItemRenderer
              renderCondition={isBookeyActive}
              item={
                <MenuItem
                  path="/manageEvents"
                  icon={<EventIconCustom className={classes.icon} />}
                  title={<Entity entity="manageEvents" />}
                  isSelected={location && location.pathname && location.pathname === '/manageEvents'}
                  onClick={() => dispatch(push('/manageEvents'))}
                />
              }
            />
          </Permission>
          {/* <Permission withLicenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.LITE]} abilityHelper={AbilityProvider.getAbilityHelper()}>
            <MenuItemRenderer
              renderCondition={isBookeyActive}
              item={
                <MenuItem
                  path="/bookeyMaps"
                  icon={<MapIconCustom className={classes.icon} />}
                  title={<Entity entity="bookeyMaps" />}
                  isSelected={location && location.pathname && location.pathname === '/bookeyMaps'}
                  onClick={() => dispatch(push('/bookeyMaps'))}
                />
              }
            />
          </Permission> */}
          <Permission withLicenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.LITE]} do={[PERMISSIONS.READ]} on={PERMISSION_ENTITIES.USER} abilityHelper={AbilityProvider.getAbilityHelper()}>
            <MenuItemRenderer
              renderCondition={isBookeyActive}
              item={
                <MenuItem
                  path="/analytics"
                  icon={<AnalyticsIcon className={classes.icon} />}
                  title={<Entity entity="analytics" />}
                  isSelected={location && location.pathname && location.pathname === '/analytics'}
                  onClick={() => dispatch(push('/analytics'))}
                />
              }
            />
          </Permission>
          {canReadLogs && (
            <Permission withLicenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.LITE, LICENSE_TYPES.RFID]} do={[PERMISSIONS.READ]} on={PERMISSION_ENTITIES.USER} abilityHelper={AbilityProvider.getAbilityHelper()}>
              <div>
                <h5 className="sidebar-header-custom">
                  <Entity entity="manageEvents" />
                </h5>
                <hr className="sidebar-separator" />
                <Permission do={[PERMISSIONS.READ]} on={PERMISSION_ENTITIES.LOG} abilityHelper={AbilityProvider.getAbilityHelper()}>
                  <MenuItem
                    path="/adminEvents"
                    icon={<AdminEventsIconCustom className={classes.icon} />}
                    iconStyle={{ width: 24 }}
                    title={<Entity entity="adminEvents" />}
                    isSelected={location && location.pathname && location.pathname === '/adminEvents'}
                    onClick={() => dispatch(push('/adminEvents'))}
                  />
                </Permission>
                {hasSharedLocks ? (
                  <Permission do={[PERMISSIONS.READ]} on={PERMISSION_ENTITIES.SMART_LOCK} abilityHelper={AbilityProvider.getAbilityHelper()}>
                    <CollapsibleMenu
                      isOpen={this.state.isEventsDropdownOpened}
                      headerIcon={<SmartLocksEventsIconCustom className={classes.icon} />}
                      headerTitle={<Entity entity="doorEvents" />}
                      onToggleOpen={isOpen => this.setState({ isEventsDropdownOpened: isOpen })}
                    >
                      <MenuItem
                        path="/smartLockEvents"
                        iconStyle={{ width: 25 }}
                        icon={(
                          <div style={{ position: 'relative' }}>
                            <Domain className={classes.icon} style={{ position: 'absolute', bottom: 2, fontSize: 15, right: -7 }} />   
                            <SubcompanyEventIconCustom className={classes.icon} />
                          </div>
                        )}
                        title={<Entity entity={getPlantName()} />}
                        isSelected={location && location.pathname && location.pathname === '/smartLockEvents'}
                        onClick={() => dispatch(push('/smartLockEvents'))}
                      />
                      {_.map(subcompanies, subcompany => (
                        <MenuItem
                          path="/subcompaniesSmartLockEvents"
                          icon={(
                            <div style={{ position: 'relative' }}>
                              <Domain className={classes.icon} style={{ position: 'absolute', bottom: 2, fontSize: 15, right: -7 }} />   
                              <SubcompanyEventIconCustom className={classes.icon} />
                            </div>
                          )}
                          iconStyle={{ width: 24 }}
                          title={<Entity entity={subcompany.plantName} />}
                          isSelected={location && location.pathname && location.pathname === '/subcompaniesSmartLockEvents' && location.search === `?subcompanyId=${subcompany.id}`}
                          onClick={() => dispatch(push(`/subcompaniesSmartLockEvents?subcompanyId=${subcompany.id}`))}
                        />
                      ))}
                    </CollapsibleMenu>
                  </Permission>
                ) : (
                  <MenuItem
                    path="/smartLockEvents"
                    iconStyle={{ width: 25 }}
                    icon={<SmartLocksEventsIconCustom className={classes.icon} />}
                    title={<Entity entity="doorEvents" />}
                    isSelected={location && location.pathname && location.pathname === '/smartLockEvents'}
                    onClick={() => dispatch(push('/smartLockEvents'))}
                  />
                )}
              </div>
            </Permission>
          )}
          <h5 className="sidebar-header-custom">
            <Entity entity="platformManagement" />
          </h5>
          <hr className="sidebar-separator" />
          <Permission do={[PERMISSIONS.ALL]} withLicenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE]} on={PERMISSION_ENTITIES.ACCESS_PROFILES} abilityHelper={AbilityProvider.getAbilityHelper()}>
            <MenuItemRenderer
              renderCondition={isAccessProfileAddonActive}
              item={
                <MenuItem
                  path="/accessProfiles"
                  icon={<AccessProfilesIconCustom className={classes.icon} />}
                  title={<Entity entity="accessProfiles" />}
                  isSelected={location && location.pathname && location.pathname === '/accessProfiles'}
                  onClick={() => dispatch(push('/accessProfiles'))}
                />
              }
            />
          </Permission>
          <Permission do={[PERMISSIONS.READ]} on={PERMISSION_ENTITIES.USER} withLicenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.LITE, LICENSE_TYPES.RFID]} abilityHelper={AbilityProvider.getAbilityHelper()}>
            <MenuItem
              path="/platformUsers"
              iconStyle={{ width: 25 }}
              icon={<PlatformUserIconCustom className={classes.icon} />}
              title={<Entity entity="platformUsers" />}
              isSelected={location && location.pathname && location.pathname === '/platformUsers'}
              onClick={() => dispatch(push('/platformUsers'))}
            />
          </Permission>
          <Permission do={[PERMISSIONS.READ]} on={PERMISSION_ENTITIES.USER} withLicenses={[LICENSE_TYPES.ENTERPRISE, LICENSE_TYPES.ENGINE, LICENSE_TYPES.LITE, LICENSE_TYPES.RFID]} abilityHelper={AbilityProvider.getAbilityHelper()}>
            <MenuItem
              path="/installers"
              iconStyle={{ width: 25 }}
              icon={<InstallerOutlinedIconCustom className={classes.icon} style={{ width: 24 }} />}
              title={<Entity entity="installers" />}
              isSelected={location && location.pathname && location.pathname === '/installers'}
              onClick={() => dispatch(push('/installers'))}
            />
          </Permission>
          <Permission do={[PERMISSIONS.READ]} withLicenses={[LICENSE_TYPES.ENTERPRISE]} on={PERMISSION_ENTITIES.ROLE} abilityHelper={AbilityProvider.getAbilityHelper()}>
            <MenuItem
              path="/roles"
              iconStyle={{ fontSize: 23 }}
              icon={<RolesIcon className={classes.icon} />}
              title={<Entity entity="rolesAndPermissions" />}
              isSelected={location && location.pathname && location.pathname === '/roles'}
              onClick={() => dispatch(push('/roles'))}
            />
          </Permission>
          {isMarketPlaceEnabled && !isFakeCloudCredits ? (
            <MenuItem
              path="/luckeyStore"
              icon={<StoreOutlinedIconCustom className={classes.iconBig} />}
              title={<Entity entity="luckeyStore" />}
              isSelected={location && location.pathname && location.pathname === '/luckeyStore'}
              onClick={() => dispatch(push('/luckeyStore'))}
            />
          ) : null}
          <MenuItem
            path="/settings"
            icon={<SettingsIcon className={classes.icon} />}
            title={<Entity entity="settings" />}
            isSelected={location && location.pathname && (location.pathname === '/settings' || location.pathname === '/settings/octorate')}
            onClick={() => dispatch(push('/settings'))}
          />
          {licenseType !== LICENSE_TYPES.LITE && licenseType !== LICENSE_TYPES.RFID && companyLogo ? (
            <div style={{ paddingRight: 10, paddingBottom: 20, marginTop: 20, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              {companyLogo && <img src={companyLogo} alt="logo" style={{ width: 120, alignSelf: 'center' }} />}
            </div>
          ) : null}
        </List>
      </div>
    );
  }
}

export default withStyles(styles)(AppSidebar);