/* eslint-disable max-len */
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import FileSaver from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { change } from 'redux-form';
import * as XLSX from 'xlsx';
import { EXPORT_FORMATS, GREEN, MATCH_TAG_MODE, ORANGE, RED } from '../../_config/consts';
import * as formatter from '../../_config/formatter';
import * as RestService from '../../_config/rest';
import { saveDataToLocalStorage } from '../../_config/utils';
import * as ModalActions from './modal.actions';
import {
  SAVE_PRIVATE_UNITS,
  APPEND_PRIVATE_UNITS,
  SET_PRIVATE_UNITS_PAGINATION_DATA,
  RESET_PRIVATE_UNITS_PAGINATION_DATA,
  
  SAVE_PRIVATE_UNIT_STANDARD_DEVICES,

  SAVE_PRIVATE_UNITS_DEFAULT,

  SELECT_PRIVATE_UNIT,

  RESET_PRIVATE_UNITS_FILTERS,
  SET_PRIVATE_UNIT_FILTER,

  UPDATE_PRIVATE_UNIT,

  SET_PRIVATE_UNIT_OPERATIONAL_MODE,

  FETCH_PRIVATE_UNIT_LOCKS_BY_TAGS,
  CANCEL_FETCH_PRIVATE_UNIT_LOCKS_BY_TAGS,
  SET_PRIVATE_UNIT_LOCKS_FILTERED_BY_TAGS,
  APPEND_PRIVATE_UNIT_LOCKS_FILTERED_BY_TAGS,
  SET_PRIVATE_UNIT_LOCKS_FILTERED_BY_TAGS_PAGINATION_DATA,

  SAVE_ALL_PRIVATE_UNITS,

  SET_PRIVATE_UNIT_ORDER,

  SAVE_PRIVATE_UNIT_TEMPLATES,
  UPDATE_PRIVATE_UNIT_TEMPLATE,
} from './actionTypes/privateUnit';


export function savePrivateUnits(privateUnits) {
  return {
    type: SAVE_PRIVATE_UNITS,
    privateUnits
  };
}
export function appendPrivateUnits(privateUnits) {
  return {
    type: APPEND_PRIVATE_UNITS,
    privateUnits
  };
}
export function setPrivateUnitsPaginationData(pagination) {
  return {
    type: SET_PRIVATE_UNITS_PAGINATION_DATA,
    pagination,
  };
}
export function resetPrivateUnitsPaginationData() {
  return {
    type: RESET_PRIVATE_UNITS_PAGINATION_DATA,
  };
}


export function savePrivateUnitStandardDevices(standardDevices) {
  return {
    type: SAVE_PRIVATE_UNIT_STANDARD_DEVICES,
    standardDevices
  };
}


export function savePrivateUnitsDefault(privateUnitsDefault) {
  return {
    type: SAVE_PRIVATE_UNITS_DEFAULT,
    privateUnitsDefault
  };
}


export function selectPrivateUnit(privateUnit) {
  return {
    type: SELECT_PRIVATE_UNIT,
    privateUnit,
  };
}


export function setFilter(name, value) {
  return {
    type: SET_PRIVATE_UNIT_FILTER,
    name,
    value,
  };
}
export function resetPrivateUnitsFilters() {
  return { type: RESET_PRIVATE_UNITS_FILTERS };
}


export function setOperationalMode(value) {
  return {
    type: SET_PRIVATE_UNIT_OPERATIONAL_MODE,
    value,
  };
}


export function updatePrivateUnitOnState(privateUnit) {
  return {
    type: UPDATE_PRIVATE_UNIT,
    privateUnit,
  };
}


export function fetchLocksByTags(tags, lockTagMatchingMode, page = 0, append = false, index = 0) {
  return {
    type: FETCH_PRIVATE_UNIT_LOCKS_BY_TAGS,
    tags,
    index,
    lockTagMatchingMode,
    page,
    append,
  };
}
export function cancelFetchLocksByTags() {
  return {
    type: CANCEL_FETCH_PRIVATE_UNIT_LOCKS_BY_TAGS,
  };
}
export function setLocksFilteredByTags(locks, index = 0) {
  return {
    type: SET_PRIVATE_UNIT_LOCKS_FILTERED_BY_TAGS,
    index: index,
    locks,
  };
}
export function appendLocksFilteredByTags(locks, index = 0) {
  return {
    type: APPEND_PRIVATE_UNIT_LOCKS_FILTERED_BY_TAGS,
    index: index,
    locks,
  };
}
export function setLocksFilteredByTagsPaginationData(pagination, index) {
  return {
    type: SET_PRIVATE_UNIT_LOCKS_FILTERED_BY_TAGS_PAGINATION_DATA,
    index: index || 0,
    pagination,
  };
}


export function saveAllPrivateUnits(privateUnits) {
  return {
    type: SAVE_ALL_PRIVATE_UNITS,
    privateUnits
  };
}


export function setOrder(orderBy, orderDir) {
  return {
    type: SET_PRIVATE_UNIT_ORDER,
    orderBy,
    orderDir,
  };
}


export function savePrivateUnitTemplates(templates) {
  return {
    type: SAVE_PRIVATE_UNIT_TEMPLATES,
    templates
  };
}

export function updatePrivateUnitTemplateOnState(template) {
  return {
    type: UPDATE_PRIVATE_UNIT_TEMPLATE,
    template,
  };
}








export function fetchPrivateUnits(page = 0, append = false, pageSize = 20) {
  return async (dispatch, getState) => {
    try {
      const filters = structuredClone(getState().privateUnit.data.filters);
      const orderBy = getState().privateUnit.data.sorting.orderBy;
      const orderDir = orderBy?getState().privateUnit.data.sorting.orderDir:undefined;
      const params = {
        page,
        pageSize,
        ...filters,
        orderBy,
        orderDir,
      };
      const response = await RestService.fetchPrivateUnits(params);
      if (response && response.data && response.data.content) {
        const formattedPrivateUnits = _.map(response.data.content, privateUnit => formatter.formatInputData(formatter.PRIVATE_UNIT, { ...privateUnit }));
        if (append) {
          dispatch(appendPrivateUnits(formattedPrivateUnits));
        } else {
          dispatch(savePrivateUnits(formattedPrivateUnits));
        }
        dispatch(setPrivateUnitsPaginationData(_.omit(response.data, 'content')));
        return formattedPrivateUnits;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchAllPrivateUnits() {
  return async (dispatch, getState) => {
    try {
      const params = {
        page:0,
        pageSize:1000,
      };
      const response = await RestService.fetchPrivateUnits(params);
      if (response && response.data && response.data.content) {
        const formattedPrivateUnits = _.map(response.data.content, privateUnit => formatter.formatInputData(formatter.PRIVATE_UNIT, { ...privateUnit }));
        dispatch(saveAllPrivateUnits(formattedPrivateUnits));
        dispatch(setPrivateUnitsPaginationData(_.omit(response.data, 'content')));
        return formattedPrivateUnits;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function getPrivateUnitDetails(privateUnitId) {
  return async (dispatch, getState) => {
    try {
      const privateUnitResponse = await RestService.fetchPrivateUnitDetails(privateUnitId);
      if (privateUnitResponse && privateUnitResponse.data) {
        return formatter.formatInputData(formatter.PRIVATE_UNIT, privateUnitResponse.data);
      }
    } catch (error) {

    }
  };
}

export function fetchPrivateUnitsDefault() {
  return async (dispatch, getState) => {
    try {
      const privateUnitsDefaultResponse = await RestService.fetchPrivateUnitsDefault();
      if (privateUnitsDefaultResponse)
        dispatch(savePrivateUnitsDefault(privateUnitsDefaultResponse));
    } catch (error) {
      return {}
    }
  };
}

export function editPrivateUnitsDefault(data) {
  return async (dispatch, getState) => {
    try {
      const response = await RestService.editPrivateUnitsDefault(data);
      if (response) {
        dispatch(savePrivateUnitsDefault(data));
        return data;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function editPrivateUnit(privateUnitId,data) {
  return async (dispatch, getState) => {
    try {
      const formattedData = formatter.formatOutputData(formatter.PRIVATE_UNIT, data);
      const response = await RestService.editPrivateUnit(privateUnitId,formattedData);
      if (response && response.data) {
        const formattedPrivateUnit = formatter.formatInputData(formatter.PRIVATE_UNIT, { ...response.data });
        dispatch(updatePrivateUnitOnState({
          ...formattedPrivateUnit
        }));
        return formattedPrivateUnit;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function createPrivateUnit(data) {
  return async (dispatch, getState) => {
    try {
      const formattedData = formatter.formatOutputData(formatter.PRIVATE_UNIT, data);
      const response = await RestService.createPrivateUnit(formattedData);
      if (response && response.data) {
        const formattedPrivateUnit = formatter.formatInputData(formatter.PRIVATE_UNIT, { ...response.data });
        return formattedPrivateUnit;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function createPrivateUnitsBatch(data) {
  return async (dispatch, getState) => {
    try {
      const formattedData = formatter.formatOutputData(formatter.PRIVATE_UNIT, data);
      const response = await RestService.createPrivateUnitsBatch(formattedData);
      if (response && response.data) {
        const formattedPrivateUnit = formatter.formatInputData(formatter.PRIVATE_UNIT, { ...response.data });
        return formattedPrivateUnit;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function deletePrivateUnit(privateUnitId) {
  return async (dispatch, getState) => {
    try {
      const response = await RestService.deletePrivateUnit(privateUnitId);
      if (response && response.data) {
        const formattedPrivateUnit = formatter.formatInputData(formatter.PRIVATE_UNIT, { ...response.data });
        dispatch(updatePrivateUnitOnState({
          ...formattedPrivateUnit
        }));
        return formattedPrivateUnit;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function setPrivateUnitsViewMode(viewMode) {
  saveDataToLocalStorage('privateUnitsViewMode', viewMode);
}

export function setLocksFilteredByTagsEpic(locks, index, pagination, append = false) {
  return (dispatch, getState) => {
    if (append) {
      dispatch(appendLocksFilteredByTags(locks, index));
    } else {
      dispatch(setLocksFilteredByTags(locks, index));
    }
    dispatch(setLocksFilteredByTagsPaginationData(pagination, index));
  };
}

export function fetchLocksByTagsFromForm(page = 0, append = false, isFromDefaultForm = false) {
  return (dispatch, getState) => {
    const state = getState();
    const { form: { PrivateUnitForm: formOperational } } = state;
    const { form: { PrivateUnitsDefaultForm: formDefault } } = state;
    const form = isFromDefaultForm?formDefault:formOperational

    let lockTagMatchingMode = MATCH_TAG_MODE.EVERY_TAG;
    if (form && form.values && form.values.lockTagMatchingMode) {
      lockTagMatchingMode = form.values.lockTagMatchingMode;
    }
    let tags = form && form.values && form.values.lockTags ? form.values.lockTags : [];
    
    dispatch(fetchLocksByTags(tags, lockTagMatchingMode, page, append));
  };
}

export function assignStandardDeviceToPrivateUnit(privateUnitId,data) {
  return async (dispatch, getState) => {
    try {
      const response = await RestService.assignStandardDeviceToPrivateUnit(privateUnitId,data);
      if (response && response.data)
        return response.data;
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function removeStandardDeviceFromPrivateUnit(privateUnitId,standardDeviceId) {
  return async (dispatch, getState) => {
    try {
      const response = await RestService.removeStandardDeviceFromPrivateUnit(privateUnitId,standardDeviceId);
      if (response && response.data)
        return response.data;
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchPrivateUnitStandardDevices(privateUnitId) {
  return async (dispatch, getState) => {
    try {
      const params = {
        page: 0,
        pageSize: 1000,
      };
      const response = await RestService.fetchPrivateUnitStandardDevices(privateUnitId,params);
      if (response && response.data && response.data.content) {
        dispatch(savePrivateUnitStandardDevices(response.data.content));
        return response.data.content;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchPrivateUnitTemplates() {
  return async (dispatch, getState) => {
    try {
      const params = {
        page:0,
        pageSize:1000,
      };
      const response = await RestService.fetchPrivateUnitTemplates(params);
      if (response && response.data && response.data.content) {
        const formattedPrivateUnitTemplates = _.map(response.data.content, privateUnitTemplate => formatter.formatInputData(formatter.PRIVATE_UNIT_TEMPLATE, { ...privateUnitTemplate }));
        dispatch(savePrivateUnitTemplates(formattedPrivateUnitTemplates));
        return formattedPrivateUnitTemplates;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function editPrivateUnitTempalte(privateUnitTemplateId,data) {
  return async (dispatch, getState) => {
    try {
      const formattedData = formatter.formatOutputData(formatter.PRIVATE_UNIT_TEMPLATE, data);
      const response = await RestService.editPrivateUnitTemplate(privateUnitTemplateId,formattedData);
      if (response && response.data) {
        const formattedPrivateUnitTemplate = formatter.formatInputData(formatter.PRIVATE_UNIT_TEMPLATE, { ...response.data });
        dispatch(updatePrivateUnitTemplateOnState({
          ...formattedPrivateUnitTemplate
        }));
        return formattedPrivateUnitTemplate;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function createPrivateUnitTemplate(data) {
  return async (dispatch, getState) => {
    try {
      const formattedData = formatter.formatOutputData(formatter.PRIVATE_UNIT_TEMPLATE, data);
      const response = await RestService.createPrivateUnitTemplate(formattedData);
      if (response && response.data) {
        const formattedPrivateUnitTemplate = formatter.formatInputData(formatter.PRIVATE_UNIT_TEMPLATE, { ...response.data });
        return formattedPrivateUnitTemplate;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function deletePrivateUnitTemplate(privateUnitTemplateId) {
  return async (dispatch, getState) => {
    try {
      const response = await RestService.deletePrivateUnitTemplate(privateUnitTemplateId);
      if (response && response.data) {
        const formattedPrivateUnitTemplate = formatter.formatInputData(formatter.PRIVATE_UNIT_TEMPLATE, { ...response.data });
        return formattedPrivateUnitTemplate;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}