import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import MDButton from '../../../components/MDButton/MDButton.jsx';
import * as CredentialActions from '../../../redux/actions/credential.actions';
import * as TagActions from '../../../redux/actions/tag.actions';
import ReservationFormSlots from '../../../components/forms/Bookey/ReservationFormSlots.jsx';

@connect(state => ({ 
  userTags: state.tags.user.data, 
  user: state.user, 
  availabileResources: state.resources.availableResources, 
  resources: state.resources.data.content,
}))
class NewReservationViewSlots extends React.Component {

  componentWillMount() {
    const { dispatch } = this.props;
  }

  fetchGuestsByTags(page, append) {
    const { dispatch } = this.props;
    dispatch(CredentialActions.cancelFetchGuestsByTags());
    dispatch(CredentialActions.fetchGuestsByTagsSmartCredentialsForm(page, append));
  }

  filterGuestTags(value) {
    const { dispatch } = this.props;
    const includeSpecialTags = true;
    dispatch(TagActions.cancelFetchGuestTagsByFilter());
    const tagType = 'USER';
    dispatch(TagActions.fetchGuestTagsByFilter(value, 5, includeSpecialTags, tagType));
  }

  render() {
    const { userTags, onSubmitReservationForm, availabileResources, onChangeReservationUser, onSaveReservation, dateHasBeenChanged, onFetchMoreAvailableResources, user } = this.props;
    return (
      <div style={{ marginLeft: 0 }}>
        <ReservationFormSlots
          availabileResources={availabileResources}
          onSubmit={(values) => onSaveReservation(values)}
          onSubmitReservationForm={() => onSubmitReservationForm()}
          dateHasBeenChanged={(dateInterval, userTagId, numberOfPeople) => dateHasBeenChanged(dateInterval, userTagId, numberOfPeople)}
          onGuestTagsChange={() => this.fetchGuestsByTags()}
          onGuestInputChange={value => this.filterGuestTags(value)}
          availableUserTags={userTags}
          onFetchMoreAvailableResources={(page, pageSize, availableResourcesData, append) => onFetchMoreAvailableResources(page, pageSize, availableResourcesData, append)}
          onChangeReservationUser={(userTag) => {onChangeReservationUser(userTag)}}
          multidayEnabled={user.companyConfigurations?.multidayBookeyReservations || false}
        />
      </div>
    );
  }
} 

export default NewReservationViewSlots;
