// @ts-nocheck
import { CircularProgress, createTheme, withStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { BLUE, GREEN, LANGUAGE_DETAILS, TICKETS_DEFAULT_CATEGORIES } from '../../_config/consts';
import MessageAlertIconCustom from '../CustomIcons/MessageAlertIconCustom.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline';

const styles = theme => ({
  container: {
    overflowY: 'auto',
    height: '120vh',
    paddingLeft: 15,
    paddingBottom: 220,
    paddingRight: 20,
    paddingTop: 0,
  },
  rowContainer: {
    padding: 20,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    marginBottom: 15,
  },
  rowTitle: {
    fontWeight: 'bold',
    color: '#3f3f3f',
    margin: 0,
  },
  rowDescription: {
    color: '#3f3f3f',
    margin: 0,
    fontSize: 16,
  },
  rowBody: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 5,
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

const EmptyPrivateUnitTemplatesView = ({ onCreateNew }) => (
  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: 20, marginTop: 50, marginRight: 50 }}>
    <MessageAlertIconCustom style={{ color: '#bdbdbd', width: 50, alignSelf: 'center' }} />
    <h3 style={{ color: '#bdbdbd', fontWeight: 'bold', textAlign: 'center', marginTop: 0 }}><Entity entity="noPrivateUnitTemplatesFound" /></h3>
    <h4 style={{ color: '#bdbdbd', fontWeight: 'normal',lineHeight: '1.3em', textAlign: 'center', marginTop: 0 }}><Entity entity="privateUnitTemplatesDescription" /></h4>
    <MDButton
      title={<Entity entity="createNewPrivateUnitTemplate" />}
      titleStyle={{ marginTop: 0, fontSize: 13 }}
      backgroundColor={GREEN}
      containerStyle={{ margin: 0, marginTop: 10 }}
      onClick={() => onCreateNew()}
    />
  </div>
);

const PrivateUnitTemplateRow = ({ template, classes, onRowClick, onDeleteTemplateRequest }) => (
  <Card className={`card card-rounded ${classes.rowContainer}`} 
    style={{cursor:'pointer', width:500, marginLeft:0, marginRight:0}} 
    onClick={() => {onRowClick()}}
  >
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 10, paddingTop: 5, paddingBottom: 5 }}>
      <img src={`/imgs/app/flags/flags/flat/32/${LANGUAGE_DETAILS[template.language]? LANGUAGE_DETAILS[template.language].flag : "NOT_FOUND"}.png`} width='25' />
      <h4 style={{ fontSize: 14, margin: 0, color: '#3f3f3f' }} key={template.language||-1}>
        <Entity className='lang-menu-text' entity='languageMenu' data={{ lang: LANGUAGE_DETAILS[template.language]? LANGUAGE_DETAILS[template.language].lang : "NOT_FOUND"}} style={{ marginLeft: 20 }} />
      </h4>
      <div style={{flexGrow:1}}></div>
      <div style={{ justifyContent: 'flex-start', marginLeft: -15, display: 'flex', alignItems: 'center', }}>
        <Button
          onClick={(event) => {
            event.stopPropagation();
            onDeleteTemplateRequest();
          }}
          style={{color: '#E53935', fontSize: 12, fontWeight: 'bold', textDecoration: 'underline',}}
        >
          <Entity entity="delete" />
          <DeleteIcon style={{ fontSize: 20, marginLeft: 5 }} />
        </Button>
      </div>
    </div>
  </Card>
);

class PrivateUnitTemplateListView extends React.Component {
  render() {
    const { templatesData, onRowClick, onDeleteTemplateRequest, onCreateNew, classes } = this.props;
    return (
      <div className={classes.container}>
        <InfiniteScroll
          initialLoad={false}
          loadMore={() => {}}
          hasMore={false}
          useWindow={false}
          loader={
            <div style={{ marginTop: 0, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingVertical: 5, }}>
              <h4 style={{ textAlign: 'center', color: '#3f3f3f' }}><Entity entity="loadingLogs" /></h4>
              <CircularProgress className={classes.circularProgress} />
            </div>
          }
        >
          <div style={{display:'flex', flexDirection:'column', alignItems:'start'}}>
            {!_.isEmpty(templatesData) ?
              _.map(templatesData, (template) =>
                <PrivateUnitTemplateRow
                  key={template.id}
                  template={template}
                  classes={classes}
                  onRowClick={() => onRowClick(template)}
                  onDeleteTemplateRequest={() => onDeleteTemplateRequest(template)}
                />)
                : (
                  <div>
                    <EmptyPrivateUnitTemplatesView
                      onCreateNew={() => onCreateNew()}
                    />
                  </div>
                )
              }
          </div>
        </InfiniteScroll>
      </div>
    );
  }

}
export default withStyles(styles)(PrivateUnitTemplateListView);