import { ctx as L20NContext, Entity } from '@sketchpixy/rubix/lib/L20n';
import moment from 'moment';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import CustomField from '../Fields/CustomField.jsx';
import DateRangeView from '../Fields/DatePickers/DateRangePicker/DateRangeView.jsx';
import * as GuestActions from '../../../redux/actions/guest.actions.js';
import SelectableField from '../Fields/SelectableInput/SelectableField.jsx';
import SimpleExportMenu from '../../ExportMenus/SimpleExportMenu.jsx';
import MDButton from '../../MDButton/MDButton.jsx';
import { AddCircleOutline } from '@material-ui/icons';
import { GREEN, RED } from '../../../_config/consts.js';


const validate = (values) => {
  const errors = {};
  return errors;
};

@reduxForm({ form: 'VisitorsFilters', validate })
@connect(state => ({ form: state.form.VisitorsFilters }))
class VisitorsFilters extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      guestsOptions: [],
      focusedInput: null,
    }
  }

  async onFilterGuests(value) {
    const { dispatch } = this.props;
    const guests = await dispatch(GuestActions.fetchGuestsForSelector(value));
    const filterSingleRoleGuests = _.filter(guests, guest => _.size(guest.roles) === 1);
    const options = _.map(filterSingleRoleGuests, guest => ({ value: guest, label: guest.email }));
    this.setState({ guestsOptions: options });
    return options;
  }
  
  onSelectGuest(guest) {
    const { onSelectReferentUser } = this.props;
    if (guest) {
      if (onSelectReferentUser) onSelectReferentUser(guest.value);
    } else {
      if (onSelectReferentUser) onSelectReferentUser(null);
    }
  }

  render() {
    const { onDatesChanged, fromDate, toDate, onResetDateFilters, themeName, onSetFilter, onResetFilters, onNewVisitorButtonClicked, onExportElements } = this.props;
    const { guestsOptions, focusedInput } = this.state;
    const themeClass = `list-view-header bg-${themeName}-theme`;
    return (
      <div className={themeClass} style={{ display: 'flex', alignItems: 'center', width: '100%', height: 120 }}>
        <div>
          <DateRangeView
            id="visitors-date-selector"
            startDateId="visitors-date-selector-start"
            endDateId="visitors-date-selector-end"
            startDate={fromDate ? moment(fromDate).startOf('day') : moment().startOf('day')}
            endDate={toDate ? moment(toDate).endOf('day') : moment().endOf('day')}
            noBorder={false}
            endDatePlaceholderText="--/--/--"
            startDatePlaceholderText="--/--/--"
            minimumNight={0}
            style={{ marginTop: 50 }}
            focusedInput={focusedInput}
            onFocusChange={focusInput => this.setState({ focusedInput: focusInput })}
            onChange={({ startDate, endDate }) => onDatesChanged(startDate, endDate)}
            onResetOptionSelection={() => onResetDateFilters()}
            helpersOptions={[
              {
                title: L20NContext.getSync('today'),
                startDate: moment().startOf('day'),
                endDate: moment().endOf('day'),
              },
              {
                title: L20NContext.getSync('thisWeek'),
                startDate: moment().startOf('week'),
                endDate: moment().endOf('week'),
              },
              {
                title: L20NContext.getSync('thisMonth'),
                startDate: moment().startOf('month'),
                endDate: moment().endOf('month'),
              },
            ]}
          />
        </div>
        <Entity
          componentClass={Field}
          name="code"
          componentAttribute="placeholder"
          entity="visitorCode"
          component={CustomField}
          containerStyle={{ marginLeft: 45, marginTop: 15, width: 200 }}
          onHandleChange={value => onSetFilter('code', value)}
        />
        <Entity
          componentClass={Field}
          name="email"
          componentAttribute="placeholder"
          entity="email"
          component={CustomField}
          containerStyle={{ marginLeft: 45, marginTop: 15, width: 200 }}
          onHandleChange={value => onSetFilter('email', value)}
        />
        <div style={{ marginLeft: 40 }}>
          <Field
            name="guestTagSelectedSearch"
            component={SelectableField}
            noCache
            options={guestsOptions}
            placeholder={<Entity entity="contactEmail" />}
            style={{ marginTop: 3 }}
            containerstyle={{ minWidth: 220 }}
            onInputChange={value => this.onFilterGuests(value)}
            onSelect={tagData => this.onSelectGuest(tagData)}
          />
        </div>
        {onNewVisitorButtonClicked ? (
          <MDButton
            onClick={() => onNewVisitorButtonClicked()}
            backgroundColor={GREEN}
            containerStyle={{ margin: 0, marginTop: 0, marginLeft: 20 }}
            title={<Entity entity="addVisitor" />}
          />
        ) : null}
        <MDButton
          onClick={() => onResetFilters()}
          backgroundColor={RED}
          containerStyle={{ margin: 0, marginTop: 0, marginLeft: 20 }}
          title={<Entity entity="reset" />}
        />
        <SimpleExportMenu
          titleContainerStyle={{ marginLeft: 40, marginTop: 0 }}
          onExportLogs={format => onExportElements(format)}
        />
      </div>
    );
  }
} 

export default VisitorsFilters;
