import WarningIcon from '@material-ui/icons/Warning';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { ADMIN_EVENTS } from '../../../_config/consts.js';
import CommunicationEventRow from './CommunicationEventRow.jsx';
import GatewayCreationRow from './GatewayCreationRow.jsx';
import GatewayDeleteRow from './GatewayDeleteRow.jsx';
import InstallationModeDisabledRow from './InstallationModeDisabledRow.jsx';
import InstallationModeEnabledRow from './InstallationModeEnabledRow.jsx';
import InvitationCreationEventRow from './InvitationCreationEventRow.jsx';
import InvitationDeletionEventRow from './InvitationDeletionEventRow.jsx';
import ItemCreateRow from './ItemCreateRow.jsx';
import ItemDeleteRow from './ItemDeleteRow.jsx';
import ItemUpdateRow from './ItemUpdateRow.jsx';
import RuleCreatedEventRow from './RuleCreatedEventRow.jsx';
import RuleDeletedEventRow from './RuleDeletedEventRow.jsx';
import SmartLockCreationRow from './SmartLockCreationRow.jsx';
import SmartLockDeleteRow from './SmartLockDeleteRow.jsx';
import StandardDeviceCreateEventRow from './StandardDeviceCreateEventRow.jsx';
import StandardDeviceDeleteEventRow from './StandardDeviceDeleteEventRow.jsx';
import StandardDeviceUpdateEventRow from './StandardDeviceUpdateEventRow.jsx';
import TemporaryAccessEventRow from './TemporaryAccessEventRow.jsx';
import TicketCreatedEventRow from './TicketCreatedEventRow.jsx';
import TicketDeletedEventRow from './TicketDeletedEventRow.jsx';
import UserCreatedEventRow from './UserCreatedEventRow.jsx';
import UserDeleteEventRow from './UserDeleteEventRow.jsx';
import UserImpersonateEventRow from './UserImpersonateEventRow.jsx';
import UserUpdatedEventRow from './UserUpdatedEventRow.jsx';
import VisitorCreatedEventRow from './VisitorCreatedEventRow.jsx';
import VisitorDeletedEventRow from './VisitorDeletedEventRow.jsx';
import LockerCreatedEventRow from './LockerCreatedEventRow.jsx';
import LockerReservationCreatedEventRow from './LockerReservationCreatedEventRow.jsx';
import LockerReservationDeletedEventRow from './LockerReservationDeletedEventRow.jsx';
import LockerDeletedEventRow from './LockerDeletedEventRow.jsx';
import { isValidEmail } from '../../../_config/utils.js';

const rowComponents = {
  [ADMIN_EVENTS.USER_CREATION]: UserCreatedEventRow,
  [ADMIN_EVENTS.USER_UPDATE]: UserUpdatedEventRow,
  [ADMIN_EVENTS.USER_DELETION]: UserDeleteEventRow,
  [ADMIN_EVENTS.CREDENTIAL_RULE_CREATION]: RuleCreatedEventRow,
  [ADMIN_EVENTS.CREDENTIAL_RULE_DELETION]: RuleDeletedEventRow,
  [ADMIN_EVENTS.STANDARD_DEVICE_UPDATE]: StandardDeviceUpdateEventRow,
  [ADMIN_EVENTS.STANDARD_DEVICE_CREATION]: StandardDeviceCreateEventRow,
  [ADMIN_EVENTS.STANDARD_DEVICE_DELETION]: StandardDeviceDeleteEventRow,
  [ADMIN_EVENTS.USER_IMPERSONATE]: UserImpersonateEventRow,
  [ADMIN_EVENTS.INVITATION_CREATION]: InvitationCreationEventRow,
  [ADMIN_EVENTS.INVITATION_DELETION]: InvitationDeletionEventRow,
  [ADMIN_EVENTS.INSTALLATION_MODE_DISABLED]: InstallationModeDisabledRow,
  [ADMIN_EVENTS.INSTALLATION_MODE_ENABLED]: InstallationModeEnabledRow,
  [ADMIN_EVENTS.ITEM_CREATION]: ItemCreateRow,
  [ADMIN_EVENTS.ITEM_DELETION]: ItemDeleteRow,
  [ADMIN_EVENTS.ITEM_UPDATE]: ItemUpdateRow,
  [ADMIN_EVENTS.COMMUNICATION_DELETION]: CommunicationEventRow,
  [ADMIN_EVENTS.COMMUNICATION_CREATION]: CommunicationEventRow,
  [ADMIN_EVENTS.COMMUNICATION_UPDATE]: CommunicationEventRow,
  [ADMIN_EVENTS.TEMPORARY_ACCESS_CREATION]: TemporaryAccessEventRow,
  [ADMIN_EVENTS.TEMPORARY_ACCESS_DELETION]: TemporaryAccessEventRow,
  [ADMIN_EVENTS.SMART_LOCK_CREATION]: SmartLockCreationRow,
  [ADMIN_EVENTS.SMART_LOCK_DELETION]: SmartLockDeleteRow,
  [ADMIN_EVENTS.GATEWAY_CREATION]: GatewayCreationRow,
  [ADMIN_EVENTS.GATEWAY_DELETION]: GatewayDeleteRow,
  [ADMIN_EVENTS.VISITOR_CREATION]: VisitorCreatedEventRow,
  [ADMIN_EVENTS.VISITOR_DELETION]: VisitorDeletedEventRow,
  [ADMIN_EVENTS.TICKET_CREATION]: TicketCreatedEventRow,
  [ADMIN_EVENTS.TICKET_DELETION]: TicketDeletedEventRow,
  [ADMIN_EVENTS.LOCKER_CREATION]: LockerCreatedEventRow,
  [ADMIN_EVENTS.LOCKER_DELETION]: LockerDeletedEventRow,
  [ADMIN_EVENTS.LOCKER_RESERVATION_CREATION]: LockerReservationCreatedEventRow,
  [ADMIN_EVENTS.LOCKER_RESERVATION_DELETION]: LockerReservationDeletedEventRow,
};

export default class AdminEventRow extends React.Component {

  render() {
    const { event } = this.props;
    if (!event || !event.eventType) {
      return null;
    }
    const isAnonymized = event && !event.data || (event.data && !event.data.actor && !event.data.lock) || (event && event.data && event.data.entity && event.data.entity.email && !isValidEmail(event.data.entity.email));
    const privateUnitIdActor = event && event.data && event.data.actor && event.data.actor.privateUnitId
    // TODO PU serve fare anche privateUnitIdEntity? Magari è una lock privata della PU
    const isImpersonated = event && event.data && event.data.actor && event.data.actor.impersonateId;
    const eventParsed = structuredClone(event)
    if (privateUnitIdActor && eventParsed && eventParsed.data && eventParsed.data.actor) {
      eventParsed.data.actor = {
        ...eventParsed.data.actor,
        firstname: `PU ${L20NContext.getSync('guest')}`,
        lastname: '',
        email: '',
      }
    }
    const Component = rowComponents[event.eventType];
    return (
      <div style={{ borderBottom: '1px solid #d6d4d5' }}>
        {Component ? <Component event={eventParsed} isAnonymized={isAnonymized} privateUnitIdActor={privateUnitIdActor} /> : null}
        {isImpersonated ? (
          <div style={{ marginTop: -10, paddingBottom: 15, paddingLeft: 65, display: 'flex', alignItems: 'center' }}>
            <WarningIcon style={{ fontSize: 18, color: '#efa638', marginRight: 8 }} />
            <h5 style={{ color: '#efa638', margin: 0 }}>{<Entity entity="logFromImpersonation" />}</h5>
          </div>
        ): null}
      </div>
    );
  }
}
